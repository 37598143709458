<script>
export default {
  name: 'TableCmpt',
  props: {
    loading: Boolean,
    caseList: {
      type: Array,
      default: () => ([])
    },
    headerList: {
      type: Array,
      default: () => ([])
    },
    rowClassName: {
      type: [Function, String],
      default: ''
    }
  },
  render() {
    const { loading, caseList, headerList, rowClassName } = this

    return (
      <el-table
        v-loading={ loading }
        data={ caseList }
        show-header
        stripe
        border
        size='samll'
        row-class-name={ rowClassName }
      >
        {headerList.map(head => (
          <el-table-column
            type={ head.type || '' }
            prop={ head.prop || '' }
            label={ head.label || '-' }
            width={ head.width }
            align={ head.align || 'center' }
            header-align={ head.headerAlign || '' }
            min-width={ head.minWidth || '' }
            render-header={ head.renderHeader }
            formatter={ head.formatter || ((...args) => (args[2] || '-')) } // row, column, cellValue, index
          />
        ))}
      </el-table>
    )
  }
}
</script>
