<template>
  <div class="create-tree">
    <StatusBar  :title="treeTitle"/>
    <div class="create-tree-content">
      <div class="create-tree-content-relation">
        <div class="create-tree-label">
          <span class="required-icon">*</span>
          逻辑树关联关系:
        </div>
        <div class="create-tree-content-form">
          <el-form :inline="true" :model="form">
            <el-row>
              <el-col :span="24" class="all-code-form-field">
                <el-form-item label="品牌车型故障码:">
                  <el-cascader
                    :props="props"
                    v-model="form.carFaultCode"
                    style="width:300px;"
                  />
                </el-form-item>
                <el-form-item>
                  <el-button type="primary" @click="add">新增</el-button>
                </el-form-item>
              </el-col>
            </el-row>
          </el-form>
          <TableCmpt
            :case-list="tableData"
            :header-list="headerList"
          />
        </div>
      </div>
      <div class="create-tree-content-encode">
        <div class="create-tree-label">
          <span class="required-icon">*</span>
          逻辑树关联关系:
        </div>
        <div class="create-tree-content-form">
          <el-form :model="formEncode" label-width="100px">
            <el-form-item label="所属系统:" :rules="[{ required: true }]">
              <el-select
                style="width:120px;"
                v-model="formEncode.system"
                clearable
                placeholder="请选择"
              >
                <el-option
                  v-for="item in systemOptions"
                  :key="item.id"
                  :label="item.name"
                  :value="item.name"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="逻辑树类型:" :rules="[{ required: true }]">
              <el-select
                style="width:150px;"
                v-model="formEncode.treeType"
                clearable
                @change="treeTypeChange"
                :disabled="false"
                placeholder="请选择"
              >
                <el-option label="新建逻辑树" value="NEW" />
                <el-option label="复用已有逻辑树" value="COPY" />
              </el-select>

              <el-select
                v-if="formEncode.treeType === 'COPY'"
                v-model="formEncode.code"
                placeholder="请选择"
              >
                <el-option
                  v-for="item in treeTypes"
                  :key="item.key"
                  :label="item.value"
                  :value="item.key"
                />
              </el-select>
            </el-form-item>

            <el-form-item label="顺序码:">
              <label>待分配</label>
            </el-form-item>
          </el-form>
        </div>
      </div>

      <div class="create-tree-content-btns">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, reactive, computed, onMounted } from 'vue'
import { useTreeStore } from '@/store/tree'
import { useCarStore } from '@/store/car'
import { useUserStore } from '@/store/user'
import { useRouter, useRoute } from 'vue-router'
import StatusBar from '@/components/StatusBar'
import TableCmpt from '@/components/TableCmpt'
import { ElMessage } from 'element-plus'
import { uniqBy } from 'lodash'

const treeStore = useTreeStore()
const carStore = useCarStore()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const tableData = ref([])
let copyTreeId = null
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'carBrand',
    label: '品牌',
  },
  {
    prop: 'carModel',
    label: '车型',
  },
  {
    prop: 'faultCode',
    label: '故障码',
    formatter(row) {
      const code = row.faultCode
      return (
        <router-link target="_blank" to={`/faultCode/info?code=${code}&id=${row.id}`}>
          <div>{code}</div>  
        </router-link>
      )
    }
  },
  {
    prop: 'enabledTreeCode',
    label: '当前启用逻辑树',
  },
  {
    prop: 'priority',
    label: '优先级',
    align: 'center',
    formatter(row) {
      return (
        <el-select clearable v-model={row.priority}>
          {[1,2,3,4,5,6,7,8,9].map(n => {
            return (<el-option key={n} label={n} value={n} />)
          })}
        </el-select>
      )
    }
  },
  {
    prop: 'opts',
    label: '操作',
    align: 'center',
    formatter(row) {
      const del = () => {
        tableData.value = tableData.value.filter(item => item.id !== row.id)
      }
      return (<el-button type="danger" size="small" onClick={del}>删除</el-button>)
    }
  }
]
const systemOptions = computed(() => {
  const list = unref(tableData).reduce((pre, cur, index) => {
    if (index === 0) {
      formEncode.system = (cur.systems || [])[0]?.name
    }
    return pre.concat(cur.systems)
  }, [])

  return uniqBy(list, 'id')
})
const treeTitle = computed(() => {
  return `逻辑编写人: ${userStore?.userInfo?.userName}`
})
const treeTypes = ref([])
const relationsList = computed(() => {
  return unref(tableData).map(item => {
    return {
      faultCodeId: item.id,
      priority: item.priority,
    }
  })
})
const form = reactive({
  carFaultCode: null
})
const formEncode = reactive({
  system: '',
  treeType: 'NEW',
  code: '',
})
let optionsLevelValue = null
const props = {
  lazy: true,
  lazyLoad(node, resolve) {
    const { level, value } = node
    if(level === 0) {
      carStore.getCarBrandOptions().then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
    if (level === 1) {
      optionsLevelValue = value
      carStore.getCarModelOptions({
        carBrandId: value
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
    if (level === 2) {
      carStore.getCarFaultCodeOptions({
        carBrandId: optionsLevelValue,
        carModelId: value,
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
  },
}

onMounted(() => {
  const { query } = route
  if (query.type === 'COPY') {
    formEncode.treeType = query.type
    copyTreeId = query.id
    formEncode.code = query.physical
    getPhysicalTreeCodes()
  }
})

const add = () => {
  getRelationListForCreateTree()
}
const confirm = () => {
  if(!form.carFaultCode) {
    ElMessage.warning('请选择品牌车型故障码!')
    return
  }
  saveTree()
}
const cancel = () => {
  router.go(-1)
}
const treeTypeChange = (value) => {
  if (
    value === 'COPY' &&
    unref(treeTypes).length === 0
  ) {
    getPhysicalTreeCodes()
  }
}
async function saveTree() {
  try {
    const treeType = route.query?.type
    let systemCode = formEncode.system

    if (typeof systemCode === 'string') {
      systemCode = systemCode?.split('-')[0]
    }

    const result = await treeStore.saveTree({
      systemCode,
      sourceType: treeType || 'NEW',
      copyReleaseId: copyTreeId,
      physicalTreeCode: formEncode.code,
      authorId: userStore.userInfo?.id,
      relations: unref(relationsList),
    })

    await treeStore.saveTreeOperateRecord({
      treeId: result,
      operatorId: userStore.userInfo.id,
      operateType: 'CREATE',
      content: treeType ? `复制已有逻辑树${copyTreeId}` : `建立全新逻辑树${result}`,
    })

    ElMessage.success('创建成功')
    router.push(`/tree/show?id=${result}`)
  } catch (error) {
    ElMessage.error(error)
    console.error(error)
  }
}
async function getPhysicalTreeCodes() {
  try {
    const result =  await treeStore.getPhysicalTreeCodes()
    treeTypes.value = result?.map(
      item => ({ key: item.value, value: item.value })
    )
  } catch (error) {
    console.error(error)
  }
}
async function getRelationListForCreateTree() {
  try {
    const [
      carBrandId,
      carNodelId,
      carFaultCodeId
    ] = form.carFaultCode
    const result = await treeStore.getRelationListForCreateTree({
      carBrandId,
      carNodelId,
      carFaultCodeId,
    })
    tableData.value = tableData.value.concat(
      result.map(item => ({ ...item, priority: 2 }))
    )
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.create-tree {
  &-content {
    &-form {
      padding: 10px 70px;
    }

    &-btns {
      text-align: right;
      padding-right: 20px;
    }
  }

  &-label {
    font-size: 16px;
    font-weight: bold;
    margin: 30px;

    .required-icon {
      color: #f56c6c;
    }
  }
}
</style>