<template>
  <div class="tree-menu">
    <el-form-item :label="`${searchObj.label}:`">
      <el-select
        v-model="form[searchObj.keyName]"
        clearable
        :placeholder="`请选择${searchObj.label}`"
        @change="selectChanged"
      >
        <el-option
          v-for="item in searchData"
          :key="item.value"
          :label="item.comment"
          :value="item.value"
        />
      </el-select>
    </el-form-item>

    <el-tree
      class="tree-menu-tree"
      :data="TreeData"
      :props="defaultProps"
      :expand-on-click-node="false"
      @node-click="handleNodeClick"
    />
  </div>
</template>

<script setup>
import { ref, defineProps, reactive, onMounted, defineEmits } from 'vue'
import { useEnumSelectStore } from '@/store/enumSelect'
import { useTreeStore } from '@/store/tree'

const enumStore = useEnumSelectStore()
const treeStrore = useTreeStore()
const props = defineProps({
  searchObj: {
    type: Object,
    default: () => ({ label: '车身大类', keyName: 'keyWord' })
  },
  type: {
    type: String,
    default: '1'
  }
})
const emits = defineEmits(['treeClicked', 'selectChanged'])

const form = reactive({ keyWord: '' })
const searchData = ref(enumStore.VEHICLE_TYPE_LIST || enumStore.getVehicleType())

const handleNodeClick = data => {
  emits('treeClicked', { data, keyword: form[props.searchObj.keyName] })
}

const defaultProps = {
  children: 'children',
  label: 'label'
}

const TreeData = ref([])

onMounted(() => {
  getRuleSystemSparePartGroupList()
})

async function getRuleSystemSparePartGroupList() {
  try {
    const result = await treeStrore.getRuleSystemSparePartGroupList(String(props.type).split(','))
    const partData = treeRecursive(result)
    TreeData.value = partData

  } catch (error) {
    console.error(error)
  }
}

function treeRecursive(partData, pid = 0) {
  let cookieArr = [];

  partData.forEach(item => {
    if (parseInt(item.parentCode, 10) === parseInt(pid, 10)) {
      let children = treeRecursive(partData, item.code);

      if (children.length) {
        item.children = children;
      }

      item.label = item.name;
      delete item.name;
      cookieArr.push(item);
    }
  });
  return cookieArr;
}

const selectChanged = (value) => {
  emits('selectChanged', value)
}

</script>

<style lang="less" scoped>
.tree-menu {
  &-tree {
    height: 448px;
    overflow: auto;
  }
  // /deep/ .el-tree-node__label{
  //   width: 100%;
  //   white-space: nowrap;
  //   overflow: hidden;
  //   text-overflow: ellipsis;
  // }
}
</style>