<template>
  <div :class="['node-rect', isSelect ? 'select' : '']" @dblclick="select" @contextmenu.prevent="showContextMenu">
    <div class="node-remark">
      <IconFaultCode v-if="hasFaultCode" />
      <IconRemark v-if="hasRemark" />
    </div>
    {{ content }}
    <div v-if="show" class="node-contextmenu">
      <div
        class="node-contextmenu-item"
        v-for="item in menus"
        :key="item.value"
        @mousedown="selectContextMenu(item, node)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed, onMounted, onUnmounted } from 'vue'
import { ElMessage } from 'element-plus'
import IconFaultCode from '@/components/icons/IconFaultCode'
import IconRemark from '@/components/icons/IconRemark'

const getNode = inject('getNode')
const node = getNode()
const show = ref(false)
const isSelect = ref(false)
const menus = [
  {
    value: 'edit',
    label: '编辑',
  },
  {
    value: 'delete',
    label: '删除',
  },
]

node.on('node:select', ({ id }) => {
  if (node.id === id) {
    isSelect.value = true
  } else {
    isSelect.value = false
  }
})
node.on('node:remark', ({ id }) => {
  if (node.id === id) {
    hasRemark.value = node?.data?.nodeInfo?.hasRemark
  }
})

const content = computed(() => {
  const { nodeType, viewContent, outputContent } = node?.data?.nodeInfo || {}
  const txt = nodeType === 'RESULT' ? outputContent : viewContent
  return txt || ''
})

const hasFaultCode = ref(node?.data?.nodeInfo?.faultCodeSets?.length > 0)
const hasRemark = ref(node?.data?.nodeInfo?.hasRemark)

const select = () => {
  console.log('查看--self', node.data)
}
const showContextMenu = () => {
  if (node.data.opt === 'edit') {
    show.value = true
  }
}
const hideContextMenu = () => {
  show.value = false
}
const selectContextMenu = (menu, node) => {
  const { func, graph } = node.data
  if (menu.value === 'edit') {
    const { disabledEdit } = node.data.nodeInfo
    if (disabledEdit) {
      ElMessage.warning('该节点禁止编辑')
      return
    }
    func.nodeEdit(node, graph)
  }
  if (menu.value === 'delete') {
    const { disabledDelete } = node.data.nodeInfo
    if (disabledDelete) {
      ElMessage.warning('该节点禁止删除')
      return
    }
    func.nodeDelete(node, graph)
  }
}

onMounted(() => {
  window.addEventListener('mousedown', hideContextMenu)
  window.addEventListener('mousewheel', hideContextMenu)
})
onUnmounted(() => {
  window.removeEventListener('mousedown', hideContextMenu)
  window.removeEventListener('mousewheel', hideContextMenu)
})
</script>

<style lang="less" scoped>
.node-rect {
  width: 100%;
  height: 100%;
  text-align: center;
  border: 1px solid #a9abaf;
  padding: 10px 20px;
  word-break: break-all;
  position: relative;
  font-size: 14px;

  &.select {
    border: 1px solid #0193df;
  }
}

.node-remark {
  position: absolute;
  top: 5px;
  right: 5px;
  color: #0193df;
  cursor: pointer;

  & > .icon {
    width: 20px;
    height: 20px;
  }
}

.node {
  &-contextmenu {
    position: absolute;
    top: 0;
    right: -70px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    padding: 5px 0;
    background-color: white;
    border-radius: 4px;
    font-size: 13px;

    &-item {
      padding: 8px 20px;
      cursor: pointer;

      &:hover {
        background-color: #dedfe0;
      }
    }
  }
}
</style>
