<template>
  <div class="edit-tree">
    <TreeStatusBar
      :treeCode="treeIds.treeCode"
      :authorId="treeIds.authorId"
      :reviewerId="treeIds.reviewerId"
    >
      <template #tree-duration>
        <div>编辑时间：{{ durationH }}时{{ durationM }}分{{ durationS }}秒</div>
      </template>
    </TreeStatusBar>
    <div id="container"></div>
    <TreeInfoCard
      :treeCode="treeIds.treeCode"
      :faultCodes="treeRelationCodes"
      @info="treeDrawer = true"
    />
    <GraphTools @mousedown="dragGraph" />
    <TreeToolBar
      :env="userPartAuth ? 'show' : 'edit'"
      :duration="duration"
      @zoom="graphZoom($event, graph)"
      @action="toolBarAction"
    />
    <TreeInfoEdit
      v-model="treeDrawer"
      :id="treeIds.treeId"
      :codes="treeRelationCodes"
      @code="faultCodeHandler"
    />
    <NodeInfoEdit
      v-model="nodeDrawer"
      :node="targetNode"
      :auth="userPartAuth"
      @reset="resetGraph"

    />
    <EdgeInfoEdit
      v-model="edgeDrawer"
      :edge="targetEdge"
      :auth="userPartAuth"
    />
    <ContextMenu
      v-if="showContextMenu"
      :menus="EDIT_GRAPH_CONTEXT_MENU"
      :position="position"
      @select="selectContextMenu"
    />
  </div>
</template>

<script setup>
import { ref, unref, onMounted, onUnmounted, reactive, watchEffect } from 'vue'
import { debounce } from 'lodash'
import { Tree } from './Tree'
import {
  GRAPH_COMPONENT_NODES,
  EDIT_GRAPH_CONTEXT_MENU,
} from '@/utils/constants'
import { useTreeStore } from '@/store/tree'
import { useTreeInfo } from './hooks/treeInfo'
import { useToolBar } from './hooks/toolBar'
import { useEdgeEdit } from './hooks/edgeEdit'
import { useNodeEdit } from './hooks/nodeEdit'
import { useFaultCode } from './hooks/faultCode'
import TreeInfoCard from './components/TreeInfoCard'
import GraphTools from './components/GraphTools'
import TreeStatusBar from './components/TreeStatusBar'
import TreeInfoEdit from './components/TreeInfoEdit'
import NodeInfoEdit from './components/NodeInfoEdit'
import EdgeInfoEdit from './components/EdgeInfoEdit'
import ContextMenu from './components/ContextMenu'
import TreeToolBar from './components/TreeToolBar'
import { ElMessage } from 'element-plus'

let graph = null
const targetEdge = ref({})
const targetNode = ref({})
const treeDrawer = ref(false)
const showContextMenu = ref(false)
const treeStore = useTreeStore()
const position = reactive({ x: 0, y: 0 })
const {
  treeIds,
  userPartAuth,
  treeInfomation,
  treeSnapshot,
} = useTreeInfo({ treeStore })
const duration = ref(0)
const {
  nodeDrawer,
  treeMoveNode,
  treeSaveNode,
  treeRemoveNode,
} = useNodeEdit({ treeStore, treeIds })
const {
  edgeDrawer,
  treeRemoveFlow,
  treeSaveFlow,
} = useEdgeEdit({ treeStore, treeIds })
const {
  treeRelationCodes,
  faultCodeHandler,
} = useFaultCode({ treeStore, treeIds })
const {
  toolBarAction,
  graphZoom,
} = useToolBar({ treeStore, treeIds, resetGraph })

const selectContextMenu = ({ item }) => {
  const { edgeInfo } = unref(targetEdge).data
  const { value } = item

  if (value === 'edit') {
    const { disabledEdit } = edgeInfo
    if (disabledEdit) {
      ElMessage.warning('该边禁止编辑')
      return
    }
    edgeDrawer.value = true
  }
  if (value === 'delete') {
    const { disabledDelete } = edgeInfo
    if (disabledDelete) {
      ElMessage.warning('该边禁止删除')
      return
    }
    treeRemoveFlow(unref(targetEdge))
  }
}
const hideContextMenu = () => {
  showContextMenu.value = false
}

const durationH = ref(0)
const durationM = ref(0)
const durationS = ref(0)
const durationAdd = () => {
  duration.value++
}

watchEffect(() => {
  durationS.value = duration.value % 60
  durationM.value = parseInt(duration.value / 60) - (durationH.value * 60)
  durationH.value = parseInt(duration.value / 3600)
})

let durationTimer = null;
console.log(durationTimer)

onMounted(() => {
  init()
  window.addEventListener('mousedown', hideContextMenu)
  window.addEventListener('mousewheel', hideContextMenu)
  durationTimer = setInterval(durationAdd, 1000)
})
onUnmounted(() => {
  graphDestroy()
  window.removeEventListener('mousedown', hideContextMenu)
  window.removeEventListener('mousewheel', hideContextMenu)
  clearInterval('durationTimer')
  durationTimer = null
})

const init = async () => {
  const result = await treeSnapshot()
  createGraph(result)
}
const createGraph = data => {
  const container = document.getElementById('container')
  const {
    scrollWidth,
    scrollHeight
  } = container
  const {
    tree: {
      nodes,
      flows
    },
    nodeInfos,
    flowInfos
  } = data

  container.innerHTML = null

  graph = new Tree({
    graphConfig: {
      container,
      width: scrollWidth,
      height: scrollHeight || 500,
      dndBox: document.getElementById('dndBox'),
      connecting: {
        async validateEdge({ edge }) {
          const sourceCell = graph.getCellById(edge.source.cell)
          const { nodeInfo } = sourceCell.data

          if (nodeInfo.disabledAddSub) {
            edge.remove()
            return false
          }

          if (edge.target.x && edge.target.y) {
            const { x, y } = edge.target
            const node = graph.createNode({
              type: 'rect',
              x,
              y
            })
            const nodeId = await treeSaveNode({
              location: {
                ...edge.target,
                width: 300,
                height: 120,
              },
              node,
              nodeType: 'PROCESS',
              graph,
            })
            edge.target = {
              cell: nodeId
            }
          }
          treeSaveFlow(edge, graph)
          return true
        },
        allowBlank: true,
      },
    },
    flows,
    nodes,
    nodeInfos,
    flowInfos,
    opt: unref(userPartAuth) ? 'show' : 'edit',
    treeIds,
  })

  bindEvents(graph)
}
const unBindEvents = (graph) => {
  ([
    'edge:dblclick',
    'node:dblclick',
    'edge:contextmenu',
    'node:move',
  ]).forEach(item => graph?.off(item))

  const events = [
    'node:add',
    'node:edit',
    'node:delete'
  ]
  events.forEach(item => graph?.graph?.off(item))
}
const bindEvents = (graph) => {
  graph.on('edge:dblclick', ({ edge }) => {
    const { disabledEdit } = edge.data.edgeInfo
    if (disabledEdit) {
      ElMessage.warning('该边禁止编辑')
      return
    }
    targetEdge.value = edge
    edgeDrawer.value = true
  })
  graph.on('node:dblclick', ({ node }) => {
    const { disabledEdit } = node.data.nodeInfo
    if (disabledEdit) {
      ElMessage.warning('该节点禁止编辑')
      return
    }
    targetNode.value = node
    nodeDrawer.value = true
  })
  graph.graph.on('node:add', ({ node }) => {
    treeSaveNode({
      location: {
        ...node.position(),
        ...node.size(),
      },
      nodeType: GRAPH_COMPONENT_NODES[node.shape],
      node,
      graph,
    })
  })
  graph.on('node:move', debounce(({ node }) => {
    treeMoveNode({
      location: {
        ...node.position(),
        ...node.size(),
      },
      nodeCode: node.id,
    })
  }, 300))
  graph.graph.on('node:edit', ({ node }) => {
    targetNode.value = node
    nodeDrawer.value = true
  })
  graph.graph.on('node:delete', ({ node }) => {
    treeRemoveNode(node)
  })
  graph.on('edge:contextmenu', ({ point, edge }) => {
    Object.assign(position, point, edge)
    targetEdge.value = edge
    showContextMenu.value = true
  })
}
const dragGraph = e => {
  graph.startDrag(e)
}
const graphDestroy = () => {
  unBindEvents(graph)
  graph?.destroyed()
  graph = null
}
function resetGraph(result) {
  graphDestroy()
  treeInfomation.value = result
  createGraph(result)
}
</script>

<style lang="less" scoped>
.edit-tree, #container {
  position: relative;
  height: 100vh;
}
</style>