<template>
  <!-- 需要修改接口数据 -->
  <div class="collapse">
    <div class="collapse-header">
      <div class="collapse-header-title" @click="toggle">
        <el-icon v-if="show"><ArrowDown /></el-icon>
        <el-icon v-else><ArrowRight /></el-icon>
        <span>{{ title }}</span>
      </div>
      <div class="collapse-header-tools">
        <el-icon v-if="!disabledAdd" @click="add"><Plus /></el-icon>
        <!-- <el-icon @click="edit"><Edit /></el-icon>
        <el-icon @click="del"><Delete /></el-icon> -->
      </div>
    </div>
    <Transition>
      <div v-show="show" >
        <div class="collapse-content">
          <ItemInfoCmpt
            v-for="item in fieldListUnDisabled"
            :key="item.id"
            :item="item"
            :icon="itemCmptIcon"
            @select="updateState"
            @handleClick="handleClick"
          />
        </div>
        <div v-if="outerGroupConfig === 'wheelIndexItem'" class="collapse-content">
          <ItemInfoCmpt
            v-for="item in fieldListDisabled"
            :key="item.id"
            :item="item"
            :icon="'IconDisabled'"
            @select="updateState"
            @handleClick="handleClick"
          />
        </div>
      </div>
    </Transition>

    <el-dialog
      v-model="dialogVisible"
      :show-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="'新增' + title"
    >
      <el-form
        ref="ruleFormRef"
        :model="form"
        :rules="rules"
        :size="formSize"
        status-icon
        style="margin: 0 40px"
      >
        <el-form-item label="字段名称:" prop="name">
          <el-input v-model="form.name" maxlength="50" />
        </el-form-item>
        <div class="collapse-danger">请注意：索引一旦添加后无法删除</div>
      </el-form>
      <template #footer>
        <span>
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </span>
      </template>
    </el-dialog>

    <EvidenceEditCmpt v-model="evidenceDrawer" :info="evidenceInfo" :isEdit="true" />
  </div>
</template>

<script setup>
import { defineProps, ref, unref, reactive, defineEmits, computed } from 'vue'
import { useFieldsStore } from '@/store/fields'
import ItemInfoCmpt from '@/components/ItemInfoCmpt'
import EvidenceEditCmpt from '@/views/commonConfig/components/EvidenceEditCmpt.vue'
import { ElMessage } from 'element-plus'

const props = defineProps({
  item: Object,
  index: Number,
  title: String,
  deletable: Boolean,
  disabledAdd: Boolean,
  isCustomAdd: Boolean,
  hasEvidenceEditCmpt: Boolean,
  outerGroupConfig: String,
})
const fieldsStore = useFieldsStore()
const emits = defineEmits(['add'])
let isLoaded = false
const show = ref(false)
const dialogVisible = ref(false)
const evidenceDrawer = ref(false)
const form = reactive({ name: '' })
const formSize = ref('default')
const rules = reactive({
  name: [
    { required: true, message: '请输入字段名称', trigger: 'blur' },
  ]
})

const fieldList = ref([])
const itemCmptIcon = computed(() => {
  return props.deletable ? 'Close' : 'CircleCheck'
})
const fieldListUnDisabled = computed(() => {
  return fieldList.value.filter(item => !item.disabled)
})
const fieldListDisabled = computed(() => {
  return fieldList.value.filter(item => item.disabled)
})


const toggle = async () => {
  const status = unref(show)

  if (!isLoaded && !status) {
    let result;
    if (props.outerGroupConfig === 'wheelIndexItem') {
      result = await fieldsStore.getWheelIndexItemList({
        indexCategory: props.item.id
      })
    }

    fieldList.value = (result || []).map(item => ({
      label: item.indexName,
      id: item.indexId,
      disabled: item.disabled,
      original: item,
    }))
    isLoaded = true
  }

  show.value = !status
}

const add = () => {
  if (props.isCustomAdd) { // 有自定义的新增方法
    emits('add')
    return
  }
  dialogVisible.value = true;
}
// const edit = () => {
//   emits('edit', props.item, props.index)
// }
// const del = () => {
//   emits('delete', props.item, props.index)
// }
const updateState = (item) => {
  if (props.outerGroupConfig === 'wheelIndexItem') {
    updateStateWheelIndexItem(item)
  }
}

const confirm = async () => {
  try {
    let result;
    if (props.outerGroupConfig === 'wheelIndexItem') {
      result = await fieldsStore.saveWheelIndexItem({
        indexCategory: 'SUSPENSION_TYPE',
        indexName: form.name,
      })
    }

    fieldList.value.push({
      id: result,
      label: form.name,
    })

    ElMessage.success('添加成功')
    cancel()
  } catch (error) {
    ElMessage.error(error)
  }
}
const cancel = () => {
  dialogVisible.value = false;
  form.name = ''
}

async function updateStateWheelIndexItem(params) {
  try {
    await fieldsStore.updateStateWheelIndexItem({ 
      indexCategory: 'SUSPENSION_TYPE',
      indexId: params.id,
      disabled: Number(!params.disabled)
     })
    fieldList.value.forEach(item => {
      if (item.id === params.id) {
        item.disabled = Number(!item.disabled)
      }
    });
    ElMessage.success('状态修改成功')
  } catch (error) {
    ElMessage.error('状态修改失败')
    console.error(error)
  }
}

const evidenceInfo = ref({})
const handleClick = (item) => {
  if (!props.hasEvidenceEditCmpt) {
    return
  }
  console.log(item, 'handleClick')
  evidenceDrawer.value = true
}
</script>

<style lang="less" scoped>
.collapse {
  margin: 10px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #409eff;
    border-radius: 4px;
    padding: 5px 10px;
    color: white;

    &-title {
      display: flex;
      align-items: center;
      & > span {
        margin-left: 5px;
      }
    }

    &-tools {
      & > i {
        margin: 0 8px;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  &-danger{
    color: #f56c6c;
  }
}
</style>