<template>
  <StatusBar :fixed="true" :title="`逻辑树: ${treeCodeToString(treeCode)}`">
    <template #tree-info>
      <div class="edit-tree-info">
        <span>编写人: {{ editors.userName }}</span>
        <span>审核人: {{ checker.userName }}</span>
      </div>
    </template>
    <template #tree-duration><slot name="tree-duration"></slot></template>
    <template #tree-tools><slot></slot></template>
  </StatusBar>
</template>

<script setup>
import { defineProps, computed } from 'vue'
import { useUserInfo } from '../hooks/userInfo'
import StatusBar from '@/components/StatusBar'
import { treeCodeToString } from '@/utils'

const props = defineProps(['treeCode', 'authorId', 'reviewerId'])
const { getTargetUserInfo } = useUserInfo()
const editors = computed(() => {
  const { authorId } = props
  return getTargetUserInfo(authorId) || {}
})
const checker = computed(() => {
  const { reviewerId } = props
  return getTargetUserInfo(reviewerId) || {}
})
</script>

<style lang="less" scoped>
.edit-tree {
  &-info {
    margin-left: 20px;

    & > span {
      margin: 0 8px;
      white-space: nowrap;
    }
  }
}
</style>