<template>
  <div class="menu">
    <div :class="['menu-logo', isCollapse ? 'fold' : 'expand']">
      <img src="@/assets/images/logo.png" alt="">
      <span>德联</span>
    </div>
    <div :class="['menu-list', isCollapse ? 'fold' : 'expand']">
      <el-menu
        active-text-color="#ffd04b"
        background-color="#4e5a6e"
        :collapse="isCollapse"
        :default-active="currentPath"
        text-color="#fff"
      >
        <MenuItemCmp
          v-for="(item) in menu"
          :key="item"
          :index="item.path"
          :item="item"
        />
      </el-menu>
    </div>
    <div class="menu-opt">
      <span @click="toggle">
        <el-icon v-if="isCollapse"><Expand /></el-icon>
        <el-icon v-else><Fold /></el-icon>
      </span>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, onMounted, defineEmits, computed, defineProps } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/user'
import { MENU } from '@/utils/constants'
import { cloneDeep } from 'lodash'
import MenuItemCmp from '@/components/MenuItemCmp'

const props = defineProps({ menuType: String })
const emits = defineEmits(['toggle'])
const { userInfo } = useUserStore()
const router = useRouter()
const currentPath = ref('')
const isCollapse = ref(false)

function menuFilter(menus, roleList) {
  return menus.filter(item => {
    const { roles, children } = item

    if (children) {
      item.children = menuFilter(children, roleList)
    }

    if (!roles || roleList.some(role => roles.includes(role.id))) {
      return true
    }

    return false
  })
}

const menu = computed(() => {
  const { roleList } = userInfo
  const menuTypeFilter = cloneDeep(MENU).filter(item => item.typeValue === props.menuType)
  return menuFilter(menuTypeFilter, roleList)
})

onMounted(() => {
  currentPath.value = unref(router.currentRoute).path;
})

const toggle = () => {
  isCollapse.value = !isCollapse.value
  emits('toggle', unref(isCollapse))
}
</script>

<style lang="less" scoped>
.menu {
  display: flex;
  height: 100%;
  color: white;
  flex-direction: column;
  box-sizing: border-box;
  background-color: rgba(78, 90, 110, 1);

  &-logo {
    height: 50px;
    line-height: 50px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;

    & > img {
      height: 50px;
    }

    &.expand {
      transition: width .6s;
      width: 200px;

      & > span {
        display: none;
      }
    }

    &.fold {
      transition: width .6s;
      width: 65px;

      & > img {
        display: none;
      }
    }
  }

  &-list {
    flex: 1;

    &.expand {
      transition: width .6s;
      width: 200px;
    }
    &.fold {
      transition: width .6s;
      width: 65px;
    }

    :deep(.el-menu) {
      border-right: none;
    }

    &-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px;
    }
  }

  &-opt {
    font-size: 20px;
    text-align: right;
    padding-right: 20px;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }
}
</style>