<template>
  <div>
    <el-dialog
      v-model="value"
      title="预设字段"
      :show-close="false"
      :close-on-click-modal="false"
      width="70%"
    >
      <TransferCmpt :hasBorder="false" :searchCmpt="false" />

      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleSubmit">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import TransferCmpt from '@/views/commonConfig/components/TransferCmpt'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const handleClose = () => {
  emits('update:modelValue', false)
}
const handleSubmit = () => {
  handleClose()
}
</script>

<style lang="less" scoped>
</style>