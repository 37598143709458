<template>
  <div class="preset">
    <div class="preset-header">
      <el-row>
        <el-col :span="6">
          <div>
            悬挂形式:
            <span>{{ rowData.suspensionTypeName || '' }}</span>
          </div>
        </el-col>
        <el-col :span="6">
          <div>
            外倾角是否可调整:
            <span>{{ rowData.adjustable == 1 ? '是' : '否' }}</span>
          </div>
        </el-col>
        <el-col :span="2" :offset="10" style="text-align: right">
          <el-button plain @click="cancelEdit">返回</el-button>
        </el-col>
      </el-row>
    </div>

    <div class="preset-groups">
      <div class="collapse">
        <div class="collapse-header">
          <div class="collapse-header-title" @click="toggle">
            <el-icon v-if="collapseShow">
              <ArrowDown />
            </el-icon>
            <el-icon v-else>
              <ArrowRight />
            </el-icon>
            <span>对应决策信息库</span>
          </div>
          <div class="collapse-header-tools">
            <el-icon @click="collapseEdit">
              <Edit />
            </el-icon>
          </div>
        </div>
        <Transition>
          <div v-show="collapseShow">
            <div class="collapse-content">
              <div class="collapse-content-title">配件:</div>
              <ItemInfoCmpt
                v-for="item in partItems"
                :key="item.id"
                :item="item"
                icon="Close"
                iconColor="red"
                @select="updateState($event, 'PART')"
                @handleClick="handleClick"
              />
            </div>
            <div class="collapse-content-title">工时:</div>
            <div class="collapse-content">
              <ItemInfoCmpt
                v-for="item in laborItems"
                :key="item.id"
                :item="item"
                icon="Close"
                iconColor="red"
                @select="updateState($event, 'LABOR')"
                @handleClick="handleClick"
              />
            </div>
            <div class="collapse-content-title">工项:</div>
            <div class="collapse-content">
              <ItemInfoCmpt
                v-for="item in workItems"
                :key="item.id"
                :item="item"
                icon="Close"
                iconColor="red"
                @select="updateState($event, 'WORK')"
                @handleClick="handleClick"
              />
            </div>
          </div>
        </Transition>
      </div>
    </div>
    <el-row>
      <el-col
        :span="12"
        :key="group.id"
        v-for="(group, index) in damagedPartsDetails"
        class="preset-groups"
      >
        <DamagedParts
          :ref="(el) => setCollapseInfoCmptRef(el, group.value)"
          :title="group.label"
          :item="group"
          :index="index"
          :libraryInfo="libraryInfo"
          @addTag="addTag($event, group.value)"
          @removeTag="removeTag"
        />
      </el-col>
    </el-row>

    <el-dialog
      v-model="dialogAddGroupVisible"
      :show-close="false"
      :close-on-click-modal="false"
      width="87%"
    >
      <AddPartsHoursCmpt ref="addPartsHoursCmptRef" />
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="confirmAdd">确定</el-button>
          <el-button @click="cancelAdd">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, onMounted, defineEmits, defineProps, computed, reactive } from 'vue'
import DamagedParts from '@/views/commonConfig/components/DamagedParts.vue'
import ItemInfoCmpt from '@/components/ItemInfoCmpt'
import AddPartsHoursCmpt from '@/views/commonConfig/components/addPartsHoursCmpt.vue'
import { useWheelStore } from '@/store/wheelAlignment'
import { useEnumSelectStore } from '@/store/enumSelect'
import { ElMessage } from 'element-plus'

const wheelStore = useWheelStore()
const enumStore = useEnumSelectStore()
const props = defineProps({
  rowData: { type: Object }
})
const emits = defineEmits(['cancelEdit', 'refreshDetails'])
const dialogAddGroupVisible = ref(false)
const damagedPartsDetails = ref([])

onMounted(() => {
  getTagList()
})

let leftFrontWheelData = ref([])
let leftRearWheelData = ref([])
let rightFrontWheelData = ref([])
let rightRearWheelData = ref([])
const getTagList = async() => {
  const leftFrontWheelResult = await wheelStore.getWheelLibraryTag({
    suspensionType: props.rowData.suspensionType,
    adjustable: props.rowData.adjustable,
    damagedWheelPosition: 1,
  })
  leftFrontWheelData.value = formatData(leftFrontWheelResult)

  const leftRearWheelResult = await wheelStore.getWheelLibraryTag({
    suspensionType: props.rowData.suspensionType,
    adjustable: props.rowData.adjustable,
    damagedWheelPosition: 2,
  })
  leftRearWheelData.value = formatData(leftRearWheelResult)

  const rightFrontWheelDataResult = await wheelStore.getWheelLibraryTag({
    suspensionType: props.rowData.suspensionType,
    adjustable: props.rowData.adjustable,
    damagedWheelPosition: 4,
  })
  rightFrontWheelData.value = formatData(rightFrontWheelDataResult)

  const rightRearWheelResult = await wheelStore.getWheelLibraryTag({
    suspensionType: props.rowData.suspensionType,
    adjustable: props.rowData.adjustable,
    damagedWheelPosition: 8,
  })
  rightRearWheelData.value = formatData(rightRearWheelResult)

  damagedPartsDetails.value = setDamagedPartsDetails()
}

const setDamagedPartsDetails = () => {
  const details = []
  const DAMAGED_WHEEL_POSITION = enumStore.DAMAGED_WHEEL_POSITION || enumStore.getWheelEnumDamageWheelPosition()
  DAMAGED_WHEEL_POSITION.forEach((pos) => {
    let fieldList = []

    if (pos.value === '1') {
      fieldList = [ ...leftFrontWheelData.value ]
    } else if (pos.value === '2') {
      fieldList = [ ...leftRearWheelData.value ]
    } else if (pos.value === '4') {
      fieldList = [ ...rightFrontWheelData.value ]
    } else {
      fieldList = [ ...rightRearWheelData.value ]
    }

    details.push({
      label: pos.label,
      value: pos.value,
      fieldList
    })
  })

  return details
}

const formatData = (data) => {
  const result = []
  const { partItems, laborItems, workItems } = data

  partItems.forEach(part => {
    result.push({
      id: part.itemId,
      value: part.partCode,
      label: part.partName,
      itemType: 'PART',
      modelCode: part.itemId
    })
  })

  laborItems.forEach(labor => {
    result.push({
      id: labor.itemId,
      value: labor.laborCode,
      label: labor.laborName,
      itemType: 'LABOR',
      modelCode: labor.itemId
    })
  })

  workItems.forEach(item => {
    result.push({
      id: item.itemId,
      value: item.workCode,
      label: item.workName,
      itemType: 'WORK',
      modelCode: item.itemId
    })
  })

  return result
}

const cancelEdit = () => {
  emits('cancelEdit')
}
const collapseEdit = () => {
  dialogAddGroupVisible.value = true
}

const addPartsHoursCmptRef = ref(null)
const partTableData = ref(null)
const laborTableData = ref(null)
const repairTypeList = ref(null)
const workItemTableData = ref(null)
const partItemsData = ref([])
const laborItemsData = ref([])
const workItemsData = ref([])
const confirmAdd = async() => {
  partTableData.value = addPartsHoursCmptRef.value?.partTransferCmptRef?.tableDataRight
  laborTableData.value = addPartsHoursCmptRef.value?.laborTransferCmptRef?.tableDataRight
  repairTypeList.value = addPartsHoursCmptRef.value?.repairTypeList
  workItemTableData.value = addPartsHoursCmptRef.value?.workItemTransferCmptRef?.tableDataRight

  partItemsData.value = partTableData.value.map(part => {
    return {
      partCode: part.code,
      partName: part.name
    }
  })

  laborTableData.value.forEach(labor => {
    const { processingMode } = labor

    processingMode.forEach(mode => {
      let repairTypeName = ref(null)
      repairTypeList.value.forEach(repairType => {
        if (repairType.value === mode) {
          repairTypeName.value = repairType.label
        }
      })

      laborItemsData.value.push({
        laborCode: labor.code,
        laborName: labor.name,
        repairTypeCode: mode,
        repairTypeName: repairTypeName.value
      })
    });
  })

  workItemsData.value = workItemTableData.value.map(workItem => {
    return {
      workCode: workItem.modelCode,
      workName: workItem.name
    }
  })

  const params = {
    libraryId: props.rowData.id,
    partItems: partItemsData.value,
    laborItems: laborItemsData.value,
    workItems: workItemsData.value
  }

  try {
    await wheelStore.saveBatchWheelLibraryItem(params)
    dialogAddGroupVisible.value = false
    emits('refreshDetails', props.rowData.id) 
  } catch (error) {
    ElMessage.error(error)
  }
}
const cancelAdd = () => {
  dialogAddGroupVisible.value = false
}

const collapseShow = ref(true)
const toggle = async () => {
  collapseShow.value = !collapseShow.value
}
const partItems = computed(() => {
  return props.rowData.partItems.map(part => {
    return {
      ...part,
      label: part.partName,
      typeName: '配件',
      modelCode: part.itemId
    }
  })
})
const laborItems = computed(() => {
  return props.rowData.laborItems.map(labor => {
    return {
      ...labor,
      label: `${labor.laborName}+${labor.repairTypeName}`,
      typeName: '工时',
      modelCode: labor.itemId
    }
  })
})
const workItems = computed(() => {
  return props.rowData.workItems.map(item => {
    return {
      ...item,
      label: item.workName,
      typeName: '工项',
      modelCode: item.itemId
    }
  })
})
const libraryInfo = computed(() => {
  return [
    ...partItems.value,
    ...laborItems.value,
    ...workItems.value
  ]
})

const updateState = async (item, type) => {
  const { itemId } = item
  const params = {
    libraryId: props.rowData.id,
    itemType: type,
    itemId
  }
  await wheelStore.deleteWheelLibraryItem(params)
  ElMessage.success('删除成功')
  emits('refreshDetails', props.rowData.id)
}

const collapseInfoCmptRefs = reactive({})
const setCollapseInfoCmptRef = (el, type) => {
  if (el) {
    collapseInfoCmptRefs[type] = el;
  }
}

const addTag = async(data, value) => {
  const params = {
    libraryId: props.rowData.id,
    damagedWheelPosition: value,
    ...data
  }

  try {
    await wheelStore.webLibraryItemTagSaveBatch(params)

    const targetRef = collapseInfoCmptRefs[value];
    if (targetRef) {
      targetRef.cancel()
    }

    getTagList()
  } catch (error) {
    ElMessage.error(error)
  }
}

const removeTag = async(data) => {
  const params = {
    libraryId: props.rowData.id,
    itemType: data.itemType,
    itemId: data.id
  }

  await wheelStore.deleteWheelLibraryTag(params)
  ElMessage.success('删除成功')
  getTagList()
}
</script>

<style lang="less" scoped>
.preset {
  padding: 10px 0px;

  &-header {
    padding: 10px 10px;
  }
  &-opts {
    padding: 10px;
  }

  &-groups {
    padding: 0 10px;
  }

  &-form-btns {
    position: absolute;
    bottom: 20px;
    right: 30px;
    text-align: right;
  }

  /deep/ .el-dialog__header {
    padding: 0;
  }
  /deep/ .el-dialog__body {
    padding: 20px;
  }
}

.collapse {
  margin: 10px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #409eff;
    border-radius: 4px;
    padding: 5px 10px;
    color: white;

    &-title {
      display: flex;
      align-items: center;
      & > span {
        margin-left: 5px;
      }
    }

    &-tools {
      cursor: pointer;
      & > i {
        margin: 0 8px;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  &-danger {
    color: #f56c6c;
  }
}
</style>