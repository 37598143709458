import request from "@/utils/request"

/**
 * =============================
 *         整车数据接口            
 * =============================
 */
// 品牌查询
export function getVehicleBrand() {
  return request({
    url: `/v1/data/vehicle/open-api/brand`,
    method: 'get',
  })
}

// 厂商查询
export function getVehicleManufacturer(params) {
  return request({
    url: '/v1/data/vehicle/open-api/manufacturer',
    method: 'get',
    params
  })
}

// 车系查询
export function getVehicleSeries(params) {
  return request({
    url: '/v1/data/vehicle/open-api/series',
    method: 'get',
    params
  })
}

// 车组查询
export function getVehicleGroup(params) {
  return request({
    url: '/v1/data/vehicle/open-api/group',
    method: 'get',
    params
  })
}

// 车型查询
export function getVehicleModel(params) {
  return request({
    url: '/v1/data/vehicle/open-api/model',
    method: 'get',
    params
  })
}

// 获取系统-车身大类
export function getVehicleType() {
  return request({
    url: `/check/enum/VehicleType`,
    method: 'get'
  })
}

// 获取系统-配件分组查询
export function ruleSparePartGroupQuery(data) {
  return request({
    url: '/v1/data/vehicle/spare-part-manage/rule/spare-part-group/query',
    method: 'post',
    data
  })
}

// 获取系统-配件查询
export function ruleSparePartQuery(data) {
  return request({
    url: '/v1/data/vehicle/spare-part-manage/rule/spare-part/query',
    method: 'post',
    data
  })
}