<template>
  <div class="car-code">
    <div class="car-code-form">
      <el-form :inline="true" :model="form">
        <el-row>
          <el-col :span="24" class="car-code-form-field">
            <el-form-item label="品牌车型故障码:" class="car-code-form-group">
              <el-cascader :props="props" v-model="form.carFaultCode" style="width:300px;" />
            </el-form-item>
            <el-form-item label="状态:">
              <el-select v-model="form.status" clearable placeholder="请选择">
                <el-option
                  v-for="item in STATUS_FAULT_CODE"
                  :key="item.value"  
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button type="success" @click="operator">操作</el-button>
          </el-col>
          <el-col :span="12" class="car-code-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="car-code-table">
      <TableCmpt
        :case-list="tableData"
        :header-list="headerList"
      />
      <PaginationCmpt
        :current-page="page"
        :total="total"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, unref, reactive, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useCarStore } from '@/store/car'
import TableCmpt from '@/components/TableCmpt'
import PaginationCmpt from '@/components/PaginationCmpt'
import { STATUS_FAULT_CODE } from '@/utils/constants'

const router = useRouter()
const carStore = useCarStore()
const page = ref(1)
const total = ref(0)
const size = ref(10)
const tableData = ref([])
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: 60,
  },
  {
    prop: 'carBrand',
    label: '品牌',
  },
  {
    prop: 'carModel',
    label: '车型',
  },
  {
    prop: 'faultCode',
    label: '故障码',
    formatter(row) {
      const code = row.faultCode
      return (
        <router-link target="_blank" to={`/faultCode/info?code=${code}&id=${row.id}`}>
          <div>{code}</div>  
        </router-link>
      )
    }
  },
  {
    prop: 'systems',
    label: '系统',
    formatter(...args) {
      const systems = args[2]
      const sysTxt = systems.map(({ name }) => name).join(',')
      return (<div>{sysTxt}</div>)
    }
  },
  {
    prop: 'remark',
    label: '内容',
  },
  {
    prop: 'priority',
    label: '优先级',
  },
  {
    prop: 'invisible',
    label: '状态',
    formatter(...args) {
      const status = args[2]
      const target = STATUS_FAULT_CODE.find(
        item => item.value == status
      )
      return (<div>{target.label}</div>)
    }
  },
]
const form = reactive({
  carFaultCode: undefined,
  status: null,
})

onMounted(() => {
  getCarFaultCodeListInfo()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  search()
}
const search = () => {
  getCarFaultCodeListInfo()
}
const reset = () => {
  form.carFaultCode = null
  form.status = null
}
const operator = () => {
  router.push('/config/carCode/operate')
}
const currentChange = (value) => {
  page.value = value
  getCarFaultCodeListInfo()
}
let optionsLevelValue = null
const props = {
  lazy: true,
  lazyLoad(node, resolve) {
    const { level, value } = node
    if(level === 0) {
      carStore.getCarBrandList().then(list => {
        const nodes = list.map(item => ({
          value: item.id,
          label: item.name,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
    if (level === 1) {
      optionsLevelValue = value
      carStore.getCarModelList({
        carBrandId: value
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.id,
          label: item.name,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
    if (level === 2) {
      carStore.getCarFaultCodeList({
        carBrandId: optionsLevelValue,
        carModelId: value,
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.id,
          label: item.faultCode,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
  },
}
async function getCarFaultCodeListInfo() {
  try {
    const { carFaultCode, status } = form
    const carBrandModel = carFaultCode
                            ? unref(carFaultCode)
                            : []
    const result = await carStore.getCarFaultCodeListInfo({
      carBrandId: carBrandModel[0],
      carModelId: carBrandModel[1],
      carFaultCodeId: carBrandModel[2],
      invisible: +status,
      page: unref(page),
      size: unref(size),
    })
    tableData.value = result.records
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.car-code {
  padding: 10px 30px;

  &-form {
    &-field {
      padding: 10px 0;
    }

    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }
  }

  .wid-100 {
    width: 100px;
  }
}
</style>