import { ref, reactive, onBeforeMount } from "vue"
import { useRoute } from 'vue-router'
import { useUserStore } from '@/store/user'
import { ElMessage } from 'element-plus'

export function useTreeInfo({ treeStore }) {
  const { userInfo, getUserAllInfo } = useUserStore()
  const route = useRoute()
  const treeIds = reactive({
    treeId: null,
    releaseId: null,
    snapshotId: null,
    authorId: null,
    reviewerId: null,
    majorVersion: null,
    userInfo: userInfo,
    treeCode: null,
    lastModifiedTime: null,
    treeStatus: null,
    verifyStatus: null,
  })
  const treeInfomation = ref({})
  const userPartAuth = ref(false)

  async function treeSnapshot() {
    try {
      const result = await treeStore.treeSnapshot({
        treeId: treeIds.treeId,
        authorId: userInfo.id,
      })
      dispatchTreeData(result)
      return result
    } catch (error) {
      ElMessage.error(error)
    }
  }

  async function getTreeStructure() {
    try {
      const result = await treeStore.getTreeStructure({
        treeId: treeIds.treeId
      })
      dispatchTreeData(result)
      return result
    } catch (error) {
      ElMessage.error(error)
    }
  }

  function dispatchTreeData(result) {
    const {
      releaseId,
      snapshotId,
      majorVersion,
      treeStatus,
      verifyStatus,
      treeInfo: {
        authorId,
        reviewerId,
        treeCode,
        lastModifiedTime,
      }
    } = result
    const roleList = userInfo.roleList
    userPartAuth.value = (
      result.treeStatus === 30 &&
      roleList.some(role => [34].includes(role.id))
    )
    treeInfomation.value = result
    Object.assign(treeIds, {
      releaseId,
      snapshotId,
      authorId,
      reviewerId,
      majorVersion,
      treeCode,
      lastModifiedTime,
      treeStatus,
      verifyStatus,
    })
  }

  onBeforeMount(async () => {
    treeIds.treeId = +route.query.id
    await getUserAllInfo()
  })

  return {
    treeIds,
    userPartAuth,
    treeInfomation,
    treeSnapshot,
    getTreeStructure,
  }
}