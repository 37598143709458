<template>
  <div>
    <!-- 查看 证据 -->
    <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false" class="evidence">
      <div class="evidence-title">配件名称:</div>
      <div class="evidence-list">
        <div class="evidence-list-title">
          麦弗逊（单下臂）+是
        </div>
        <div class="evidence-list-label">证据内容:</div>
        <div class="evidence-list-txt">麦弗逊（单下臂）+是+左前轮+前方位+后倾角：异常数据＜最小值，外倾角：异常数据＜最大值</div>
        <div class="evidence-list-label">关联文件:</div>
        <div class="evidence-list-txt">文件1</div>
      </div>

      <el-button class="evidence-close" @click="close">关闭</el-button>
    </el-drawer>

  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  isEdit: Boolean,
  info: {
    type: Object,
    default: () => ({})
  }
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const close = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="less" scoped>
.evidence {
  &-title{
    font-size: 16px;
    font-weight: bold;
  }
  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 14px;
    margin: 5px 0;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &-list {
    border: 1px solid #bbb;
    border-bottom: none;
    margin-top: 15px;
    &-title {
      height: 30px;
      line-height: 30px;
      text-align: center;
      border-bottom: 1px solid #bbb;
      background-color: #409eff;
      color: #fff;
      font-size: 14px;
      position: relative;
    }
    &-label {
      font-size: 14px;
      font-weight: bold;
      margin: 3px 10px 0;
    }
    &-txt {
      font-size: 14px;
      border-bottom: 1px solid #bbb;
      padding: 3px 10px;
    }
  }
}
</style>