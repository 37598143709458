export const MENU_TYPE = [
  { label: '故障诊断', value: 'carCode_treeCode' },
  { label: '四轮定位', value: 'wheelAlignment' },
]

export const MENU = [
  {
    name: '通用配置',
    path: '/config',
    icon: 'IconSetting',
    typeLabel: '故障码-逻辑树',
    typeValue: 'carCode_treeCode',
    children: [
      {
        name: '品牌-车型-故障码',
        path: '/config/carCode/brand',
        roles: [32, 33],
      },
      {
        name: '预设字段配置',
        path: '/config/carCode/preset',
        roles: [32, 33],
      },
    ]
  },
  {
    name: '通用配置',
    path: '/config',
    icon: 'IconSetting',
    typeLabel: '四轮定位',
    typeValue: 'wheelAlignment',
    children: [
      {
        name: '索引枚举配置',
        path: '/config/wheelAlignment/index',
        roles: [40, 41],
      },
      {
        name: '车型-悬挂形式配置',
        path: '/config/wheelAlignment/suspension',
        roles: [39, 40, 41, 42],
      },
      {
        name: '决策信息库配置',
        path: '/config/wheelAlignment/partsHours',
        roles: [39, 40, 41, 42],
      },
      {
        name: '预设字段配置',
        path: '/config/wheelAlignment/Preset',
        roles: [40, 41],
      },
    ]
  },
  {
    name: '汽车故障诊断',
    path: '/audit',
    icon: 'IconCar',
    typeLabel: '故障码-逻辑树',
    typeValue: 'carCode_treeCode',
    children: [
      {
        name: '全量故障码信息',
        path: '/audit/carCodeLib/all',
        roles: [34, 35, 36],
      },
      {
        name: '全量逻辑树信息',
        path: '/audit/treeCodeLib/all',
        roles: [34, 35, 36],
      },
      {
        name: '待审核逻辑树信息',
        path: '/audit/treeCodeLib/audit',
        roles: [34],
      },
      {
        name: '我的逻辑树信息',
        path: '/audit/myTree/info',
        roles: [35],
      },
    ]
  },
  {
    name: '四轮定位索引',
    path: '/wheelAlignment/index',
    icon: 'IconIndex',
    typeLabel: '四轮定位',
    typeValue: 'wheelAlignment',
    children: [
      {
        name: '全量索引信息',
        path: '/wheelAlignment/index/all',
        roles: [40, 41, 42],
      },
      {
        name: '无效索引管理',
        path: '/wheelAlignment/index/invalid',
        roles: [40],
      },
    ]
  },
  {
    name: '四轮定位决策',
    path: '/wheelAlignment/decision',
    icon: 'IconSetting',
    typeLabel: '四轮定位',
    typeValue: 'wheelAlignment',
    children: [
      {
        name: '全量决策信息',
        path: '/wheelAlignment/decision/all',
        roles: [40, 41, 42],
      },
      {
        name: '待审核决策信息',
        path: '/wheelAlignment/decision/audit',
        roles: [40],
      },
      {
        name: '我的决策信息',
        path: '/wheelAlignment/decision/my',
        roles: [41],
      },
    ]
  },
  // { 因时间不够，暂时不做
  //   name: '四轮定位备注',
  //   path: '/wheelAlignment/remark',
  //   icon: 'IconRemark1',
  //   children: [
  //     {
  //       name: '索引备注信息',
  //       path: '/wheelAlignment/remark/info',
  //       roles: [39, 40, 41, 42],
  //     },
  //   ]
  // },
  // {
  //   name: '操作日志',
  //   path: '/logs',
  //   children: [
  //     {
  //       name: '操作日志',
  //       path: '/logs',
  //     }
  //   ]
  // }
]

// 故障码状态
export const STATUS_FAULT_CODE = [
  {
    value: '0',
    label: '显示'
  },
  {
    value: '1',
    label: '隐藏'
  },
]

// 逻辑树状态
export const STATUS_TREE = [
  {
    label: '待提交',
    value: 10,
  },
  {
    label: '待审核',
    value: 20,
  },
  {
    label: '审核通过',
    value: 30,
  },
  {
    label: '被退回',
    value: 40,
  },
]

// 逻辑树状态
export const STATUS_TREE_ABLED = [
  {
    label: '未上线',
    value: 10,
  },
  {
    label: '已启用',
    value: 20,
  },
  {
    label: '已停用',
    value: 30,
  },
]

// 图形组件类型对应关系
export const GRAPH_NODE_COMPONENTS = {
  'ROOT': 'custom-node-ellipse',
  'RESULT': 'custom-node-ellipse',
  'PROCESS': 'custom-node-rect',
  'OUTPUT_OR_PROCESS': 'custom-node-paralle',
}

// 节点类型
export const GRAPH_COMPONENT_NODES = {
  'custom-node-ellipse': 'RESULT',
  'custom-node-rect': 'PROCESS',
  'custom-node-paralle': 'OUTPUT_OR_PROCESS',
}

// 节点名映射
export const GRAPH_NODES_TYPE_NAME = {
  'ROOT': '根节点',
  'RESULT': '结果节点',
  'PROCESS': '过程节点',
  'OUTPUT_OR_PROCESS': '输出+过程节点',
}

// 编辑页面右键菜单
export const EDIT_GRAPH_CONTEXT_MENU = [
  {
    value: 'edit',
    label: '编辑',
  },
  {
    value: 'delete',
    label: '删除',
  },
]

// 查看页面右键菜单
export const SHOW_GRAPH_CONTEXT_MENU = [
  {
    value: 'check',
    label: '重复校验',
  },
]

// 默认节点数据
export const NODE_DEFAULT_DATA = {
  node: {
    nodeCode: '',
    location: {
      x: 0,
      y: 0,
      height: 120,
      width: 300
    },
  },
  nodeInfo: {
    nodeCode: '',
    nodeType: '',
    viewContent: null,
    faultCodeSets: null,
    outputContent: null,
    outputType: null,
    conclusionId: null,
    conclusionPaddings: [],
    partIds: null,
    attributes: {},
    disabledEdit: null,
    disabledDelete: null
  }
}

// 默认边数据
export const EDGE_DEFAULT_DATA = {
  edge: {
    flowCode: '',
    fromNodeCode: '',
    toNodeCode: '',
    locations: []
  },
  edgeInfo: {
    flowCode: '',
    viewContent: '',
    disabledEdit: null,
    disabledDelete: null
  }
}

// 是否可调整
export const ADJUSTABLE_MENU = [
  {
    value: '0',
    label: '否',
  },
  {
    value: '1',
    label: '是',
  },
]

// 决策状态
export const DECISION_STATE = [
  {
    value: 'TO_BE_SUBMITTED',
    label: '待提交',
  },
  {
    value: 'TO_BE_REVIEWED',
    label: '待审核',
  },
  {
    value: 'REVIEWED_APPROVED',
    label: '审核通过',
  },
  {
    value: 'REVIEWED_REJECTED',
    label: '已退回',
  },
]

// 决策层数
export const DECISION_LAYERS = [
  {
    value: '1',
    label: '1',
  },
  {
    value: '2',
    label: '2',
  },
  {
    value: '3',
    label: '3',
  },
  {
    value: '4',
    label: '4',
  },
  {
    value: '5',
    label: '5',
  },
  {
    value: '6',
    label: '6',
  },
  {
    value: '7',
    label: '7',
  },
  {
    value: '8',
    label: '8',
  },
  {
    value: '9',
    label: '9',
  },
]

// 决策启用状态
export const RELATION_STATE = [
  {
    value: 'OFFLINE',
    label: '未上线',
  },
  {
    value: 'ENABLED',
    label: '启用',
  },
  {
    value: 'DISABLED',
    label: '停用',
  },
]

// 决策方案类型
export const DECISION_PLAN_TYPE = [
  {
    value: 'REASONABLE',
    label: '合理数据',
  },
  {
    value: 'UNREASONABLE',
    label: '不合理数据',
  },
]

// 前转向横拉杆拉杆球头形状
export const SHAPE_TYPE = [
  {
    value: '0',
    label: '弯',
  },
  {
    value: '1',
    label: '直',
  },
]
