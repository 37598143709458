<template>
  <div class="my-tree">
    <div class="my-tree-form">
      <el-form :inline="true" :model="form" label-width="128px" label-position="left">
        <el-row>
          <el-col :span="6">
            <el-form-item label="索引启用情况:">
              <el-radio-group v-model="form.suspensionTypeDisabled">
                <el-radio label="1" size="large">启用</el-radio>
                <el-radio label="0" size="large">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="悬挂形式:" prop="suspensionType">
              <el-select v-model="form.suspensionType" clearable filterable placeholder="请选择悬挂形式">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                  :disabled="form.suspensionTypeDisabled == item.disabled"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外倾角是否可调整:" prop="adjustable">
              <el-select v-model="form.adjustable" clearable placeholder="请选择">
                <el-option
                  v-for="item in ADJUSTABLE_MENU"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="受损车轮位置:">
              <el-select
                v-model="form.damagedWheelPosition"
                clearable
                filterable
                placeholder="请选择受损车轮位置"
              >
                <el-option
                  v-for="item in DAMAGED_WHEEL_POSITION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="车轮受力方向:">
              <el-select
                v-model="form.wheelForceDirection"
                clearable
                filterable
                placeholder="请选择车轮受力方向"
              >
                <el-option
                  v-for="item in WHEEL_FORCE_DIRECTION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="后倾角:" prop="casterState">
              <el-select v-model="form.casterState" clearable filterable placeholder="请选择异常数值状态">
                <el-option
                  v-for="item in ABNORMAL_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外倾角:" prop="camberState">
              <el-select v-model="form.camberState" clearable filterable placeholder="请选择异常数值状态">
                <el-option
                  v-for="item in ABNORMAL_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="前束值:" prop="toeState">
              <el-select v-model="form.toeState" clearable filterable placeholder="请选择异常数值状态">
                <el-option
                  v-for="item in ABNORMAL_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="my-tree-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="my-tree-table">
      <el-table show-header stripe border :data="tableData">
        <!-- <el-table-column align="center" label prop type="selection" :selectable="selectableFn" /> -->
        <el-table-column align="center" label="序号" prop type="index" width="60px" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="悬挂形式"
          prop="suspensionTypeName"
        />
        <el-table-column align="center" label="外倾角是否可调整" width="160px">
          <template #default="scope">
            <span
              :class="{'my-tree-table-gary': suspensionTypeState(scope.row.suspensionType).disabled}"
            >{{ scope.row.adjustable == 1 ? '是' : '否' }} {{ suspensionTypeState(scope.row.suspensionType).label }}</span>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="受损车轮位置"
          prop="damagedWheelPositionName"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="车轮受力方向"
          prop="wheelForceDirectionName"
        />
        <el-table-column
          align="center"
          label="异常状态"
          prop="abnormalStateName"
          width="500px"
          show-overflow-tooltip
        />
        <el-table-column align="center" label="是否已启用决策">
          <template #default="scope">
            <span>{{ scope.row.enabledDecision == 1 ? '是' : '否' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" class="icon" width="140px">
          <template #header>
            操作
            <!-- <Remove class="icon-common icon-remove" /> -->
          </template>
          <template #default="scope">
            <Connection class="icon-common icon-connection" @click="toIndexDecision(scope.row)" />
            <!-- <Warning class="icon-common icon-warning" @click="toRecords(scope.row)" /> -->
            <span title="启用">
              <CircleCheck class="icon-common icon-circleCheck" @click="updateState(scope.row)" />
            </span>
          </template>
        </el-table-column>
      </el-table>
      <PaginationCmpt :current-page="page" :total="total" @current-change="currentChange" />
    </div>
  </div>
</template>
  
  <script setup>
import { ref, unref, reactive, computed, onMounted } from 'vue'
import PaginationCmpt from '@/components/PaginationCmpt'
import { useWheelStore } from '@/store/wheelAlignment'
import { ADJUSTABLE_MENU } from '@/utils/constants'
import { useEnumSelectStore } from '@/store/enumSelect'
import { ElMessage } from 'element-plus'
import { getAbnormalStateName } from '@/views/wheelAlignment/wheelCommon'

const enumStore = useEnumSelectStore()
const wheelStore = useWheelStore()
const SUSPENSION_TYPE = ref(null)
const DAMAGED_WHEEL_POSITION = computed(() => {
  return (
    enumStore.DAMAGED_WHEEL_POSITION ||
    enumStore.getWheelEnumDamageWheelPosition()
  )
})
const WHEEL_FORCE_DIRECTION = computed(() => {
  return (
    enumStore.WHEEL_FORCE_DIRECTION || enumStore.getWheelEnumForceDirection()
  )
})
// const DAMAGE_CATEGORY = computed(() => {
//   return enumStore.DAMAGE_CATEGORY || enumStore.getWheelEnumDamagedCategory()
// })
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})
const suspensionTypeState = code => {
  if (!SUSPENSION_TYPE.value || !SUSPENSION_TYPE.value.length) {
    return { label: '', disabled: true }
  }
  const targetItem = SUSPENSION_TYPE.value.find(item => item.code === code)
  return {
    label: targetItem.disabled == 1 ? '(已停用)' : '',
    disabled: targetItem.disabled == 1
  }
}
const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)

const form = reactive({})

onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
  getWheelCompleteIndex()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  getWheelCompleteIndex()
}

const reset = () => {
  form.suspensionTypeDisabled = null
  form.suspensionType = null
  form.adjustable = null
  form.damagedWheelPosition = null
  form.wheelForceDirection = null
  form.casterState = null
  form.camberState = null
  form.toeState = null
}

// const selectableFn = row => {
//   console.log(row)
//   return row
// }

const currentChange = value => {
  page.value = value
  getWheelCompleteIndex()
}

async function getWheelCompleteIndex() {
  try {
    const result = await wheelStore.getWheelCompleteIndex({
      pageIndex: unref(page),
      pageSize: unref(size),
      ineffective: 1, // 是否无效索引；0-有效；1-无效
      ...form
    })

    tableData.value = result.records
    tableData.value = tableData.value.map(item => {
      return {
        suspensionTypeName: SUSPENSION_TYPE.value.find(
          enumItem => enumItem.value == item.suspensionType
        )?.label,
        damagedWheelPositionName: DAMAGED_WHEEL_POSITION.value.find(
          enumItem => enumItem.value == item.damagedWheelPosition
        )?.label,
        wheelForceDirectionName: WHEEL_FORCE_DIRECTION.value.find(
          enumItem => enumItem.value == item.wheelForceDirection
        )?.label,
        abnormalStateName: getAbnormalStateName(item, ABNORMAL_TYPE.value),
        ...item
      }
    })
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}

const toIndexDecision = row => {
  window.open(
    `/wheelAlignment/index/decision?completeIndexId=${row.id}`,
    '_blank'
  )
}
// const toRecords = () => {
//   window.open(`/wheelAlignment/index/records`, '_blank')
// }
const updateState = async row => {
  try {
    await wheelStore.updateStateWheelCompleteIndex({
      id: row.id,
      ineffective: 0 // 是否无效索引；0-有效；1-无效
    })
    ElMessage.success('状态更新成功')
    getWheelCompleteIndex()
  } catch (error) {
    ElMessage.error(error)
  }
}
</script>
  
<style lang="less" scoped>
.my-tree {
  padding: 10px 30px;

  &-form {
    &-field {
      // display: flex;
      // justify-content: space-between;
      // padding: 10px 0;
    }

    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }

    .icon {
      &-common {
        width: 1.5em;
        height: 1.5em;
        margin: 0 8px;
        cursor: pointer;
        vertical-align: middle;
      }
      &-warning,
      &-connection {
        color: #409eff;
      }
      &-circleCheck {
        color: #67c23a;
      }
      &-disabled {
        color: #aaa;
        cursor: not-allowed;
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>