// 获取更换方案文字数据
export function getChangePlanTxt(decision) {
  const { indices } = decision
  let arr = []

  if (!indices || !indices.length) {
    return
  }

  indices.forEach((item) => {
    if (!arr[item.layer - 1]) {
      arr[item.layer - 1] = []
    }
    const result = getResourceItem(item.itemType, item.itemId, decision).label + '、'
    arr[item.layer - 1].push(result)
  });

  return arr
}

// 根据资源项的类别和id获取原数据
function getResourceItem(itemType, itemId, target) {
  const { labors, parts, works } = target

  let result = {}
  if (itemType === 'PART') {
    result.type = 'parts'
    result.typeName = '配件'
    const findItem = parts.find(item => item.id === itemId)
    result.original = findItem
    result.label = findItem.partName
    result.modelCode = findItem.partCode
  } else if (itemType === 'LABOR') {
    result.type = 'labors'
    result.typeName = '工时'
    const findItem = labors.find(item => item.id === itemId)
    result.original = findItem
    result.label = `${findItem.repairTypeName}+${findItem.laborName}`
    result.modelCode = findItem.repairTypeCode + '_' + findItem.laborCode
  } else if (itemType === 'WORK') {
    result.type = 'works'
    result.typeName = '工项'
    const findItem = works.find(item => item.id === itemId)
    result.original = findItem
    result.label = findItem.workName
    result.modelCode = findItem.workCode
  }
  return result
}

// 保存决策时，需要的数据格式
export function submitDecisionFormat(layerData = []) {
  const result = { parts: [], labors: [], works: [] }

  if (!layerData.length) {
    return result
  }

  layerData.forEach((item, layer) => {
    item.partsFlat.forEach((part, sort) => {
      result[part.type].push({
        layer: layer + 1,
        sort: sort + 1,
        ...part.original
      })
    })
  })

  return result
}

// 决策方案-页面展示需要的数据格式
export function viewDecisionFormat(decision) {
  const { indices } = decision
  let arr = []

  if (!indices || !indices.length) {
    return
  }

  indices.forEach((item) => {
    if (!arr[item.layer - 1]) {
      arr[item.layer - 1] = []
    }
    const result = getResourceItem(item.itemType, item.itemId, decision)
    arr[item.layer - 1].push(result)
  });

  return arr
}