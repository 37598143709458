<template>
  <el-card class="tree-card" @click="emits('info')">
    <template #header>
      <span>整树信息:</span>
    </template>
    <div class="tree-card-item">
      <div class="tree-label">逻辑树编码:</div>
      <div class="tree-txt">{{ treeCodeToString(treeCode) }}</div>
    </div>
    <div class="tree-card-item">
      <div class="tree-label">关联故障码:</div>
      <div class="tree-txt">{{ faultCodesTxt }}</div>
    </div>
    <div class="tree-card-item">
      <div class="tree-label">整树备注:</div>
      <div class="tree-txt remark">点击查看整树备注详情</div>
    </div>
  </el-card>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  computed,
  unref
} from 'vue'
import { treeCodeToString } from '@/utils'

const emits = defineEmits(['info'])
const props = defineProps(['treeCode', 'faultCodes'])
const faultCodesTxt = computed(() => {
  const { faultCodes } = props
  if (!faultCodes || faultCodes.length == 0) {
    return '-'
  }
  return unref(faultCodes)?.map(
    ({ faultCode }) => faultCode
  ).join('、')
})
</script>

<style lang="less" scoped>
.tree {
  &-card {
    position: absolute;
    top: 80px;
    left: 20px;
  }

  &-label {
    font-size: 15px;
    font-weight: bold;
    margin: 3px 0;
  }

  &-txt {
    max-width: 500px;
    font-size: 13px;
    margin: 3px 0;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
</style>