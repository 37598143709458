<template>
  <svg
    t="1678783402437"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="2085"
    width="200"
    height="200"
  >
    <path
      d="M684.191222 580.87567L339.808778 580.87567c-19.018146 0-34.438347 15.437597-34.438347 34.438347s15.420201 34.437323 34.438347 34.437323l344.382445 0c19.035542 0 34.438347-15.436574 34.438347-34.437323S703.225741 580.87567 684.191222 580.87567zM856.381933 202.055902L615.313505 202.055902l0-68.876694c0-38.036291-30.839379-68.876694-68.87567-68.876694l-68.876694 0c-38.037315 0-68.876694 30.840402-68.876694 68.876694l0 68.876694L167.618067 202.055902c-38.036291 0-68.876694 30.840402-68.876694 68.876694l0 619.888196c0 38.037315 30.839379 68.876694 68.876694 68.876694l688.764889 0c38.036291 0 68.87567-30.839379 68.87567-68.876694l0-619.888196C925.257603 232.896304 894.418224 202.055902 856.381933 202.055902zM477.562165 167.618579c0-19.019169 15.419178-34.438347 34.438347-34.438347 19.035542 0 34.438347 15.419178 34.438347 34.438347l0 68.876694c0 19.018146-15.403828 34.437323-34.438347 34.437323-19.018146 0-34.438347-15.419178-34.438347-34.437323L477.562165 167.618579zM856.381933 821.944098c0 38.037315-30.841426 68.876694-68.877717 68.876694L236.493737 890.820792c-38.036291 0-68.876694-30.839379-68.876694-68.876694L167.617044 339.808266c0-38.036291 30.839379-68.876694 68.876694-68.876694l172.190711 0c0 38.037315 30.839379 68.876694 68.876694 68.876694l68.876694 0c38.036291 0 68.87567-30.839379 68.87567-68.876694l172.191734 0c38.036291 0 68.877717 30.840402 68.877717 68.876694L856.382956 821.944098zM684.191222 408.68496L339.808778 408.68496c-19.018146 0-34.438347 15.420201-34.438347 34.438347s15.420201 34.438347 34.438347 34.438347l344.382445 0c19.035542 0 34.438347-15.420201 34.438347-34.438347S703.225741 408.68496 684.191222 408.68496zM511.999488 753.067404l-172.191734 0c-19.018146 0-34.438347 15.436574-34.438347 34.438347 0 19.000749 15.420201 34.437323 34.438347 34.437323l172.191734 0c19.035542 0 34.438347-15.436574 34.438347-34.437323C546.438858 768.503978 531.03503 753.067404 511.999488 753.067404z"
      fill="#409eff"
      p-id="2086"
    ></path>
  </svg>
</template>
