<template>
  <!-- 需要修改接口数据 -->
  <div class="remark">
    <div class="remark-form">
      <el-form :inline="true" :model="form">
        <el-row>
          <el-col :span="6">
            <el-form-item label="索引启用情况:">
              <el-radio-group v-model="form.suspensionTypeDisabled">
                <el-radio label="1" size="large">启用</el-radio>
                <el-radio label="0" size="large">停用</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="悬挂形式:" prop="suspensionType">
              <el-select v-model="form.suspensionType" clearable filterable placeholder="请选择悬挂形式">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外倾角是否可调整:" prop="adjustable">
              <el-select v-model="form.adjustable" clearable placeholder="请选择">
                <el-option
                  v-for="item in ADJUSTABLE_MENU"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="受损车轮位置:">
              <el-select
                v-model="form.damagedWheelPosition"
                clearable
                filterable
                placeholder="请选择受损车轮位置"
              >
                <el-option
                  v-for="item in DAMAGED_WHEEL_POSITION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="车轮受力方向:">
              <el-select
                v-model="form.wheelForceDirection"
                clearable
                filterable
                placeholder="请选择车轮受力方向"
              >
                <el-option
                  v-for="item in WHEEL_FORCE_DIRECTION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="损伤类别:">
              <el-select v-model="form.damagedCategory" clearable filterable placeholder="请选择损伤类别">
                <el-option
                  v-for="item in DAMAGE_CATEGORY"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="异常数值状态:">
              <el-select v-model="form.abnormalType" clearable filterable placeholder="请选择异常数值状态">
                <el-option
                  v-for="item in ABNORMAL_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button type="success" @click="create">新增备注</el-button>
          </el-col>
          <el-col :span="12" class="remark-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="remark-table">
      <TableCmpt :case-list="tableData" :header-list="headerList" />
      <PaginationCmpt :current-page="page" :total="total" @current-change="currentChange" />
    </div>

    <RemarkAddCmpt v-model="remarkAddVisible" />
  </div>
</template>
  
  <script setup>
import { ref, reactive, onMounted, computed, unref } from 'vue'
import PaginationCmpt from '@/components/PaginationCmpt'
import TableCmpt from '@/components/TableCmpt'
import RemarkAddCmpt from '@/views/wheelAlignment/components/RemarkAddCmpt'
import { useWheelStore } from '@/store/wheelAlignment'
import { ADJUSTABLE_MENU } from '@/utils/constants'
import { useEnumSelectStore } from '@/store/enumSelect'
// import { ElMessage } from 'element-plus'

const enumStore = useEnumSelectStore()
const wheelStore = useWheelStore()
const SUSPENSION_TYPE = computed(() => {
  return enumStore.SUSPENSION_TYPE || enumStore.getWheelIndexItemList()
})
const DAMAGED_WHEEL_POSITION = computed(() => {
  return (
    enumStore.DAMAGED_WHEEL_POSITION ||
    enumStore.getWheelEnumDamageWheelPosition()
  )
})
const WHEEL_FORCE_DIRECTION = computed(() => {
  return (
    enumStore.WHEEL_FORCE_DIRECTION || enumStore.getWheelEnumForceDirection()
  )
})
const DAMAGE_CATEGORY = computed(() => {
  return enumStore.DAMAGE_CATEGORY || enumStore.getWheelEnumDamagedCategory()
})
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})

const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)
const remarkAddVisible = ref(false)

const create = () => {
  remarkAddVisible.value = true
}

const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'label',
    label: '悬挂形式',
    formatter(...args) {
      // const { label } = args[2]
      console.log(args)
      return <div>{'label'}</div>
    }
  },
  {
    prop: 'authorId',
    label: '外倾角是否可调整',
    formatter(...args) {
      const editors = args[2]
      console.log(editors)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '受损车轮位置',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '车轮受力方向',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'lastModifiedTime',
    label: '异常状态',
    width: '400px',
    formatter(row) {
      const datetime = row.lastModifiedTime
      return <div>{datetime}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '备注信息',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '附件数量',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'label',
    label: '操作',
    width: '80px',
    formatter(...args) {
      console.log(args)
      return (
        <router-link
          target='_blank'
          to={`/wheelAlignment/remark/indexInfo?id=`}
        >
          <el-icon style='font-size: 20px; color: #409eff;'>
            <Tickets />
          </el-icon>
        </router-link>
      )
    }
  }
]

const form = reactive({})

onMounted(async () => {
  getWheelIndexRemark()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  getWheelIndexRemark()
}

const reset = () => {
  form.systemCode = null
  form.physicalTreeCode = null
  form.serialNumber = null
  form.status = null
  form.checker = null
  form.date1 = null
  form.date2 = null
}

const currentChange = value => {
  page.value = value
  getWheelIndexRemark()
}
async function getWheelIndexRemark() {
  try {
    const result = await wheelStore.getWheelIndexRemark({
      pageIndex: unref(page),
      pageSize: unref(size),
      ...form
    })

    tableData.value = result.records
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}
</script>
  
<style lang="less" scoped>
.remark {
  padding: 10px 30px;

  &-form {
    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }

    .icon {
      &-common {
        width: 1.5em;
        height: 1.5em;
        margin: 0 8px;
        cursor: pointer;
        vertical-align: middle;
      }
      &-warning,
      &-connection {
        color: #409eff;
      }
      &-remove {
        color: #f56c6c;
      }
      &-disabled {
        color: #aaa;
        cursor: not-allowed;
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>