<template>
  <div class="add-PH">
    <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
      <el-tab-pane label="德联标准配件" name="1">
        <el-row :gutter="10">
          <el-col :span="4">
            <TreeMenuCmpt
              :type="[1]"
              @selectChanged="treeMenuSelectChanged"
              @treeClicked="treeClicked($event, 'part')"
            />
          </el-col>
          <el-col :span="20">
            <TransferCmpt
              ref="partTransferCmptRef"
              :searchObj="searchObj"
              :hasBorder="false"
              :tableDataLeft="partTableDataLeft"
              :tableFormat="tableFormat"
              @transferSearch="transferSearch($event, 'part')"
            />
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="德联标准工时" name="1,5">
        <el-row :gutter="10">
          <el-col :span="4">
            <TreeMenuCmpt
              :type="[1, 5]"
              @selectChanged="treeMenuSelectChanged"
              @treeClicked="treeClicked($event, 'labor')"
            />
          </el-col>
          <el-col :span="20">
            <TransferCmpt
              ref="laborTransferCmptRef"
              :searchObj="searchObj"
              :hasBorder="false"
              :tableDataLeft="laborTableDataLeft"
              :tableFormat="tableFormat"
              :tableFormatRight="tableFormatRight"
              @transferSearch="transferSearch($event, 'labor')"
            />
          </el-col>
        </el-row>
      </el-tab-pane>

      <el-tab-pane label="工项" name="third">
        <el-form :inline="true" :model="form" label-position="left" class="transfer-box-form">
          <el-row>
            <el-col :span="8">
              <el-form-item label="名称：">
                <el-input v-model="form.name" placeholder="请输入名称" maxlength="20" style="width: 350px"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-button type="success" @click="addWorkItem">新增</el-button>
            </el-col>
          </el-row>
        </el-form>

        <TransferCmpt
          ref="workItemTransferCmptRef"
          :searchObj="searchObj"
          :hasBorder="false"
          :tableDataLeft="workItemTableDataLeft"
          :tableFormat="workItemTableFormat"
          @transferSearch="transferSearch($event, 'workItem')"
        />
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, defineExpose } from 'vue'
import TransferCmpt from '@/views/commonConfig/components/TransferCmpt.vue'
import TreeMenuCmpt from '@/views/commonConfig/components/TreeMenuCmpt.vue'
import { useTreeStore } from '@/store/tree'
import { useWheelStore } from '@/store/wheelAlignment'
import { useCarStore } from '@/store/car'
import { ElMessage } from 'element-plus'

const treeStrore = useTreeStore()
const wheelStore = useWheelStore()
const carStore = useCarStore()
const activeName = ref('1')
const partTableDataLeft = ref([])
const laborTableDataLeft = ref([])
const workItemTableDataLeft = ref([])

const handleClick = (tab, event) => {
  console.log(tab, event)
}

const searchObj = reactive({ label: '关键字', keyName: 'keyWord', labelType: 'input' })

const form = reactive({ groupName: '' })

const tableFormat = ref([
  { label: '配件编码', code: 'code' },
  { label: '配件名称', code: 'name' }
])

const workItemTableFormat = ref([
  { label: '工项', code: 'name' }
])

const repairTypeList = ref([])
const tableFormatRight = ref([
  { label: '配件编码', code: 'code' },
  { label: '配件名称', code: 'name' },
  { label: '处理方式', code: 'processingMode', labelType: 'select', enumList: repairTypeList }
])

const transferSearch = async (value, tabPaneType) => {
  const { type, keyWord } = value

  if (type === 'query') {
    if (['part', 'labor'].includes(tabPaneType)) {
      treeClicked({ sparePartName: keyWord, keyword: vehicleType.value }, tabPaneType)
    } else {
     getWheelLibraryWorkList(keyWord)
    }
  } else if (type === 'reset') {
    if (tabPaneType === 'part') {
      partTableDataLeft.value = []
    } else if (tabPaneType === 'labor') {
      laborTableDataLeft.value = []
    } else {
      getWheelLibraryWorkList()
    }
  }
}

const vehicleType = ref(null)
const treeMenuSelectChanged = (value) => {
  if (value) {
    vehicleType.value = value
  }
}
const treeClicked = (params, type) => {
  const { data, keyword, sparePartName } = params
  if (!keyword) {
    ElMessage.warning('请先选择车身大类')
    return
  }

  getRuleSystemSparePartList(type, {
    carBodyMainTypeId: keyword,
    groupCode: data?.code,
    sparePartName,
    batchSearch: false,
  })
}

const standardList = ref([])
async function getRuleSystemSparePartList(type, data) {
  try {
    const result = await treeStrore.getRuleSystemSparePartList(data)
    standardList.value = result.map(item => {
      const { code, name, enableStatus } = item
      return {
        code,
        name,
        modelCode: code,
        disabled: !enableStatus,
        enableStatus
      }
    })

    if (type === 'part') {
      partTableDataLeft.value = standardList.value
    } else if (type === 'labor') {
      laborTableDataLeft.value = standardList.value
    }
  } catch (error) {
    console.error(error)
  }
}

const addWorkItem = async () => {
  if (!form.name) {
    ElMessage.warning('请输入名称')
    return
  }

  const params = {
    workName: form.name
  }

  await wheelStore.saveWheelLibraryWork(params)
  ElMessage.success('新增工项成功')
  getWheelLibraryWorkList()
}

const getWheelLibraryWorkList = async (workName) => {
  const result = await wheelStore.getWheelLibraryWorkList({ workName })
  workItemTableDataLeft.value = result.map(res => {
    return {
      name: res.workName,
      modelCode: res.workId
    }
  })
}

const getCheckEnumRepairType = async() => {
   const result = await carStore.getCheckEnumRepairType()
   repairTypeList.value = result.map(item => {
    return {
      value: item.value,
      label: item.comment
    }
   })
}

onMounted(() => {
  getWheelLibraryWorkList()
  getCheckEnumRepairType()
})

const partTransferCmptRef = ref(null)
const laborTransferCmptRef = ref(null)
const workItemTransferCmptRef = ref(null)

defineExpose({
  partTransferCmptRef,
  laborTransferCmptRef,
  workItemTransferCmptRef,
  repairTypeList
})
</script>

<style lang="less" scoped>
.add-PH{

}
</style>