<template>
  <div class="list">
    <ItemCmpt
      v-for="(item, index) in list"
      :key="index"
      :item="item"
      icon="IconDisabled"
      @select="select"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'
import ItemCmpt from '@/components/ItemCmpt'

const emits = defineEmits(['select'])
defineProps({
  list: {
    type: Array,
    default: () => []
  },
})
const select = item => {
  emits('select', item)
}
</script>

<style lang="less" scoped>
.list {
  display: flex;
  flex-wrap: wrap;
}
</style>