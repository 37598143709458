<template>
  <svg
    t="1677894274943"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="838"
    width="200"
    height="200"
  >
    <path
      d="M785.066667 452.266667C706.133333 384 603.733333 341.333333 490.666667 341.333333c-198.4 0-366.08 129.28-424.96 308.053334L166.4 682.666667a341.418667 341.418667 0 0 1 324.266667-234.666667c83.2 0 159.146667 30.72 218.453333 80.213333L554.666667 682.666667h384V298.666667l-153.6 153.6z"
      p-id="839"
    ></path>
  </svg>
</template>
