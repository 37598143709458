<template>
  <div class="look-up">
    <StatusBar class="tree-status-bar" :title="'逻辑树: ' + treeCodeName">
      <template #tree-info>
        <div class="look-up-info">
          <span>编写人: {{ editorsName }}</span>
          <span>审核人: {{ checkerName }}</span>
        </div>
      </template>
    </StatusBar>
    <div id="container"></div>
    <el-card v-if="false" class="look-up-card">
      <template #header>
        <span>整树信息:</span>
      </template>
      <div class="look-up-card-item">
        <div class="look-up-label">逻辑树编码:</div>
        <div class="look-up-txt">{{ treeCodeName }}</div>
      </div>
      <div class="look-up-card-item">
        <div class="look-up-label">关联故障码:</div>
        <div class="look-up-txt">{{ treeRelationCodesTxt }}</div>
      </div>
      <div class="look-up-card-item">
        <div class="look-up-label">整树备注:</div>
        <div class="look-up-txt remark">点击查看整树备注详情》</div>
      </div>
    </el-card>
    <div style="height:500px;">
      <div style="margin: 20px;font-weight: bold;">所关联故障码:</div>
      <div style="margin: 20px;">
        <TableCmpt
          :case-list="treeRelationCodes"
          :header-list="headerList"
        />
        <div style="margin: 10px;font-size: 12px;color:#606266;">* 注：若选择通过时启用现有逻辑树，则对应的故障码原有所启用的逻辑树将皆变为停用状态。</div>
      </div>
      <div style="margin: 20px;font-weight: bold;">审核意见:</div>
      <div style="margin: 20px;">
        <el-input
          v-model="auditComment"
          :rows="3"
          type="textarea"
          placeholder="请输入审核意见"
        />
      </div>
      <div style="margin: 30px 20px;text-align:right;">
        <el-button type="primary" @click="confirm">通过</el-button>
        <el-button type="danger" @click="goback">退回</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, unref, computed, onMounted, onUnmounted, watch } from 'vue'
import { useTreeStore } from '@/store/tree'
import { useUserStore } from '@/store/user'
import { useRoute, useRouter } from 'vue-router'
import { Tree } from './Tree'
import StatusBar from '@/components/StatusBar'
import TableCmpt from '@/components/TableCmpt'
import { ElMessage } from 'element-plus'

const treeStore = useTreeStore()
const userStore = useUserStore()
const currentRoute = useRoute()
const router = useRouter()
const auditComment = ref('')
const checkboxChecked = ref()

const headerList = [
  {
    type: 'index',
    label: '序号',
    width: 60,
  },
  {
    prop: 'carBrand',
    label: '品牌',
  },
  {
    prop: 'carModel',
    label: '车型',
  },
  {
    prop: 'faultCode',
    label: '故障码',
    formatter(row) {
      const code = row.faultCode
      return (
        <router-link target="_blank" to={`/faultCode/info?code=${code}&id=${row.id}`}>
          <div>{code}</div>  
        </router-link>
      )
    }
  },
  {
    prop: 'enabledTreeCode',
    label: '启用逻辑树',
    align: 'center',
    formatter(row) {
      const { enabledTreeCode } = row
      if (enabledTreeCode) {
        const {
          systemCode,
          physicalTreeCode,
          serialNumber
        } = enabledTreeCode
        const treeCode = `${systemCode}${physicalTreeCode}${serialNumber}`
        return (
          <router-link target="_blank" to={`/tree/show?tree=${treeCode}`}>
            <div>{treeCode}</div>
          </router-link>
        )
      } else {
        return (<div>-</div>)
      }
    }
  },
  {
    prop: 'relationStatus',
    renderHeader() {
      return (<el-checkbox v-model={checkboxChecked.value} label="通过且启用本树" size="large" onChange={checkboxChanged} />)
    },
    formatter(row) {
      return (<el-switch v-model={row.relationStatus} />)
    }
  },
  {
    prop: 'priority',
    label: '优先级',
  },
]

const treeInfomation = ref({})
const treeRelationCodes = ref([])
const treeRelationRemarks = ref([])
let graph = null
let treeId = null

const userAllList = computed(() => {
  return (userStore.userAllList || []).filter(({ inside }) => inside)
})
const treeInfoFromOriginal = computed(() => {
  return unref(treeInfomation).treeInfo || {}
})
const treeCodeName = computed(() => {
  const treeInfo = unref(treeInfoFromOriginal)

  if (treeInfo.treeCode) {
    const {
      physicalTreeCode,
      serialNumber,
      systemCode
    } = treeInfo.treeCode
    return `${systemCode}${physicalTreeCode}${serialNumber}`
  }

  return '-'
})
const treeRelationCodesTxt = computed(() => {
  return unref(treeRelationCodes)?.map(
    ({ faultCode }) => faultCode
  ).join('、') || '-'
})
const editorsName = computed(() => {
  const { authorId } = unref(treeInfoFromOriginal)
  return getTargetUser(authorId)?.userName || '-'
})
const checkerName = computed(() => {
  const { reviewerId } = unref(treeInfoFromOriginal)
  return getTargetUser(reviewerId)?.userName || '-'
})

onMounted(async () => {
  treeId = currentRoute.query?.id
  await userStore.getUserAllInfo()
  getTreeStructure()
  getRelationTreeRemarks()
  getRelationTreeFaultCodes()
})
onUnmounted(() => {
  graph?.destroyed()
  graph = null
})

watch(treeRelationCodes, (data) => {
  const relationStatusList = unref(data).map(item => item.relationStatus)
  checkboxChecked.value = relationStatusList.every(m => m === true)
}, { deep: true })

async function getTreeStructure() {
  try {
    const result = await treeStore.getTreeStructure({ treeId })
    treeInfomation.value = result
    createGraph(result)
  } catch (error) {
    console.error(error)
  }
}
async function getRelationTreeRemarks() {
  try {
    const result = await treeStore.getRelationTreeRemarks({ treeId })
    treeRelationRemarks.value = result
  } catch (error) {
    console.error(error)
  }
}
async function getRelationTreeFaultCodes() {
  try {
    const result = await treeStore.getRelationTreeFaultCodes({ treeId })
    treeRelationCodes.value = result
  } catch (error) {
    console.error(error)
  }
}
async function treeReview(reviewType) {
  treeStore.saveTreeOperateRecord({
    treeId,
    operatorId: userStore.userInfo.id,
    operateType: 'REVIEW',
    content: reviewType === 'APPROVED' ? `审核已通过:${unref(auditComment)}` : `审核未通过:${unref(auditComment)}`,
  })
  try {
    const relationEnableStatus = unref(treeRelationCodes).map(item => ({
      id: item.id,
      status :item.relationStatus ? 1 : 0,
    }))
    await treeStore.treeReview({
      treeId: treeId,
      reviewType,
      releaseId: unref(treeInfomation).releaseId,
      relationEnableStatus,
      comments: unref(auditComment),
      reviewerId: userStore.userInfo.id,
    })
    ElMessage.success('审查提交成功')
    router.push('/audit/treeCodeLib/audit')
  } catch (error) {
    console.error(error)
    ElMessage.error('审查提交失败')
  }
}

function checkboxChanged(value) {
  unref(treeRelationCodes).forEach(item => {
    item.relationStatus = value
  })
}
const getTargetUser = id => (unref(userAllList).find(item => item.id ===  id) || {})
const createGraph = data => {
  const container = document.getElementById('container')
  const {
    scrollWidth,
    scrollHeight
  } = container
  const {
    tree: {
      nodes,
      flows
    },
    nodeInfos,
    flowInfos
  } = data

  graph = new Tree({
    graphConfig: {
      container,
      width: scrollWidth,
      height: scrollHeight || 500,
      mousewheel: false,
      panning: false,
      grid: false,
      interacting: false,
    },
    flows,
    nodes,
    nodeInfos,
    flowInfos,
  })
}

const confirm = () => {
  treeReview('APPROVED')
}
const goback = () => {
  treeReview('REJECTED')
}
</script>

<style lang="less" scoped>
.look-up, #container {
  position: relative;
  height: 100vh;
}

.look-up {
  &-info {
    margin-left: 20px;

    & > span {
      margin: 0 8px;
    }
  }

  &-tools {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 13px;
    color: rgb(96,98,102);

    & > div {
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-right: 1px solid gray;
      border-left: 1px solid gray;
      cursor: pointer;

      &:last-child {
        border-right: none;
      }

      &:first-child {
        border-left: none;
      }

      & > i {
        margin-right: 5px;
      }
    }
  }

  &-card {
    position: absolute;
    top: 80px;
    left: 20px;

    &-item {
      margin: 10px 0;
    }
  }

  &-label {
    font-size: 15px;
    font-weight: bold;
    margin: 5px 0;
  }

  &-txt {
    font-size: 13px;
    margin: 3px 0;

    &.remark {
      color: #0090e1;
      text-decoration-line: underline;
    }
  }

  .tree-status-bar {
    position:absolute;
    top:0;
    left:0;
    right:0;
    background-color:#ffffff;
    z-index:99;
  }
}
</style>