<template>
  <div class="margin-top-sm flex justify-end">
    <el-pagination
      background
      :current-page="currentPage"
      layout="total, prev, pager, next, jumper"
      :total="total"
      @current-change="change"
    />
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue'

defineProps({
  currentPage: {
    type: Number,
    default: 1
  },
  total: {
    type: Number,
    default: 0
  }
})
const emits = defineEmits(['current-change'])

function change(e) {
  emits('current-change', e)
}
</script>

<style lang="less" scoped>
.margin-top-sm {
  margin-top: 20px;
  padding-bottom: 30px;
}
</style>
