<template>
  <el-drawer
    v-model="value"
    :with-header="false"
    :close-on-click-modal="false"
    :modal="true"
  >
    <div
      style="margin-bottom: 20px;"
      v-for="(item, index) in reviewResultList"
      :key="index"
    >
      <div>{{ item.title }}</div>
      <div style="height:100px;overflow-y:scroll;background:#e7e7e7;">
        <router-link
          v-for="c in item.treeList"
          :key="c.id"
          target="_blank"
          :to='`/tree/show?id=${c.id}`'
        >
          <div>逻辑树{{
            c.systemCode + c.physicalTreeCode + c.serialNumber
          }}</div>
        </router-link>
        <!-- <a
          href="#"
          v-for="c in item.treeList"
          :key="c.id"
        >逻辑树{{ c.systemCode + c.physicalTreeCode + c.serialNumber }}</a> -->
      </div>
      <div style="text-align:right;">
        <el-button type="primary" @click="review(item)">在此范围内校验</el-button>
      </div>
    </div>
    <div class="tree-info-close">
      <el-button type="primary" @click="reset">重置</el-button>
      <el-button type="danger" @click="logout">退出</el-button>
    </div>
  </el-drawer>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useTreeStore } from '@/store/tree'

const emits = defineEmits(['update:modelValue', 'review'])
const props = defineProps({ modelValue: Boolean })
const treeStore = useTreeStore()
const reviewResultList = computed(() => {
  return treeStore.reviewResultList
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})
const logout = () => {
  emits('update:modelValue', false)
}
const reset = () => {
  treeStore.resetReviewResultList()
}
const review = item => {
  emits('review', item)
}
</script>

<style lang="less" scoped>
.tree-info {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>