import { computed, unref } from "vue";

export function userCommon(userStore) {
  const userAllList = computed(() => {
    return (userStore.userAllList || [])
      .filter(({ inside }) => inside)
      .map(item => ({
        label: item.userName,
        value: item.id,
        original: item
      }))
  })

  // 编写人
  const userAllListEditor = computed(() => {
    return unref(userAllList).filter(item => {
      const { original } = item || {}
      return original?.roleList?.some(user => [41].includes(user.id))
    })
  })
  // 审核人
  const userAllListAuditor = computed(() => {
    return unref(userAllList).filter(item => {
      const { original } = item || {}
      return original?.roleList?.some(user => [40].includes(user.id))
    })
  })

  const getUserInfo = (userId) => {
    if (!userId) {
      return
    }
    const findUser = unref(userAllList).find(user => user.value == userId)
    return findUser?.label
  }

  return {
    userAllList,
    userAllListEditor,
    userAllListAuditor,
    getUserInfo,
  }
}

export function isOrNot(value) {
  return value == 1 ? '是' : value === undefined ? '-' : '否'
}
export function isEnabled(value) {
  return value == 1 ? '启用' : value === undefined ? '-' : '未启用'
}

export function getAbnormalStateName(row, ABNORMAL_TYPE) {
  let str = ''
  if (row) {
    console.log(row)
    if (row.casterState) {
      str += `后倾角： ${ABNORMAL_TYPE.find(enumItem => enumItem.value == row.casterState)
        ?.label
      }`
    }
    if (row.camberState) {
      str += ` ；外倾角：${ABNORMAL_TYPE.find(enumItem => enumItem.value == row.camberState)
        ?.label
        }`
    }
    if (row.toeState) {
      str += ` ；前束值：${ABNORMAL_TYPE.find(enumItem => enumItem.value == row.toeState)
        ?.label
        }`
    }
    return str
  } else {
    return '-'
  }
}