<template>
  <div class="decision">
    <el-form
      ref="formRef"
      :inline="true"
      :rules="rules"
      :model="form"
      label-position="left"
      class="decision-form"
    >
      <el-row>
        <el-col :span="24">
          <el-radio-group v-model="form.decisionPlanType" class="decision-radio-group">
            <el-radio
              v-for="(item, index) in DECISION_PLAN_TYPE"
              :label="item.value"
              size="large"
              :key="index"
            >{{item.label}}</el-radio>
          </el-radio-group>
        </el-col>
      </el-row>
      <div v-if="form.decisionPlanType === 'REASONABLE'">
        <el-row>
          <el-col :span="12">
            <el-form-item label="使用决策信息库:" prop="decisionInfoLibrary">
              <el-select
                v-model="form.decisionInfoLibrary"
                placeholder="请选择使用决策信息库"
                class="decision-form-selectLibrary"
              >
                <el-option
                  v-for="item in DECISION_INFO_LIBRARY"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12" class="decision-btn-control">
            <div class="decision-btn-control-box">
              <el-button type="primary" text :disabled="!hasChecked || isTop" @click="layerTop">
                <el-icon>
                  <Top />
                </el-icon>上移
              </el-button>
              <el-button
                type="primary"
                text
                :disabled="!hasChecked || isBottom"
                @click="layerBottom"
              >
                <el-icon>
                  <Bottom />
                </el-icon>下移
              </el-button>
              <el-button type="primary" text :disabled="layerData.length >= 9" @click="layerAdd">
                <el-icon>
                  <Plus />
                </el-icon>新建
              </el-button>
              <el-button type="danger" text :disabled="!hasChecked" @click="layerDelete">
                <el-icon>
                  <Close />
                </el-icon>删除
              </el-button>
            </div>
          </el-col>
        </el-row>

        <el-row class="decision-form-layer" v-for="(item, index) in layerData" :key="index">
          <el-col :span="12">
            <el-checkbox v-model="item.checked" :label="`第${index + 1}层`" size="large" />
          </el-col>
          <el-col :span="12" style="text-align: right;">
            <el-button
              link
              class="decision-form-layer-link"
              @click="openDecisionLibrary(index)"
            >决策信息库</el-button>
          </el-col>
          <el-col :span="24" class="decision-form-layer-buttonbox">
            <el-button
              size="small"
              class="decision-form-layer-button"
              v-for="(part, i) in item.partsFlat"
              :key="i"
              @click="showEvidenceDrawer(part.label)"
            >
              {{part.label}}
              <!-- <el-icon class="decision-form-layer-icon">
                <Flag />
              </el-icon> -->
            </el-button>
          </el-col>
        </el-row>
      </div>
    </el-form>

    <div v-if="form.decisionPlanType === 'UNREASONABLE'">
      <el-form
        ref="unreasonableFormRef"
        :inline="true"
        :model="unreasonableForm"
        label-width="120px"
        label-position="top"
        class="decision-form"
        :rules="unreasonableFormRules"
      >
        <el-form-item
          label="不合理数据信息:"
          class="decision-form-textarea"
          prop="unreasonablePlanContent"
        >
          <el-input
            type="textarea"
            v-model="unreasonableForm.unreasonablePlanContent"
            rows="4"
            placeholder
            maxlength="200"
          ></el-input>
        </el-form-item>
      </el-form>
    </div>

    <div class="decision-page-submit">
      <el-button type="primary" @click="ChangePlanSubmit">确定</el-button>
      <el-button @click="close">取消</el-button>
    </div>

    <EvidenceCmpt v-model="evidenceDrawer" :info="evidenceInfo" :isEdit="false" />

    <el-dialog
      v-model="dialogTransferVisible"
      title="决策信息库"
      :show-close="false"
      :close-on-click-modal="false"
      class="audit-dialog"
      width="75%"
    >
      <TransferCmpt
        ref="transferCmptRef"
        :hasBorder="false"
        :isSort="true"
        :tableDataLeft="tableDataLeft"
        :tableDataRightConfig="tableDataRight"
        :tableFormat="tableFormat"
        :searchCmpt="false"
        :disabledData="disabledDataTransfer"
      />
      <template #footer>
        <el-row>
          <el-col :span="24">
            <el-button type="primary" @click="handleConfirm">确定</el-button>
            <el-button @click="closeDecisionLibrary">取消</el-button>
          </el-col>
        </el-row>
        <span class="dialog-footer"></span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineEmits, reactive, ref, computed, defineProps, watchEffect } from 'vue'
import { ElMessage } from 'element-plus'
import EvidenceCmpt from '@/views/commonConfig/components/EvidenceCmpt.vue'
import TransferCmpt from '@/views/commonConfig/components/TransferCmpt'
import { DECISION_PLAN_TYPE } from '@/utils/constants'
import { useWheelStore } from '@/store/wheelAlignment'
import { useEnumSelectStore } from '@/store/enumSelect'
import { useRouter } from 'vue-router'
import { uniqBy, cloneDeep } from 'lodash'
import { submitDecisionFormat } from '@/views/wheelAlignment/wheelAlignment'

const emits = defineEmits(['submitAddDecisoin'])
const props = defineProps({
  propLayerData: Object, // 更换方案回显数据
  decisionPlanType: String,
  unreasonablePlanContent: String,
})
const router = useRouter()
const wheelStore = useWheelStore()
const enumStore = useEnumSelectStore()

const addDecisionIndexList = computed(() => wheelStore.addDecisionIndexList)
const DECISION_INFO_LIBRARY = ref(null)

const setDecisionInfoLibrary = () => {
  if (addDecisionIndexList.value) {
    Promise.all(
      addDecisionIndexList.value.map(async item => {
        let arrRes = []
        const suspensionTypeName = await enumStore.getEnumName(
          'SUSPENSION_TYPE',
          item.suspensionType
        )
        const adjustableName = await enumStore.getEnumName(
          'ADJUSTABLE_MENU',
          item.adjustable
        )

        arrRes.push({
          original: item,
          label: `${suspensionTypeName}+${adjustableName}`,
          value: `LIBRARY_${item.suspensionType}_${item.adjustable}`
        })

        // 如果有受损车轮位置
        if (item.damagedWheelPosition) {
          const damagedWheelPositionName = await enumStore.getEnumName(
            'DAMAGED_WHEEL_POSITION',
            item.damagedWheelPosition
          )
          arrRes.push({
            original: item,
            label: `${suspensionTypeName}+${adjustableName}+${damagedWheelPositionName}`,
            value: `TAG_${item.suspensionType}_${item.adjustable}_${item.damagedWheelPosition}`
          })
        }

        return arrRes
      })
    ).then(res => {
      DECISION_INFO_LIBRARY.value = res.flat()
      DECISION_INFO_LIBRARY.value = uniqBy(DECISION_INFO_LIBRARY.value, 'value')
    })
  }
}
setDecisionInfoLibrary()

watchEffect(() => {
  if (props.propLayerData) {
    layerData.value = cloneDeep(props.propLayerData)
  }
  if (props.decisionPlanType) {
    form.decisionPlanType = props.decisionPlanType
  }
  if (props.unreasonablePlanContent) {
    unreasonableForm.unreasonablePlanContent = props.unreasonablePlanContent
  }
})

const form = reactive({ decisionPlanType: 'REASONABLE' })
const formRef = ref(null)
const unreasonableFormRef = ref(null)
const unreasonableForm = reactive({ unreasonablePlanContent: '' })
const disabledDataTransfer = ref(null)
const unreasonableFormRules = reactive({
  unreasonablePlanContent: [
    { required: true, message: '请输入不合理数据信息', trigger: 'blur' }
  ]
})
const rules = reactive({
  decisionInfoLibrary: [
    { required: true, message: '请选择决策信息库', trigger: 'change' }
  ]
})

const layerData = ref([
  {
    checked: false,
    partsFlat: null
  }
])
const hasChecked = computed(() => {
  return layerData.value.some(item => item.checked)
})
const isTop = computed(() => {
  const firstItem = layerData.value[0]
  return firstItem.checked
})
const isBottom = computed(() => {
  const lastItem = layerData.value[layerData.value.length - 1]
  return lastItem.checked
})
const layerAdd = () => {
  if (layerData.value.length >= 9) {
    ElMessage.warning('决策层数最多不可超过九层！')
  } else {
    layerData.value.push({
      checked: false,
      partsFlat: null
    })
  }
}
const layerTop = () => {
  layerData.value.forEach((item, index) => {
    if (item.checked) {
      layerData.value.splice(index, 1)
      layerData.value.splice(index - 1, 0, item)
    }
  })
}
const layerBottom = () => {
  for (let index = layerData.value.length - 1; index >= 0; index--) {
    const item = layerData.value[index]
    if (item.checked) {
      layerData.value.splice(index, 1)
      layerData.value.splice(index + 1, 0, item)
    }
  }
}
const layerDelete = () => {
  layerData.value.forEach((item, index) => {
    if (item.checked) {
      layerData.value.splice(index, 1)
    }
  })
}

// 提交接口数据
const ChangePlanSubmit = async () => {
  let result = { decisionPlanType: form.decisionPlanType }

  if (form.decisionPlanType === 'UNREASONABLE') {
    const validate = await unreasonableFormRef.value.validate(() => {})
    if (!validate) {
      return
    }
    Object.assign(result, {
      unreasonablePlanContent: unreasonableForm.unreasonablePlanContent
    })
  } else {
    const validateLayerData = layerData.value.every(item => item.partsFlat && item.partsFlat.length)
    if (!validateLayerData) {
      ElMessage.warning('请选择决策信息库')
      return
    }
    const libraryData = submitDecisionFormat(layerData.value)
    Object.assign(result, libraryData)
  }

  emits('submitAddDecisoin', result)
}
const close = () => {
  router.back()
}

const evidenceDrawer = ref(false)
const evidenceInfo = ref({})
const showEvidenceDrawer = item => {
  console.log(item, 'showEvidenceDrawer')
  // evidenceDrawer.value = true
}

const libraryLayer = ref(0)
const dialogTransferVisible = ref(false)
// 调用决策信息库数据
const openDecisionLibrary = async layer => {
  const validate = await formRef.value.validateField(
    'decisionInfoLibrary',
    valid => console.log(valid, 'valid')
  )
  if (!validate) {
    return
  }
  libraryLayer.value = layer

  const Library = form.decisionInfoLibrary.split('_')[0]
  if (Library === 'LIBRARY') {
    const { original } = DECISION_INFO_LIBRARY.value.find(
      item => item.value === form.decisionInfoLibrary
    )
    const { suspensionType, adjustable } = original
    tableDataLeft.value = await wheelStore.getWheelLibraryByLibraryIndex({
      suspensionType,
      adjustable
    })
  } else {
    const { original } = DECISION_INFO_LIBRARY.value.find(
      item => item.value === form.decisionInfoLibrary
    )
    const { suspensionType, adjustable, damagedWheelPosition } = original
    tableDataLeft.value = await wheelStore.getWheelLibraryTag({
      suspensionType,
      adjustable,
      damagedWheelPosition
    })
  }

  const flatRes = []
  if (tableDataLeft.value.laborItems) {
    const list = tableDataLeft.value.laborItems.map(item => {
      return {
        type: 'labors',
        typeName: '工时',
        label: item.repairTypeName + item.laborName,
        modelCode: item.repairTypeCode + '_' + item.laborCode,
        original: item
      }
    })
    flatRes.push(...list)
  }
  if (tableDataLeft.value.partItems) {
    const list = tableDataLeft.value.partItems.map(item => {
      return {
        type: 'parts',
        typeName: '配件',
        label: item.partName,
        modelCode: item.partCode,
        original: item
      }
    })
    flatRes.push(...list)
  }
  if (tableDataLeft.value.workItems) {
    const list = tableDataLeft.value.workItems.map(item => {
      return {
        type: 'works',
        typeName: '工项',
        label: item.workName,
        modelCode: item.workCode,
        original: item
      }
    })
    flatRes.push(...list)
  }
  tableDataLeft.value = flatRes
  tableDataRight.value = layerData.value[libraryLayer.value].partsFlat
  setDisabledDataTransfer()
  dialogTransferVisible.value = true
}

// 设置穿梭框需要禁用的数据 - 就是所有层数的配件集合
const setDisabledDataTransfer = () => {
  let flatLayerData = []
  layerData.value.forEach((item, index) => {
    if (index !== libraryLayer.value && item.partsFlat) {
      flatLayerData.push(...item.partsFlat)
    }
  })
  disabledDataTransfer.value = flatLayerData
}

const closeDecisionLibrary = () => {
  dialogTransferVisible.value = false
}
const handleConfirm = () => {
  const rightValue = transferCmptRef.value.tableDataRight
  layerData.value[libraryLayer.value].partsFlat = cloneDeep(
    rightValue
  )
  closeDecisionLibrary()
}

const transferCmptRef = ref(null)
const tableDataLeft = ref(null)
const tableDataRight = ref(null)
const tableFormat = ref([
  { label: '类型', code: 'typeName' },
  { label: '名称', code: 'label' }
])
</script>

<style lang="less" scoped>
.decision {
  &-btn-control {
    text-align: right;
    &-box {
      display: inline-block;
      width: 295px;
      // width: 150px;
      border: 1px solid #bbb;
      /deep/ .el-button + .el-button {
        margin: 0;
      }
    }
  }

  &-form {
    &-layer {
      margin-bottom: 10px;
      &-link {
        text-decoration: underline;
      }
      /deep/ .el-button.is-link {
        color: #409eff;
      }
      &-buttonbox {
        min-height: 44px;
        border: 1px solid #bbb;
      }
      &-button {
        margin: 10px;
        padding: 0 20px;
        border-radius: 12px;
      }
      &-icon {
        color: #409eff;
        margin-left: 15px;
      }
    }
    &-textarea {
      width: 100%;
    }
    &-selectLibrary {
      width: 300px;
    }
  }

  &-page-submit {
    margin-top: 30px;
    position: relative;
    bottom: 0px;
    width: 100%;
    text-align: right;
  }
}
</style>