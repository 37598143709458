<template>
  <div class="code-info">
    <StatusBar :title="brandModelCode" />
    <div class="code-info-content">
      <div class="code-info-content-label">品牌-车型-故障码：{{ brandModelCode }}</div>
      <div class="code-info-content-label">故障码系统：{{ systems }}</div>
      <div class="code-info-content-label">故障码内容：{{ remark }}</div>
      <div class="code-info-content-label">故障码优先级：{{ priority }}</div>
      <div class="code-info-content-label">关联逻辑树:</div>
      <TableCmpt
        :case-list="tableData"
        :header-list="headerList"
      />
      <PaginationCmpt
        :current-page="page"
        :total="total"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, unref, computed, onMounted } from 'vue'
import { useCarStore } from '@/store/car'
import { useUserStore } from '@/store/user'
import { useRouter } from 'vue-router'
import StatusBar from '@/components/StatusBar'
import TableCmpt from '@/components/TableCmpt'
import PaginationCmpt from '@/components/PaginationCmpt'
import { STATUS_TREE, STATUS_TREE_ABLED } from '@/utils/constants'

const carStore = useCarStore()
const userStore = useUserStore()
const router = useRouter()
const page = ref(1)
const size = ref(10)
const total = ref(10)
let id = null
const TreeInfo = ref({})
const tableData = ref([])
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'treeCode',
    label: '逻辑树编码',
    formatter(row) {
      const {
        systemCode,
        physicalTreeCode,
        serialNumber
      } = row.treeCode
      const treeCode = `${systemCode}${physicalTreeCode}${serialNumber}`
      return (
        <router-link target="_blank" to={`/tree/show?id=${row.id}`}>
          <div>{treeCode}</div>
        </router-link>
      )
    }
  },
  {
    prop: 'treeStatus',
    label: '逻辑树状态',
    formatter(...args) {
      const status = args[2]
      const statusConfig = STATUS_TREE.find(
        item => item.value === status
      )
      return (<div>{statusConfig ? statusConfig.label : '-'}</div>)
    }
  },
  {
    prop: 'relationStatus',
    label: '关联状态',
    formatter(...args) {
      const status = args[2]
      const statusConfig = STATUS_TREE_ABLED.find(
        item => item.value === status
      )
      return (<div>{statusConfig ? statusConfig.label : '-'}</div>)
    }
  },
  {
    prop: 'priority',
    label: '优先级',
  },
  {
    prop: 'authorId',
    label: '编写人',
    align: 'center',
    formatter(...args) {
      const editors = args[2]
      const editorsConfig = unref(userAllList).find(
        item => item.value === editors
      )
      return (<div>{editorsConfig ? editorsConfig.label : '-'}</div>)
    }
  },
  {
    prop: 'reviewerId',
    label: '审核人',
    align: 'center',
    formatter(...args) {
      const checker = args[2]
      const checkerConfig = unref(userAllList).find(
        item => item.value === checker
      )
      return (<div>{checkerConfig ? checkerConfig.label : '-'}</div>)
    }
  },
  {
    prop: 'lastModifiedTime',
    label: '最新操作时间',
  },
]
const userAllList = computed(() => {
  return (userStore.userAllList || []).filter(
    ({ inside }) => inside).map(item => ({
      label: item.userName,
      value: item.id,
      original: item
    })
  )
})
const brandModelCode = computed(() => {
  const {
    carBrand,
    carModel,
    faultCode
  } = unref(TreeInfo)
  if (carBrand) {
    return `${carBrand?.name}-${carModel.name}-${faultCode}`
  }
  return '-'
})
const systems = computed(() => {
  const { systems } = unref(TreeInfo)
  return (systems || []).map(item => item.name).join('、')
})
const remark = computed(() => {
  return unref(TreeInfo).remark
})
const priority = computed(() => {
  return unref(TreeInfo).priority
})

onMounted(async () => {
  const { query } = unref(router.currentRoute)
  if (query?.id) {
    id = query.id
  }
  await userStore.getUserAllInfo()
  getCarFaultCodeInfo()
  getCarFaultCodeRelationTrees()
})

const currentChange = value => {
  page.value = value
  getCarFaultCodeRelationTrees()
}
async function getCarFaultCodeRelationTrees() {
  try {
    const result = await carStore.getCarFaultCodeRelationTrees({
      id,
      page: unref(page),
      size: unref(size),
    })

    tableData.value = result.records
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}
async function getCarFaultCodeInfo() {
  try {
    const result = await carStore.getCarFaultCodeInfo({ id })
    TreeInfo.value = result
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.code-info {
  &-content {
    padding: 20px 30px;

    &-label {
      font-size:14px;
      margin: 10px 0;
      font-weight: bold;
    }
  }
}
</style>