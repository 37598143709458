<template>
  <!-- 需要修改接口数据 -->
  <div>
    <StatusBar :title="brandModelCode" />

    <div class="decision">
      <div class="decision-title">异常状态：</div>
      <div class="decision-content">后倾角：异常数值＜最小值 ； 外倾角：异常数值＞最大值</div>

      <div class="decision-title">关联备注信息：</div>
      <TableCmpt :case-list="tableData" :header-list="headerList" />
      <PaginationCmpt :current-page="page" :total="total" @current-change="currentChange" />
    </div>
  </div>
</template>

<script setup>
import StatusBar from '@/components/StatusBar'
import PaginationCmpt from '@/components/PaginationCmpt'
import TableCmpt from '@/components/TableCmpt'
import { computed, ref } from 'vue'

const brandModelCode = computed(() => {
  return '麦弗逊+单下臂+'
})

const page = ref(1)
const size = ref(10)
const total = ref(0)
const tableData = ref([])
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'label',
    label: '悬挂形式',
    formatter(...args) {
      const { label } = args[2]
      return <div>{label}</div>
    }
  },
  {
    prop: 'authorId',
    label: '外倾角是否可调整',
    formatter(...args) {
      const editors = args[2]
      console.log(editors)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '受损车轮位置',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'lastModifiedTime',
    label: '车轮受力方向',
    formatter(row) {
      const datetime = row.lastModifiedTime
      return <div>{datetime}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '异常状态',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '备注信息',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '附件数量',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'label',
    label: '操作',
    formatter(...args) {
      const { label } = args[2]
      return (
        <router-link target='_blank' to={`/tree/show?id=`}>
          <div>{label}</div>
        </router-link>
      )
    }
  }
]

const search = () => {
  console.log(size)
  // const {
  //   systemCode,
  //   physicalTreeCode,
  //   serialNumber,
  //   status,
  //   checker,
  //   date1,
  //   date2
  // } = form
  // getTreePageList({
  //   treeCode: {
  //     systemCode: systemCode,
  //     physicalTreeCode: physicalTreeCode,
  //     serialNumber: serialNumber
  //   },
  //   treeStatus: status,
  //   reviewerId: checker,
  //   modifiedBeginTime: LocalToGMT(date1),
  //   modifiedEndTime: LocalToGMT(date2)
  // })
}

const currentChange = value => {
  page.value = value
  search()
}
</script>

<style lang="less" scoped>
.decision {
  padding: 0 40px 20px;

  &-title {
    padding: 20px 0;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
}
</style>