<template>
  <div class="log">
    <div class="log-form">
      <el-form :inline="true" :model="form">
        <el-row>
          <el-col :span="24" class="log-form-field">
            <el-form-item label="操作用户:">
              <el-select v-model="form.user" clearable placeholder="请选择">
                <el-option label="德联易控" value="1" />
                <el-option label="平安保险" value="2" />
              </el-select>
            </el-form-item>
            <el-form-item label="操作时间:">
              <el-col :span="11">
                <el-date-picker
                  v-model="form.date1"
                  type="date"
                  placeholder="开始时间"
                  style="width: 100%"
                />
              </el-col>
              <el-col :span="2" class="text-center">
                <span>-</span>
              </el-col>
              <el-col :span="11">
                <el-date-picker
                  v-model="form.date2"
                  type="date"
                  placeholder="结束时间"
                  style="width: 100%"
                />
              </el-col>
            </el-form-item>
            <el-form-item label="操作类型:">
              <el-select v-model="form.type" clearable placeholder="请选择">
                <el-option label="公共管理员" value="1" />
                <el-option label="规则管理员" value="2" />
              </el-select>
            </el-form-item>
            <el-form-item label="操作对象:">
              <el-select v-model="form.target" clearable placeholder="请选择">
                <el-option label="启用" value="1" />
                <el-option label="禁用" value="2" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="log-form-btns">
            <el-button type="primary" @click="search">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
            <el-button type="success" @click="download">下载Excel</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="log-table">
      <TableCmpt
        :case-list="tableData"
        :header-list="headerList"
      />
      <PaginationCmpt
        :current-page="1"
        :total="100"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import TableCmpt from '@/components/TableCmpt'
import PaginationCmpt from '@/components/PaginationCmpt'

function currentChange(value) {
  console.log('页码变化', value)
}

const tableData = [
  {
    user: '用户1',
    type: '登陆操作',
    target: '测试',
    content: '登陆系统',
    dateTime: '2000-11-12 12:12:12'
  },
  {
    user: '用户1',
    type: '登陆操作',
    target: '测试',
    content: '登陆系统',
    dateTime: '2000-11-12 12:12:12'
  },
  {
    user: '用户1',
    type: '登陆操作',
    target: '测试',
    content: '登陆系统',
    dateTime: '2000-11-12 12:12:12'
  },
]

const headerList = [
  {
    type: 'index',
    label: '编号',
    align: 'center',
  },
  {
    prop: 'user',
    label: '操作用户',
    align: 'center',
  },
  {
    prop: 'type',
    label: '操作类型',
    align: 'center',
  },
  {
    prop: 'target',
    label: '操作对象',
    align: 'center',
  },
  {
    prop: 'content',
    label: '操作内容',
    align: 'center',
  },
  {
    prop: 'dateTime',
    label: '操作时间',
    align: 'center',
  }
]

const form = reactive({
  user: '1',
  date1: '',
  date2: '',
  type: '1',
  target: '1',
})

const search = () => {
  console.log('submit!')
}

const reset = () => {
  console.log('reset!')
}

const download = () => {
  console.log('download!')
}
</script>

<style lang="less" scoped>
.log {
  padding: 10px 30px;

  &-form {
    &-field {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>