<template>
  <div class="tree-tools">
    <div>
      <el-button-group>
        <el-button
          v-if="env === 'edit'"
          size="large"
          @click="emits('action', { type: 'undo' })"
        >
          <el-icon>
            <component :is="IconUndo"></component>
          </el-icon>
        </el-button>
        <el-button
          v-if="env === 'edit'"
          size="large"
          @click="emits('action', { type: 'redo' })"
        >
          <el-icon>
            <component :is="IconRedo"></component>
          </el-icon>
        </el-button>
        <el-button class="tree-tools-zoom" size="large" @click="showSlider">
          <span>{{ level }}%</span>
          <el-icon><Operation /></el-icon>
        </el-button>
        <el-button
          v-if="env === 'edit'"
          type="primary"
          size="large"
          @click="emits('action', { type: 'save', duration: duration })"
        >保存</el-button>
      </el-button-group>
    </div>
    <div v-show="sliderStatus" class="tree-tools-slider">
      <el-slider
        v-model="level"
        @change="changeLevel"
        :min="4"
        :max="400"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, watch, defineEmits, defineProps } from 'vue'
import IconRedo from '@/components/icons/IconRedo'
import IconUndo from '@/components/icons/IconUndo'

const emits = defineEmits(['zoom'])
defineProps({
  env: String,
  duration: Number,
})
const level = ref(100)
const sliderStatus = ref(false)
const showSlider = () => {
  sliderStatus.value = !sliderStatus.value
}

watch(level, value => {
  emits('zoom', value)
})
</script>

<style lang="less" scoped>
.tree {
  &-tools {
    position: fixed;
    bottom: 20px;
    right: 20px;
    display: flex;
    align-items: center;
    font-size: 16px;

    &-zoom {
      width: 100px;

      & :deep(.el-icon) {
        margin-left: 10px;
      }
    }

    &-slider {
      width: 100%;
      position: absolute;
      bottom: 60px;
      right: 0px;
    }
  }
}
</style>