<template>
  <!-- 联动选择组件 - 品牌-厂商-车系-车组-车型 -->
  <el-form ref="formRef" :inline="true" :model="form" :rules="rules" :label-width="isExtra ? '226px' : ''">
    <el-row>
      <el-col :span="span" v-if="!isEdit">
        <el-form-item label="品牌:">
          <el-cascader
            v-model="form.cascaderValue"
            :options="cascaderOptions"
            :props="cascaderProps"
            filterable
            clearable
            @change="handleCascaderChange">
          </el-cascader>
        </el-form-item>
      </el-col>
      <template v-if="isExtra">
        <el-col :span="span">
          <el-form-item label="前外倾角是否可调整:" prop="frontCamberAdjustable">
            <el-select v-model="form.frontCamberAdjustable" clearable placeholder="请选择">
              <el-option
                v-for="item in ADJUSTABLE_MENU"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="后外倾角是否可调整:" prop="nearCamberAdjustable">
            <el-select v-model="form.nearCamberAdjustable" clearable placeholder="请选择">
              <el-option
                v-for="item in ADJUSTABLE_MENU"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="前束值是否可调整:" prop="toeAdjustable">
            <el-select v-model="form.toeAdjustable" clearable placeholder="请选择">
              <el-option
                v-for="item in ADJUSTABLE_MENU"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="前上摆臂球头是否单独更换:" prop="frontUpperArmBallHeadSR">
            <el-select v-model="form.frontUpperArmBallHeadSR" clearable placeholder="请选择">
              <el-option
                v-for="item in ADJUSTABLE_MENU"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="前下摆臂球头是否单独更换:" prop="frontLowerArmBallHeadSR">
            <el-select v-model="form.frontLowerArmBallHeadSR" clearable placeholder="请选择">
              <el-option
                v-for="item in ADJUSTABLE_MENU"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="前下控制臂位置位于半轴前:" prop="frontLowerControlArmOTFA">
            <el-select v-model="form.frontLowerControlArmOTFA" clearable placeholder="请选择">
              <el-option
                v-for="item in ADJUSTABLE_MENU"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="前转向横拉杆拉杆球头形状:" prop="frontSteeringCrossTieRodBallHeadShape">
            <el-select v-model="form.frontSteeringCrossTieRodBallHeadShape" clearable placeholder="请选择">
              <el-option
                v-for="item in SHAPE_TYPE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="前拉杆球头位于半轴前:" prop="frontSteeringCrossTieRodBallHeadOTFA">
            <el-select v-model="form.frontSteeringCrossTieRodBallHeadOTFA" clearable placeholder="请选择">
              <el-option
                v-for="item in ADJUSTABLE_MENU"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="span">
          <el-form-item label="前转向节连接上摆臂部位形状:" prop="frontSteeringKnuckleConnectUpperSwingArmShape">
            <el-select v-model="form.frontSteeringKnuckleConnectUpperSwingArmShape" clearable placeholder="请选择">
              <el-option
                v-for="item in SHAPE_TYPE"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
        </el-col>
      </template>
    </el-row>
  </el-form>
</template>

<script setup>
import { reactive, defineProps, computed, ref, defineEmits, watch, onMounted } from 'vue'
import { useEnumSelectStore } from '@/store/enumSelect'
import { ADJUSTABLE_MENU, SHAPE_TYPE } from '@/utils/constants'
import { webVehicleList } from '@/api/presetField'

const enumStore = useEnumSelectStore()
const props = defineProps({
  span: Number,
  isExtra: Boolean,
  rules: Object,
  formDetails: Object,
  isEdit: Boolean
})
const emits = defineEmits(['linkageSelectChange'])

const formRef = ref(null)
const form = reactive({
  cascaderValue: []
})
const submitForm = reactive({})
const BRAND = computed(() => {
  return enumStore.BRAND || enumStore.getVehicleBrand()
})
const MANUFACTURER = ref([])
const SERIES = ref([])
const GROUP = ref([])
const MODEL = ref([])

const cascaderOptions = ref([])
const cascaderProps = {
  checkStrictly: true
}
onMounted(() => {
  cascaderOptions.value = BRAND.value
})
const handleCascaderChange = async(value) => {
  if (!value) return

  const level = value.length

  switch (level) {
    case 1:
      await fetchDataForManufacturer(value)
      break
    case 2:
      await fetchDataForSeries(value)
      break
    case 3:
      await fetchDataForGroup(value)
      break
    case 4:
      await fetchDataForModel(value)
      break
  }

  updateFormFromCascader(value)
}

const fetchDataForManufacturer = async(value) => {
  const result = await enumStore.getVehicleManufacturer({ 'brand-codes': value[0] })
  const mappedOptions = result.map(item => ({ value: item.value, label: item.label, children: [] }))
  MANUFACTURER.value = mappedOptions
  updateChildren(cascaderOptions.value, value, mappedOptions)
}

const fetchDataForSeries = async(value) => {
  const result = await enumStore.getVehicleSeries({ 'manufacturer-codes': value[1] })
  const mappedOptions = result.map(item => ({ value: item.value, label: item.label, children: [] }))
  SERIES.value = mappedOptions

  updateChildren(cascaderOptions.value, value, mappedOptions)
}

const fetchDataForGroup = async(value) => {
  const result = await enumStore.getVehicleGroup({ 'series-codes': value[2] })
  const mappedOptions = result.map(item => ({ value: item.value, label: item.label, children: [] }))
  GROUP.value = mappedOptions
  updateChildren(cascaderOptions.value, value, mappedOptions)
}

const fetchDataForModel = async(value) => {
  const vehicleModelData = await enumStore.getVehicleModel({ 'group-codes': value[3] })
  const existsVehicleModelData = await webVehicleList({
    brandCode: value[0],
    manufacturerCode: value[1],
    seriesCode: value[2],
    groupCode: value[3]
  })
  let mappedOptions = []

  if (props.isExtra) {
    vehicleModelData.forEach(vehicle => {
      existsVehicleModelData.forEach(item => {
        if (vehicle.value === item.modelCode) {
          mappedOptions.push({
            value: vehicle.value,
            label: vehicle.label,
          })
        }
      })
    })

    updateChildren(cascaderOptions.value, value, mappedOptions)
  } else {
    mappedOptions = existsVehicleModelData.length ?
    vehicleModelData.filter(model => !existsVehicleModelData.some(item => model.value === item.modelCode)) :
    vehicleModelData
    submitForm.vehicleModel = mappedOptions
  }

  MODEL.value = mappedOptions
}

const updateFormFromCascader = (val) => {
  const properties = ['brandCode', 'manufacturerCode', 'seriesCode', 'groupCode', 'modelCode']

  properties.forEach((property, index) => {
    form[property] = val[index] || ''
  })
}

const updateChildren = (options, path, children) => {
  let currentOptions = options
  for (let i = 0; i < path.length - 1; i++) {
    const index = currentOptions.findIndex(opt => opt.value === path[i])
    if (index !== -1) {
      currentOptions[index].children = currentOptions[index].children || []
      currentOptions = currentOptions[index].children
    } else {
      return
    }
  }
  const lastValue = path[path.length - 1]
  const lastIndex = currentOptions.findIndex(opt => opt.value === lastValue)
  if (lastIndex !== -1) {
    currentOptions[lastIndex].children = children
  }
}

const getForm = () => {
  const {
    isPart,
    brandCode,
    manufacturerCode,
    seriesCode,
    groupCode,
    modelCode,
    ...reset
  } = form

  if (isPart) {
    return form
  }

  const mapCodeToSubmitForm = (code, data, property, nameProperty) => {
    if (code) {
      const matchItem = data.find(item => item.value == code)
      submitForm[property] = matchItem?.value
      submitForm[nameProperty] = matchItem?.label
    } else {
      submitForm[property] = null
      submitForm[nameProperty] = null
    }
  }

  mapCodeToSubmitForm(brandCode, BRAND.value, 'brandCode', 'brandName')
  mapCodeToSubmitForm(manufacturerCode, MANUFACTURER.value, 'manufacturerCode', 'manufacturerName')
  mapCodeToSubmitForm(seriesCode, SERIES.value, 'seriesCode', 'seriesName')
  mapCodeToSubmitForm(groupCode, GROUP.value, 'groupCode', 'groupName')
  submitForm.vehicleModel = groupCode ? MODEL.value : null
  mapCodeToSubmitForm(modelCode, MODEL.value, 'modelCode', 'modelName')

  return { ...submitForm, ...reset }
}

watch(
  form, () => {
    emits('linkageSelectChange', getForm())
  }
)

watch(
    () => props.formDetails,
    (value) => {
    if (value) {
      Object.assign(form, {
        ...value,
        brandCode: value.brandCode,
        manufacturerCode: value. manufacturerCode,
        modelCode: value.modelCode,
        seriesCode: value.seriesCode,
        groupCode: value.groupCode,
      })
    }
  }, { deep: true, immediate: true }
)

const reset = () => {
  form.cascaderValue = []
  form.brandCode = ''
  form.manufacturerCode = ''
  form.seriesCode = ''
  form.groupCode = ''
  form.modelCode = ''
  form.frontCamberAdjustable = ''
  form.nearCamberAdjustable = ''
  form.toeAdjustable = ''
  form.frontUpperArmBallHeadSR = ''
  form.frontLowerArmBallHeadSR = ''
  form.frontLowerControlArmOTFA = ''
  form.frontSteeringCrossTieRodBallHeadShape = ''
  form.frontSteeringCrossTieRodBallHeadOTFA = ''
  form.frontSteeringKnuckleConnectUpperSwingArmShape = ''
}

const validate = async () => {
  let res = false
  await formRef.value.validate(valid => {
    if (valid) {
      res = true
    } else {
      res = false
    }
  })
  return res
}

// eslint-disable-next-line no-undef
defineExpose({
  getForm,
  reset,
  validate
})
</script>