<template>
  <el-dialog
    v-model="show"
    title="增加故障码集合:"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-form :model="form">
      <div class="code-set-item">
        <div class="code-set-label">选择故障码集合类型:</div>
        <div class="code-set-content">
          <el-radio-group v-model="form.setType">
            <el-radio label="WITHIN">故障码判断集合(是否包含)</el-radio>
            <el-radio label="FORWARD">故障码跳转集合</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="code-set-item">
        <div class="code-set-label">新增故障码:</div>
        <div class="code-set-content">
          <el-cascader
            :props="cascaderProps"
            v-model="form.faultCode"
          />
          <el-button
            type="primary"
            class="code-set-add"
            @click="addCode"
          >新增</el-button>
        </div>
      </div>
      <div class="code-set-item code-set-list">
        <ItemCmpt
          v-for="item in fieldList"
          :key="item.id"
          :item="item"
          icon="Close"
          @select="deleteField"
        />
      </div>
      <div style="text-align:right;">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  computed,
  reactive,
  ref,
  unref,
  watch
} from 'vue'
import { useCarStore } from '@/store/car'
import ItemCmpt from '@/components/ItemCmpt'
import { ElMessage } from 'element-plus';

const emits = defineEmits(['update:modelValue', 'confirm'])
const props = defineProps({
  modelValue: Boolean,
  faultCodeSet: Object,
  list: Array,
})

const carStore = useCarStore()
const fieldList = ref([])
const form = reactive({
  setType: 'FORWARD',
  faultCode: null,
  brands: [],
  models: [],
  codes: [],
})
let optionsLevelValue = null
const cascaderProps = {
  lazy: true,
  lazyLoad(node, resolve) {
    const { level, value } = node
    if(level === 0) {
      carStore.getCarBrandOptions().then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          original: item,
          leaf: level >= 2,
        }))
        form.brands = nodes
        resolve(nodes)
      })
    }
    if (level === 1) {
      optionsLevelValue = value
      carStore.getCarModelOptions({
        carBrandId: value
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          original: item,
          leaf: level >= 2,
        }))
        form.models = nodes
        resolve(nodes)
      })
    }
    if (level === 2) {
      carStore.getCarFaultCodeOptions({
        carBrandId: optionsLevelValue,
        carModelId: value,
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          original: item,
          leaf: level >= 2,
        }))
        form.codes = nodes
        resolve(nodes)
      })
    }
  },
}
const show = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})
const cancel = () => {
  show.value = false
}
const confirm = () => {
  emits('confirm', {
    opt: props.faultCodeSet.opt,
    index: props.faultCodeSet.index,
    type: form.setType,
    fieldList: unref(fieldList).map(item => {
      return {
        id: (item.key || item.id),
        faultCode: item.label,
      }
    }),
  })
  cancel()
}
const addCode = () => {
  const { codes, faultCode } = form
  const [ , , faultCodeId ] = faultCode
  const target = codes.find(
    item => item.value === faultCodeId
  )
  const faultCodeList = (props.list?.map(item => {
    return item.faultCodes?.map(code => code.id)
  })).flat(Infinity)

  const fieldListIds = unref(fieldList).map(({ key }) => key)
  faultCodeList.push(...fieldListIds)

  if (faultCodeList.includes(target.value)) {
    ElMessage.warning('故障码重复')
    return
  }

  fieldList.value.push({
    ...target.original,
    ...target,
  })
}
const deleteField = (item) => {
  fieldList.value = fieldList.value?.filter(
    ({ key, id }) => (key || id) !== (item.key || item.id)
  )
}
watch(props.faultCodeSet, (value) => {
  form.setType = value?.type
  fieldList.value = value.faultCodes?.map(
    ({ id, faultCode}) => ({ id, label:faultCode })
  )
}, { deep: true })
</script>

<style lang="less" scoped>
.code-set {
  &-label {
    font-size: 15px;
    margin: 10px 0;
  }

  &-content {
    padding: 5px 20px;
  }

  &-add {
    margin-left: 30px;
  }

  &-list {
    display: flex;  
    flex-wrap: wrap;
    margin-top: 10px;
  }
}
</style>