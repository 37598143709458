import { defineStore } from "pinia"
import {
  getVehicleBrand,
  getVehicleManufacturer,
  getVehicleSeries,
  getVehicleGroup,
  getVehicleModel,
  getVehicleType,
  ruleSparePartGroupQuery,
  ruleSparePartQuery
} from '@/api/enumSelect'
import { getWheelIndexItemList } from '@/api/presetField';
import { ADJUSTABLE_MENU, DECISION_STATE, DECISION_LAYERS, RELATION_STATE, DECISION_PLAN_TYPE, } from "@/utils/constants";

export const useEnumSelectStore = defineStore('linkageSelect', {
  persist: true,
  state: () => {
    return {
      ADJUSTABLE_MENU, // 是否
      DECISION_STATE, // 决策状态
      DECISION_LAYERS, // 决策层数
      RELATION_STATE, // 决策启用状态
      DECISION_PLAN_TYPE, // 决策方案类型

      BRAND: null, // 品牌
      // SUSPENSION_TYPE: null, // 悬挂形式
      DAMAGED_WHEEL_POSITION: null, // 受损车轮位置
      WHEEL_FORCE_DIRECTION: null, // 车轮受力方向
      DAMAGE_CATEGORY: null, // 损伤类别
      ABNORMAL_TYPE: null, // 异常数值状态
      VEHICLE_TYPE_LIST: null, // 车身大类
      RULE_SPARE_PART_GROUP_TREE: null, // 配件分组查询
      RULE_SPARE_PART_LIST: null, // 配件查询

      menuType: 'carCode_treeCode', // 控制菜单显示
    }
  },
  actions: {
    async reset() {
      this.BRAND = null
    },

    setMenuType (data) {
      this.menuType = data
    },

    // 获取品牌
    async getVehicleBrand(data) {
      try {
        const result = await getVehicleBrand(data)
        const reflectResult = result.map(item => {
          return {
            label: item.name,
            value: item.code,
            original: item
          }
        })
        this.BRAND = reflectResult
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取厂商
    async getVehicleManufacturer(data) {
      try {
        const result = await getVehicleManufacturer(data)
        const reflectResult = result.map(item => {
          return {
            label: item.name,
            value: item.code,
            original: item
          }
        })
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取车系
    async getVehicleSeries(data) {
      try {
        const result = await getVehicleSeries(data)
        const reflectResult = result.map(item => {
          return {
            label: item.name,
            value: item.code,
            original: item
          }
        })
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取车组
    async getVehicleGroup(data) {
      try {
        const result = await getVehicleGroup(data)
        const reflectResult = result.map(item => {
          return {
            label: item.name,
            value: item.code,
            original: item
          }
        })
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取车型
    async getVehicleModel(data) {
      try {
        const result = await getVehicleModel(data)
        const reflectResult = result.map(item => {
          return {
            label: item.name,
            value: item.code,
            original: item
          }
        })
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取悬挂形式
    async getWheelIndexItemList() {
      try {
        const result = await getWheelIndexItemList({ indexCategory: 'SUSPENSION_TYPE' })
        const reflectResult = result.map(item => {
          return {
            label: item.indexName,
            value: item.indexId,
            code: item.indexCode,
            disabled: item.disabled,
            original: item,
          }
        })
        this.SUSPENSION_TYPE = reflectResult
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取所有枚举 Name
    async getEnumName(enumItem, value) {
      if (enumItem === 'SUSPENSION_TYPE') {
        await this.getWheelIndexItemList()
      }
      if (!this[enumItem]) {
        return
      }
      const target = this[enumItem].find(item => item.value == value)
      return target?.label || ''
    },

    // 获取所有枚举 Name
    getEnumName2(enumItem, value) {
      if (!this[enumItem]) {
        return
      }
      const target = this[enumItem].find(item => item.value == value)
      return target?.label || ''
    },

    // 获取受损车轮位置
    async getWheelEnumDamageWheelPosition() {
      try {
        const result = await getWheelIndexItemList({ indexCategory: 'DAMAGED_WHEEL_POSITION' })
        const reflectResult = result.map(item => {
          return {
            label: item.indexName,
            value: item.indexCode,
            code: item.indexCode,
            disabled: item.disabled,
            original: item,
          }
        })
        this.DAMAGED_WHEEL_POSITION = reflectResult
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取车轮受力方向
    async getWheelEnumForceDirection() {
      try {
        const result = await getWheelIndexItemList({ indexCategory: 'WHEEL_FORCE_DIRECTION' })
        const reflectResult = result.map(item => {
          return {
            label: item.indexName,
            value: item.indexCode,
            code: item.indexCode,
            disabled: item.disabled,
            original: item,
          }
        })
        this.WHEEL_FORCE_DIRECTION = reflectResult
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取损伤类别
    async getWheelEnumDamagedCategory() {
      try {
        const result = await getWheelIndexItemList({ indexCategory: 'DAMAGE_CATEGORY' })
        const reflectResult = result.map(item => {
          return {
            label: item.indexName,
            value: item.indexCode,
            code: item.indexCode,
            disabled: item.disabled,
            original: item,
          }
        })
        this.DAMAGE_CATEGORY = reflectResult
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取异常数值状态
    async getWheelEnumAbnormalType() {
      try {
        const result = await getWheelIndexItemList({ indexCategory: 'ABNORMAL_TYPE' })
        const reflectResult = result.map(item => {
          return {
            label: item.indexName,
            value: item.indexCode,
            code: item.indexCode,
            disabled: item.disabled,
            original: item,
          }
        })
        this.ABNORMAL_TYPE = reflectResult
        return reflectResult
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 车身大类
    async getVehicleType() {
      try {
        const result = await getVehicleType()
        this.VEHICLE_TYPE_LIST = result.memberList
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 配件分组查询ruleSparePartQuery
    async ruleSparePartGroupQuery(data) {
      try {
        const result = await ruleSparePartGroupQuery(data)
        this.RULE_SPARE_PART_GROUP_TREE = result.data
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 配件查询
    async ruleSparePartQuery(data) {
      try {
        const result = await ruleSparePartQuery(data)
        this.RULE_SPARE_PART_LIST = result.data
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

  }
})