import request from "@/utils/request"

/**
 * =============================
 *        车型悬挂管理            
 * =============================
 */
// 分页查询
export function getWheelVehicleList(data) {
  return request({
    url: `/wheelalignment/web/vehicle/page`,
    method: 'post',
    data
  })
}

// 规则系统/车型查询
export function getWheelRuleModelQuery(data) {
  return request({
    url: '/v1/data/vehicle/vehicle-manage/rule/model/query',
    method: 'post',
    data
  })
}

/**
 * =============================
 *       决策信息资源库管理            
 * =============================
 */
// 资源库 - 分页查询
export function getWheelLibraryPage(data) {
  return request({
    url: `/wheelalignment/web/library/page`,
    method: 'post',
    data
  })
}

// 资源库 - 新增
export function saveWheelLibrary(data) {
  return request({
    url: `/wheelalignment/web/library/save`,
    method: 'post',
    data
  })
}

// 资源库 - 删除
export function deleteWheelLibrary(data) {
  return request({
    url: `/wheelalignment/web/library/remove`,
    method: 'post',
    data
  })
}

// 资源项 - 获取资源库资源项（By 悬挂形式）
export function getWheelLibraryByLibraryIndex(params) {
  return request({
    url: `/wheelalignment/web/library/item/listByLibraryIndex`,
    method: 'get',
    params
  })
}

// 资源项 - 获取资源库资源项（By 资源库 ID）
export function getWheelLibraryByLibraryId(params) {
  return request({
    url: `/wheelalignment/web/library/item/listByLibraryId`,
    method: 'get',
    params
  })
}

// 资源项 - 添加资源项
export function saveBatchWheelLibraryItem(data) {
  return request({
    url: `/wheelalignment/web/library/item/saveBatch`,
    method: 'post',
    data
  })
}

// 资源项 - 删除资源项
export function deleteWheelLibraryItem(data) {
  return request({
    url: `/wheelalignment/web/library/item/remove`,
    method: 'post',
    data
  })
}

// 标签 - 添加资源项  标签就是  受损车轮位置的枚举
export function saveWheelLibraryTag(data) {
  return request({
    url: `/wheelalignment/web/library/item/tag/save`,
    method: 'post',
    data
  })
}

// 标签 - 添加资源项 - 批量操作
export function webLibraryItemTagSaveBatch(data) {
  return request({
    url: '/wheelalignment/web/library/item/tag/saveBatch',
    method: 'post',
    data
  })
}

// 标签 - 删除资源项
export function deleteWheelLibraryTag(data) {
  return request({
    url: `/wheelalignment/web/library/item/tag/remove`,
    method: 'post',
    data
  })
}

// 标签 - 获取资源项
export function getWheelLibraryTag(params) {
  return request({
    url: `/wheelalignment/web/library/item/tag/listByLibraryIndex`,
    method: 'get',
    params
  })
}

// 工项 - 新增工项
export function saveWheelLibraryWork(data) {
  return request({
    url: `/wheelalignment/web/work/save`,
    method: 'post',
    data
  })
}

// 工项 - 删除工项
export function deleteWheelLibraryWork(data) {
  return request({
    url: `/wheelalignment/web/work/remove`,
    method: 'post',
    data
  })
}

// 工项 - 获取工项列表
export function getWheelLibraryWorkList(params) {
  return request({
    url: `/wheelalignment/web/work/list`,
    method: 'get',
    params
  })
}

// 备注 - 添加备注
export function saveWheelLibraryRemark(data) {
  return request({
    url: `/wheelalignment/web/library/item/remark/save`,
    method: 'post',
    data
  })
}

// 备注 - 删除备注
export function deleteWheelLibraryRemark(data) {
  return request({
    url: `/wheelalignment/web/library/item/remark/remove`,
    method: 'post',
    data
  })
}

// 备注 - 获取备注列表
export function getWheelLibraryRemarkList(data) {
  return request({
    url: `/wheelalignment/web/library/item/remark/list`,
    method: 'post',
    data
  })
}

// 备注 - 更新备注
export function updateWheelLibraryRemark(data) {
  return request({
    url: `/wheelalignment/web/library/item/remark/update`,
    method: 'post',
    data
  })
}

/**
 * =============================
 *       四轮定位索引            
 * =============================
 */

// 分页获取全量索引
export function getWheelCompleteIndex(data) {
  return request({
    url: `/wheelalignment/web/indexItem/completeIndex/page`,
    method: 'post',
    data
  })
}

// 更新全量索引状态
export function updateStateWheelCompleteIndex(data) {
  return request({
    url: `/wheelalignment/web/indexItem/completeIndex/updateState`,
    method: 'post',
    data
  })
}

// 获取全量索引（By 索引ID）
export function getWheelCompleteIndexGet(params) {
  return request({
    url: `/wheelalignment/web/indexItem/completeIndex/get`,
    method: 'get',
    params
  })
}

// 获取全量索引集
export function getWheelCompleteIndexList(data) {
  return request({
    url: `/wheelalignment/web/indexItem/completeIndex/list`,
    method: 'post',
    data
  })
}

/**
 * =============================
 *         组合索引备注            
 * =============================
 */

// 分页获取组合索引备注
export function getWheelIndexRemark(data) {
  return request({
    url: `/wheelalignment/web/indexItem/combinationIndex/remark/page`,
    method: 'post',
    data
  })
}

// 获取组合索引备注信息
export function getWheelIndexRemarkGet(params) {
  return request({
    url: `/wheelalignment/web/indexItem/combinationIndex/remark/get`,
    method: 'get',
    params
  })
}

// 初始化备注（新建）
export function initWheelIndexRemark(data) {
  return request({
    url: `/wheelalignment/web/indexItem/combinationIndex/remark/init`,
    method: 'post',
    data
  })
}

// 更新备注
export function updateWheelIndexRemark(data) {
  return request({
    url: `/wheelalignment/web/indexItem/combinationIndex/remark/init`,
    method: 'post',
    data
  })
}

// 删除备注
export function deleteWheelIndexRemark(data) {
  return request({
    url: `/wheelalignment/web/indexItem/combinationIndex/remark/remove`,
    method: 'post',
    data
  })
}

/**
 * =============================
 *         决策管理            
 * =============================
 */

// 分页获取决策信息
export function getWheelDecisionList(data) {
  return request({
    url: `/wheelalignment/web/decision/page`,
    method: 'post',
    data
  })
}

// 获取决策信息（By 决策ID）
export function getWheelDecisionListGet(params) {
  return request({
    url: `/wheelalignment/web/decision/get`,
    method: 'get',
    params
  })
}

// 新增决策
export function saveWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/save`,
    method: 'post',
    data
  })
}

// 更新决策
export function updateWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/update`,
    method: 'post',
    data
  })
}

// 提交决策
export function submitWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/submit`,
    method: 'post',
    data
  })
}

// 转交
export function forwardWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/forward`,
    method: 'post',
    data
  })
}

// 审核
export function reviewWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/review`,
    method: 'post',
    data
  })
}

// 删除
export function deleteWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/remove`,
    method: 'post',
    data
  })
}

// 关联-绑定决策
export function bindIndexWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/bindCompleteIndex`,
    method: 'post',
    data
  })
}

// 关联-删除（By 关联 ID）
export function deleteIndexWheelDecisionRelationById(data) {
  return request({
    url: `/wheelalignment/web/decision/removeRelationById`,
    method: 'post',
    data
  })
}

// 关联-删除（By 索引ID + 决策ID）
export function deleteIndexWheelDecisionRelation(data) {
  return request({
    url: `/wheelalignment/web/decision/removeRelation`,
    method: 'post',
    data
  })
}

// 关联-启用、停用
export function updateStateWheelDecision(data) {
  return request({
    url: `/wheelalignment/web/decision/updateRelationState`,
    method: 'post',
    data
  })
}

// 获取【索引】对应的【决策列表】
export function getWheelListCompleteIndexDecisions(params) {
  return request({
    url: `/wheelalignment/web/decision/listCompleteIndexDecisions`,
    method: 'get',
    params
  })
}

// 获取【决策】关联的【索引列表】
export function getWheelListDecisionCompleteIndexes(params) {
  return request({
    url: `/wheelalignment/web/decision/listDecisionCompleteIndexes`,
    method: 'get',
    params
  })
}

// 记录：决策-审核
export function getWheelListReviewRecord(params) {
  return request({
    url: `/wheelalignment/web/record/decision/listReviewRecord`,
    method: 'get',
    params
  })
}

// 记录：决策-转交
export function getWheelListForwardRecord(params) {
  return request({
    url: `/wheelalignment/web/record/decision/listForwardRecord`,
    method: 'get',
    params
  })
}

// 记录：决策-提交
export function getWheelListReviewSubmitRecord(params) {
  return request({
    url: `/wheelalignment/web/record/decision/listReviewSubmit`,
    method: 'get',
    params
  })
}

// 记录：关联-索引关联记录
export function getWheelListCompleteIndexOperate(params) {
  return request({
    url: `/wheelalignment/web/record/relation/completeIndex/listCompleteIndexOperate`,
    method: 'get',
    params
  })
}
