import request from "@/utils/request"

/**
 * =============================
 *              登录            
 * =============================
 */
// 获取验证码
export function getAuthCode(username) {
  return request({
    url: `/check/auth/getAuthCode/${username}`,
    method: 'get',
  })
}

// 登录
export function login(data) {
  return request({
    url: '/check/auth/sign-in',
    method: 'post',
    data,
  })
}

// 退出登录
export function logout(flag, data) {
  return request({
    url: `/check/auth/logOut/${flag}`,
    method: 'post',
    data,
  })
}

// 更新token
export function updateAuth(params) {
  return request({
    url: '/check/auth/renew',
    method: 'get',
    params,
  })
}


/**
 * =============================
 *            用户信息           
 * =============================
 */
// 修改密码
export function updatePassword(data) {
  return request({
    url: '/check/user/password',
    method: 'put',
    data,
  })
}

// 修改个人信息
export function updateUserInfo(data) {
  return request({
    url: '/check/user/update',
    method: 'put',
    data,
  })
}

// 获取所有用户信息
export function getUserAllInfo() {
  return request({
    url: '/check/user/all',
    method: 'get',
  })
}

// 文件上传 - 获取OSS上传STS授权
export function getOSSSTSAuth() {
  return request({
    url: '/dlyk-decision/common/stsToken',
    method: 'get'
  })
}

// CE - 车身大类字典
export function getEnumVehicleType() {
  return request({
    url: '/check/enum/VehicleType',
    method: 'get',
  })
}