<template>
  <div class="decision-info">
    <StatusBar :title="brandModelCode">
      <template #tree-info>
        <div class="decision-info-title-more">
          <div class="decision-info-title-more-list">编写人：{{ operator.userName || '-' }}</div>
          <div v-if="reviewer" class="decision-info-title-more-list">审核人：{{ reviewer }}</div>
          <div v-if="operateTime" class="decision-info-title-more-list">操作时间：{{ operateTime }}</div>
        </div>
      </template>
    </StatusBar>

    <div class="decision">
      <div class="decision-title">决策索引：</div>
      <TableCmpt :case-list="tableData" :header-list="headerList" />

      <div class="decision-title">更换方案：</div>
      <ChangePlanCmpt
        :operator="operator"
        :propLayerData="layerData"
        :decisionPlanType="decisionInfo && decisionInfo.decisionPlanType"
        :unreasonablePlanContent="decisionInfo && decisionInfo.unreasonablePlanContent"
        @submitAddDecisoin="submitAddDecisoin"
      />
    </div>

    <EvidenceCmpt v-model="evidenceDrawer" :info="evidenceInfo" :isEdit="false" />
  </div>
</template>

<script setup>
import StatusBar from '@/components/StatusBar'
import TableCmpt from '@/components/TableCmpt'
import EvidenceCmpt from '@/views/commonConfig/components/EvidenceCmpt.vue'
import { computed, ref, onMounted } from 'vue'
import ChangePlanCmpt from '@/views/wheelAlignment/components/ChangePlanCmpt'
import { useUserStore } from '@/store/user'
import { useWheelStore } from '@/store/wheelAlignment'
import { ElMessage } from 'element-plus'
import { useRoute, useRouter } from 'vue-router'
import { viewDecisionFormat } from '@/views/wheelAlignment/wheelAlignment'
import { useEnumSelectStore } from '@/store/enumSelect'
import {
  isOrNot,
  isEnabled,
  getAbnormalStateName
} from '@/views/wheelAlignment/wheelCommon'

const userStore = useUserStore()
const wheelStore = useWheelStore()
const enumStore = useEnumSelectStore()
const route = useRoute()
const router = useRouter()
const brandModelCode = computed(() => {
  return ''
})
const operator = computed(() => {
  const userInfo = userStore?.userInfo
  return userInfo
})

const SUSPENSION_TYPE = ref(null)
const DAMAGED_WHEEL_POSITION = computed(() => {
  return (
    enumStore.DAMAGED_WHEEL_POSITION ||
    enumStore.getWheelEnumDamageWheelPosition()
  )
})
const WHEEL_FORCE_DIRECTION = computed(() => {
  return (
    enumStore.WHEEL_FORCE_DIRECTION || enumStore.getWheelEnumForceDirection()
  )
})
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})

onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
  if (route.query.decisionId) {
    getWheelListDecisionCompleteIndexes()
    getWheelDecisionListGet()
  }
})

// 获取索引列表
const getWheelListDecisionCompleteIndexes = async () => {
  try {
    const res = await wheelStore.getWheelListDecisionCompleteIndexes({
      decisionId: route.query.decisionId
    })
    getTableData.value = res.map(item => {
      return {
        suspensionTypeName: SUSPENSION_TYPE.value.find(
          enumItem => enumItem.value == item.suspensionType
        )?.label,
        damagedWheelPositionName: DAMAGED_WHEEL_POSITION.value.find(
          enumItem => enumItem.value == item.damagedWheelPosition
        )?.label,
        wheelForceDirectionName: WHEEL_FORCE_DIRECTION.value.find(
          enumItem => enumItem.value == item.wheelForceDirection
        )?.label,
        abnormalStateName: getAbnormalStateName(item, ABNORMAL_TYPE.value),
        ...item
      }
    })
  } catch (error) {
    console.log(error)
  }
}
// 获取决策信息
const getWheelDecisionListGet = async () => {
  try {
    const res = await wheelStore.getWheelDecisionListGet({
      decisionId: route.query.decisionId
    })
    decisionInfo.value = res
    layerData.value = viewDecisionFormat(res)
    layerData.value = layerData.value.map(item => {
      return {
        checked: false,
        partsFlat: item
      }
    })
  } catch (error) {
    console.log(error)
  }
}

const reviewer = ref('')
const operateTime = ref('')
const evidenceDrawer = ref(false)
const evidenceInfo = ref({})
const layerData = ref(null)
const decisionInfo = ref(null)
const getTableData = ref(null)

const tableData = computed(() => {
  return getTableData.value || wheelStore.addDecisionIndexList
})
const submitAddDecisoin = async planData => {
  try {
    const submitData = {
      operator: operator.value.id,
      completeIndexIds: tableData.value.map(item => item.id),
      ...planData
    }
    let resultDecisionId = ''

    if (route.query.decisionId && !route.query.isCopy) {
      submitData.decisionId = route.query.decisionId
      delete submitData.completeIndexIds
      resultDecisionId = await wheelStore.updateWheelDecision(submitData)
    } else {
      resultDecisionId = await wheelStore.saveWheelDecision(submitData)
    }

    ElMessage.success(
      route.query.decisionId && !route.query.isCopy ? '修改成功' : '新增成功'
    )
    router.push({
      path: '/wheelAlignment/decision/info',
      query: { decisionId: resultDecisionId }
    })
  } catch (error) {
    ElMessage.error(error)
  }
}

const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'suspensionTypeName',
    label: '悬挂形式'
  },
  {
    prop: 'authorId',
    label: '外倾角是否可调整',
    formatter(row) {
      const result = isOrNot(row.adjustable)
      return <div>{result}</div>
    }
  },
  {
    prop: 'damagedWheelPositionName',
    label: '受损车轮位置'
  },
  {
    prop: 'wheelForceDirectionName',
    label: '车轮受力方向'
  },
  {
    prop: 'abnormalStateName',
    label: '异常状态',
    width: '600px'
  },
  {
    prop: 'enabledDecision',
    label: '是否有启用决策',
    formatter(row) {
      const result = isEnabled(row.enabledDecision)
      return <div>{result}</div>
    }
  },
  {
    prop: 'relationState',
    label: '本决策启用状态',
    formatter(row) {
      const relationStateName = enumStore.getEnumName2(
        'RELATION_STATE',
        row.relationState
      )
      return <div>{relationStateName || '-'}</div>
    }
  },
  {
    prop: 'label',
    label: '操作',
    width: '165px',
    formatter(...args) {
      console.log(args)
      return (
        <div class='decision-icon' style='font-size: 20px;'>
          <router-link
            target='_blank'
            to={`/wheelAlignment/index/decision?completeIndexId=${args[0].id}`}
          >
            <Connection class='decision-icon-common decision-icon-connection' />
          </router-link>
        </div>
        // <router-link target='_blank' to={`/wheelAlignment/index/records?id=`}>
        //     <Warning class='decision-icon-common decision-icon-warning' />
        //   </router-link>
        //   <router-link
        //     target='_blank'
        //     to={`/wheelAlignment/index/sameIndex?id=`}
        //   >
        //     <Refresh class='decision-icon-common decision-icon-connection' />
        //   </router-link>
      )
    }
  }
]
</script>

<style lang="less" scoped>
.decision {
  padding: 0 40px 20px;

  &-title {
    padding: 20px 0;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
  &-icon {
    color: #409eff;
    cursor: pointer;
  }
}

.decision-info {
  &-title-more {
    display: flex;
    margin-left: 10px;
    &-list {
      margin: 0 15px;
    }
  }
}

/deep/ .decision-icon {
  &-common {
    width: 1em;
    height: 1em;
    margin: 0 7px;
    cursor: pointer;
    vertical-align: -2px;
  }
  &-warning,
  &-connection {
    color: #409eff;
  }
  &-remove {
    color: #f56c6c;
  }
  &-disabled {
    color: #aaa;
    cursor: not-allowed;
  }
}
</style>