<template>
  <div class="transfer">
    <!-- left -->
    <div class="transfer-box" :class="{ 'transfer-box-border': hasBorder }">
      <div v-if="title" class="transfer-box-title">{{ title }}</div>
      <el-form
        v-if="searchCmpt"
        :inline="true"
        :model="form"
        label-position="left"
        class="transfer-box-form"
      >
        <el-row>
          <el-col :span="12" :offset="1">
            <el-form-item :label="`${searchObj.label}:`">
              <template v-if="searchObj.labelType === 'select'">
                <el-select
                  v-model="form[searchObj.keyName]"
                  clearable
                  filterable
                  :placeholder="`请选择${searchObj.label}`"
                  @change="modelChanged"
                  @clear="modelCleared"
                >
                  <el-option
                    v-for="item in modelSelectData"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </template>
              <template v-else-if="searchObj.labelType === 'input'">
                <el-input
                  v-model="form[searchObj.keyName]"
                  :placeholder="`请选择${searchObj.label}`"
                  clearable
                  @clear="modelCleared"
                />
              </template>
            </el-form-item>
          </el-col>
          <el-col :span="10" style="text-align: right">
            <el-button type="primary" @click="transferSearch('query')"
              >查询</el-button
            >
            <el-button
              type="primary"
              @click="transferSearch('reset')"
              class="transfer-box-form-reset"
              >重置</el-button
            >
          </el-col>
        </el-row>
      </el-form>

      <el-table
        ref="multipleTableRefLeft"
        show-header
        stripe
        border
        :data="tableDataLeft"
        :max-height="leftHeight"
        class="transfer-box-table"
        :show-overflow-tooltip="true"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          align="center"
          label
          prop
          type="selection"
          :selectable="selectableFn"
        />
        <el-table-column
          align="center"
          label="序号"
          prop
          type="index"
          width="60px"
        />
        <el-table-column
          align="center"
          v-for="(item, index) in tableFormat"
          :key="index"
          :label="item.label"
          :prop="item.code"
        />

        <el-table-column align="center" class="icon" width="60px">
          <template #header>
            <Right class="icon-common icon-edit" @click="moveAllToRight()" />
          </template>
          <template #default="scope">
            <el-button
              class="transfer-box-table-icon-move"
              :disabled="!selectableFn(scope.row)"
              @click="moveToRight(scope.row)"
            >
              <Right
                class="icon-common icon-edit"
                :class="{ 'icon-disabled': !selectableFn(scope.row) }"
              />
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- right -->
    <div class="transfer-box">
      <el-table
        ref="multipleTableRefRight"
        show-header
        stripe
        border
        :data="tableDataRight"
        max-height="498px"
        class="transfer-box-table"
        :show-overflow-tooltip="true"
      >
        <el-table-column align="center" label prop type="selection" />
        <el-table-column
          align="center"
          label="序号"
          prop
          type="index"
          width="60px"
        />
        <el-table-column
          align="center"
          v-for="(item, index) in tableFormatRight.length
            ? tableFormatRight
            : tableFormat"
          :key="index"
          :label="item.label"
          :prop="item.code"
        >
          <template #default="{ row }" v-if="item.labelType === 'select'">
            <el-select v-model="row[item.code]" size="small" multiple>
              <el-option
                v-for="opt in item.enumList"
                :key="opt.value"
                :label="opt.label"
                :value="opt.value"
              />
            </el-select>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          class="icon"
          :width="!(isSort || isPartEdit) ? '60px' : '125px'"
        >
          <template #header>
            <Edit
              v-if="isPartEdit"
              class="icon-common icon-edit"
              @click="editFn('batch')"
            />
            <Delete class="icon-common icon-delete" @click="deleteAll" />
          </template>
          <template #default="scope">
            <span v-if="isPartEdit">
              <el-button
                type="primary"
                text
                class="icon-common icon-edit"
                @click="editFn('single', scope.row)"
              >
                <el-icon>
                  <Edit />
                </el-icon>
              </el-button>
            </span>
            <span v-if="isSort">
              <el-button
                type="primary"
                text
                class="icon-common icon-edit"
                :disabled="scope.$index === 0"
                @click="selectTop(scope.row, scope.$index)"
              >
                <el-icon>
                  <Upload />
                </el-icon>
              </el-button>
              <el-button
                type="primary"
                text
                class="icon-common icon-edit"
                :disabled="scope.$index === tableDataRight.length - 1"
                @click="selectBottom(scope.row, scope.$index)"
              >
                <el-icon>
                  <Download />
                </el-icon>
              </el-button>
            </span>

            <el-button
              type="primary"
              text
              class="icon-common icon-delete"
              @click="deleteSelect(scope.row)"
            >
              <el-icon>
                <Delete />
              </el-icon>
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <SuspensionEditCmpt
      ref="suspensionEditCmptRef"
      v-model="dialogAddGroupVisible"
      :info="editInfo"
      :isPart="true"
      :isSingle="isSingle"
      @getPartInfo="getPartInfo"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  reactive,
  ref,
  computed,
  defineEmits,
  watchEffect,
} from 'vue'
import SuspensionEditCmpt from '@/views/commonConfig/components/SuspensionEditCmpt'
import { ElMessage } from 'element-plus'

const props = defineProps({
  title: { type: String, default: '' },
  searchObj: {
    type: Object,
    default: () => ({
      label: '关键字',
      keyName: 'keyWord',
      labelType: 'select',
    }),
  },
  hasBorder: { type: Boolean, default: true },
  isSort: { type: Boolean, default: false }, // 是否有排序功能
  isPartEdit: { type: Boolean, default: false }, // 是否有设置特殊配件功能
  searchCmpt: { type: Boolean, default: true }, // 是否有搜索框
  tableDataLeft: {
    type: Array,
    default: () => [],
  },
  tableDataRightConfig: {
    type: Array,
    default: () => [],
  },
  disabledData: { type: Array, default: () => [] }, // 左侧需要禁用的数据
  tableFormat: { type: Array, default: () => [] }, // 表格里的参数
  tableFormatRight: { type: Array, default: () => [] }, // 右侧表格里的参数
  modelSelectData: { type: Array, default: () => [] }, // 车型选择下拉列表数据
})
const emits = defineEmits(['transferSearch', 'refreshRow'])

const transferSearch = (type) => {
  if (type === 'reset') {
    form[props.searchObj.keyName] = ''
  }

  emits('transferSearch', { type, keyWord: form[props.searchObj.keyName] })
}

const leftHeight = computed(() => {
  if (!props.searchCmpt) {
    return '498px'
  }
  return props.title ? '406px' : '448px'
})
const multipleTableRefLeft = ref(null)
const multipleTableRefRight = ref(null)

const form = reactive({ keyWord: '' })
const tableDataRight = ref([])

// 右侧数据 如果需要默认值就用这个参数传进来
watchEffect(() => {
  if (props.tableDataRightConfig && props.tableDataRightConfig.length) {
    tableDataRight.value = props.tableDataRightConfig
  } else {
    tableDataRight.value = []
  }
})

const moveToRight = (row) => {
  const isSelect = tableDataRight.value.some(
    (item) => row.modelCode === item.modelCode
  )
  if (!isSelect) {
    row.disabled = true
    tableDataRight.value.push(row)
  }
}
const moveAllToRight = () => {
  const selectRows = multipleTableRefLeft.value.getSelectionRows()
  if (selectRows.length) {
    tableDataRight.value = selectRows

    tableDataRight.value.forEach((item) => {
      item.disabled = true
    })
  }
}

// 控制左边数据是否可以被选中
const selectableFn = (row) => {
  const isDisabled = row.disabled
  const isSelect = tableDataRight.value.some(
    (item) => row.modelCode === item.modelCode
  )
  let filterDisabled = false
  if (props.disabledData && props.disabledData.length) {
    filterDisabled = props.disabledData.some(
      (item) => row.modelCode === item.modelCode
    )
  }
  return !isSelect && !isDisabled && !filterDisabled
}
const deleteSelect = (row) => {
  emits('refreshRow', row)
  tableDataRight.value = tableDataRight.value.filter(
    (item) => row.modelCode !== item.modelCode
  )
}
const deleteAll = () => {
  const selectRows = multipleTableRefRight.value.getSelectionRows()
  if (selectRows.length) {
    for (let index = tableDataRight.value.length - 1; index >= 0; index--) {
      const item = tableDataRight.value[index]
      const selectStatus = selectRows.some(
        (element) => element.modelCode === item.modelCode
      )
      if (selectStatus) {
        tableDataRight.value.splice(index, 1)
      }
    }
  }
}
const selectTop = (row, index) => {
  tableDataRight.value.splice(index, 1)
  tableDataRight.value.splice(index - 1, 0, row)
}
const selectBottom = (row, index) => {
  tableDataRight.value.splice(index, 1)
  tableDataRight.value.splice(index + 1, 0, row)
}

const modelChanged = () => {
  transferSearch('query')
}

const modelCleared = () => {
  transferSearch('reset')
}

let editInfo = ref({})
let isSingle = ref()
const dialogAddGroupVisible = ref(false)
const editFn = (type, row) => {
  if (type === 'single') {
    editInfo.value = row
    isSingle.value = true
  } else if (type === 'batch') {
    editInfo.value = {}
    isSingle.value = false
    const selectionRows = multipleTableRefRight.value.getSelectionRows()
    if (selectionRows.length !== tableDataRight.value.length) {
      ElMessage.warning('请全部勾选')
      return
    }
  }

  dialogAddGroupVisible.value = true
}

const suspensionEditCmptRef = ref()
const getPartInfo = async ({ form: info, isSingle }) => {
  const suspensionEditCmptValidate =
    await suspensionEditCmptRef.value.validate()

  if (!suspensionEditCmptValidate) return

  const {
    frontCamberAdjustable,
    frontLowerArmBallHeadSR,
    frontLowerControlArmOTFA,
    frontSteeringCrossTieRodBallHeadOTFA,
    frontSteeringCrossTieRodBallHeadShape,
    frontSteeringKnuckleConnectUpperSwingArmShape,
    nearCamberAdjustable,
    suspensionType,
    toeAdjustable,
    modelCode,
  } = info

  const updateTarget = (target) => {
    if (target) {
      Object.assign(target, {
        frontCamberAdjustable,
        frontLowerArmBallHeadSR,
        frontLowerControlArmOTFA,
        frontSteeringCrossTieRodBallHeadOTFA,
        frontSteeringCrossTieRodBallHeadShape,
        frontSteeringKnuckleConnectUpperSwingArmShape,
        nearCamberAdjustable,
        suspensionType,
        toeAdjustable,
      })
    }
  }

  if (isSingle) {
    const target = tableDataRight.value.find(
      (item) => item.modelCode === modelCode
    )
    updateTarget(target)
  } else {
    tableDataRight.value = tableDataRight.value.map((item) => ({ ...item }))
    tableDataRight.value.forEach(updateTarget)
  }

  dialogAddGroupVisible.value = false
}

const tableRowClassName = ({ row }) => {
  const { enableStatus } = row

  if (enableStatus === false) {
    return 'delete-row'
  }

  return ''
}

// eslint-disable-next-line no-undef
defineExpose({
  tableDataRight,
})
</script>

<style lang="less" scoped>
.transfer {
  display: flex;
  margin-bottom: 15px;
  &-box {
    width: 50%;

    &-title {
      margin: 10px 15px;
      font-weight: bold;
    }

    &-form {
      &-reset {
        background-color: #e6a23c;
        border-color: #e6a23c;
      }
    }

    &-table {
      .icon {
        &-common {
          width: 1.5em;
          height: 1.5em;
          margin: 0 5px;
          padding: 0;
          cursor: pointer;
          vertical-align: middle;
          /deep/ .el-icon {
            font-size: 21px;
          }
        }
        &-edit {
          color: #409eff;
        }
        &-delete {
          color: #f56c6c;
        }
        &-disabled {
          color: #aaa;
          cursor: not-allowed;
        }
      }
      &-icon-move {
        border: none;
        padding: 0;
        margin: 0;
        background: none;
        &:hover {
          background: none;
        }
      }

      /deep/ .el-checkbox__input.is-disabled .el-checkbox__inner {
        background-color: #ddd;
      }
    }
  }
  &-box-border {
    border: 1px solid #ebeef5;
  }

  /deep/ .el-table .delete-row {
    text-decoration: line-through;
  }
}
</style>