<template>
  <div class="tree-remark">
    <div class="tree-remark-title">
      {{ title }}
      <div class="tree-remark-tools">
        <slot></slot>
      </div>
    </div>
    <div class="tree-remark-code">
      <div class="tree-remark-label">对应故障码:</div>
      <FaultCodesCmpt :faultCodes="remark.faultCodes" />
    </div>
    <div class="tree-remark-content">
      <div class="tree-remark-label">备注内容:</div>
      <div>{{ remark.remark }}</div>
    </div>
    <div class="tree-remark-relation">
      <div class="tree-remark-label">关联文件:</div>
      <div>
        <a
          target="_blank"
          v-for="item in files"
          :key="item.name"
          :href="item.url"
        >{{ item.name }}</a>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed } from 'vue'

const props = defineProps({
  title: String,
  remark: {
    type: Object,
    default: () => ({})
  }
})

function FaultCodesCmpt(props) {
  return (
    <div>
      {props.faultCodes?.map(item => (
        <router-link target="_blank" to={`/faultCode/info?code=${item.faultCode}&id=${item.id}`}>
          <div>{item.faultCode}、</div>
        </router-link>
      ))}
    </div>
  )
}

const files = computed(() => {
  return props.remark.files || []
})
</script>

<style lang="less" scoped>
.tree-remark {
  border: 1px solid #dcdfe6;
  font-size: 12px;
  margin-bottom: 10px;

  & > div {
    border-bottom: 1px solid #dcdfe6;
  }

  &-title {
    text-align: center;
    padding: 5px;
    background-color: #0090e1;
    color: #ffffff;
    position: relative;
  }

  &-tools {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  &-label {
    font-weight: bold;
  }
}
</style>