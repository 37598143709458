<template>
  <div>
    <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false" class="drawer">
      <div class="drawer-label">提交说明:</div>
      <el-form ref="formRef" :model="form" :rules="rules">
        <div class="drawer-txt">
          <el-form-item prop="remark">
            <el-input
              v-model="form.remark"
              type="textarea"
              placeholder="请输入提交说明"
              rows="4"
              maxlength="200"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>

      <div class="drawer-footer-btn">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, reactive, ref } from 'vue'
import { useWheelStore } from '@/store/wheelAlignment'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/user'

const wheelStore = useWheelStore()
const userStore = useUserStore()

const emits = defineEmits(['update:modelValue', 'getWheelDecisionListGet'])
const props = defineProps({
  modelValue: Boolean,
  decisionId: Number
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const operator = computed(() => {
  return userStore?.userInfo?.id || ''
})

const formRef = ref(null)
const form = reactive({
  remark: ''
})
const rules = reactive({
  remark: [{ required: true, message: '请填写提交说明', trigger: 'change' }]
})

const handleClose = () => {
  emits('update:modelValue', false)
}
const handleSubmit = async () => {
  const validate = await formRef.value.validate(() => {})
  if (!validate) {
    return
  }

  try {
    await wheelStore.submitWheelDecision({
      decisionId: props.decisionId,
      remark: form.remark,
      operator: operator.value
    })
    ElMessage.success('提交成功')
    emits('getWheelDecisionListGet')
    handleClose()
  } catch (error) {
    ElMessage.error(error)
  }
}
</script>

<style lang="less" scoped>
.drawer {
  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-record {
    max-height: 190px;
    overflow-y: auto;
  }

  &-txt {
    font-size: 14px;
  }

  &-footer-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>