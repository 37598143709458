<template>
  <div class="preset">
    <div class="preset-opts">
      <el-button type="success" @click="create">新增分组</el-button>
    </div>
    <div class="preset-groups">
      <CollapseCmpt
        v-for="(group, index) in fieldGroups"
        :key="group.id"
        :title="group.name"
        :item="group"
        :index="index"
        @edit="editGroupName"
        @delete="deleteGroup"
      />
    </div>
    <el-dialog
      v-model="dialogAddGroupVisible"
      title="请输入分组名称:"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-form :model="form" :rules="rules">
        <el-form-item label="分组名称:" prop="groupName">
          <el-input
            v-model="form.groupName"
            maxlength="50"
            autocomplete="off"
          />
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, ref, unref, onMounted } from 'vue'
import { useFieldsStore } from '@/store/fields'
import CollapseCmpt from '@/components/CollapseCmpt'
import { ElMessage } from 'element-plus'

let editTarget = null
const fieldsStore = useFieldsStore()
const dialogAddGroupVisible = ref(false)
let fieldGroups = ref([])
const form = reactive({ groupName: '' })
const rules = reactive({
  groupName: [
    {
      required: true,
      message: '请输入分组名称',
      trigger: 'blur'
    }
  ]
})

onMounted(() => {
  fieldsStore.getPresetFieldGroupList()
    .then(result => {
      fieldGroups.value = result
    })
})

const create = () => {
  dialogAddGroupVisible.value = true
}
const cancel = () => {
  dialogAddGroupVisible.value = false
  editTarget = null
  form.groupName = ''
}
const confirm = () => {
  if (editTarget) {
    updatePresetFieldGroup()
  } else {
    savePresetFieldGroup()
  }
}
const editGroupName = item => {
  editTarget = item
  form.groupName = item.name
  dialogAddGroupVisible.value = true
}
const deleteGroup = async ({ id }) => {
  try {
    await fieldsStore.deletePresetFieldGroup({ id })
    fieldGroups.value = unref(fieldGroups).filter(
      target => target.id !== id
    )
    ElMessage.success('删除成功')
  } catch (error) {
    ElMessage.error('删除失败')
  }
}
async function savePresetFieldGroup() {
  try {
    const { groupName } = form
    const result = await fieldsStore.savePresetFieldGroup({
      name: groupName
    })
    fieldGroups.value.push({
      id: result,
      name: groupName,
    })
    ElMessage.success('添加成功')
    cancel()
  } catch (error) {
    ElMessage.error(error)
  }
}
async function updatePresetFieldGroup() {
  try {
    const { id } = editTarget
    const { groupName } = form
    await fieldsStore.updatePresetFieldGroup({
      id,
      name: groupName
    })
    fieldGroups.value.forEach(item => {
      if (item.id === id) {
        item.name = groupName
      }
    })
    ElMessage.success('修改成功')
    cancel()
  } catch (error) {
    ElMessage.error(error)
  }
}
</script>

<style lang="less" scoped>
.preset {
  &-opts {
    padding: 10px;
  }

  &-groups {
    padding: 0 10px;
  }
}
</style>