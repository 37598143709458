<template>
  <!-- 新增决策信息 -->
  <div>
    <StatusBar :title="brandModelCode" />

    <div class="decision">
      <el-form ref="formRef" :inline="true" :model="form" :rules="rules" class="decision-form">
        <el-row>
          <el-col :span="6">
            <el-form-item label="悬挂形式:" prop="suspensionType">
              <el-select v-model="form.suspensionType" clearable filterable placeholder="请选择悬挂形式">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="外倾角是否可调整:" prop="adjustable">
              <el-select v-model="form.adjustable" clearable placeholder="请选择">
                <el-option
                  v-for="item in ADJUSTABLE_MENU"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="受损车轮位置:" prop="damagedWheelPosition">
              <el-select
                v-model="form.damagedWheelPosition"
                clearable
                filterable
                placeholder="请选择受损车轮位置"
              >
                <el-option
                  v-for="item in DAMAGED_WHEEL_POSITION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="车轮受力方向:" prop="wheelForceDirection">
              <el-select
                v-model="form.wheelForceDirection"
                clearable
                filterable
                placeholder="请选择车轮受力方向"
              >
                <el-option
                  v-for="item in WHEEL_FORCE_DIRECTION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="后倾角:" prop="casterState">
              <el-radio-group v-model="form.casterState" class="decision-radio-group">
                <el-radio
                  v-for="(element, i) in ABNORMAL_TYPE"
                  :key="i"
                  :label="element.value"
                  size="large"
                >{{element.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="外倾角:" prop="camberState">
              <el-radio-group v-model="form.camberState" class="decision-radio-group">
                <el-radio
                  v-for="(element, i) in ABNORMAL_TYPE"
                  :key="i"
                  :label="element.value"
                  size="large"
                >{{element.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="前束值:" prop="toeState">
              <el-radio-group v-model="form.toeState" class="decision-radio-group">
                <el-radio
                  v-for="(element, i) in ABNORMAL_TYPE"
                  :key="i"
                  :label="element.value"
                  size="large"
                >{{element.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2" :offset="22">
            <el-button type="primary" @click="decisionAdd">新增</el-button>
          </el-col>
        </el-row>
      </el-form>

      <TableCmpt :case-list="tableData" :header-list="headerList" />

      <div class="decision-page-submit">
        <el-button type="primary" @click="pageSubmit">确定</el-button>
        <el-button @click="pageClose">取消</el-button>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed, ref, reactive, onMounted } from 'vue'
import StatusBar from '@/components/StatusBar'
import { useUserStore } from '@/store/user'
import TableCmpt from '@/components/TableCmpt'
import { useWheelStore } from '@/store/wheelAlignment'
import { ADJUSTABLE_MENU } from '@/utils/constants'
import { useEnumSelectStore } from '@/store/enumSelect'
import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'
import { uniqBy } from 'lodash'
import {
  isOrNot,
  isEnabled,
  getAbnormalStateName
} from '@/views/wheelAlignment/wheelCommon'

const router = useRouter()
const enumStore = useEnumSelectStore()
const wheelStore = useWheelStore()
const SUSPENSION_TYPE = ref(null)
const DAMAGED_WHEEL_POSITION = computed(() => {
  return (
    enumStore.DAMAGED_WHEEL_POSITION ||
    enumStore.getWheelEnumDamageWheelPosition()
  )
})
const WHEEL_FORCE_DIRECTION = computed(() => {
  return (
    enumStore.WHEEL_FORCE_DIRECTION || enumStore.getWheelEnumForceDirection()
  )
})
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})
const userStore = useUserStore()
const brandModelCode = computed(() => {
  const userName = userStore?.userInfo?.userName || ''
  return '决策编写人： ' + userName
})

const rules = reactive({
  suspensionType: [
    { required: true, message: '请选择悬挂形式', trigger: 'change' }
  ],
  adjustable: [{ required: true, message: '请选择', trigger: 'change' }],
  damagedWheelPosition: [
    { required: true, message: '请选择', trigger: 'change' }
  ],
  wheelForceDirection: [
    { required: true, message: '请选择', trigger: 'change' }
  ],
  camberState: [{ required: true, message: '请选择', trigger: 'change' }],
  casterState: [{ required: true, message: '请选择', trigger: 'change' }],
  toeState: [{ required: true, message: '请选择', trigger: 'change' }]
})

onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
})

const formRef = ref(null)
const form = reactive({
  suspensionType: null,
  adjustable: null,
  damagedWheelPosition: null,
  wheelForceDirection: null,
  camberState: '4',
  casterState: '4',
  toeState: '4',
  ineffective: 0 // 是否无效索引 必需 0-有效；1-无效
})

const tableData = ref([])
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'suspensionTypeName',
    label: '悬挂形式'
  },
  {
    prop: 'authorId',
    label: '外倾角是否可调整',
    formatter(row) {
      const result = isOrNot(row.adjustable)
      return <div>{result}</div>
    }
  },
  {
    prop: 'damagedWheelPositionName',
    label: '受损车轮位置'
  },
  {
    prop: 'wheelForceDirectionName',
    label: '车轮受力方向'
  },
  {
    prop: 'abnormalStateName',
    label: '异常状态',
    width: '600px'
  },
  {
    prop: 'enabledDecision',
    label: '是否有启用决策',
    formatter(row) {
      const result = isEnabled(row.enabledDecision)
      return <div>{result}</div>
    }
  },
  {
    prop: 'label',
    label: '操作',
    width: '165px',
    formatter(...args) {
      const deleteRelationIndex = async () => {
        tableData.value.splice(args[3], 1)
      }
      return (
        <div class='decision-icon' style='font-size: 20px;'>
          <router-link
            target='_blank'
            to={`/wheelAlignment/index/decision?completeIndexId=${args[0].id}`}
          >
            <Connection class='decision-icon-common decision-icon-connection' />
          </router-link>

          <Remove
            class='decision-icon-common decision-icon-remove'
            onClick={deleteRelationIndex}
          />
        </div>
        // <router-link target='_blank' to={`/wheelAlignment/index/records?id=`}>
        //     <Warning class='decision-icon-common decision-icon-warning' />
        //   </router-link>
        //   <router-link
        //     target='_blank'
        //     to={`/wheelAlignment/index/sameIndex?id=`}
        //   >
        //     <Refresh class='decision-icon-common decision-icon-connection' />
        //   </router-link>
      )
    }
  }
]

// const resetForm = () => {
//   formRef.value.resetFields()
// }

const decisionAdd = async () => {
  await formRef.value.validate(async valid => {
    if (valid) {
      await getWheelCompleteIndexList()
    }
  })
  console.log(form)
}

const getWheelCompleteIndexList = async () => {
  try {
    const res = await wheelStore.getWheelCompleteIndexList(form)
    if (!res.length) {
      ElMessage.error('没有查询到索引数据')
      return
    }

    const tableDataIds = tableData.value.map(item => item.id)
    const resIds = res.map(item => item.id)
    const paramsIds = resIds.filter(id => !tableDataIds.includes(id))
    if (!paramsIds.length) {
      ElMessage.warning('已存在相同数据')
      return
    }
    const resArr = tableData.value.concat(res)
    tableData.value = uniqBy(resArr, 'id')
    tableData.value = tableData.value.map(item => {
      return {
        suspensionTypeName: SUSPENSION_TYPE.value.find(
          enumItem => enumItem.value == item.suspensionType
        )?.label,
        damagedWheelPositionName: DAMAGED_WHEEL_POSITION.value.find(
          enumItem => enumItem.value == item.damagedWheelPosition
        )?.label,
        wheelForceDirectionName: WHEEL_FORCE_DIRECTION.value.find(
          enumItem => enumItem.value == item.wheelForceDirection
        )?.label,
        abnormalStateName: getAbnormalStateName(item, ABNORMAL_TYPE.value),
        ...item
      }
    })
  } catch (error) {
    ElMessage.error(error)
  }
}

const pageClose = () => {
  window.close()
}
const pageSubmit = () => {
  if (tableData.value.length) {
    wheelStore.setDecisionIndexList(tableData.value)
    router.push('/wheelAlignment/decision/editChangePlan')
  }
}
</script>

<style lang="less" scoped>
.decision {
  padding: 20px 40px;

  &-title {
    padding: 20px 0;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
  &-form {
    margin-bottom: 30px;
  }
  &-radio-group {
    margin-left: 30px;
  }
  &-page-submit {
    position: absolute;
    z-index: 9;
    bottom: 30px;
    right: 40px;
  }
}
/deep/ .decision-icon {
  &-common {
    width: 1em;
    height: 1em;
    margin: 0 7px;
    cursor: pointer;
    vertical-align: -2px;
  }
  &-warning,
  &-connection {
    color: #409eff;
  }
  &-remove {
    color: #f56c6c;
  }
  &-disabled {
    color: #aaa;
    cursor: not-allowed;
  }
}
</style>