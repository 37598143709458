<template>
  <div :class="['status', fixed ? 'fixed' : '']">
    <div class="status-title">
      <el-select v-if="value" v-model="value" class="status-select">
        <el-option
          v-for="item in MENU_TYPE"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
      <span v-else class="status-title-name">{{ title }}</span>
      <slot name="tree-info"></slot>
      <div class="status-title-duration"><slot name="tree-duration"></slot></div>
    </div>
    <div class="status-user">
      <slot name="tree-tools"></slot>
      <el-dropdown trigger="click">
        <div class="status-user-nickname">
          <el-icon style="margin-right: 10px;font-size:20px;"><User /></el-icon>
          <span>
            {{ userName }}<el-icon class="el-icon--right"><arrow-down /></el-icon>
          </span>
        </div>
        <template #dropdown>
          <el-dropdown-menu>
            <!-- <el-dropdown-item :icon="User" @click="toUserInfo">个人中心</el-dropdown-item> -->
            <el-dropdown-item :icon="User" @click="logout">退出登录</el-dropdown-item>
          </el-dropdown-menu>
        </template>
      </el-dropdown>
    </div>
  </div>
</template>

<script setup>
import { defineProps, computed, defineEmits } from 'vue'
import { useRouter } from 'vue-router'
import { useUserStore } from '@/store/user'
import { ElMessage } from 'element-plus'
import { MENU_TYPE } from '@/utils/constants'
const props = defineProps({
  title: String,
  fixed: Boolean,
  modelValue: String,
})
const emits = defineEmits(['update:modelValue'])

const userStore = useUserStore()
const router = useRouter()
const userName = computed(() => {
  return userStore?.userInfo?.userName || '-'
})

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})


// const toUserInfo = () => {
//   router.push('/userinfo')
// }

const logout = async () => {
  try {
    await userStore.logout()
    router.push('/login')
  } catch (error) {
    console.log('退出失败:', error)
    ElMessage.error(error)
  }
  
}
</script>

<style lang="less" scoped>
.status {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  border-bottom: 1px solid #ebebeb;

  &.fixed {
    position:absolute;
    top:0;
    left:0;
    right:0;
    background-color:#ffffff;
    z-index:99;
  }

  & > div {
    display: flex;
    align-items: center;
  }

  &-title {
    display: flex;
    align-items: center;

    &-name {
      margin-left: 5px;
      white-space: nowrap;
    }

    &-duration{
      margin-left: 50px;
    }
  }

  &-user {
    &-nickname {
      display: flex;
      align-items: center;
      white-space: nowrap;
    }
  }

  &-select{
    width: 170px;
  }
}
</style>