<template>
  <el-drawer
    v-model="value"
    :with-header="false"
    :close-on-click-modal="false"
    class="tree-info"
  >
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane name="content" label="线逻辑">
        <div class="tree-info-label">线内容:</div>
        <div class="tree-info-txt">{{ info.viewContent || '-'}}</div>
      </el-tab-pane>
      <el-tab-pane name="remark" label="线备注">
        <EdgeInfoRemarkEdit
          v-if="activeTab === 'remark'"
          :status="remarkStatus"
          :edge="edge"
        />
      </el-tab-pane>
    </el-tabs>
    <el-button class="tree-info-close" @click="close">关闭</el-button>
  </el-drawer>
</template>

<script setup>
import { defineProps, defineEmits, ref, computed, watch } from 'vue'
import EdgeInfoRemarkEdit from './EdgeInfoRemarkEdit'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  edge: {
    type: Object,
    default: () => ({})
  },
  info: {
    type: Object,
    default: () => ({})
  },
})
const activeTab = ref('content')
const remarkStatus = ref('show')
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

watch(value, () => {
  if (value) {
    const {
      treeStatus,
      userInfo,
      reviewerId
    } = props?.edge?.data?.treeIds
    activeTab.value = 'content'
    if (
      treeStatus === 30 &&
      reviewerId === userInfo.id
    ) {
      remarkStatus.value = 'edit'
    }
  }
})

const close = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="less" scoped>
.tree-info {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>