import request from "@/utils/request"

// 规则系统/配件分组查询
export function getRuleSystemSparePartGroupList(data) {
  return request({
    url: '/v1/data/vehicle/spare-part-manage/rule/spare-part-group/query',
    method: 'post',
    data,
  })
}

// 规则系统/配件查询
export function getRuleSystemSparePartList(data) {
  return request({
    url: '/v1/data/vehicle/spare-part-manage/rule/spare-part/query',
    method: 'post',
    data,
  })
}