<template>
  <div class="suspension">
    <div class="suspension-form">
      <el-form
        ref="formRef"
        :inline="true"
        :model="form"
        :rules="rules"
      >
        <div class="suspension-form-title">新增车型：</div>
        <LinkageSelectCmpt
          ref="linkageSelectRef"
          :span="6"
          :rules="linkageSelectRules"
          @linkageSelectChange="linkageSelectChange"
        />

        <TransferCmpt
          ref="transferCmptRef"
          title="车型选择"
          :tableDataLeft="tableDataLeft"
          :tableFormat="tableFormat"
          :isPartEdit="true"
          :modelSelectData="modelSelectData"
          @transferSearch="transferSearch"
          @refreshRow="refreshRow"
        />

        <div class="suspension-form-title">分组备注：</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="备注:" prop="remark">
              <el-input v-model="form.remark" placeholder="请输入备注" maxlength="50" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="23" class="suspension-form-btns">
            <el-button type="primary" @click="confirm">确定</el-button>
            <el-button @click="router.back()">取消</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import LinkageSelectCmpt from '@/views/commonConfig/components/LinkageSelectCmpt'
import TransferCmpt from '@/views/commonConfig/components/TransferCmpt'
import { useRouter } from 'vue-router'
import { vehicleSaveBatch } from '@/api/presetField'
import { ElMessage } from 'element-plus'

const router = useRouter()
const formRef = ref(null)
const linkageSelectRef = ref(null)
const transferCmptRef = ref(null)

const rules = reactive({
  suspensionType: [
    { required: true, message: '请选择悬挂形式', trigger: 'change' }
  ],
  adjustable: [{ required: true, message: '请选择', trigger: 'change' }],
})
const linkageSelectRules = reactive({
  brandId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
  manufacturerId: [
    { required: true, message: '请选择厂商', trigger: 'change' }
  ],
  seriesId: [{ required: true, message: '请选择车系', trigger: 'change' }],
  groupId: [{ required: true, message: '请选择车组', trigger: 'change' }]
})
const form = reactive({
  suspensionType: '',
  adjustable: '',
  remark: ''
})

const linkageSelectChange = ({
  brandCode, brandName, manufacturerCode, manufacturerName,
  seriesCode, seriesName, groupCode, groupName, modelCode, modelName,
  vehicleModel, frontCamberAdjustable, nearCamberAdjustable, toeAdjustable,
  frontLowerArmBallHeadSR, frontLowerControlArmOTFA, frontSteeringCrossTieRodBallHeadShape,
  frontSteeringCrossTieRodBallHeadOTFA, frontSteeringKnuckleConnectUpperSwingArmShape
}) => {
  form.brandCode = brandCode
  form.brandName = brandName
  form.manufacturerCode = manufacturerCode
  form.manufacturerName = manufacturerName
  form.seriesCode = seriesCode
  form.seriesName = seriesName
  form.groupCode = groupCode
  form.groupName = groupName
  form.modelCode = modelCode
  form.modelName = modelName
  form.frontCamberAdjustable = frontCamberAdjustable
  form.nearCamberAdjustable = nearCamberAdjustable
  form.toeAdjustable = toeAdjustable
  form.frontLowerArmBallHeadSR = frontLowerArmBallHeadSR
  form.frontLowerControlArmOTFA = frontLowerControlArmOTFA
  form.frontSteeringCrossTieRodBallHeadShape = frontSteeringCrossTieRodBallHeadShape
  form.frontSteeringCrossTieRodBallHeadOTFA = frontSteeringCrossTieRodBallHeadOTFA
  form.frontSteeringKnuckleConnectUpperSwingArmShape = frontSteeringKnuckleConnectUpperSwingArmShape

  if (vehicleModel && vehicleModel.length > 0) {
    modelSelectData.value = vehicleModel

    tableSourceDataLeft.value = vehicleModel.map(model => ({
      groupCode,
      groupName,
      modelCode: model.value,
      modelName: model.label,
      brandCode,
      brandName,
      manufacturerCode,
      manufacturerName,
      seriesCode,
      seriesName,
    }))

    tableDataLeft.value = tableSourceDataLeft.value
  } else {
    tableSourceDataLeft.value = []
    tableDataLeft.value = tableSourceDataLeft.value
  }
}

const transferSearch = async (value) => {
  const linkageSelectValidate = await linkageSelectRef.value.validate()
  if (!linkageSelectValidate) {
    return
  }

  const { type, keyWord } = value

  if (type === 'query') {
    tableDataLeft.value = tableSourceDataLeft.value.filter(item => item.modelCode === keyWord)
  } else if (type === 'reset') {
    tableDataLeft.value = []
    modelSelectData.value = []
  }
}

const refreshRow = (row) => {
  const matchedRow = tableDataLeft.value.find(item => item.modelCode === row.modelCode)

  if (matchedRow) {
    const labeledProps = [
      'suspensionType', 'frontCamberAdjustable', 'nearCamberAdjustable',
      'toeAdjustable', 'frontLowerArmBallHeadSR', 'frontLowerControlArmOTFA',
      'frontSteeringCrossTieRodBallHeadShape', 'frontSteeringCrossTieRodBallHeadOTFA',
      'frontSteeringKnuckleConnectUpperSwingArmShape'
    ]

    matchedRow.disabled = false
    labeledProps.forEach(field => matchedRow[field] = '')
  }
}

const tableSourceDataLeft = ref([])
const tableDataLeft = ref([])
const tableFormat = ref([
  { label: '车组', code: 'groupName' },
  { label: '车型', code: 'modelName' },
  // { label: '参数1', code: 'params1' },
])
const modelSelectData = ref([])

const confirm = async () => {
  const tableDataRight = transferCmptRef.value.tableDataRight
  const { remark } = form

  const vehicles = tableDataRight.map(item => ({ ...item, remark }))

  const hasMissingSuspensionType = vehicles.some(item => !item.suspensionType)

  if (hasMissingSuspensionType) {
    ElMessage.warning('有必填项未输入')
    return
  }

  await formRef.value.validate(async (valid, fields) => {
    if (valid) {
      const params = { vehicles }

      try {
        await vehicleSaveBatch(params)
        router.push(`/config/wheelAlignment/suspension`)
      } catch (error) {
        ElMessage.error(error)
      }
    } else {
      console.log('error submit!', fields)
    }
  })
}

</script>

<style lang="less" scoped>
.suspension {
  padding: 10px 30px;

  &-form {
    &-title {
      margin-bottom: 15px;
      font-weight: bold;
    }
    &-field {
      // display: flex;
      // justify-content: space-between;
      // padding: 10px 0;
    }

    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }
    .icon {
      &-common {
        width: 1.5em;
        height: 1.5em;
        margin: 0 10px;
        cursor: pointer;
        vertical-align: middle;
      }
      &-edit {
        color: #409eff;
      }
      &-delete {
        color: #f56c6c;
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>