<template>
  <div class="decision-info">
    <StatusBar :title="brandModelCode">
      <template #tree-info>
        <div class="decision-info-title-more">
          <div class="decision-info-title-more-list">编写人：{{ editor }}</div>
          <div class="decision-info-title-more-list">审核人：{{ reviewer }}</div>
          <div class="decision-info-title-more-list">操作时间：{{ operateTime }}</div>
        </div>
      </template>
      <template #tree-tools>
        <div class="look-up-tools">
          <div
            v-for="item in tools"
            :key="item.sign"
            @click="toolsHandle(item)"
            :class="{ 'disabled': item.disabled }"
          >
            <el-icon>
              <component :is="item.icon"></component>
            </el-icon>
            {{ item.text }}
          </div>
          <el-dropdown>
            <div>
              <el-icon>
                <MoreFilled />
              </el-icon>更多
            </div>
            <template #dropdown>
              <el-dropdown-menu>
                <el-dropdown-item
                  v-for="item in moreTools"
                  @click="moreToolsHandle(item)"
                  :key="item.sign"
                  :icon="item.icon"
                  :disabled="item.disabled"
                >{{ item.text }}</el-dropdown-item>
              </el-dropdown-menu>
            </template>
          </el-dropdown>
        </div>
      </template>
    </StatusBar>

    <div class="decision">
      <div class="decision-title">
        决策索引：
        <el-icon v-if="authority" class="decision-icon" @click="showeditIndexDrawer">
          <EditPen />
        </el-icon>
      </div>
      <TableCmpt :case-list="tableData" :header-list="headerList" />

      <div class="decision-title">
        更换方案：
        <el-icon v-if="authority" class="decision-icon" @click="toEditChangePlan">
          <EditPen />
        </el-icon>
      </div>
      <div class="decision-plan">
        <div v-if="decisionInfo && decisionInfo.decisionPlanType === 'REASONABLE'">
          <div class="decision-plan-label">合理数据</div>
          <div class="decision-plan-list" v-for="(list, index) in planData" :key="index">
            <div class="decision-plan-list-title">第{{ index + 1 }}层：</div>

            <el-button
              size="small"
              v-for="(item, i) in list"
              :key="i"
              class="decision-plan-list-button"
              @click="showEvidenceDrawer(item)"
            >
              {{ item.label }}
              <!-- <el-icon class="decision-plan-list-icon">
              <Flag />
              </el-icon>-->
            </el-button>
          </div>
        </div>
        <div v-if="decisionInfo && decisionInfo.decisionPlanType === 'UNREASONABLE'">
          <div class="decision-plan-label">不合理数据</div>
          <div>{{ decisionInfo.unreasonablePlanContent }}</div>
        </div>
      </div>
    </div>

    <EvidenceCmpt v-model="evidenceDrawer" :info="evidenceInfo" :isEdit="false" />
    <EditIndexCmpt
      v-if="editIndexDrawer"
      v-model="editIndexDrawer"
      :decisionId="route.query.decisionId"
      @getWheelListDecisionCompleteIndexes="getWheelListDecisionCompleteIndexes"
    />
    <DecisionExamineCmpt
      v-if="examineDrawer"
      v-model="examineDrawer"
      :decisionId="decisionInfo && decisionInfo.id"
      @getWheelDecisionListGet="getWheelDecisionListGet"
    />
    <DecisionSubmitCmpt
      v-model="submitDrawer"
      :decisionId="decisionInfo && decisionInfo.id"
      @getWheelDecisionListGet="getWheelDecisionListGet"
    />
    <DecisionTransferCmpt
      v-model="transferDrawer"
      :decisionId="decisionInfo && decisionInfo.id"
      :decisionCode="decisionInfo && decisionInfo.decisionCode"
      :editorName="editor"
      :editorId="decisionInfo && decisionInfo.editor"
      @getWheelDecisionListGet="getWheelDecisionListGet"
    />
    <DecisionOptRecordCmpt v-if="optRecordDrawer" v-model="optRecordDrawer" />
  </div>
</template>

<script setup>
import StatusBar from '@/components/StatusBar'
import TableCmpt from '@/components/TableCmpt'
import EvidenceCmpt from '@/views/commonConfig/components/EvidenceCmpt.vue'
import EditIndexCmpt from '@/views/wheelAlignment/components/EditIndexCmpt'
import DecisionExamineCmpt from '@/views/wheelAlignment/components/DecisionExamineCmpt'
import DecisionSubmitCmpt from '@/views/wheelAlignment/components/DecisionSubmitCmpt'
import DecisionTransferCmpt from '@/views/wheelAlignment/components/DecisionTransferCmpt'
import DecisionOptRecordCmpt from '@/views/wheelAlignment/components/DecisionOptRecordCmpt'
import { computed, ref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useWheelStore } from '@/store/wheelAlignment'
import { GMTToLocal } from '@/utils'
import { viewDecisionFormat } from '@/views/wheelAlignment/wheelAlignment'
import { useEnumSelectStore } from '@/store/enumSelect'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/user'
import {
  isOrNot,
  isEnabled,
  getAbnormalStateName,
  userCommon
} from '@/views/wheelAlignment/wheelCommon'

const route = useRoute()
const router = useRouter()
const wheelStore = useWheelStore()
const enumStore = useEnumSelectStore()
const userStore = useUserStore()
const { getUserInfo } = userCommon(userStore)
const brandModelCode = computed(
  () => `决策：${decisionInfo.value?.decisionCode}`
)
const editor = computed(() => {
  return getUserInfo(decisionInfo.value?.editor) || '-'
})
const reviewer = computed(() => {
  return getUserInfo(decisionInfo.value?.reviewer) || '-'
})
const operateTime = computed(() =>
  GMTToLocal(decisionInfo.value?.lastModifyTime)
)

const SUSPENSION_TYPE = ref(null)
const DAMAGED_WHEEL_POSITION = computed(() => {
  return (
    enumStore.DAMAGED_WHEEL_POSITION ||
    enumStore.getWheelEnumDamageWheelPosition()
  )
})
const WHEEL_FORCE_DIRECTION = computed(() => {
  return (
    enumStore.WHEEL_FORCE_DIRECTION || enumStore.getWheelEnumForceDirection()
  )
})
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})
const operator = computed(() => {
  return userStore?.userInfo?.id || ''
})

// 【39管理员】
// 【作者】
const isAauthor = computed(() => {
  return userStore.userInfo.id === decisionInfo.value?.editor
})
// 【41编写员】
const isEditor = computed(() => {
  return userStore.userInfo?.roleList.some(({ id }) => id === 41)
})
// 【40审核员】
const isReviewer = computed(() => {
  return userStore.userInfo?.roleList.some(({ id }) => id === 40)
})
// const isViewer = computed(() => { // 【42查看员】
//   return userStore.userInfo?.roleList.some(({ id }) => id === 42)
// })
const authority = computed(() => {
  return (
    (isAauthor.value &&
      (decisionInfo.value?.decisionState == 'TO_BE_SUBMITTED' ||
        decisionInfo.value?.decisionState == 'REVIEWED_REJECTED')) ||
    (isReviewer.value &&
      decisionInfo.value?.decisionState === 'REVIEWED_APPROVED')
  )
})

const evidenceDrawer = ref(false)
const decisionInfo = ref(null)
const planData = ref([])

onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
  getWheelListDecisionCompleteIndexes()
  getWheelDecisionListGet()
})

// 查看备注/证据
const evidenceInfo = ref({})
const showEvidenceDrawer = item => {
  console.log(item)
  // evidenceDrawer.value = true
}
const editIndexDrawer = ref(false)
const showeditIndexDrawer = item => {
  console.log(item, 'showeditIndexDrawer')
  editIndexDrawer.value = true
}
const toEditChangePlan = () => {
  router.push({
    path: '/wheelAlignment/decision/editChangePlan',
    query: route.query
  })
}
const examineDrawer = ref(false)
const submitDrawer = ref(false)
const transferDrawer = ref(false)
const optRecordDrawer = ref(false)

// 获取索引列表
const getWheelListDecisionCompleteIndexes = async () => {
  try {
    const res = await wheelStore.getWheelListDecisionCompleteIndexes({
      decisionId: route.query.decisionId
    })
    tableData.value = res
    tableData.value = tableData.value.map(item => {
      return {
        suspensionTypeName: SUSPENSION_TYPE.value.find(
          enumItem => enumItem.value == item.suspensionType
        )?.label,
        damagedWheelPositionName: DAMAGED_WHEEL_POSITION.value.find(
          enumItem => enumItem.value == item.damagedWheelPosition
        )?.label,
        wheelForceDirectionName: WHEEL_FORCE_DIRECTION.value.find(
          enumItem => enumItem.value == item.wheelForceDirection
        )?.label,
        abnormalStateName: getAbnormalStateName(item, ABNORMAL_TYPE.value),
        ...item
      }
    })
  } catch (error) {
    console.log(error)
  }
}
// 获取决策信息
const getWheelDecisionListGet = async () => {
  try {
    const res = await wheelStore.getWheelDecisionListGet({
      decisionId: route.query.decisionId
    })
    decisionInfo.value = res
    planData.value = viewDecisionFormat(res)
  } catch (error) {
    console.log(error)
  }
}

const tools = computed(() => {
  // const { treeStatus, verifyStatus } = unref(treeInfomation)
  // const userId = userStore.userInfo.id
  // const roleList = userStore.userInfo.roleList
  // const { authorId } = (unref(treeInfomation).treeInfo || {})
  return [
    {
      text: '审核',
      icon: 'Setting',
      sign: 'toExamine',
      disabled: !(
        decisionInfo.value?.decisionState === 'TO_BE_REVIEWED' &&
        isReviewer.value
      )
    },
    // {
    //   text: '编辑',
    //   icon: 'EditPen',
    //   sign: 'toEdit',
    //   // disabled: !(((treeStatus === 10 || treeStatus === 40) && userId === authorId)) || treeStatus === 30 ,
    // },
    // {
    //   text: '验证',
    //   icon: 'Setting',
    //   sign: 'toVerify'
    //   // disabled: !(treeStatus === 10 && userId === authorId),
    // },
    {
      text: '提交',
      icon: 'UploadFilled',
      sign: 'toSubmit',
      disabled: !(
        decisionInfo.value?.decisionState === 'TO_BE_SUBMITTED' &&
        isAauthor.value
      )
    }
  ]
})

const moreTools = computed(() => {
  // const treeStatus = unref(treeInfomation).treeStatus
  // const userId = userStore.userInfo.id
  // const roleList = userStore.userInfo.roleList
  // const { authorId } = (unref(treeInfomation).treeInfo || {})
  return [
    {
      text: '复制',
      icon: 'CopyDocument',
      sign: 'toCopy',
      disabled: !isEditor
    },
    // {
    //   text: '停用并调整',
    //   icon: 'Sort',
    //   sign: 'toSort'
    //   // disabled: !(roleList.some(role => [35].includes(role.id))),
    // },
    {
      text: '转交',
      icon: 'Promotion',
      sign: 'toTransfer',
      disabled: !(
        (decisionInfo.value?.decisionState === 'TO_BE_SUBMITTED' ||
          decisionInfo.value?.decisionState === 'REVIEWED_REJECTED') &&
        (isAauthor.value || isReviewer.value)
      )
    },
    {
      text: '历史记录',
      icon: 'Edit',
      sign: 'toOptRecord',
      disabled: !(isEditor.value || isReviewer.value)
    },
    // {
    //   text: '重复校验',
    //   icon: 'User',
    //   sign: 'toVerifyAgain',
    //   disabled: !(roleList.some(role => [34, 35].includes(role.id))),
    // },
    {
      text: '删除',
      icon: 'Delete',
      sign: 'toDel',
      disabled: !(
        decisionInfo.value?.decisionState === 'TO_BE_SUBMITTED' &&
        isAauthor.value
      )
    }
  ]
})
const toolsHandle = item => {
  if (item.disabled) return
  switch (item.sign) {
    case 'toExamine':
      toExamine()
      break
    case 'toEdit':
      toEdit()
      break
    case 'toVerify':
      toVerify()
      break
    case 'toSubmit':
      toSubmit()
      break
  }
}
const moreToolsHandle = item => {
  if (item.disabled) return
  switch (item.sign) {
    case 'toCopy':
      toCopy()
      break
    case 'toSort':
      toSort()
      break
    case 'toTransfer':
      toTransfer()
      break
    case 'toOptRecord':
      toOptRecord()
      break
    case 'toVerifyAgain':
      toVerifyAgain()
      break
    case 'toDel':
      toDel()
      break
  }
}
const toExamine = () => {
  examineDrawer.value = true
}
const toEdit = () => {}
const toVerify = () => {}
const toSubmit = () => {
  submitDrawer.value = true
}
const toCopy = () => {
  window.open(
    `/wheelAlignment/decision/editChangePlan?decisionId=${route.query.decisionId}&isCopy=1`,
    '_blank'
  )
}
const toSort = () => {}
const toTransfer = () => {
  transferDrawer.value = true
}
const toOptRecord = () => {
  optRecordDrawer.value = true
}
const toVerifyAgain = () => {}
const toDel = async () => {
  try {
    await wheelStore.deleteWheelDecision({
      decisionId: route.query.decisionId,
      operator: operator.value
    })
    ElMessage.success('删除成功')
    setTimeout(() => {
      window.close()
    }, 2000)
  } catch (error) {
    ElMessage.error(error)
  }
}

const tableData = ref([])
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'suspensionTypeName',
    label: '悬挂形式'
  },
  {
    prop: 'authorId',
    label: '外倾角是否可调整',
    formatter(row) {
      const result = isOrNot(row.adjustable)
      return <div>{result}</div>
    }
  },
  {
    prop: 'damagedWheelPositionName',
    label: '受损车轮位置'
  },
  {
    prop: 'wheelForceDirectionName',
    label: '车轮受力方向'
  },
  {
    prop: 'abnormalStateName',
    label: '异常状态',
    width: '600px'
  },
  {
    prop: 'enabledDecision',
    label: '是否有启用决策',
    formatter(row) {
      const result = isEnabled(row.enabledDecision)
      return <div>{result}</div>
    }
  },
  {
    prop: 'label',
    label: '操作',
    width: '165px',
    formatter(...args) {
      console.log(args)
      // const test = () => {
      // }
      return (
        <div class='decision-icon' style='font-size: 20px;'>
          <router-link
            target='_blank'
            to={`/wheelAlignment/index/decision?completeIndexId=${args[0].id}`}
          >
            <Connection class='decision-icon-common decision-icon-connection' />
          </router-link>
        </div>
        // <router-link
        //     target='_blank'
        //     to={`/wheelAlignment/index/sameIndex?id=`}
        //   >
        //     <Refresh class='decision-icon-common decision-icon-connection' />
        //   </router-link>
        // <router-link target='_blank' to={`/wheelAlignment/index/records?id=`}>
        //     <Warning class='decision-icon-common decision-icon-warning' />
        //   </router-link>
        // <Remove
        //     class='decision-icon-common decision-icon-remove'
        //     onClick={test}/>
      )
    }
  }
]
</script>

<style lang="less" scoped>
.decision {
  padding: 0 40px 20px;

  &-title {
    padding: 20px 0;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
  &-icon {
    color: #409eff;
    cursor: pointer;
  }

  &-plan {
    margin: 0 30px;
    &-label {
      margin-bottom: 20px;
    }
    &-list {
      margin-bottom: 30px;
      &-title {
        margin-bottom: 10px;
      }
      &-button {
        padding: 0 20px;
        border-radius: 12px;
      }
      &-icon {
        color: #409eff;
        margin-left: 15px;
      }
    }
  }
}

.decision-info {
  &-title-more {
    display: flex;
    margin-left: 10px;
    &-list {
      margin: 0 15px;
    }
  }
}

.look-up {
  &-tools {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 13px;
    color: rgb(96, 98, 102);

    & > div {
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-right: 1px solid gray;
      border-left: 1px solid gray;
      cursor: pointer;
      white-space: nowrap;

      &.disabled {
        cursor: not-allowed;
        color: #c0c4cc;
      }

      &:last-child {
        border-right: none;
      }

      &:first-child {
        border-left: none;
      }

      & > i {
        margin-right: 5px;
      }
    }
  }
}

/deep/ .decision-icon {
  &-common {
    width: 1em;
    height: 1em;
    margin: 0 7px;
    cursor: pointer;
    vertical-align: -2px;
  }
  &-warning,
  &-connection {
    color: #409eff;
  }
  &-remove {
    color: #f56c6c;
  }
  &-disabled {
    color: #aaa;
    cursor: not-allowed;
  }
}
</style>