<template>
  <div class="audit-tree">
    <div class="audit-tree-form">
      <el-form :inline="true" :model="form">
        <el-row>
          <el-col :span="24" class="audit-tree-form-field">
            <el-form-item label="逻辑树:">
              <el-input style="width:100px;" v-model="form.systemCode" placeholder="系统" />
              <el-input style="width:100px;" v-model="form.physicalTreeCode" placeholder="逻辑树类型" />
              <el-input style="width:100px;" v-model="form.serialNumber" placeholder="顺序码" />
            </el-form-item>
            <el-form-item label="编写人:">
              <el-select
                style="width:160px;"
                v-model="form.editors"
                clearable
                filterable
                placeholder="请选择编写人"
              >
                <el-option
                  v-for="item in userAllListEditorOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="审核人:">
              <el-select
                style="width:160px;"
                v-model="form.checker"
                clearable
                filterable
                placeholder="请选择审核人"
              >
                <el-option
                  v-for="item in userAllListReviewerOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="操作时间:">
              <el-col :span="11">
                <el-date-picker
                  v-model="form.date1"
                  type="date"
                  placeholder="开始时间"
                  style="width: 100%"
                />
              </el-col>
              <el-col :span="2" class="text-center">
                <span>-</span>
              </el-col>
              <el-col :span="11">
                <el-date-picker
                  v-model="form.date2"
                  type="date"
                  placeholder="结束时间"
                  style="width: 100%"
                />
              </el-col>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="audit-tree-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="audit-tree-table">
      <TableCmpt
        :case-list="tableData"
        :header-list="headerList"
      />
      <PaginationCmpt
        :current-page="page"
        :total="total"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, unref, reactive, computed, onMounted } from 'vue'
import { useTreeStore } from '@/store/tree'
import { useUserStore } from '@/store/user'
import TableCmpt from '@/components/TableCmpt'
import PaginationCmpt from '@/components/PaginationCmpt'
import { GMTToLocal, LocalToGMT } from '@/utils'

const treeStore = useTreeStore()
const userStore = useUserStore()
const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)
const userAllList = computed(() => {
  return (userStore.userAllList || []).filter(
    ({ inside }) => inside).map(item => ({
      label: item.userName,
      value: item.id,
      original: item
    })
  )
})
const userAllListEditorOptions = computed(() => {
  return unref(userAllList).filter(item => {
    const { original } = item || {}
    return original?.roleList?.some(user => [35].includes(user.id))
  })
})
const userAllListReviewerOptions = computed(() => {
  return unref(userAllList).filter(item => {
    const { original } = item || {}
    return original?.roleList?.some(user => [34].includes(user.id))
  })
})
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px',
  },
  {
    prop: 'treeCode',
    label: '逻辑树编码',
    formatter(...args) {
      const {
        systemCode,
        physicalTreeCode,
        serialNumber,
        id
      } = args[2]
      const code = `${systemCode}${physicalTreeCode}${serialNumber}`
      return (
        <router-link target="_blank" to={`/tree/show?id=${id}`}>
          <div>{code}</div>
        </router-link>
      )
    }
  },
  {
    prop: 'authorId',
    label: '编写人',
    formatter(...args) {
      const editors = args[2]
      const editorsConfig = unref(userAllList).find(
        item => item.value === editors
      )
      return (<div>{editorsConfig ? editorsConfig.label : '-'}</div>)
    }
  },
  {
    prop: 'reviewerId',
    label: '审核人',
    formatter(...args) {
      const checker = args[2]
      const checkerConfig = unref(userAllList).find(
        item => item.value === checker
      )
      return (<div>{checkerConfig ? checkerConfig.label : '-'}</div>)
    }
  },
  {
    prop: 'lastModifiedTime',
    label: '最后操作时间',
    formatter(row) {
      const datetime = GMTToLocal(row.lastModifiedTime)
      return (<div>{datetime}</div>)
    }
  },
]
const form = reactive({
  systemCode: null,
  physicalTreeCode: null,
  serialNumber: null,
  editors: null,
  checker: null,
  date1: null,
  date2: null,
})

onMounted(async () => {
  await userStore.getUserAllInfo()
  getTreePageList()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  search()
}
const search = () => {
  const {
    systemCode,
    physicalTreeCode,
    serialNumber,
    editors,
    checker,
    date1,
    date2,
  } = form
  getTreePageList({
    treeCode: {
      systemCode: systemCode,
      physicalTreeCode: physicalTreeCode,
      serialNumber: serialNumber,
    },
    authorId: editors,
    reviewerId: checker,
    modifiedBeginTime: LocalToGMT(date1),
    modifiedEndTime: LocalToGMT(date2),
  })
}
const reset = () => {
  form.systemCode = null
  form.physicalTreeCode = null
  form.serialNumber = null
  form.editors = null
  form.checker = null
  form.date1 = null
  form.date2 = null
}
const currentChange = value => {
  page.value = value
  search()
}
async function getTreePageList(data = {}) {
  try {
    const result = await treeStore.getTreePageList({
      page: unref(page),
      size: unref(size),
      ...data,
      treeStatus: 20,
    })
    
    tableData.value = result.records
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.audit-tree {
  padding: 10px 30px;

  &-form {
    &-field {
      padding: 10px 0;
    }

    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>