<template>
  <div class="collapse">
    <div class="collapse-header">
      <div class="collapse-header-title" @click="toggle">
        <el-icon v-if="show"><ArrowDown /></el-icon>
        <el-icon v-else><ArrowRight /></el-icon>
        <span>{{ title }}</span>
      </div>
      <div class="collapse-header-tools">
        <el-icon @click="add"><Plus /></el-icon>
      </div>
    </div>
    <Transition>
      <div v-show="show" >
        <div class="collapse-content">
          <ItemInfoCmpt
            v-for="item in fieldList"
            :key="item.id"
            :item="item"
            icon="Close"
            iconColor="red"
            @select="updateState"
            @handleClick="handleClick"
          />
        </div>
      </div>
    </Transition>

    <el-dialog
      v-model="dialogVisible"
      :show-close="true"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="'新增' + title"
    >
      <TransferCmpt
        ref="transferCmptRef"
        :hasBorder="false"
        :tableDataLeft="libraryInfo"
        :tableFormat="tableFormat"
        :searchCmpt="false"
        :disabledData="disabledDataTransfer"
      />
      <template #footer>
        <span>
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </span>
      </template>
    </el-dialog>

  </div>
</template>

<script setup>
import { defineProps, ref, unref, defineEmits, computed, defineExpose } from 'vue'
import ItemInfoCmpt from '@/components/ItemInfoCmpt'
import TransferCmpt from '@/views/commonConfig/components/TransferCmpt.vue'

const props = defineProps({
  item: Object,
  index: Number,
  title: String,
  libraryInfo: Array
})

const emits = defineEmits(['addTag', 'removeTag'])
const show = ref(true)
const dialogVisible = ref(false)
const fieldList = computed(() => {
  return props.item.fieldList
})
const disabledDataTransfer = computed(() => {
  const fieldListIds = props.item.fieldList.map(list => list.id)

  return props.libraryInfo.filter(info => fieldListIds.includes(info.itemId)) || []
})

const toggle = () => {
  const status = unref(show)

  show.value = !status
}

const add = () => {
  dialogVisible.value = true;
}

const updateState = (item) => {
  emits('removeTag', item)
}

const tableFormat = ref([
  { label: '类型', code: 'typeName' },
  { label: '名称', code: 'label' }
])
const transferCmptRef = ref(null)
const confirm = async () => {
  const tableDataRight = transferCmptRef.value?.tableDataRight
  const partItemIds = []
  const laborItemIds = []
  const workItemIds = []

  tableDataRight.forEach(item => {
    const { typeName, itemId } = item

    if (typeName === '配件') {
      partItemIds.push(itemId)
    } else if (typeName === '工时') {
      laborItemIds.push(itemId)
    } else {
      workItemIds.push(itemId)
    }
  })

  const params = {
    partItemIds,
    laborItemIds,
    workItemIds
  }

  emits('addTag', params)
}
const cancel = () => {
  dialogVisible.value = false;
}

const handleClick = (item) => {
  console.log(item, 'handleClick')
}

defineExpose({
  cancel
})
</script>

<style lang="less" scoped>
.collapse {
  margin: 10px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #409eff;
    border-radius: 4px;
    padding: 5px 10px;
    color: white;

    &-title {
      display: flex;
      align-items: center;
      & > span {
        margin-left: 5px;
      }
    }

    &-tools {
      & > i {
        margin: 0 8px;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }

  &-danger{
    color: #f56c6c;
  }
}
</style>