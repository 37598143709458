<template>
  <div>
    <el-dialog v-model="value" title="配置修改:" :show-close="false" :close-on-click-modal="false">
      <el-form ref="formRef" :model="form" :rules="rules">
        <el-row :gutter="20" v-if="!(isPart && !isSingle)">
          <el-col :span="24">
            <span>{{ formattedBrandInfo }}</span>
          </el-col>
        </el-row>
        <br>
        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="悬挂形式:" prop="suspensionType" label-width="226px">
              <el-select v-model="form.suspensionType" clearable filterable placeholder="请选择悬挂形式" style="width: 90%;">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="24">
            <LinkageSelectCmpt
              ref="linkageSelectRef"
              :span="12"
              :rules="linkageSelectRules"
              :formDetails="form"
              :isEdit=true
              :isExtra=true
              @linkageSelectChange="linkageSelectChange"
            />
          </el-col>

          <el-col v-if="!isPart" :span="24">
            <el-form-item label="分组备注:" prop="remark">
              <el-input v-model="form.remark" maxlength="50" autocomplete="off" />
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { reactive, defineEmits, defineProps, computed, watch, onMounted, ref } from 'vue'
import { useEnumSelectStore } from '@/store/enumSelect'
import { vehicleReset } from '@/api/presetField'
import { ElMessage } from 'element-plus'
import LinkageSelectCmpt from '@/views/commonConfig/components/LinkageSelectCmpt'

const enumStore = useEnumSelectStore()
const emits = defineEmits(['update:modelValue', 'refreshList', 'getPartInfo'])
const props = defineProps({
  modelValue: Boolean,
  isEdit: Boolean,
  info: {
    type: Object,
    default: () => ({})
  },
  isPart: Boolean,
  isSingle: Boolean,
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})
const formattedBrandInfo = computed(() => {
  const {
    brandName,
    manufacturerName,
    seriesName,
    groupName,
    modelName
  } = form

  return `${brandName}-${manufacturerName}-${seriesName}-${groupName}-${modelName}`;
})
const SUSPENSION_TYPE = ref([])
onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
})

const rules = reactive({
  suspensionType: [
    { required: true, message: '请选择悬挂形式', trigger: 'change' }
  ],
  adjustable: [{ required: true, message: '请选择', trigger: 'change' }]
})
const linkageSelectRules = reactive({
  brandId: [{ required: true, message: '请选择品牌', trigger: 'change' }],
  manufacturerId: [
    { required: true, message: '请选择厂商', trigger: 'change' }
  ],
  seriesId: [{ required: true, message: '请选择车系', trigger: 'change' }]
})
let form = reactive({
  isPart: props.isPart
})
const linkageSelectChange = ({
  brandCode,
  manufacturerCode,
  seriesCode,
  groupCode,
  modelCode,
  frontCamberAdjustable,
  nearCamberAdjustable,
  toeAdjustable,
  frontLowerArmBallHeadSR,
  frontLowerControlArmOTFA,
  frontSteeringCrossTieRodBallHeadShape,
  frontSteeringCrossTieRodBallHeadOTFA,
  frontSteeringKnuckleConnectUpperSwingArmShape
}) => {
  form.brandCode = brandCode
  form.manufacturerCode = manufacturerCode
  form.seriesCode = seriesCode
  form.groupCode = groupCode
  form.modelCode = modelCode
  form.frontCamberAdjustable = frontCamberAdjustable
  form.nearCamberAdjustable = nearCamberAdjustable
  form.toeAdjustable = toeAdjustable
  form.frontLowerArmBallHeadSR = frontLowerArmBallHeadSR
  form.frontLowerControlArmOTFA = frontLowerControlArmOTFA
  form.frontSteeringCrossTieRodBallHeadShape = frontSteeringCrossTieRodBallHeadShape
  form.frontSteeringCrossTieRodBallHeadOTFA = frontSteeringCrossTieRodBallHeadOTFA
  form.frontSteeringKnuckleConnectUpperSwingArmShape = frontSteeringKnuckleConnectUpperSwingArmShape
}

const cancel = () => {
  emits('update:modelValue', false)
  form.remark = ''
}

const confirm = async () => {
  if (props.isPart) {
    emits('getPartInfo', { form, isSingle: props.isSingle })
  } else {
    await vehicleReset(form)
    emits('update:modelValue', false)
    ElMessage({
      message: '修改成功',
      type: 'success'
    })
    emits('refreshList')
  }
}

watch(() => props.info, (value) => {
  const stringProperties = [
    'frontCamberAdjustable',
    'nearCamberAdjustable',
    'toeAdjustable',
    'frontLowerArmBallHeadSR',
    'frontLowerControlArmOTFA',
    'frontSteeringCrossTieRodBallHeadShape',
    'frontSteeringCrossTieRodBallHeadOTFA',
    'frontSteeringKnuckleConnectUpperSwingArmShape'
  ]

  Object.assign(form, {
    ...value,
    suspensionType: Number(value?.suspensionType) || '',
    ...stringProperties.reduce((acc, prop) => {
      acc[prop] = String(value[prop] ?? '')
      return acc
    }, {})
  })
}, { deep: true })

const formRef = ref(null)
const validate = async () => {
  let res = false
  await formRef.value.validate(valid => {
    if (valid) {
      res = true
    } else {
      res = false
    }
  })
  return res
}

// eslint-disable-next-line no-undef
defineExpose({
  validate
})
</script>