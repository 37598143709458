<template>
  <!-- 需要修改接口数据 -->
  <div>
    <StatusBar :title="brandModelCode"></StatusBar>

    <div class="remark">
      <div class="remark-title">异常状态:</div>
      <div class="remark-content">后倾角：异常数值＜最小值 ； 外倾角：异常数值＞最大值</div>

      <div class="remark-flex">
        <div class="remark-title">备注内容:</div>
        <div class="remark-link" @click="openDefaultField">预设字段</div>
      </div>
      <div class="remark-content">
        <el-input v-model="remarkContent" type="textarea" rows="4" placeholder></el-input>
      </div>

      <div class="remark-title">关联附件:</div>
      <div class="remark-content">
        <el-upload
          :file-list="form.fileList"
          multiple
          :on-change="handleChange"
          :limit="9"
          :auto-upload="false"
          accept=".jpg, .png, .xls, .xlsx, .doc, .docx, .pdf, .mp4, .mov, .avi, .rmvb"
        >
          <el-button type="primary">点击上传</el-button>
          <template #tip>
            <div class="remark-tip">
              <p>最多只能上传9个文件,且文件最大不能超过50MB</p>
              <p>(上传文件格式能且只能为: .jpg/.png/.xls/.xlsx/.doc/.docx/.pdf/.mp4/.mov/.avi/.rmvb)</p>
            </div>
          </template>
        </el-upload>
      </div>
    </div>

    <RemarkDefaultFieldCmpt v-model="defaultFieldVisible" />
  </div>
</template>

<script setup>
import { computed, reactive, ref } from 'vue'
import StatusBar from '@/components/StatusBar'
import RemarkDefaultFieldCmpt from '@/views/wheelAlignment/components/RemarkDefaultFieldCmpt'

const brandModelCode = computed(() => {
  return '麦弗逊+单下臂+'
})
const defaultFieldVisible = ref(false)
const openDefaultField = () => {
  defaultFieldVisible.value = true
}

const form = reactive({
  fileList: [
    {
      "name": "favicon.ico",
      "percentage": 0,
      "status": "ready",
      "size": 9662,
      "raw": {
          "uid": 1678082927750
      },
      "uid": 1678082927750
    },
    {
      "name": "favicon.ico",
      "percentage": 0,
      "status": "ready",
      "size": 9662,
      "raw": {
          "uid": 1678082927750
      },
      "uid": 1678082927750
    }
  ],
})
const handleChange = (file, uploadFiles) => {
  form.fileList = uploadFiles
}
</script>

<style lang="less" scoped>
.remark {
  padding: 0 40px 20px;

  &-title {
    margin: 20px 0;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
  &-icon {
    color: #409eff;
    cursor: pointer;
  }
  &-tip{
    margin-top: 20px;
    font-size: 12px;
  }
  &-flex{
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &-link{
    cursor: pointer;
    color: #3452DB;
    text-decoration: underline;
  }

  /deep/ .el-upload-list{
    width: 300px;
  }
}

</style>