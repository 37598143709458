<template>
  <div :class="['item', item.disabled == 1 ? 'disabled' : '']">
    <span @click="handleClick">{{ item.label }}</span>
    <el-icon @click="select">
      <component :is="iconsMap[icon]"></component>
    </el-icon>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import * as icons from '@element-plus/icons-vue'
import IconDisabled from '@/components/icons/IconDisabled'

const props = defineProps({
  item: Object,
  icon: String,
})
const emits = defineEmits(['select', 'handleClick'])
const iconsMap = computed(() => {
  return {
    ...icons,
    IconDisabled,
  }
})
const select = () => {
  emits('select', props.item)
}
const handleClick = () => {
  emits('handleClick', props.item)
}
</script>

<style lang="less" scoped>
.item {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 10px;
  height: 20px;
  text-align: center;
  border: 1px solid #dcdfe6;
  border-radius: 30px;
  padding: 2px 15px;
  cursor: pointer;
  font-size: 12px;

  &.disabled {
    background-color: silver;

    :deep(.el-icon) {
      color: #1afa29;
    }
  }

  :deep(.el-icon) {
    margin-left: 5px;
    color: #f56c6c;
  }
}
</style>