<template>
  <svg
    t="1677894345376"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="984"
    width="200"
    height="200"
  >
    <path
      d="M533.333333 341.333333c-113.066667 0-215.466667 42.666667-294.4 110.933334L85.333333 298.666667v384h384l-154.453333-154.453334c59.306667-49.493333 134.826667-80.213333 218.453333-80.213333 151.04 0 279.466667 98.56 324.266667 234.666667l101.12-33.28C899.413333 470.613333 731.733333 341.333333 533.333333 341.333333z"
      p-id="985"
    ></path>
  </svg>
</template>
