<template>
  <div class="login">
    <div class="login-bg"></div>
    <div class="login-box">
      <h2 class="login-title">德联易控知识库</h2>
      <el-card>
        <el-form
          :model="form"
          :rules="rules"
          class="login-form"
        >
          <div class="login-label">用户登陆</div>
          <el-form-item prop="loginName">
            <el-input v-model="form.loginName">
              <template #prepend><el-icon><User /></el-icon></template>
            </el-input>
          </el-form-item>
          <el-form-item prop="password">
            <el-input v-model="form.password" type="password" autocomplete="off">
              <template #prepend><el-icon><Lock /></el-icon></template>
            </el-input>
          </el-form-item>
          <el-form-item prop="code">
            <el-input v-model="form.code">
              <template #prepend><el-icon><Lock /></el-icon></template>
              <template #append>
                <span class="login-code" @click="getAuthCode">{{ btnText }}</span>
              </template>
            </el-input>
          </el-form-item>
          <el-form-item>
            <el-button class="login-btn" type="primary" @click="login">登陆</el-button>
          </el-form-item>
        </el-form>
      </el-card>
    </div>
  </div>
</template>
<script setup>
import { reactive, computed, unref, onMounted } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useUserStore } from '@/store/user'
import { ElMessage } from 'element-plus'
import { useTime } from '@/hooks/time'

const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const timer = useTime()
const form = reactive({
  loginName: '',
  password: '',
  code: '',
})
const rules = reactive({
  loginName: [
    { required: true, message: '请输入用户名', trigger: 'blur' }
  ],
  password: [
    { required: true, message: '请输入密码', trigger: 'blur' }
  ],
  code: [
    { required: true, message: '请输入验证码', trigger: 'blur' }
  ]
})
let isDev = false

const btnText = computed(() => {
  const time = unref(timer.time)
  return time > 0 ? `${time}s` : '获取验证码'
})

onMounted(() => {
  isDev = route.query?.dev === 'true'
})

const login = async () => {
  try {
    if (!form.code && !isDev) {
      ElMessage.error('请输入验证码')
      return
    }

    await userStore.login(form)
    router.push('/')
  } catch (error) {
    ElMessage.error(error)
  }
}
const getAuthCode = async () => {
  try {
    if (unref(timer.time) > 0) return
    await userStore.getAuthCode(form.loginName)
    timer.start(90)
  } catch (error) {
    ElMessage.error(error)
  }
}
</script>

<style lang="less" scoped>
.login {
  display: flex;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  position: relative;

  &-bg {
    position: absolute;
    top: 0;
    bottom: 50%;
    right: 0;
    left: 0;
    background-image: url(~@/assets/images/login-bg.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center top;
  }

  &-box {
    position: relative;
  }

  &-title {
    color: #ffffff;
    text-align: center;
    margin-bottom:10px;
  }

  &-label {
    text-align: center;
    font-weight: bold;
    padding-bottom: 20px;
    font-size: 15px;
  }

  &-code {
    cursor: pointer;
  }

  &-btn {
    width: 100%;
  }
}
</style>