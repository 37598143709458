import { defineStore } from "pinia"
import {
  saveCarBrand,
  saveCarModel,
  saveCarFaultCode,
  getCarBrandList,
  getCarBrandOptions,
  getCarModelOptions,
  getCarFaultCodeOptions,
  getCarModelList,
  getCarFaultCodeList,
  getCarFaultCodeListInfo,
  getCheckEnumElectronicControlSystem,
  getCheckEnumRepairType,
  updateCarFaultCodeRemarkAndSys,
  updateCarBrandVisible,
  updateCarModelVisible,
  updateCarFaultCodeVisible,
  getCarFaultCodeInfo,
  getCarFaultCodeRelationTrees,
  getCarFaultCodeRelationPage,
} from '@/api/car'

export const useCarStore = defineStore('car', {
  persist: true,
  state: () => {
    return {
      carBrandList: [],
      carModelList: [],
      carFaultCodeList: [],
      electronicControlSystemList: null,
    }
  },
  actions: {
    async getCarBrandOptions() {
      try {
        const result = await getCarBrandOptions()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarBrandList() {
      try {
        const result = await getCarBrandList()
        this.carBrandList = result
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async saveCarBrand(data) {
      try {
        const result = await saveCarBrand(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarModelOptions(data) {
      try {
        const result = await getCarModelOptions(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarModelList(data) {
      try {
        const result = await getCarModelList(data)
        this.carModelList = result
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async saveCarModel(data) {
      try {
        const result = await saveCarModel(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarFaultCodeList(data) {
      try {
        const result = await getCarFaultCodeList(data)
        this.carFaultCodeList = result
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarFaultCodeOptions(data) {
      try {
        const result = await getCarFaultCodeOptions(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },


    async saveCarFaultCode(data) {
      try {
        const result = await saveCarFaultCode(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarFaultCodeListInfo(data) {
      try {
        const result = await getCarFaultCodeListInfo(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCheckEnumElectronicControlSystem() {
      try {
        const result = await getCheckEnumElectronicControlSystem()
        this.electronicControlSystemList = result.memberList
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCheckEnumRepairType() {
      try {
        const result = await getCheckEnumRepairType()
        return result.memberList
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updateCarFaultCodeRemarkAndSys(data) {
      try {
        const result = await updateCarFaultCodeRemarkAndSys(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarFaultCodeRelationPage(data) {
      try {
        const result = await getCarFaultCodeRelationPage(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarFaultCodeRelationTrees(data) {
      try {
        const result = await getCarFaultCodeRelationTrees(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getCarFaultCodeInfo(data) {
      try {
        const result = await getCarFaultCodeInfo(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 品牌显隐
    async updateCarBrandVisible(data) {
      try {
        const result = await updateCarBrandVisible(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 车型显隐
    async updateCarModelVisible(data) {
      try {
        const result = await updateCarModelVisible(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 故障码显隐
    async updateCarFaultCodeVisible(data) {
      try {
        const result = await updateCarFaultCodeVisible(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },
  }
})