<template>
  <svg
    t="1684288816425"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="8475"
    width="200"
    height="200"
  >
    <path
      d="M853.3 85.3A42.7 42.7 0 0 1 896 128v160.3l-85.3 85.3V170.7H213.3v682.7h597.3V735.7l85.3-85.3V896a42.7 42.7 0 0 1-42.7 42.7H170.7A42.7 42.7 0 0 1 128 896V128a42.7 42.7 0 0 1 42.7-42.7h682.7z m75.9 290.5l60.3 60.3L657.7 768l-60.4-0.1 0.1-60.2 331.9-331.9zM554.7 512v85.3H341.3V512h213.3z m128-170.7v85.3H341.3v-85.3h341.3z"
      p-id="8476"
      fill="#ffffff"
    />
  </svg>
</template>
