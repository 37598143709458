<template>
  <div class="opt-code">
    <div class="opt-code-head">
      <div class="opt-code-head-label">
        <span>*</span>所属品牌车型:
      </div>
      <el-cascader
        :props="props"
        v-model="carModelValue"
        style="width:260px;"
        @change="selectCarModel"
      />
    </div>
    <div class="opt-code-content">
      <div class="opt-code-form">
        <div class="opt-code-label">操作故障码:</div>
        <el-form :model="form" label-width="120px">
          <el-form-item label="故障码:" :rules="[{ required: true }]" prop="optType">
            <el-select style="width:200px;" v-model="form.optType" clearable placeholder="请选择">
              <el-option label="新增故障码" value="1" />
              <el-option label="修改故障码" value="2" />
            </el-select>
          </el-form-item>
          <el-form-item label="" prop="code" :rules="[{ validator: validateCode, trigger: 'blur' }]">
            <el-select
              style="width:200px;"
              v-if="form.optType == 2"
              v-model="form.code"
              clearable
              @change="selectCode"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in carFaultCode"
                :key="index"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
            <el-input
              v-else
              style="width:200px;"
              maxlength="50"
              v-model="form.code"
              placeholder="请输入"
            />
          </el-form-item>
          <el-form-item label="对应系统:" prop="system">
            <el-select
              style="width:200px;"
              v-model="form.system"
              clearable
              :multiple="true"
              placeholder="请选择"
            >
              <el-option
                v-for="(item, index) in systemOptions"
                :key="index"
                :label="item.comment"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="优先级:" :rules="[{ required: true }]" prop="grade">
            <el-select
              style="width:200px;"
              v-model="form.grade"
              clearable
              placeholder="请选择"
            >
              <el-option
                v-for="item in 9"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="故障码内容" prop="content">
            <el-input
              style="width:200px;"
              v-model="form.content"
              maxlength="200"
              type="textarea"
              placeholder="请输入"
            />
          </el-form-item>
        </el-form>
        <div class="opt-code-btns">
          <el-button v-if="form.optType == 2" type="primary" @click="edit">修改</el-button>
          <el-button v-else type="primary" @click="add">新增</el-button>
        </div>
      </div>
      <div class="opt-code-list">
        <div class="opt-code-label">已有故障码:</div>
        <ListCmpt :list="carFaultCode" @select="disabledHandle" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { reactive, computed, ref, unref } from 'vue'
import { useCarStore } from '@/store/car'
import ListCmpt from './ListCmpt'
import { ElMessage } from 'element-plus'

const carStore = useCarStore()
const carModelValue = ref(null)
const carFaultCode = ref([])
const form = reactive({
  optType: '',
  code: '',
  system: [],
  grade: 2,
  content: '',
})
const props = {
  lazy: true,
  lazyLoad(node, resolve) {
    const { level, value } = node
    if(level === 0) {
      carStore.getCarBrandOptions().then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 1,
          original: item,
        }))
        resolve(nodes)
      })
    }
    if (level === 1) {
      carStore.getCarModelOptions({
        carBrandId: value
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 1,
        }))
        resolve(nodes)
      })
    }
  },
}
const systemOptions = computed(() => {
  return carStore.electronicControlSystemList || []
})
// const validateCode = (rule, value, callback) => {
//   if (value === '') {
//     callback(new Error('请输入故障码'))
//   } else if (!/^[A-Za-z0-9]+$/.test(value)) {
//     callback(new Error('只能输入字母和数字'))
//   } else {
//     callback()
//   }
// }

// 故障码校验
const validateCode = (rule, value, callback) => {
  if (value === '') {
    callback(new Error('请输入故障码'))
  } else if (!/^[A-Za-z0-9\u4e00-\u9fa5]+$/.test(value)) {
    callback(new Error('只能输入字母、数字和中文（目前中文作为临时使用，后续需要把中文单独拆出）'))
  } else {
    callback()
  }
}
const selectCarModel = value => {
  getCarFaultCodeList(value)
}
const selectCode = value => {
  if (form.optType == 2) {
    const code = unref(carFaultCode).find(
      (item) => item.value === value
    )
    const { original } = code
    form.system = original.systems.map(item => item.id)
    form.grade = original.priority
    form.content = original.remark
  }
}
const add = () => {
  saveCarFaultCode()
}
const edit = () => {
  updateCarFaultCodeRemarkAndSys()
}
const disabledHandle = item => {
  updateCarFaultCodeVisible({
    id: item.value,
    status: item.disabled == 1 ? 0 : 1, // 0: 显示 1: 隐藏
  })
}
async function getCarFaultCodeList(value) {
  try {
    const list = await carStore.getCarFaultCodeList({
      carBrandId: value[0],
      carModelId: value[1],
    })
    carFaultCode.value = list.map(item => ({
      value: item.id,
      label: item.faultCode,
      disabled: item.invisible,
      original: item,
    }))
  } catch (error) {
    console.error(error)
  }
}
async function saveCarFaultCode() {
  try {
    const systems = form.system.map((value => {
      const target = unref(systemOptions).find(
        item => item.value === value
      )
      return {
        id: value,
        name: target.comment
      }
    }))
    const brandModelId = unref(carModelValue)
    const result = await carStore.saveCarFaultCode({
      carBrandId: brandModelId[0],
      carModelId: brandModelId[1],
      faultCode: form.code,
      remark: form.content,
      priority: form.grade,
      systems,
    })
    carFaultCode.value.push({
      id: result,
      value: result,
      label: form.code
    })
    ElMessage.success('添加成功')
  } catch (error) {
    ElMessage.error(error)
  }
}
async function updateCarFaultCodeRemarkAndSys() {
  try {
    const systems = form.system.map((value => {
      const target = unref(systemOptions).find(
        item => item.value === value
      )
      return {
        id: value,
        name: target.comment
      }
    }))
    await carStore.updateCarFaultCodeRemarkAndSys({
      id: form.code,
      remark: form.content,
      priority: form.grade,
      systems,
    })
    ElMessage.success('编辑成功')
  } catch(error) {
    ElMessage.error('编辑失败')
    console.error(error)
  }
}
async function updateCarFaultCodeVisible(data) {
  try {
    const result = await carStore.updateCarFaultCodeVisible(data)
    carFaultCode.value.forEach(item => {
      if (item.value === result.id) {
        item.disabled = result.status
      }
    })
    ElMessage.success('操作成功')
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.opt-code {
  display: flex;
  flex-direction: column;

  &-head {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &-label {
      font-size: 14px;
      color: #606266;
      margin-right: 10px;

      & > span {
        color: red;
      }
    }
  }

  &-content {
    flex: 1;
    display: flex;

    & > div {
      flex: 1;
      padding: 10px 20px;
    }
  }

  &-form {
    border-right: 1px solid #dcdfe6;
  }
  
  &-list {
    border-left: 1px solid #dcdfe6;
  }

  &-label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-mark {
    font-size: 12px;
    color: red;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>