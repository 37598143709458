import { defineStore } from "pinia"
import {
  getWheelVehicleList,
  getWheelRuleModelQuery,
  getWheelLibraryPage,
  saveWheelLibrary,
  deleteWheelLibrary,
  getWheelLibraryByLibraryIndex,
  getWheelLibraryByLibraryId,
  saveBatchWheelLibraryItem,
  deleteWheelLibraryItem,
  saveWheelLibraryTag,
  webLibraryItemTagSaveBatch,
  deleteWheelLibraryTag,
  getWheelLibraryTag,
  saveWheelLibraryWork,
  deleteWheelLibraryWork,
  getWheelLibraryWorkList,
  saveWheelLibraryRemark,
  deleteWheelLibraryRemark,
  getWheelLibraryRemarkList,
  updateWheelLibraryRemark,
  getWheelCompleteIndex,
  updateStateWheelCompleteIndex,
  getWheelCompleteIndexGet,
  getWheelCompleteIndexList,

  getWheelIndexRemark,
  getWheelIndexRemarkGet,
  initWheelIndexRemark,
  updateWheelIndexRemark,
  deleteWheelIndexRemark,

  getWheelDecisionList,
  getWheelDecisionListGet,
  saveWheelDecision,
  updateWheelDecision,
  submitWheelDecision,
  forwardWheelDecision,
  reviewWheelDecision,
  deleteWheelDecision,
  bindIndexWheelDecision,
  deleteIndexWheelDecisionRelationById,
  deleteIndexWheelDecisionRelation,
  updateStateWheelDecision,
  getWheelListCompleteIndexDecisions,
  getWheelListDecisionCompleteIndexes,

  getWheelListReviewRecord,
  getWheelListForwardRecord,
  getWheelListReviewSubmitRecord,
  getWheelListCompleteIndexOperate,

} from '@/api/wheelAlignment'

export const useWheelStore = defineStore('wheel', {
  persist: true,
  state: () => {
    return {
      addDecisionIndexList: null, // 新增决策时的决策索引数据
    }
  },
  actions: {
    async setDecisionIndexList(tableData = null) {
      this.addDecisionIndexList = tableData
    },

    // 获取字典
    async getWheelVehicleList(data) {
      try {
        const result = await getWheelVehicleList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 规则系统/车型查询
    async getWheelRuleModelQuery(data) {
      try {
        const result = await getWheelRuleModelQuery(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
 * =============================
 *       决策信息资源库管理            
 * =============================
 */

    // 资源库 - 分页查询
    async getWheelLibraryPage(data) {
      try {
        const result = await getWheelLibraryPage(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 资源库 - 新增
    async saveWheelLibrary(data) {
      try {
        const result = await saveWheelLibrary(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 资源库 - 删除
    async deleteWheelLibrary(data) {
      try {
        const result = await deleteWheelLibrary(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 资源项 - 获取资源库资源项（By 悬挂形式）
    async getWheelLibraryByLibraryIndex(data) {
      try {
        const result = await getWheelLibraryByLibraryIndex(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 资源项 - 获取资源库资源项（By 资源库 ID）
    async getWheelLibraryByLibraryId(data) {
      try {
        const result = await getWheelLibraryByLibraryId(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 资源项 - 添加资源项
    async saveBatchWheelLibraryItem(data) {
      try {
        const result = await saveBatchWheelLibraryItem(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 资源项 - 删除资源项
    async deleteWheelLibraryItem(data) {
      try {
        const result = await deleteWheelLibraryItem(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 标签 - 添加资源项  标签就是  受损车轮位置的枚举
    async saveWheelLibraryTag(data) {
      try {
        const result = await saveWheelLibraryTag(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 标签 - 添加资源项 - 批量操作
    async webLibraryItemTagSaveBatch(data) {
      try {
        const result = await webLibraryItemTagSaveBatch(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 标签 - 删除资源项
    async deleteWheelLibraryTag(data) {
      try {
        const result = await deleteWheelLibraryTag(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 标签 - 获取资源项
    async getWheelLibraryTag(data) {
      try {
        const result = await getWheelLibraryTag(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 工项 - 新增工项
    async saveWheelLibraryWork(data) {
      try {
        const result = await saveWheelLibraryWork(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 工项 - 删除工项
    async deleteWheelLibraryWork(data) {
      try {
        const result = await deleteWheelLibraryWork(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 工项 - 获取工项列表
    async getWheelLibraryWorkList(data) {
      try {
        const result = await getWheelLibraryWorkList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 备注 - 添加备注
    async saveWheelLibraryRemark(data) {
      try {
        const result = await saveWheelLibraryRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 备注 - 删除备注
    async deleteWheelLibraryRemark(data) {
      try {
        const result = await deleteWheelLibraryRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 备注 - 获取备注列表
    async getWheelLibraryRemarkList(data) {
      try {
        const result = await getWheelLibraryRemarkList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 备注 - 更新备注
    async updateWheelLibraryRemark(data) {
      try {
        const result = await updateWheelLibraryRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
* =============================
*       四轮定位索引            
* =============================
*/
    // 分页获取全量索引
    async getWheelCompleteIndex(data) {
      try {
        const result = await getWheelCompleteIndex(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 更新全量索引状态
    async updateStateWheelCompleteIndex(data) {
      try {
        const result = await updateStateWheelCompleteIndex(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取全量索引（By 索引ID）
    async getWheelCompleteIndexGet(data) {
      try {
        const result = await getWheelCompleteIndexGet(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取全量索引集
    async getWheelCompleteIndexList(data) {
      try {
        const result = await getWheelCompleteIndexList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
* =============================
*            备注            
* =============================
*/

    // 分页获取组合索引备注
    async getWheelIndexRemark(data) {
      try {
        const result = await getWheelIndexRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取组合索引备注信息
    async getWheelIndexRemarkGet(data) {
      try {
        const result = await getWheelIndexRemarkGet(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 初始化备注（新建）
    async initWheelIndexRemark(data) {
      try {
        const result = await initWheelIndexRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 更新备注
    async updateWheelIndexRemark(data) {
      try {
        const result = await updateWheelIndexRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 删除备注
    async deleteWheelIndexRemark(data) {
      try {
        const result = await deleteWheelIndexRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
* =============================
*         决策管理            
* =============================
*/

    // 分页获取决策信息
    async getWheelDecisionList(data) {
      try {
        const result = await getWheelDecisionList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取决策信息（By 决策ID）
    async getWheelDecisionListGet(data) {
      try {
        const result = await getWheelDecisionListGet(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 新增决策
    async saveWheelDecision(data) {
      try {
        const result = await saveWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 更新决策
    async updateWheelDecision(data) {
      try {
        const result = await updateWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 提交决策
    async submitWheelDecision(data) {
      try {
        const result = await submitWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 转交
    async forwardWheelDecision(data) {
      try {
        const result = await forwardWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 审核
    async reviewWheelDecision(data) {
      try {
        const result = await reviewWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 删除
    async deleteWheelDecision(data) {
      try {
        const result = await deleteWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 关联-绑定决策
    async bindIndexWheelDecision(data) {
      try {
        const result = await bindIndexWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 关联-删除（By 关联 ID）
    async deleteIndexWheelDecisionRelationById(data) {
      try {
        const result = await deleteIndexWheelDecisionRelationById(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 关联-删除（By 索引ID + 决策ID）
    async deleteIndexWheelDecisionRelation(data) {
      try {
        const result = await deleteIndexWheelDecisionRelation(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 关联-启用、停用
    async updateStateWheelDecision(data) {
      try {
        const result = await updateStateWheelDecision(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取【索引】对应的【决策列表】
    async getWheelListCompleteIndexDecisions(data) {
      try {
        const result = await getWheelListCompleteIndexDecisions(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取【决策】关联的【索引列表】
    async getWheelListDecisionCompleteIndexes(data) {
      try {
        const result = await getWheelListDecisionCompleteIndexes(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
* =============================
*          操作记录            
* =============================
*/

    // 记录：决策-审核
    async getWheelListReviewRecord(data) {
      try {
        const result = await getWheelListReviewRecord(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 记录：决策-转交
    async getWheelListForwardRecord(data) {
      try {
        const result = await getWheelListForwardRecord(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 记录：决策-提交
    async getWheelListReviewSubmitRecord(data) {
      try {
        const result = await getWheelListReviewSubmitRecord(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 记录：关联-索引关联记录
    async getWheelListCompleteIndexOperate(data) {
      try {
        const result = await getWheelListCompleteIndexOperate(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

  }
})