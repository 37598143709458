<template>
  <div class="look-up">
    <TreeStatusBar
      :treeCode="treeIds.treeCode"
      :authorId="treeIds.authorId"
      :reviewerId="treeIds.reviewerId"
    >
      <div class="look-up-tools">
        <div
          v-for="item in tools"
          :key="item.sign"
          @click="toolsHandle(item)"
          :class="{ 'disabled': item.disabled }"
        >
          <el-icon><component :is="item.icon"></component></el-icon>
          {{ item.text }}
        </div>
        <el-dropdown>
          <div><el-icon><MoreFilled /></el-icon>更多</div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item
                v-for="item in moreTools"
                @click="moreToolsHandle(item)"
                :key="item.sign"
                :icon="item.icon"
                :disabled="item.disabled"
              >{{ item.text }}</el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </TreeStatusBar>
    <div id="container"></div>
    <TreeInfoCard
      :treeCode="treeIds.treeCode"
      :faultCodes="treeRelationCodes"
      @info="openTreeInfoCard"
    />
    <TreeInfo v-model="treeDrawer" :info="treeInfo" />
    <NodeInfo v-model="nodeDrawer" :node="targetNode" :info="nodeInfo" />
    <EdgeInfo v-model="edgeDrawer" :edge="targetEdge" :info="edgeInfo" />
    <TreeTransfer
      v-model="transferDrawer"
      :tree="treeIds.treeCode"
      :authorId="treeIds.authorId"
      @confirm="transferUser"
    />
    <TreeRecord v-model="recordDrawer" :records="treeRecordList" />
    <TreeCheckin
      v-model="checkInDrawer"
      @review="flowReview"
    />
    <ContextMenu
      v-if="showContextMenu"
      :menus="SHOW_GRAPH_CONTEXT_MENU"
      :position="position"
      @select="selectContextMenu"
    />
    <TreeToolBar @zoom="graphZoom" />
    <TreeInfoEdit
      v-model="treeDrawerEdit"
      status="show"
      :id="treeIds.treeId"
      :codes="treeRelationCodes"
      @code="faultCodeHandler"
    />
  </div>
</template>

<script setup>
import { reactive, ref, unref, computed, onMounted, onUnmounted } from 'vue'
import { useTreeStore } from '@/store/tree'
import { useUserStore } from '@/store/user'
import { useRouter, useRoute } from 'vue-router'
import { emitter } from '@/utils/eventBus'
import { Tree } from './Tree'
import { SHOW_GRAPH_CONTEXT_MENU } from '@/utils/constants'
import { useTreeInfo } from './hooks/treeInfo'
import { useFaultCode } from './hooks/faultCode'
import TreeInfoCard from './components/TreeInfoCard'
import { ElLoading, ElMessage, ElMessageBox } from 'element-plus'
import TreeStatusBar from './components/TreeStatusBar'
import TreeInfo from './components/TreeInfo'
import NodeInfo from './components/NodeInfo'
import EdgeInfo from './components/EdgeInfo'
import TreeTransfer from './components/TreeTransfer'
import TreeRecord from './components/TreeRecord'
import TreeCheckin from './components/TreeCheckin'
import ContextMenu from './components/ContextMenu'
import TreeToolBar from './components/TreeToolBar'
import TreeInfoEdit from './components/TreeInfoEdit'

const position = reactive({ x: 0, y: 0 })
const selectContextMenu = ({ item, position }) => {
  if (item.value === 'info') {
    edgeDrawer.value = true
  }
  if (item.value === 'check') {
    treeRepeatVerify({
      flowCode: position.cell.id,
    })
    toVerifyAgain()
  }
}
const flowReview = item => {
  treeRepeatVerify({
    flowCode: position.cell.id,
    treeIds: item.treeList.map(({ id }) => id)
  })
}
const showContextMenu = ref(false)
const hideContextMenu = () => {
  showContextMenu.value = false
}
const treeStore = useTreeStore()
const userStore = useUserStore()
const router = useRouter()
const route = useRoute()
const targetEdge = ref({})
const targetNode = ref({})
const {
  treeIds,
  getTreeStructure,
  treeInfomation,
} = useTreeInfo({ treeStore })
const {
  treeRelationCodes,
  faultCodeHandler,
} = useFaultCode({ treeStore, treeIds })
const treeDrawerEdit = ref(false)
const treeDrawer = ref(false)
const nodeDrawer = ref(false)
const edgeDrawer = ref(false)
const transferDrawer = ref(false)
const recordDrawer = ref(false)
const checkInDrawer = ref(false)
const treeRecordList = ref([])
const nodeInfo = reactive({})
const edgeInfo = reactive({})
// const treeRelationCodes = ref([])
const treeRelationRemarks = ref([])
let graph = null
let treeId = 3

const tools = computed(() => {
  const { treeStatus, verifyStatus } = unref(treeInfomation)
  const userId = userStore.userInfo.id
  const roleList = userStore.userInfo.roleList
  const { authorId } = (unref(treeInfomation).treeInfo || {})
  return [
    {
      text: '审核',
      icon: 'Setting',
      sign: 'toAdit',
      disabled: !(treeStatus === 20 && roleList.some(role => [34].includes(role.id))),
    },
    {
      text: '编辑',
      icon: 'EditPen',
      sign: 'toEdit',
      disabled: !(((treeStatus === 10 || treeStatus === 40) && userId === authorId)) || treeStatus === 30 ,
    },
    {
      text: '验证',
      icon: 'Setting',
      sign: 'toVerify',
      disabled: !(treeStatus === 10 && userId === authorId),
    },
    {
      text: '提交',
      icon: 'UploadFilled',
      sign: 'toSubmit',
      disabled: !(treeStatus === 10 && userId === authorId && verifyStatus),
    },
  ]
})

const moreTools = computed(() => {
  const treeStatus = unref(treeInfomation).treeStatus
  const userId = userStore.userInfo.id
  const roleList = userStore.userInfo.roleList
  const { authorId } = (unref(treeInfomation).treeInfo || {})
  return [
    {
      text: '复制',
      icon: 'CopyDocument',
      sign: 'toCopy',
      disabled: !(roleList.some(role => [35].includes(role.id))),
    },
    {
      text: '转交',
      icon: 'Promotion',
      sign: 'toTransfer',
      disabled: !(userId === authorId || roleList.some(role => [34].includes(role.id))),
    },
    {
      text: '操作记录',
      icon: 'Edit',
      sign: 'toOptRecord',
      disabled: !(roleList.some(role => [34, 35].includes(role.id))),
    },
    // {
    //   text: '重复校验',
    //   icon: 'User',
    //   sign: 'toVerifyAgain',
    //   disabled: !(roleList.some(role => [34, 35].includes(role.id))),
    // },
    {
      text: '删除',
      icon: 'Delete',
      sign: 'toDel',
      disabled: !((treeStatus === 10 || treeStatus === 20 || treeStatus === 40) && userId === authorId),
    },
  ]
})

const treeInfo = computed(() => {
  return {
    treeRelationCodes: unref(treeRelationCodes),
    treeRelationRemarks: unref(treeRelationRemarks),
  }
})

onMounted(async () => {
  treeId = route.query.id
  init()
  getRelationTreeRemarks()
  getRelationTreeFaultCodes()
  getTreeOperateRecords()
  window.addEventListener('mousedown', hideContextMenu)
  window.addEventListener('mousewheel', hideContextMenu)
})
onUnmounted(() => {
  graph?.destroyed()
  graph = null
  emitter.off('graph:node')
  window.removeEventListener('mousedown', hideContextMenu)
  window.removeEventListener('mousewheel', hideContextMenu)
})

const init = async () => {
  await treeStore.getTreeOperateTypes()
  const result = await getTreeStructure()
  createGraph(result)
}
async function getRelationTreeRemarks() {
  try {
    const result = await treeStore.getRelationTreeRemarks({ treeId })
    treeRelationRemarks.value = result
  } catch (error) {
    console.error(error)
  }
}
async function getRelationTreeFaultCodes() {
  try {
    const result = await treeStore.getRelationTreeFaultCodes({ treeId })
    treeRelationCodes.value = result
  } catch (error) {
    console.error(error)
  }
}
async function getRelationNodeRemarks(nodeCode) {
  try {
    const result = await treeStore.getRelationNodeRemarks({ nodeCode })
    Object.assign(nodeInfo, {
      remarks: result,
    })
  } catch (error) {
    console.error(error)
  }
}
async function getRelationEdgeRemarks(flowCode) {
  try {
    const result = await treeStore.getRelationEdgeRemarks({ flowCode })
    Object.assign(edgeInfo, {
      remarks: result,
    })
  } catch (error) {
    console.error(error)
  }
}
async function treeVerify() {
  const loading = ElLoading.service({
    target: document.querySelector('.look-up'),
    background: 'rgba(0, 0, 0, 0.5)',
    text: '逻辑树验证中......'
  })
  try {
    const result = await treeStore.treeVerify({
      treeId: treeId,
      releaseId: unref(treeInfomation).releaseId,
    })
    loading.close()
    if (result.result === 1) {
      treeInfomation.value.verifyStatus = 1
      ElMessage.success(result.description)
    } else {
      ElMessage.error(result.description)
    }
    treeStore.saveTreeOperateRecord({
      treeId,
      operatorId: userStore.userInfo.id,
      operateType: 'VERIFY',
      content: result.result === 1 
                  ? `验证已通过` 
                  : `验证未通过: ${result.description}`,
    })
  } catch (error) {
    console.error(error)
  }
}
async function treeSubmit() {
  try {
    await treeStore.treeSubmit({
      treeId: treeId,
      releaseId: unref(treeInfomation).releaseId,
      authorId: userStore.userInfo.id,
    })
    ElMessage.success('提交成功')
    treeStore.saveTreeOperateRecord({
      treeId,
      operatorId: userStore.userInfo.id,
      operateType: 'COMMIT',
      content: '提交审核',
    })
  } catch (error) {
    console.error(error)
    ElMessage.success('提交失败')
  }
}
async function treeRemove() {
  try {
    await treeStore.treeRemove({
      treeId: treeId
    })
  } catch (error) {
    console.error(error)
    ElMessage.success('删除失败')
  }
}
async function treeForward(authorId) {
  try {
    await treeStore.treeForward({
      treeId: treeId,
      authorId
    })
    ElMessage.success('转交成功')
  } catch (error) {
    console.error(error)
    ElMessage.success('转交失败')

  }
}
async function treeRepeatVerify(data = {}) {
  try {
    await treeStore.treeRepeatVerify({
      releaseId: unref(treeInfomation).releaseId,
      flowCode: '',
      treeIds: [],
      ...data,
    })
  } catch (error) {
    console.error(error)
  }
}
async function getTreeOperateRecords() {
  try {
    const result = await treeStore.getTreeOperateRecords({ treeId })
    treeRecordList.value = result
  } catch (error) {
    console.error(error)
  }
}
const createGraph = data => {
  const container = document.getElementById('container')
  const { scrollWidth, scrollHeight } = container
  const {
    tree: {
      nodes,
      flows
    },
    nodeInfos,
    flowInfos
  } = data

  graph = new Tree({
    graphConfig: {
      container,
      width: scrollWidth,
      height: scrollHeight || 500,
    },
    flows,
    nodes,
    nodeInfos,
    flowInfos,
    treeIds,
  })

  bindEvents(graph)
}
const transferUser = async ({ id, oldEditor }) => {
  await treeForward(id)
  const toEditor = await userStore.getTargetUserInfo(id)
  treeStore.saveTreeOperateRecord({
    treeId,
    operatorId: userStore.userInfo.id,
    operateType: 'FORWARD',
    content: `编写人由【${oldEditor}】改为【${toEditor.userName}】`,
  })
}
const toAdit = () => {
  window.open(`/tree/audit?id=${treeId}`, '_blank')
}
const toEdit = () => {
  treeStore.saveTreeOperateRecord({
      treeId,
      operatorId: userStore.userInfo.id,
      operateType: 'EDIT',
      content: '编辑',
  })
  router.push({
    path: `/tree/edit`,
    query: {
      id: treeId
    }
  })
}
const toVerify = () => {
  treeVerify()
}
const toSubmit = () => {
  treeSubmit()
}
const toCopy = () => {
  const {
    releaseId,
    treeInfo
  } = unref(treeInfomation)
  router.push({
    path: '/tree/create',
    query: {
      type: 'COPY',
      id: releaseId,
      physical: treeInfo.treeCode.physicalTreeCode
    }
  })
}
const toTransfer = () => {
  transferDrawer.value = true
}
const toOptRecord = () => {
  recordDrawer.value = true
}
const toVerifyAgain = () => {
  checkInDrawer.value = true
}
const toDel = () => {
  ElMessageBox.confirm(
    '确认删除?',
    '警告',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(async () => {
    await treeRemove()
    ElMessage.success('删除成功')
    router.push('/audit/treeCodeLib/all')
  })
  .catch(() => { })
}
const bindEvents = (graph) => {
  graph.on('edge:dblclick', ({ edge }) => {
    targetEdge.value = edge
    edgeDrawer.value = true
    Object.assign(edgeInfo, graph?.getEdgeInfo(edge.id))
    getRelationEdgeRemarks(edge.id)
  })
  graph.on('node:dblclick', ({ node }) => {
    targetNode.value = node
    nodeDrawer.value = true
    Object.assign(nodeInfo, graph?.getNodeInfo(node.id))
    getRelationNodeRemarks(node.id)
  })
  graph.on('edge:contextmenu', ({ point, view }) => {
    Object.assign(position, point, view)
    showContextMenu.value = true
  })
}
const toolsHandle = item => {
  if (item.disabled) return
  switch (item.sign) {
    case 'toAdit':
      toAdit()
      break
    case 'toEdit':
      toEdit()
      break
    case 'toVerify':
      toVerify()
      break
    case 'toSubmit':
      toSubmit()
      break
  }
}
const moreToolsHandle = item => {
  if (item.disabled) return
  switch (item.sign) {
    case 'toCopy':
      toCopy()
      break
    case 'toTransfer':
      toTransfer()
      break
    case 'toOptRecord':
      toOptRecord()
      break
    case 'toVerifyAgain':
      toVerifyAgain()
      break
    case 'toDel':
      toDel()
      break
  }
}
const graphZoom = level => {
  graph.zoom(level/100)
}
const openTreeInfoCard = () => {
  const { treeStatus } = unref(treeInfomation)
  const userId = userStore.userInfo.id
  const { reviewerId } = (unref(treeInfomation).treeInfo || {})

  if (
    treeStatus === 30 &&
    reviewerId === userId
  ) {
    treeDrawerEdit.value = true
  } else {
    treeDrawer.value = true
  }
}
</script>

<style lang="less" scoped>
.look-up, #container {
  position: relative;
  height: 100vh;
}

.look-up {
  &-tools {
    display: flex;
    align-items: center;
    margin-right: 20px;
    font-size: 13px;
    color: rgb(96,98,102);

    & > div {
      display: flex;
      align-items: center;
      padding: 0 20px;
      border-right: 1px solid gray;
      border-left: 1px solid gray;
      cursor: pointer;
      white-space: nowrap;

      &.disabled {
        cursor: not-allowed;
        color: #c0c4cc;
      }

      &:last-child {
        border-right: none;
      }

      &:first-child {
        border-left: none;
      }

      & > i {
        margin-right: 5px;
      }
    }
  }
}
</style>