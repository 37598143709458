<template>
  <!-- 索引枚举配置 -->
  <div class="preset">
    <div class="preset-groups">
      <CollapseInfoCmpt
        v-for="(group, index) in fieldGroups"
        :key="group.id"
        :title="group.name"
        :item="group"
        :index="index"
        :outerGroupConfig="'wheelIndexItem'"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useFieldsStore } from '@/store/fields'
import CollapseInfoCmpt from '@/components/CollapseInfoCmpt'
// import { ElMessage } from 'element-plus'

// let editTarget = null
const fieldsStore = useFieldsStore()
console.log(fieldsStore)
// 目前只有一个悬挂形式
let fieldGroups = ref([{ id: 'SUSPENSION_TYPE', name: '悬挂形式' }])
// const form = reactive({ groupName: '' })
// const rules = reactive({
//   groupName: [
//     {
//       required: true,
//       message: '请输入分组名称',
//       trigger: 'blur'
//     }
//   ]
// })

onMounted(() => {})

// const confirm = () => {
//   if (editTarget) {
//     updatePresetFieldGroup()
//   } else {
//     savePresetFieldGroup()
//   }
// }

// const deleteGroup = async ({ id }) => {
//   try {
//     await fieldsStore.deletePresetFieldGroup({ id })
//     fieldGroups.value = unref(fieldGroups).filter(
//       target => target.id !== id
//     )
//     ElMessage.success('删除成功')
//   } catch (error) {
//     ElMessage.error('删除失败')
//   }
// }
// async function savePresetFieldGroup() {
//   try {
//     const { groupName } = form
//     const result = await fieldsStore.savePresetFieldGroup({
//       name: groupName
//     })
//     fieldGroups.value.push({
//       id: result,
//       name: groupName,
//     })
//     ElMessage.success('添加成功')
//     cancel()
//   } catch (error) {
//     ElMessage.error(error)
//   }
// }
// async function updatePresetFieldGroup() {
//   try {
//     const { id } = editTarget
//     const { groupName } = form
//     await fieldsStore.updatePresetFieldGroup({
//       id,
//       name: groupName
//     })
//     fieldGroups.value.forEach(item => {
//       if (item.id === id) {
//         item.name = groupName
//       }
//     })
//     ElMessage.success('修改成功')
//     cancel()
//   } catch (error) {
//     ElMessage.error(error)
//   }
// }
</script>

<style lang="less" scoped>
.preset {
  &-opts {
    padding: 10px;
  }

  &-groups {
    padding: 0 10px;
  }
}
</style>