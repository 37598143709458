<template>
  <!-- 需要修改接口数据 -->
  <div>
    <StatusBar :title="brandModelCode">
      <template #tree-tools>
        <el-button type text @click="openRecordsView">
          <el-icon style="margin-right: 5px" size="16px" color="#409EFF">
            <Search />
          </el-icon>操作记录
        </el-button>
      </template>
    </StatusBar>

    <div class="decision">
      <div class="decision-title">异常状态:</div>
      <div class="decision-content">后倾角：异常数值＜最小值 ； 外倾角：异常数值＞最大值</div>

      <div class="decision-title">
        四轮定位备注:
        <el-icon class="decision-icon" @click="toEdit">
          <EditPen />
        </el-icon>
      </div>
      <div class="decision-title2">备注内容:</div>
      <div class="decision-content">
        <div>决策编码：23445345325325</div>
        <div>编写人： 钱一</div>
        <div>审核人： 孙二</div>
      </div>

      <div class="decision-title2">关联附件:</div>
      <div class="decision-content">附件1</div>
    </div>

    <el-drawer
      v-model="showRecords"
      title="操作记录"
      :with-header="false"
      :close-on-click-modal="false"
      class="records"
    >
      <div class="records-title">操作记录：</div>
      <div class="records-content">
        <div class="records-list">操作记录啊啊啊啊啊啊啊</div>
        <div class="records-list">操作记录啊啊啊啊啊啊啊</div>
        <div class="records-list">操作记录啊啊啊啊啊啊啊</div>
      </div>
      <el-button class="records-close" @click="closeRecordsView">关闭</el-button>
    </el-drawer>
  </div>
</template>

<script setup>
import StatusBar from '@/components/StatusBar'
import { computed, ref } from 'vue'
import { useRouter } from 'vue-router'

const router = useRouter()
const brandModelCode = computed(() => {
  return '麦弗逊+单下臂+'
})

const toEdit = () => {
  router.push(`/wheelAlignment/remark/indexInfoEdit`)
}

const showRecords = ref(false)
const openRecordsView = () => {
  showRecords.value = true
}
const closeRecordsView = () => {
  showRecords.value = false
}

</script>

<style lang="less" scoped>
.decision {
  padding: 0 40px 20px;

  &-title {
    margin: 20px 0;
    font-weight: bold;
  }
  &-title2{
    margin: 20px 0;
    margin-left: 30px;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
  &-icon {
    color: #409eff;
    cursor: pointer;
  }
}

.records {
  position: relative;
  &-title {
    font-weight: bold;
    margin: 20px 0;
  }
  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>