<template>
  <div>
    <!-- 编辑索引信息 -->
    <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false" class="decision">
      <div class="decision-label">决策索引:</div>

      <el-form
        ref="formRef"
        :rules="rules"
        :inline="true"
        :model="form"
        label-position="left"
        class="decision-form"
      >
        <el-row>
          <el-col :span="24">
            <el-form-item label="悬挂形式:" prop="suspensionType">
              <el-select v-model="form.suspensionType" clearable filterable placeholder="请选择悬挂形式">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="外倾角是否可调整:" prop="adjustable">
              <el-select v-model="form.adjustable" clearable placeholder="请选择">
                <el-option
                  v-for="item in ADJUSTABLE_MENU"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="受损车轮位置:" prop="damagedWheelPosition">
              <el-select
                v-model="form.damagedWheelPosition"
                clearable
                filterable
                placeholder="请选择受损车轮位置"
              >
                <el-option
                  v-for="item in DAMAGED_WHEEL_POSITION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="车轮受力方向:" prop="wheelForceDirection">
              <el-select
                v-model="form.wheelForceDirection"
                clearable
                filterable
                placeholder="请选择车轮受力方向"
              >
                <el-option
                  v-for="item in WHEEL_FORCE_DIRECTION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="外倾角:" prop="camberState">
              <el-radio-group v-model="form.camberState" class="decision-radio-group">
                <el-radio
                  v-for="(element, i) in ABNORMAL_TYPE"
                  :key="i"
                  :label="element.value"
                  size="large"
                >{{element.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="前倾角:" prop="casterState">
              <el-radio-group v-model="form.casterState" class="decision-radio-group">
                <el-radio
                  v-for="(element, i) in ABNORMAL_TYPE"
                  :key="i"
                  :label="element.value"
                  size="large"
                >{{element.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="前束值:" prop="toeState">
              <el-radio-group v-model="form.toeState" class="decision-radio-group">
                <el-radio
                  v-for="(element, i) in ABNORMAL_TYPE"
                  :key="i"
                  :label="element.value"
                  size="large"
                >{{element.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="2" :offset="22">
            <el-button type="primary" @click="decisionAdd">新增</el-button>
          </el-col>
        </el-row>
      </el-form>

      <TableCmpt :case-list="tableData" :header-list="headerList" />

      <el-button class="decision-close" @click="close">关闭</el-button>
    </el-drawer>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  reactive,
  ref,
  onMounted
} from 'vue'
import TableCmpt from '@/components/TableCmpt'
import { useWheelStore } from '@/store/wheelAlignment'
import { ADJUSTABLE_MENU } from '@/utils/constants'
import { useEnumSelectStore } from '@/store/enumSelect'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/user'
import { uniqBy } from 'lodash'

const userStore = useUserStore()
const emits = defineEmits([
  'update:modelValue',
  'getWheelListDecisionCompleteIndexes'
])
const props = defineProps({
  modelValue: Boolean,
  decisionId: String
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const enumStore = useEnumSelectStore()
const wheelStore = useWheelStore()
const SUSPENSION_TYPE = ref(null)
const DAMAGED_WHEEL_POSITION = computed(() => {
  return (
    enumStore.DAMAGED_WHEEL_POSITION ||
    enumStore.getWheelEnumDamageWheelPosition()
  )
})
const WHEEL_FORCE_DIRECTION = computed(() => {
  return (
    enumStore.WHEEL_FORCE_DIRECTION || enumStore.getWheelEnumForceDirection()
  )
})
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})
const operator = computed(() => {
  const userInfo = userStore?.userInfo
  return userInfo
})

const rules = reactive({
  suspensionType: [
    { required: true, message: '请选择悬挂形式', trigger: 'change' }
  ],
  adjustable: [{ required: true, message: '请选择', trigger: 'change' }],
  damagedWheelPosition: [
    { required: true, message: '请选择', trigger: 'change' }
  ],
  wheelForceDirection: [
    { required: true, message: '请选择', trigger: 'change' }
  ],
  camberState: [{ required: true, message: '请选择', trigger: 'change' }],
  casterState: [{ required: true, message: '请选择', trigger: 'change' }],
  toeState: [{ required: true, message: '请选择', trigger: 'change' }]
})

onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
  getWheelListDecisionCompleteIndexes()
})

const formRef = ref(null)
const form = reactive({
  suspensionType: null,
  adjustable: null,
  damagedWheelPosition: null,
  wheelForceDirection: null,
  camberState: '4',
  casterState: '4',
  toeState: '4',
  ineffective: 0 // 是否无效索引 必需 0-有效；1-无效
})

const decisionAdd = async () => {
  await formRef.value.validate(async valid => {
    if (valid) {
      await getWheelCompleteIndexList()
    }
  })
  console.log(form)
}

// 查询索引集
const getWheelCompleteIndexList = async () => {
  try {
    const res = await wheelStore.getWheelCompleteIndexList(form)
    if (!res.length) {
      ElMessage.warning('没有查询到索引数据')
      return
    }
    const tableDataIds = tableData.value.map(item => item.id)
    const resIds = res.map(item => item.id)
    const paramsIds = resIds.filter(id => !tableDataIds.includes(id))
    if (!paramsIds.length) {
      ElMessage.warning('已存在相同数据')
      return
    }
    await bindIndexWheelDecision(paramsIds)
    const resArr = tableData.value.concat(res)
    tableData.value = uniqBy(resArr, 'id')
  } catch (error) {
    ElMessage.error(error)
  }
}
// 关联-索引绑定决策
const bindIndexWheelDecision = async completeIndexIds => {
  try {
    await wheelStore.bindIndexWheelDecision({
      completeIndexIds,
      decisionId: props.decisionId,
      operator: operator.value.id
    })
  } catch (error) {
    ElMessage.error(error)
  }
}
// 关联-删除绑定决策的索引
const deleteIndexWheelDecisionRelation = async id => {
  try {
    await wheelStore.deleteIndexWheelDecisionRelation({
      completeIndexId: id,
      decisionId: props.decisionId,
      operator: operator.value.id
    })
  } catch (error) {
    ElMessage.error(error)
  }
}

// 获取索引列表
const getWheelListDecisionCompleteIndexes = async () => {
  try {
    const res = await wheelStore.getWheelListDecisionCompleteIndexes({
      decisionId: props.decisionId
    })
    tableData.value = res
  } catch (error) {
    console.log(error)
  }
}

const close = () => {
  emits('getWheelListDecisionCompleteIndexes')
  emits('update:modelValue', false)
}
const brandModelCode = row => {
  if (row) {
    const {
      suspensionType,
      damagedWheelPosition,
      wheelForceDirection,
      adjustable
    } = row
    return `${
      SUSPENSION_TYPE.value.find(enumItem => enumItem.value == suspensionType)
        ?.label
    }+${enumStore.getEnumName2(
      'ADJUSTABLE_MENU',
      adjustable
    )}+${enumStore.getEnumName2(
      'DAMAGED_WHEEL_POSITION',
      damagedWheelPosition
    )}+${enumStore.getEnumName2('WHEEL_FORCE_DIRECTION', wheelForceDirection)}`
  } else {
    return ''
  }
}

const tableData = ref([])
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'label',
    label: '索引',
    align: 'left',
    formatter(row) {
      const getparamsName = brandModelCode(row)
      return (
        <div>
          {row.enabledDecision == 1 && (
            <el-icon class='decision-icon-star'>
              <Star />
            </el-icon>
          )}

          {getparamsName}
        </div>
      )
    }
  },
  {
    prop: 'label',
    label: '操作',
    width: '121x',
    formatter(...args) {
      const deleteRelationIndex = async () => {
        await deleteIndexWheelDecisionRelation(args[0].id)
        tableData.value.splice(args[3], 1)
      }
      return (
        <div class='decision-icon' style='font-size: 20px;'>
          <router-link
            target='_blank'
            to={`/wheelAlignment/index/decision?completeIndexId=${args[0].id}`}
          >
            <Connection class='decision-icon-common decision-icon-connection' />
          </router-link>

          <Remove
            class='decision-icon-common decision-icon-remove'
            onClick={deleteRelationIndex}
          />
        </div>
        // <router-link target='_blank' to={`/wheelAlignment/index/records?id=`}>
        //   <Warning class='decision-icon-common decision-icon-warning' />
        // </router-link>
        // <router-link
        //   target='_blank'
        //   to={`/wheelAlignment/index/sameIndex?id=`}
        // >
        //   <Refresh class='decision-icon-common decision-icon-connection' />
        // </router-link>
      )
    }
  }
]
</script>

<style lang="less" scoped>
.decision {
  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }
  &-form {
    margin: 0 15px 20px;
  }
  &-radio-group {
    margin-left: 30px;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
    z-index: 9;
  }
}

/deep/ .decision-icon {
  &-common {
    width: 1em;
    height: 1em;
    margin: 0 2px;
    cursor: pointer;
    vertical-align: -2px;
  }
  &-warning,
  &-connection {
    color: #409eff;
  }
  &-remove {
    color: #f56c6c;
  }
  &-disabled {
    color: #aaa;
    cursor: not-allowed;
  }
  &-star {
    margin-right: 3px;
    vertical-align: -1px;
    font-weight: bold;
    color: #409eff;
  }
}
</style>