<template>
  <el-dialog
    v-model="isShow"
    title="备注:"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
  >
    <el-form :model="form" :label-width="150">
      <el-form-item label="品牌-车型-故障码:" :rules="[{ required: true }]">
        <el-select
          v-model="form.faultCode"
          clearable
          :multiple="true"
          placeholder="请选择品牌"
        >
          <el-option
            v-for="item in faultCodeOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </el-form-item>
      <el-form-item label="备注内容:">
        <el-input
          v-model="form.content"
          :rows="2"
          type="textarea"
          placeholder="请输入备注内容"
        />
      </el-form-item>
      <el-form-item label="关联文件:">
        <el-upload
          :file-list="form.fileList"
          multiple
          :on-change="handleChange"
          :auto-upload="false"
        >
          <el-button type="primary">点击上传</el-button>
          <template #tip>
            <div>
              <p>最多只能上传9个文件,且文件最大不能超过50MB</p>
              <p>(上传文件格式能且只能为:.jpg/.png/.xls/.xlsx/.doc/.docx/.pdf/.mp4/.mov/.avi/.rmvb)</p>
            </div>
          </template>
        </el-upload>
      </el-form-item>
      <div style="text-align:right;">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </div>
    </el-form>
  </el-dialog>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  reactive,
  ref,
  unref,
  computed,
  watch
} from 'vue'
import { useTreeStore } from '@/store/tree'
import { useUserStore } from '@/store/user'
import { createOSSClient} from '@/utils/oss'
import { ElLoading, ElMessage } from 'element-plus'

const emits = defineEmits(['update:modelValue', 'confirm'])
const props = defineProps({
  modelValue: Boolean,
  remark: {
    type: Object,
    default: () => ({
      faultCode: null,
      content: '',
      fileList: [],
    })
  },
})

let clientOSS = null
const treeStore = useTreeStore()
const userStore = useUserStore()
const faultCodeOptions = ref([])
const form = reactive({
  faultCode: null,
  content: '',
  fileList: [
    {
      "name": "favicon.ico",
      "percentage": 0,
      "status": "ready",
      "size": 9662,
      "raw": {
          "uid": 1678082927750
      },
      "uid": 1678082927750
    },
    {
      "name": "favicon.ico",
      "percentage": 0,
      "status": "ready",
      "size": 9662,
      "raw": {
          "uid": 1678082927750
      },
      "uid": 1678082927750
    }
  ],
})
const isShow = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

watch(isShow, (value) => {
  if (value) {
    getRelationTreeFaultCodeOptions()
    const { remark } = props.remark
    form.faultCode = remark?.faultCodes?.map(item => item.id) || []
    form.content = remark?.remark || ''
    form.fileList = remark?.files || []
  }
})

const handleChange = (file, uploadFiles) => {
  form.fileList = uploadFiles
}
const confirm = async () => {
  if (!form.faultCode?.length) {
    ElMessage.warning('请选择故障码')
    return
  }

  const loading = ElLoading.service({
    lock: true,
    text: `保存中`,
    background: 'rgba(0, 0, 0, 0.7)',
  })
  const {
    region,
    accessKeyId,
    accessKeySecret,
    bucket,
    securityToken,
    endpoint,
  } = await getOSSSTSAuth()

  clientOSS = createOSSClient({
    region,
    accessKeyId,
    accessKeySecret,
    stsToken: securityToken,
    bucket,
    endpoint,
  })
  const fileResult = await upFiles(clientOSS, form.fileList)
  emits('confirm', {
    ...form,
    faultCode: form.faultCode.map(item => {
      const options = unref(faultCodeOptions)
      return options.find(code => code.value === item).original
    }),
    fileList: fileResult.map(item => {
      return {
        name: item.name,
        url: item.url,
        size: item.size,
      }
    })
  })
  loading.close()
  cancel()
}
const cancel = () => {
  isShow.value = !isShow.value
}

async function getRelationTreeFaultCodeOptions() {
  try {
    const { treeId } = props.remark
    const result = await treeStore.getRelationTreeFaultCodeOptions({
      treeId
    })
    faultCodeOptions.value = result?.map((item) => {
      const { key, value} = item
      return {
        label: `${value.carBrand}-${value.carModel}-${value.faultCode}`,
        value: key,
        original: {
          key,
          ...value,
        },
      }
    })
  } catch (error) {
    console.error(error)
  }
}
async function getOSSSTSAuth() {
  try {
    const result = await userStore.getOSSSTSAuth()
    return result
  } catch (error) {
    console.error(error)
  }
}
async function upFiles(client, files) {
  try {
    const resultList = []
    for(let index = 0; index < files.length; index++) {
      const file = files[index]
      if (file.status === 'success') {
        resultList.push(file)
      } else {
        const fileName = `${Date.now()}-${file.name}`
        const result = await client.put(
          `remarks/${fileName}`,
          file.raw
        )
        resultList.push({
          ...result,
          size: file.size,
          name: fileName,
        })
      }
    }
    return resultList
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
</style>