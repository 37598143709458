<template>
  <div>
    <StatusBar :title="brandModelCode">
      <!-- <template #tree-tools>
        <el-button type text @click="openRecordsView">
          <el-icon style="margin-right: 5px" size="16px" color="#409EFF">
            <Search />
          </el-icon>关联记录
        </el-button>
      </template>-->
    </StatusBar>

    <div class="decision">
      <div class="decision-title">
        异常状态：
        <!-- <el-icon class="decision-icon" @click="toSameIndex">
          <Refresh />
        </el-icon>-->
      </div>
      <div class="decision-content">{{ getAbnormalStateName(completeIndexInfo, ABNORMAL_TYPE) }}</div>

      <div class="decision-title">
        已启用决策信息：
        <el-icon
          v-if="enabledDecisionInfo && enabledDecisionInfo.id"
          class="decision-icon"
          @click="toDecisionInfo(enabledDecisionInfo.id)"
        >
          <Tickets />
        </el-icon>
      </div>
      <div v-if="enabledDecisionInfo && enabledDecisionInfo.id" class="decision-content">
        <div>决策编码：{{ enabledDecisionInfo.id }}</div>
        <div>编写人： {{getUserInfo(enabledDecisionInfo.editor)}}</div>
        <div>审核人： {{getUserInfo(enabledDecisionInfo.reviewer)}}</div>
        <div class="decision-flex">
          <div>更换方案：</div>
          <div>
            <div v-for="(item, index) in getChangePlanTxt(enabledDecisionInfo)" :key="index">
              第{{index + 1}}层：
              <span v-for="(element, i) in item" :key="i">{{ element }}</span>
            </div>
          </div>
        </div>
      </div>
      <div v-else class="decision-content">-</div>

      <div class="decision-title">全部关联决策信息：</div>
      <el-table
        show-header
        stripe
        border
        :data="tableData"
        max-height="545px"
        class="transfer-box-table"
      >
        <el-table-column align="center" label="序号" prop type="index" width="60px" />
        <el-table-column align="center" label="决策编码" prop="decisionCode" width="200px" />
        <el-table-column align="center" label="决策状态" prop="decisionStateName" width="200px" />
        <el-table-column align="center" label="决策层数" prop="layers" width="100px" />
        <el-table-column align label="更换方案" prop>
          <template #default="scope">
            <div v-for="(item, index) in getChangePlanTxt(scope.row)" :key="index">
              第{{index + 1}}层：
              <span v-for="(element, i) in item" :key="i">{{ element }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column align="center" label="编写人" prop="editorName" width="120px" />
        <el-table-column align="center" label="审核人" prop="reviewerName" width="120px" />
        <el-table-column align="center" label="最后操作时间" width="165px">
          <template #default="scope">{{ GMTToLocal(scope.row.lastModifyTime) }}</template>
        </el-table-column>
        <el-table-column align="center" label="启用情况" prop="name" width="100px">
          <template #default="scope">
            <el-switch
              v-if="showSwitch"
              v-model="scope.row.switchValue"
              :loading="switchLoading"
              :disabled="scope.row.relationState === 'OFFLINE'"
              size="small"
              @change="switchFn($event, scope.row)"
            />
            <template v-else>
              {{ enumStore.getEnumName2(
              'RELATION_STATE',
              scope.row.relationState
              ) }}
            </template>
          </template>
        </el-table-column>
        <el-table-column align="center" label="操作" prop="name" width="80px">
          <template #default="scope">
            <el-icon class="decision-icon" @click="toDecisionInfo(scope.row.id)">
              <Tickets />
            </el-icon>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-drawer
      v-model="showRecords"
      title="关联记录"
      :with-header="false"
      :close-on-click-modal="false"
      class="records"
    >
      <div class="records-title">关联记录：</div>
      <div class="records-content">
        <div class="records-list">关联记录啊啊啊啊啊啊啊</div>
        <div class="records-list">关联记录啊啊啊啊啊啊啊</div>
        <div class="records-list">关联记录啊啊啊啊啊啊啊</div>
      </div>
      <el-button class="records-close" @click="closeRecordsView">关闭</el-button>
    </el-drawer>
  </div>
</template>

<script setup>
import StatusBar from '@/components/StatusBar'
import { computed, onMounted, ref } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { useWheelStore } from '@/store/wheelAlignment'
import { ElMessage } from 'element-plus'
import { getChangePlanTxt } from '@/views/wheelAlignment/wheelAlignment'
import { GMTToLocal } from '@/utils'
import { useUserStore } from '@/store/user'
import { useEnumSelectStore } from '@/store/enumSelect'
import { userCommon, getAbnormalStateName } from '@/views/wheelAlignment/wheelCommon'

const route = useRoute()
const router = useRouter()
const wheelStore = useWheelStore()
const userStore = useUserStore()
const enumStore = useEnumSelectStore()
const { getUserInfo } = userCommon(userStore)

const operator = computed(() => {
  const userInfo = userStore?.userInfo
  return userInfo
})
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})

onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
  if (route.query.completeIndexId) {
    getDecision()
    getWheelCompleteIndexGet()
  }
})

const completeIndexInfo = ref(null)
// const decisionInfo = ref(null)
const enabledDecisionInfo = ref(null)
const tableData = ref([])
const SUSPENSION_TYPE = ref(null)

const showSwitch = computed(() => {
  return userStore.userInfo?.roleList.some(({ id }) => id === 40)
})
const brandModelCode = computed(() => {
  if (completeIndexInfo.value) {
    const {
      suspensionType,
      damagedWheelPosition,
      wheelForceDirection,
      adjustable
    } = completeIndexInfo.value
    return `${
      SUSPENSION_TYPE.value.find(enumItem => enumItem.value == suspensionType)
        ?.label
    }+${enumStore.getEnumName2(
      'ADJUSTABLE_MENU',
      adjustable
    )}+${enumStore.getEnumName2(
      'DAMAGED_WHEEL_POSITION',
      damagedWheelPosition
    )}+${enumStore.getEnumName2('WHEEL_FORCE_DIRECTION', wheelForceDirection)}`
  } else {
    return ''
  }
})

// 获取索引信息
const getWheelCompleteIndexGet = async () => {
  try {
    const res = await wheelStore.getWheelCompleteIndexGet({
      completeIndexId: route.query.completeIndexId
    })
    completeIndexInfo.value = res
  } catch (error) {
    ElMessage.error(error)
  }
}
// 获取【索引】对应的【决策列表】
const getDecision = async () => {
  try {
    const res = await wheelStore.getWheelListCompleteIndexDecisions({
      completeIndexId: route.query.completeIndexId
    })
    tableData.value = res.map(item => {
      return {
        decisionStateName: enumStore.getEnumName2(
          'DECISION_STATE',
          item.decisionState
        ),
        switchValue: item.relationState === 'ENABLED',
        editorName: getUserInfo(item.editor),
        reviewerName: getUserInfo(item.reviewer),
        ...item
      }
    })

    if (Array.isArray(res)) {
      enabledDecisionInfo.value = res.find(
        item => item.relationState === 'ENABLED'
      )
    }
  } catch (error) {
    ElMessage.error(error)
  }
}

const switchLoading = ref(false)
const switchFn = async (value, row) => {
  switchLoading.value = true
  try {
    await wheelStore.updateStateWheelDecision({
      decisionId: row.id,
      completeIndexId: route.query.completeIndexId,
      disabled: Number(!value), // 0-启用；1-禁用
      operator: operator.value.id
    })
    switchLoading.value = false
  } catch (error) {
    ElMessage(error)
  }
}

const showRecords = ref(false)
// const openRecordsView = () => {
//   showRecords.value = true
// }
const closeRecordsView = () => {
  showRecords.value = false
}

const toDecisionInfo = id => {
  router.push({
    path: '/wheelAlignment/decision/info',
    query: { decisionId: id }
  })
}
// const toSameIndex = () => {
//   router.push({ path: '/wheelAlignment/decision/sameIndex', query: {decisionId: id} })
// }
</script>

<style lang="less" scoped>
.decision {
  padding: 0 40px 20px;
  &-flex {
    display: flex;
  }
  &-title {
    padding: 20px 0;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
  &-icon {
    font-size: 20px;
    color: #409eff;
    cursor: pointer;
  }
}

.records {
  position: relative;
  &-title {
    font-weight: bold;
    margin: 20px 0;
  }
  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>