<template>
  <div>
    <!-- 操作记录 -->
    <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false" class="drawer">
      <el-tabs v-model="activeName" type="card" class="demo-tabs" @tab-click="handleClick">
        <el-tab-pane label="操作记录" name="first">
          <div class="drawer-label">操作记录:</div>
          <div class="drawer-record">
            <div
              class="drawer-txt"
              v-for="(item, index) in firstRecordList"
              :key="index"
            >{{ getRecordStr(item) }}</div>
          </div>
        </el-tab-pane>
        <!-- <el-tab-pane label="关联记录" name="second">
          <div class="drawer-label">关联记录:</div>
          <div class="drawer-record">
            <div class="drawer-txt">记录啊啊啊啊啊</div>
          </div>
        </el-tab-pane>
        <el-tab-pane label="证据记录" name="third">
          <div class="drawer-label">证据记录:</div>
          <div class="drawer-record">
            <div class="drawer-txt">记录啊啊啊啊啊</div>
          </div>
        </el-tab-pane>-->
        <el-tab-pane label="意见记录" name="fourth">
          <div class="drawer-label">意见记录:</div>
          <div class="drawer-record">
            <div
              class="drawer-txt"
              v-for="(item, index) in fourthRecordList"
              :key="index"
            >{{ getRecordStr(item) }}</div>
          </div>
        </el-tab-pane>
      </el-tabs>

      <div class="drawer-footer-btn">
        <el-button @click="handleClose">关闭</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import { GMTToLocal } from '@/utils'
import { orderBy } from 'lodash'
import { useWheelStore } from '@/store/wheelAlignment'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/user'
import { userCommon } from '@/views/wheelAlignment/wheelCommon'

const route = useRoute()
const wheelStore = useWheelStore()
const userStore = useUserStore()
const { getUserInfo } = userCommon(userStore)
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  isEdit: Boolean,
  info: {
    type: Object,
    default: () => ({})
  }
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const activeName = ref('first')

const recordList = ref([])
const submitRecord = ref([])
const forwardRecord = ref([])
const firstRecordList = computed(() => {
  const result = submitRecord.value.concat(
    recordList.value,
    forwardRecord.value
  )
  return orderBy(result, 'operateTime', ['desc'])
})
const fourthRecordList = computed(() => {
  const result = submitRecord.value.concat(recordList.value)
  return orderBy(result, 'operateTime', ['desc'])
})

const getRecordStr = item => {
  let str = `${getUserInfo(item.operator)}于${GMTToLocal(
    item.operateTime
  )}进行了`

  if (item.toEditor) {
    str += `转交，编写人由 ${getUserInfo(item.fromEditor)} 改为 ${getUserInfo(
      item.toEditor
    )}`
  } else {
    str += `${item.reviewState ? '审核' : '提交'}，操作内容为：${item.content}`
  }

  if (item.reviewState) {
    str += item.reviewState === 'APPROVED' ? '+通过' : '+退回'
  }
  return str
}

onMounted(() => {
  getWheelListReviewRecord()
  getWheelListForwardRecord()
  getWheelListReviewSubmitRecord()
})

// 提交记录
const getWheelListReviewSubmitRecord = async () => {
  try {
    const res = await wheelStore.getWheelListReviewSubmitRecord({
      decisionId: route.query.decisionId
    })
    submitRecord.value = res
  } catch (error) {
    ElMessage.error(error)
  }
}
// 转交记录
const getWheelListForwardRecord = async () => {
  try {
    const res = await wheelStore.getWheelListForwardRecord({
      decisionId: route.query.decisionId
    })
    forwardRecord.value = res
  } catch (error) {
    ElMessage.error(error)
  }
}
// 审核记录
const getWheelListReviewRecord = async () => {
  try {
    const res = await wheelStore.getWheelListReviewRecord({
      decisionId: route.query.decisionId
    })
    recordList.value = res
  } catch (error) {
    ElMessage.error(error)
  }
}

const handleClose = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="less" scoped>
.drawer {
  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-record {
    max-height: 703px;
    overflow-y: auto;
    line-height: 28px;
  }

  &-txt {
    font-size: 14px;
  }

  &-footer-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>