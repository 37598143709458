import { ref, unref } from 'vue'

export function useTime() {
  const time = ref(0)

  const timeOut = () => {
    time.value = unref(time) - 1
    
    if (time.value !== 0) {
      setTimeout(() => {
        timeOut()
      }, 1000)
    }
  }

  const start = (t) => {
    // reset time
    if (typeof t === 'number' && t > 0) {
      time.value = t
    }

    timeOut()
  }

  return {
    time,
    start,
  }
}
