<template>
  <div>
    <!-- 审核 -->
    <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false" class="drawer">
      <div class="drawer-label">意见记录:</div>
      <div class="drawer-record">
        <div
          class="drawer-txt"
          v-for="(item, index) in allRecordList"
          :key="index"
        >{{ getRecordStr(item) }}</div>
      </div>

      <div class="drawer-label">本次审核意见:</div>
      <el-form ref="formRef" :model="form" :rules="rules">
        <div class="drawer-txt">
          <el-form-item prop="content">
            <el-input
              v-model="form.content"
              type="textarea"
              placeholder="请输入审核意见"
              rows="4"
              maxlength="200"
            ></el-input>
          </el-form-item>
        </div>
      </el-form>

      <div class="drawer-footer-btn">
        <el-button @click="handleClose">关闭</el-button>
        <div>
          <el-button type="primary" @click="handlePass">通过</el-button>
          <el-button type="warning" @click="handleReturn">退回</el-button>
        </div>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  ref,
  reactive,
  onMounted
} from 'vue'
import { useWheelStore } from '@/store/wheelAlignment'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/user'
import { useRoute } from 'vue-router'
import { GMTToLocal } from '@/utils'
import { orderBy } from 'lodash'
import { userCommon } from '@/views/wheelAlignment/wheelCommon'

const wheelStore = useWheelStore()
const userStore = useUserStore()
const { getUserInfo } = userCommon(userStore)
const emits = defineEmits(['update:modelValue', 'getWheelDecisionListGet'])
const props = defineProps({
  modelValue: Boolean,
  decisionId: Number
})
const route = useRoute()
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const operator = computed(() => {
  return userStore?.userInfo?.id || ''
})

const formRef = ref(null)
const form = reactive({
  content: ''
})
const rules = reactive({
  content: [{ required: true, message: '请填写提交说明', trigger: 'change' }]
})

const recordList = ref([])
const submitRecord = ref([])
const allRecordList = computed(() => {
  const result = submitRecord.value.concat(recordList.value)
  return orderBy(result, 'operateTime', ['desc'])
})
const getRecordStr = item => {
  let str = ''
  str = `${getUserInfo(item.operator)}于${GMTToLocal(item.operateTime)}进行了${
    item.reviewState ? '审核' : '提交'
  }，操作内容为：${item.content}`
  if (item.reviewState) {
    str += item.reviewState === 'APPROVED' ? '+通过' : '+退回'
  }
  return str
}

onMounted(() => {
  getWheelListReviewRecord()
  getWheelListReviewSubmitRecord()
})

// 提交记录
const getWheelListReviewSubmitRecord = async () => {
  try {
    const res = await wheelStore.getWheelListReviewSubmitRecord({
      decisionId: route.query.decisionId
    })
    submitRecord.value = res
  } catch (error) {
    ElMessage.error(error)
  }
}
// 审核记录
const getWheelListReviewRecord = async () => {
  try {
    const res = await wheelStore.getWheelListReviewRecord({
      decisionId: route.query.decisionId
    })
    recordList.value = res
  } catch (error) {
    ElMessage.error(error)
  }
}

const handleClose = () => {
  emits('update:modelValue', false)
}
const handlePass = () => {
  handleSubmit('APPROVED')
}
const handleReturn = () => {
  handleSubmit('REJECTED')
}

const handleSubmit = async reviewState => {
  const validate = await formRef.value.validate(() => {})
  if (!validate) {
    return
  }

  try {
    await wheelStore.reviewWheelDecision({
      decisionId: props.decisionId,
      reviewState,
      content: form.content,
      operator: operator.value
    })
    ElMessage.success('审核成功')
    emits('getWheelDecisionListGet')
    handleClose()
  } catch (error) {
    ElMessage.error(error)
  }
}
</script>

<style lang="less" scoped>
.drawer {
  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-record {
    max-height: 280px;
    overflow-y: auto;
    line-height: 28px;
  }

  &-txt {
    font-size: 14px;
  }

  &-footer-btn {
    position: absolute;
    width: 93%;
    bottom: 20px;
    display: flex;
    justify-content: space-between;
  }
}
</style>