<template>
  <div class="collapse">
    <div class="collapse-header">
      <div class="collapse-header-title" @click="toggle">
        <el-icon v-if="show"><ArrowDown /></el-icon>
        <el-icon v-else><ArrowRight /></el-icon>
        <span>{{ title }}</span>
      </div>
      <div class="collapse-header-tools">
        <el-icon @click="add"><Plus /></el-icon>
        <el-icon @click="edit"><Edit /></el-icon>
        <el-icon @click="del"><Delete /></el-icon>
      </div>
    </div>
    <Transition>
      <div v-show="show" class="collapse-content">
        <ItemCmpt
          v-for="item in fieldList"
          :key="item.id"
          :item="item"
          icon="Close"
          @select="deleteField"
        />
      </div>
    </Transition>
    <el-dialog
      v-model="dialogVisible"
      :show-close="false"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :title="title + '新增预设字段:'"
    >
      <el-form
        ref="ruleFormRef"
        :model="form"
        :rules="rules"
        :size="formSize"
        status-icon
      >
        <el-form-item label="字段名称:" prop="name">
          <el-input v-model="form.name" maxlength="50" />
        </el-form-item>
      </el-form>
      <template #footer>
        <span>
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, ref, unref, reactive } from 'vue'
import { useFieldsStore } from '@/store/fields'
import ItemCmpt from '@/components/ItemCmpt'
import { ElMessage } from 'element-plus'

const props = defineProps({
  item: Object,
  index: Number,
  title: String,
  isWheel: Boolean,
})
const fieldsStore = useFieldsStore()
const emits = defineEmits(['add', 'edit', 'delete'])
let isLoaded = false
const show = ref(false)
const dialogVisible = ref(false)
const form = reactive({ name: '' })
const formSize = ref('default')
const rules = reactive({
  name: [
    { required: true, message: '请输入字段名称', trigger: 'blur' },
  ]
})
const fieldList = ref([])
const toggle = async () => {
  const status = unref(show)

  if (!isLoaded && !status) {
    let result;
    if (props.isWheel) {
      result = await fieldsStore.getWheelPresetFieldList({
        groupId: props.item.groupId
      })
      fieldList.value = (result || []).map(item => ({
        label: item.fieldName,
        id: item.fieldId,
        original: item,
      }))
    } else {
      result = await fieldsStore.getPresetFieldList({
        id: props.item.id
      })
      fieldList.value = (result || []).map(item => ({
        label: item.name,
        id: item.id,
        original: item,
      }))
    }

    // fieldList.value = (result || []).map(item => ({
    //   label: item.name,
    //   id: item.id || item.groupId,
    //   original: item,
    // }))
    isLoaded = true
  }

  show.value = !status
}

const add = () => {
  dialogVisible.value = true;
}
const edit = () => {
  emits('edit', props.item, props.index)
}
const del = () => {
  emits('delete', props.item, props.index)
}
const deleteField = (item) => {
  deletePresetField(item.id)
}
const confirm = async () => {
  try {
    let result;
    if (props.isWheel) {
      result = await fieldsStore.saveWheelPresetField({
        groupId: props.item.groupId,
        fieldName: form.name,
      })
    } else {
      result = await fieldsStore.savePresetField({
        groupId: props.item.id,
        name: form.name,
      })
    }

    fieldList.value.push({
      id: result,
      label: form.name,
    })

    ElMessage.success('添加成功')
    cancel()
  } catch (error) {
    ElMessage.error(error)
  }
}
const cancel = () => {
  dialogVisible.value = false;
  form.name = ''
}

async function deletePresetField(id) {
  try {
    if (props.isWheel) {
      await fieldsStore.deleteWheelPresetField({ fieldId: id })
    } else {
      await fieldsStore.deletePresetField({ id })
    }
    fieldList.value = unref(fieldList).filter(item => item.id !== id)
    ElMessage.success('删除成功')
  } catch (error) {
    ElMessage.error('删除失败')
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.collapse {
  margin: 10px 0;

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #409eff;
    border-radius: 4px;
    padding: 5px 10px;
    color: white;

    &-title {
      display: flex;
      align-items: center;
      & > span {
        margin-left: 5px;
      }
    }

    &-tools {
      & > i {
        margin: 0 8px;
      }
    }
  }

  &-content {
    display: flex;
    flex-wrap: wrap;
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
}
</style>