<template>
  <el-dialog
    v-model="show"
    :show-close="false"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :destroy-on-close="true"
    class="preset-field"
    draggable
  >
  <el-tabs type="card" v-model="activeTab">
    <el-tab-pane
      v-if="position !== 'release'"
      name="field"
      label="字段选择"
      class="preset-field-select"
    >
      <el-scrollbar class="preset-field-select-menu" height="600px">
        <div
          class="preset-field-select-menu-item"
          v-for="item in presetGroup"
          :key="item.id"
          @click="selectGroup(item)"
        >{{ item.name }}</div>
      </el-scrollbar>
      <el-scrollbar class="preset-field-select-list">
        <div
          class="preset-field-select-list-item"
          v-for="item in presetItemList"
          :key="item.id"
          @click="select('field', item)"
        >{{ item.name }}</div>
      </el-scrollbar>
    </el-tab-pane>
    <el-tab-pane name="standard" label="德联标准名称">
      <div class="preset-field-standard">
        <div class="preset-field-standard-menu">
          <div class="preset-field-car-classify">
            <div><span>*</span>车身大类:</div>
            <el-select
              v-model="carClassify"
              clearable
              placeholder="请选择"
              style="width: 130px;"
            >
              <el-option
                v-for="item in carClassifyOptions"
                :key="item.enumId"
                :label="item.comment"
                :value="item.value">
              </el-option>
            </el-select>
          </div>
          <el-scrollbar height="540px">
            <el-tree
              :data="standardNames"
              :props="defaultProps"
              @node-click="selectStandard"
            />
          </el-scrollbar>
        </div>
        <el-scrollbar class="preset-field-standard-list">
          <div
            class="preset-field-standard-list-item"
            v-for="item in standardItemList"
            :key="item.id"
            @click="select('standard', item)"
          >{{ item.name }}</div>
        </el-scrollbar>
      </div>
    </el-tab-pane>
  </el-tabs>
  <el-button
    type="text"
    class="preset-field-close"
    @click="show = false"
  >
    <el-icon><Close /></el-icon>
  </el-button>
</el-dialog>
</template>

<script setup>
import {
  defineEmits,
  defineProps,
  computed,
  ref,
  unref,
  onMounted,
  watchEffect
} from 'vue'
import { useFieldsStore } from '@/store/fields'
import { useTreeStore } from '@/store/tree'
import { useUserStore } from '@/store/user'
import { ElMessage } from 'element-plus'

const emits = defineEmits(['update:modelValue', 'select'])
const props = defineProps({
  modelValue: Boolean,
  outputType: String,
  position: String,
})

const fieldsStore = useFieldsStore()
const treeStrore = useTreeStore()
const userStore = useUserStore()
const activeTab = ref('field')
const presetGroup = ref([])
const presetItemList = ref([])
const standardItemList = ref([])
const standardNames = ref([])
const carClassify = ref('')
const carClassifyOptions = ref([])
const defaultProps = {
  children: 'children',
  label: 'name',
}

const show = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})
watchEffect(() => {
  activeTab.value = props.position === 'release' ? 'standard' : 'field'
})

onMounted(() => {
  getPresetFieldGroupList()
  getRuleSystemSparePartGroupList()
  getEnumVehicleType()
})

async function getPresetFieldGroupList() {
  try {
    const result = await fieldsStore.getPresetFieldGroupList()
    presetGroup.value = result
  } catch (error) {
    console.error(error)
  }
}
async function getPresetFieldList(id) {
  try {
    const result = await fieldsStore.getPresetFieldList({ id })
    presetItemList.value = result
  } catch (error) {
    console.error(error)
  }
}
async function getRuleSystemSparePartGroupList() {
  try {
    const result = await treeStrore.getRuleSystemSparePartGroupList([ 1 ])
    standardNames.value = getIdChildrens(0, result)
  } catch (error) {
    console.error(error)
  }
}
async function getRuleSystemSparePartList(data) {
  try {
    const result = await treeStrore.getRuleSystemSparePartList(data)
    standardItemList.value = result.map(item => {
      return {
        id: item.code,
        label: item.name,
        ...item,
      }
    })
  } catch (error) {
    console.error(error)
  }
}
async function getEnumVehicleType() {
  try {
    const result = await userStore.getEnumVehicleType()
    carClassifyOptions.value = result.memberList
  } catch (error) {
    console.error(error)
  }
}
const selectGroup = item => {
  getPresetFieldList(item.id)
}
const selectStandard = item => {
  const carClassValue = unref(carClassify)
  if (typeof carClassValue !== 'number') {
    ElMessage.warning('请先选择车身大类')
    return
  }
  getRuleSystemSparePartList({
    carBodyMainTypeId: unref(carClassify),
    groupCode: item.code,
    // sparePartName: item.name,
    sparePartName: '', // query???
    batchSearch: false,
  })
}
const select = (type, item) => {
  emits('select', { type, item })
}
function getIdChildrens(code, list) {
  const result = list.filter(
    item => item.parentCode === code
  )
  if (result.length > 0) {
    for(let i = 0; i < result.length; i++) {
      const target = result[i]
      result[i].children = getIdChildrens(target.code, list)
    }
  }
  return result
}
</script>

<style lang="less" scoped>
.preset-field {
  &-select {
    display: flex;
    position: relative;
    
    &-menu {
      width: 240px;
      height: 600px;
      padding: 10px 10px 10px 20px;
      border-right: 1px solid #dcdfe6;

      &-item {
        margin: 5px 0;
        padding: 5px;
        cursor: pointer;

        &:hover {
          background-color: #f5f7fa;
        }
      }
    }

    &-list {
      flex: 1;
      padding: 10px;
      display: flex;
      height: 600px;
      flex-wrap: wrap;
      align-items: baseline;

      & :deep(.el-scrollbar__wrap), :deep(.el-scrollbar__view) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }

      &-item {
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 30px;
        padding: 0 20px;
        margin: 10px;
        cursor: pointer;
        background-color: rgb(242, 242, 242);
      }
    }
  }

  &-standard {
    display: flex;
    
    &-menu {
      width: 240px;
      height: 600px;
      padding: 0 10px 20px 10px;
      border-right: 1px solid #dcdfe6;

      & > div {
        margin: 10px 0;
      }
    }

    &-list {
      flex: 1;
      display: flex;
      padding: 10px;
      height: 600px;
      flex-wrap: wrap;
      align-items: baseline;

      & :deep(.el-scrollbar__wrap), :deep(.el-scrollbar__view) {
        width: 100%;
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
      }

      &-item {
        height: 30px;
        line-height: 30px;
        text-align: center;
        border-radius: 30px;
        padding: 0 20px;
        margin: 10px;
        cursor: pointer;
        background-color: rgb(242, 242, 242);
        
      }
    }
  }

  &-car-classify {
    display: flex;
    align-items: center;
    margin: 10px 0 10px;

    & > div {
      &:first-child {
        margin: 0 10px 0 10px;
      }
    }
  }

  &-close {
    position: absolute;
    top: 4px;
    right: 10px;
    font-size: 20px;
  }

  :deep(.el-tabs__header) {
    border-bottom: none;
  }
}
</style>