<template>
  <div class="all-code">
    <div class="all-code-form">
      <el-form :inline="true" :model="form">
        <el-row>
          <el-col :span="24" class="all-code-form-field">
            <el-form-item label="查询维度:">
              <el-radio-group v-model="form.dimension">
                <el-radio label="FAULT_CODE">故障码</el-radio>
                <el-radio label="TREE">逻辑树</el-radio>
              </el-radio-group>
            </el-form-item>
            <template v-if="form.dimension === 'FAULT_CODE'">
              <el-form-item label="品牌车型故障码:" class="car-code-form-group">
                <el-cascader
                  :props="props"
                  v-model="form.carFaultCode"
                  style="width:300px;"
                />
              </el-form-item>
              <el-form-item label="系统:">
                <el-select v-model="form.system" clearable placeholder="请选择">
                  <el-option
                    v-for="(item, index) in systemOptions"
                    :key="index"
                    :label="item.comment"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </template>
            <template v-else>
              <el-form-item label="逻辑树启用状态:">
                <el-select v-model="form.status" clearable placeholder="请选择">
                  <el-option label="未启用" value="0" />
                  <el-option label="已启用" value="1" />
                </el-select>
              </el-form-item>
              <el-form-item label="逻辑树:">
                <el-input style="width:120px;" v-model="form.systemCode" placeholder="系统" />
                <el-input style="width:120px;" v-model="form.physicalTreeCode" placeholder="逻辑树类型" />
                <el-input style="width:120px;" v-model="form.serialNumber" placeholder="顺序码" />
              </el-form-item>
            </template>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="all-code-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="all-code-table">
      <TableCmpt
        :case-list="tableData"
        :header-list="headerList"
      />
      <PaginationCmpt
        :current-page="page"
        :total="total"
        @current-change="currentChange"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, unref, reactive, computed, onMounted } from 'vue'
import { useCarStore } from '@/store/car'
import TableCmpt from '@/components/TableCmpt'
import PaginationCmpt from '@/components/PaginationCmpt'

const carStore = useCarStore()
const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)
const headerList = [
  {
    type: 'index',
    label: '序号',
    align: 'center',
    width: '60px'
  },
  {
    prop: 'carBrand',
    label: '品牌',
    align: 'center',
  },
  {
    prop: 'carModel',
    label: '车型',
    align: 'center',
  },
  {
    prop: 'faultCode',
    label: '故障码',
    align: 'center',
    formatter(row) {
      const code = row.faultCode
      return (
        <router-link target="_blank" to={`/faultCode/info?code=${code}&id=${row.id}`}>
          <div>{code}</div>  
        </router-link>
      )
    }
  },
  {
    prop: 'systems',
    label: '系统',
    align: 'center',
    formatter(...args) {
      const systems = args[2]
      const sysTxt = systems.map(({ name }) => name).join(',')
      return (<div>{sysTxt}</div>)
    }
  },
  {
    prop: 'enabledTreeCode',
    label: '启用逻辑树',
    align: 'center',
    formatter(row) {
      const { enabledTreeCode } = row
      if (enabledTreeCode) {
        const {
          systemCode,
          physicalTreeCode,
          serialNumber
        } = enabledTreeCode
        const treeCode = `${systemCode}${physicalTreeCode}${serialNumber}`
        return (
          <router-link target="_blank" to={`/tree/show?id=${enabledTreeCode.id}`}>
            <div>{treeCode}</div>
          </router-link>
        )
      } else {
        return (<div>-</div>)
      }
    }
  },
  {
    prop: 'treeCount',
    label: '关联逻辑树数量',
    align: 'center',
    formatter(...args) {
      const count = args[2]
      return (<div>{count}</div>)
    }
  },
]
const form = reactive({
  dimension: 'FAULT_CODE',
  carFaultCode: null,
  system: null,
  status: null,
  systemCode: null,
  physicalTreeCode: null,
  serialNumber: null,
})

const systemOptions = computed(() => {
  return carStore.electronicControlSystemList || []
})

onMounted(() => {
  if (!carStore.electronicControlSystemList) {
    carStore.getCheckEnumElectronicControlSystem()
  }
  getCarFaultCodeRelationPage()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  search()
}
const search = () => {
  const {
    dimension,
    carFaultCode,
    system,
    status,
    systemCode,
    physicalTreeCode,
    serialNumber,
  } = form
  const data = {}
  const [
    carBrandId,
    carModelId,
    carFaultCodeId
  ] = (carFaultCode || [])
  if(dimension === 'FAULT_CODE') {
    Object.assign(data, {
      carBrandId,
      carModelId,
      carFaultCodeId,
      systemId: system,
    })
  }
  if (dimension === 'TREE') {
    Object.assign(data, {
      isRelatedToEnabledTree: status,
      treeCode: {
        systemCode: systemCode,
        physicalTreeCode: physicalTreeCode,
        serialNumber: serialNumber,
      }
    })
  }
  getCarFaultCodeRelationPage(data)
}
const reset = () => {
  form.dimension = 'FAULT_CODE'
  form.carFaultCode = null
  form.system = null
  form.systemCode = null
  form.physicalTreeCode = null
  form.serialNumber =null
  form.status = null
}

let optionsLevelValue = null
const props = {
  lazy: true,
  lazyLoad(node, resolve) {
    const { level, value } = node
    if(level === 0) {
      carStore.getCarBrandOptions().then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
    if (level === 1) {
      optionsLevelValue = value
      carStore.getCarModelOptions({
        carBrandId: value
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
    if (level === 2) {
      carStore.getCarFaultCodeOptions({
        carBrandId: optionsLevelValue,
        carModelId: value,
      }).then(list => {
        const nodes = list.map(item => ({
          value: item.key,
          label: item.value,
          leaf: level >= 2,
        }))
        resolve(nodes)
      })
    }
  },
}
const currentChange = value => {
  page.value = value
  search()
}
async function getCarFaultCodeRelationPage(data = {}) {
  try {
    const { dimension } = form
    const result = await carStore.getCarFaultCodeRelationPage({
      dimension,
      page: unref(page),
      size: unref(size),
      ...data,
    })
    tableData.value = result.records
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.all-code {
  padding: 10px 30px;

  &-form {
    &-field {
      padding: 10px 0;
    }

    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }
  }
}
</style>