<template>
  <div class="tpl">
    <template v-for="(item, index) in tplArray">
      <span
        v-if="item.type === 'text'"
        :key="index"
      >{{ item.value }}</span>
      <component
        v-else
        :is="item.type"
        v-model="item.value"
        :key="item.type"
        class="tpl-input"
      >
        <template #append>
          <span @click="emits('add', { item, index })">字段</span>
        </template>
      </component>
    </template>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineExpose,
  defineEmits,
  ref,
  watchEffect
} from 'vue'

const emits = defineEmits(['add'])
const props = defineProps({
  tpl: String,
  conclusionPadValues: Array
})
const tplArray = ref([])

watchEffect(() => {
  const tpl = props.tpl || ''
  const tplValues = props.conclusionPadValues || []
  const arr = tpl.split('（）')
  const res = []
  let valuePos = 0
  for(let i = 0; i < arr.length; i++) {
      if (i === arr.length - 1) {
        res.push({
            type: 'text',
            value: arr[i]
        })
      } else {
        const value = tplValues[valuePos] || ''
        res.push({
            type: 'text',
            value: arr[i],
        }, {
            type: 'el-input',
            value,
        })
        valuePos++
      }
  }

  tplArray.value = res
})

defineExpose({ tplValue: tplArray })
</script>

<style lang="less" scoped>
.tpl {
  margin-top: 10px;

  &-input {
    width: 200px;
    margin: 0 10px;

    :deep(.el-input-group__append) {
      cursor: pointer;
    }
  }
}
</style>