<template>
  <el-drawer
    v-model="value"
    :with-header="false"
    :close-on-click-modal="false"
    class="tree-info"
  >
    <div class="tree-info-label">关联故障码:</div>
    <div class="tree-info-txt tree-info-relation">
      <TableCmpt
        :case-list="info.treeRelationCodes"
        :header-list="headerList"
      />
    </div>
    <div class="tree-info-label">整树备注:</div>
    <TreeRemark
      v-for="(item, index) in info.treeRelationRemarks"
      :key="index"
      :remark="item"
      :title="`备注${index + 1}`"
    />
    <el-button class="tree-info-close" @click="close">关闭</el-button>
  </el-drawer>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { STATUS_TREE_ABLED } from '@/utils/constants'
import TableCmpt from '@/components/TableCmpt'
import TreeRemark from './TreeRemark'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  info: {
    type: Object,
    default: () => ({})
  }
})
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: 60,
  },
  {
    prop: 'faultCode',
    label: '所关联故障码',
    formatter(row) {
      const code = row.faultCode
      return (
        <router-link target="_blank" to={`/faultCode/info?code=${code}&id=${row.id}`}>
          <div>{code}</div>  
        </router-link>
      )
    }
  },
  {
    prop: 'relationStatus',
    label: '本树状态',
    formatter(row) {
      const status = STATUS_TREE_ABLED.find(
        item => item.value == row.relationStatus
      )
      return (<div>{status.label}</div>)
    }
  },
  {
    prop: 'priority',
    label: '本树优先级',
  },
]
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const close = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="less" scoped>
.tree-info {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>