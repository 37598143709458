import request from "@/utils/request"

/**
 * =============================
 *        配置管理-品牌管理       
 * =============================
 */
// 获取品牌 - 所有
export function getCarBrandList() {
  return request({
    url: '/dlyk-decision/config/carBrand/list',
    method: 'post',
  })
}

// 【select】获取品牌 - 显示状态
export function getCarBrandOptions() {
  return request({
    url: '/dlyk-decision/config/carBrand/options',
    method: 'post',
  })
}

// 新增品牌
export function saveCarBrand(data) {
  return request({
    url: '/dlyk-decision/config/carBrand/save',
    method: 'post',
    data,
  })
}

// 品牌显示/隐藏
export function updateCarBrandVisible(data) {
  return request({
    url: '/dlyk-decision/config/carBrand/invisible',
    method: 'post',
    data,
  })
}



/**
 * =============================
 *        配置管理-车型管理       
 * =============================
 */
// 获取车型 - 所有
export function getCarModelList(data) {
  return request({
    url: '/dlyk-decision/config/carModel/list',
    method: 'post',
    data,
  })
}

// 【select】获取车型 - 显示状态
export function getCarModelOptions(data) {
  return request({
    url: '/dlyk-decision/config/carModel/options',
    method: 'post',
    data,
  })
}

// 新增车型
export function saveCarModel(data) {
  return request({
    url: '/dlyk-decision/config/carModel/save',
    method: 'post',
    data,
  })
}

// 车型显示/隐藏
export function updateCarModelVisible(data) {
  return request({
    url: '/dlyk-decision/config/carModel/invisible',
    method: 'post',
    data,
  })
}



/**
 * =============================
 *       配置管理-故障码管理      
 * =============================
 */
// 获取故障码 - 所有
export function getCarFaultCodeList(data) {
  return request({
    url: '/dlyk-decision/config/carFaultCode/list',
    method: 'post',
    data,
  })
}

// 【select】获取故障码 - options
export function getCarFaultCodeOptions(data) {
  return request({
    url: '/dlyk-decision/config/carFaultCode/options',
    method: 'post',
    data,
  })
}

// 获取故障码信息
export function getCarFaultCodeInfo(data) {
  return request({
    url: '/dlyk-decision/config/carFaultCode/info',
    method: 'post',
    data,
  })
}

// 分页查询故障码
export function getCarFaultCodeListInfo(data) {
  return request({
    url: '/dlyk-decision/config/carFaultCode/page',
    method: 'post',
    data,
  })
}

// 新增故障码
export function saveCarFaultCode(data) {
  return request({
    url: '/dlyk-decision/config/carFaultCode/save',
    method: 'post',
    data,
  })
}

// 故障码显示/隐藏
export function updateCarFaultCodeVisible(data) {
  return request({
    url: '/dlyk-decision/config/carFaultCode/invisible',
    method: 'post',
    data,
  })
}

// 编辑故障码 - 备注&系统
export function updateCarFaultCodeRemarkAndSys(data) {
  return request({
    url: '/dlyk-decision/config/carFaultCode/modify',
    method: 'post',
    data,
  })
}

// 获取故障码关联树
export function getCarFaultCodeRelationTrees(data) {
  return request({
    url: '/dlyk-decision/relation/trees',
    method: 'post',
    data,
  })
}

// 分页获取故障码信息
export function getCarFaultCodeRelationPage(data) {
  return request({
    url: '/dlyk-decision/relation/page',
    method: 'post',
    data,
  })
}


/**
 * =============================
 *           CE3.0接口          
 * =============================
 */
// 获取系统
export function getCheckEnumElectronicControlSystem() {
  return request({
    url: `/check/enum/ElectronicControlSystem`,
    method: 'get',
  })
}

// 获取工种
export function getCheckEnumRepairType() {
  return request({
    url: '/check/enum/RepairType',
    method: 'get',
  })
}
