<template>
  <div class="user">
    <div class="user-info">
      <div class="user-title">基本信息:</div>
      <el-form :model="formUser" :rules="rulesUser" label-width="120px">
        <el-form-item label="用户姓名:" prop="name">
          <el-input v-model="formUser.name" />
        </el-form-item>
        <el-form-item label="登陆账号:" prop="account">
          <el-input v-model="formUser.account" />
        </el-form-item>
        <el-form-item label="手机号码:" prop="phone">
          <el-input v-model="formUser.phone" />
        </el-form-item>
        <el-form-item label="用户类型:" prop="userType">
          <el-radio-group v-model="formUser.userType">
            <el-radio label="inside">内部</el-radio>
            <el-radio label="outside">外部</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item label="所属机构:" prop="organization">
          <el-select v-model="formUser.organization" clearable placeholder="请选择">
            <el-option label="德联易控" value="1" />
            <el-option label="平安保险" value="2" />
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changeUserInfo">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
    <div class="user-pwd">
      <div class="user-title">修改密码:</div>
      <el-form :model="formPwd" :rules="rulesPwd" label-width="120px">
        <el-form-item label="旧密码:" prop="oldPwd">
          <el-input v-model="formPwd.oldPwd" type="password" show-password />
        </el-form-item>
        <el-form-item label="新密码:" prop="newPwd">
          <el-input v-model="formPwd.newPwd" type="password" show-password />
        </el-form-item>
        <el-form-item label="确认新密码:" prop="checkNewPwd">
          <el-input v-model="formPwd.checkNewPwd" type="password" show-password />
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="changePwd">确定</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>
<script setup>
import { reactive } from 'vue'
import { useUserStore } from '@/store/user';

const userStore = useUserStore()
const formUser = reactive({
  name: '测试',
  account: 'ce-it-yanqilin',
  phone: '18510086593',
  userType: 'inside',
  organization: '1',
})
const formPwd = reactive({
  oldPwd: '',
  newPwd: '',
  checkNewPwd: '',
})
const rulesUser = reactive({
  name: [
    {
      required: true,
      message: '请输入用户名',
      trigger: 'blur'
    }
  ],
  account: [
    {
      required: true,
      message: '请输入登陆账号',
      trigger: 'blur'
    }
  ],
  phone: [
    {
      required: true,
      message: '请输入手机号',
      trigger: 'blur'
    },
    {
      message: '请输入正确的手机号',
      trigger: 'change',
      pattern: /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/
    }
  ],
  userType: [
    {
      required: true,
      message: '请选择用户类型',
      trigger: 'change'
    }
  ],
  organization: [
    {
      required: true,
      message: '请选择所属机构',
      trigger: 'change'
    }
  ]
})
const rulesPwd = reactive({
  oldPwd: [
    {
      required: true,
      message: '请输入旧密码',
      trigger: 'blur'
    }
  ],
  newPwd: [
    {
      required: true,
      message: '请输入新密码',
      trigger: 'blur'
    }
  ],
  checkNewPwd: [
    {
      required: true,
      message: '请确认新密码',
      trigger: 'blur'
    }
  ]
})
const changeUserInfo = async () => {
  console.log('修改用户信息:', formUser)
  try {
    await userStore.updateUserInfo({
      userName: formUser.account,
      loginName: formUser.name,
      phone: formUser.phone,
      inside: formUser.userType === 'inside',
      stdBranchCompany: formUser.organization
    })
  } catch (error) {
    console.log(error)
  }
}
const changePwd = async () => {
  console.log('修改用户信息:', formPwd)
  try {
    await userStore.updatePassword({
      password: formPwd.newPwd,
      originPassword: formPwd.oldPwd,
    })
  } catch (error) {
    console.log(error)
  }
}
</script>
<style lang="less" scoped>
.user {
  padding: 30px 20px;

  & > div {
    width: 500px;
  }

  &-title {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 10px;
  }
}
</style>