<template>
  <div class="partsHours">
    <div class="partsHours-form">
      <el-form ref="searchFormRef" :inline="true" :model="form">
        <el-row>
          <el-col :span="24">
            <el-form-item label="悬挂形式:">
              <el-select v-model="form.suspensionType" clearable filterable placeholder="请选择悬挂形式">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="外倾角是否可调整:">
              <el-select v-model="form.adjustable" clearable placeholder="请选择">
                <el-option
                  v-for="item in ADJUSTABLE_MENU"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button type="success" @click="create">新增</el-button>
          </el-col>
          <el-col :span="12" class="partsHours-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="partsHours-table">
      <el-table show-header stripe border :data="tableData">
        <el-table-column align="center" label prop type="selection" :selectable="selectableFn" />
        <el-table-column align="center" label="序号" prop type="index" width="60px" />
        <el-table-column align="center" show-overflow-tooltip label="悬挂形式" prop="suspensionTypeName" width="200px" />
        <el-table-column align="center" label="外倾角是否可调整" prop width="200px">
          <template #default="scope">
            <span>{{ scope.row.adjustable == 1 ? '是' : '否' }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" label="对应决策信息库" prop show-overflow-tooltip>
          <template #default="scope">
            <span>{{ jointData(scope.row) }}</span>
          </template>
        </el-table-column>
        <el-table-column align="center" class="icon" width="120px">
          <template #header>操作</template>
          <template #default="scope">
            <div class="partsHours-table-iconbox">
              <el-icon class="partsHours-table-iconbox-icon" @click="openEdit(scope.row)">
                <Edit />
              </el-icon>
              <el-icon
                class="partsHours-table-iconbox-icon partsHours-danger"
                @click="deleteWheelLibrary(scope.row.id)"
              >
                <Delete />
              </el-icon>
            </div>
          </template>
        </el-table-column>
      </el-table>
      <PaginationCmpt :current-page="page" :total="total" @current-change="currentChange" />
    </div>

    <div class="partsHours-add" v-if="partsHoursEditVisible">
      <CommonConfigPartsHoursAdd
        :rowData="rowData"
        @cancelEdit="cancelEdit"
        @refreshDetails="refreshDetails"
      />
    </div>

    <el-dialog
      v-model="dialogAddVisible"
      title="新增决策信息库:"
      :show-close="false"
      :close-on-click-modal="false"
    >
      <el-row>
        <el-col :span="22" :offset="2">
          <el-form ref="formAddRef" :model="formAdd" :rules="rules" label-width="150px">
            <el-form-item label="悬挂形式:" prop="suspensionType">
              <el-select v-model="formAdd.suspensionType" clearable filterable placeholder="请选择悬挂形式">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
            <el-form-item label="外倾角是否可调整:" prop="adjustable">
              <el-select v-model="formAdd.adjustable" clearable placeholder="请选择">
                <el-option
                  v-for="item in ADJUSTABLE_MENU"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-form>
        </el-col>
      </el-row>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="confirm">确定</el-button>
          <el-button @click="cancel">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { ref, unref, reactive, onMounted } from 'vue'
import { useWheelStore } from '@/store/wheelAlignment'
import PaginationCmpt from '@/components/PaginationCmpt'
import { ADJUSTABLE_MENU } from '@/utils/constants'
import { useEnumSelectStore } from '@/store/enumSelect'
import { ElMessage } from 'element-plus'
import CommonConfigPartsHoursAdd from '@/views/commonConfig/CommonConfigPartsHoursAdd'

const enumStore = useEnumSelectStore()
const wheelStore = useWheelStore()
const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)
const dialogAddVisible = ref(false)
const partsHoursEditVisible = ref(false)
const rowData = ref(null)
const SUSPENSION_TYPE = ref(null)

const jointData = row => {
  let res = []
  if (row.partItems) {
    const partItems = row.partItems.map(item => item.partName)
    res = res.concat(partItems)
  }
  if (row.laborItems) {
    const laborItems = row.laborItems.map(
      item => item.repairTypeName + item.laborName
    )
    res = res.concat(laborItems)
  }
  if (row.workItems) {
    const workItems = row.workItems.map(item => item.workName)
    res = res.concat(workItems)
  }
  return res.join('、')
}

const form = reactive({
  suspensionType: null,
  adjustable: null
})
const formAdd = reactive({
  suspensionType: '',
  adjustable: ''
})
const searchFormRef = ref(null)
const formAddRef = ref(null)

onMounted(async () => {
  SUSPENSION_TYPE.value = await enumStore.getWheelIndexItemList()
  search()
})

const preSearch = async () => {
  page.value = 1
  total.value = 0
  await searchFormRef.value.validate(async valid => {
    if (valid) {
      search()
    }
  })
}
const search = async() => {
 await getWheelLibraryPage({
    suspensionType: form.suspensionType,
    adjustable: form.adjustable
  })
}
const reset = () => {
  form.suspensionType = null
  form.adjustable = null
}

const cancelEdit = () => {
  partsHoursEditVisible.value = false
}
const openEdit = row => {
  rowData.value = row
  partsHoursEditVisible.value = true
}

const create = () => {
  dialogAddVisible.value = true
}
const cancel = () => {
  dialogAddVisible.value = false
  formAdd.suspensionType = ''
  formAdd.adjustable = ''
}
const confirm = async () => {
  await formAddRef.value.validate(async valid => {
    if (valid) {
      try {
        await wheelStore.saveWheelLibrary(formAdd)
        ElMessage.success('添加成功')
        cancel()
      } catch (error) {
        ElMessage.error(error)
      }
    }
  })
}
const rules = reactive({
  suspensionType: [
    { required: true, message: '请选择悬挂形式', trigger: 'change' }
  ],
  adjustable: [{ required: true, message: '请选择', trigger: 'change' }]
})

const currentChange = value => {
  page.value = value
  search()
}
async function getWheelLibraryPage(data = {}) {
  try {
    const result = await wheelStore.getWheelLibraryPage({
      pageIndex: unref(page),
      pageSize: unref(size),
      ...data
    })

    tableData.value = result.records
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}
async function deleteWheelLibrary(libraryId) {
  try {
    await wheelStore.deleteWheelLibrary({ libraryId })
    ElMessage.success('删除成功')
    preSearch()
  } catch (error) {
    ElMessage.error('删除失败')
    console.error(error)
  }
}

async function refreshDetails(libraryId) {
  await search()
  changedRowData(libraryId)
}

async function changedRowData (libraryId) {
  const currentLibrary = tableData.value.filter(item => item.id === libraryId)
  rowData.value = currentLibrary && currentLibrary[0]
}
</script>

<style lang="less" scoped>
.partsHours {
  padding: 10px 30px;

  &-form {
    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }
    &-operate {
      font-size: 15px;
    }
    &-iconbox {
      font-size: 20px;
      &-icon {
        margin: 5px;
        color: #409eff;
        cursor: pointer;
      }
    }
  }
  &-danger {
    color: #f56c6c;
  }

  &-add {
    position: absolute;
    width: calc(100% - 200px);
    height: 100%;
    top: 0;
    right: 0;
    z-index: 999;
    background-color: #fff;
  }

  .text-center {
    text-align: center;
  }
}
</style>