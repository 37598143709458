import { Graph } from '@antv/x6'
import { Dnd } from '@antv/x6-plugin-dnd'
import { Snapline } from '@antv/x6-plugin-snapline'
import { register } from '@antv/x6-vue-shape'
import { GRAPH_NODE_COMPONENTS } from '@/utils/constants'
import { merge } from 'lodash'
import EventEmitter from 'eventemitter3'
import NodeEllipse from './components/NodeEllipse'
import NodeParallelogram from './components/NodeParallelogram'
import NodeRect from './components/NodeRect'

const NODE_WIDTH = 300
const NODE_HEIGHT = 120
const NODE_ZINDEX = 99

function arrayToObject(array, key) {
  return array.reduce((pre, cur) => {
    pre[cur[key]] = cur
    return pre
  }, {})
}

const ports = {
  groups: {
    top: {
      position: 'top',
      attrs: {
        circle: {
          magnet: true,
          stroke: '#8f8f8f',
          r: 5,
        },
      },
      label: {
        position: 'top',
      },
    },
    bottom: {
      position: 'bottom',
      attrs: {
        circle: {
          magnet: true,
          stroke: '#8f8f8f',
          r: 5,
        },
      },
      label: {
        position: 'top',
      },
    },
  },
}

const EDGE_ATTRS = {
  line: {
    stroke: '#C2C8D5',
  },
}

register({
  shape: 'custom-node-ellipse',
  component: NodeEllipse,
  zIndex: NODE_ZINDEX,
  width: NODE_WIDTH,
  height: NODE_HEIGHT,
  ports,
})
register({
  shape: 'custom-node-paralle',
  component: NodeParallelogram,
  zIndex: NODE_ZINDEX,
  width: NODE_WIDTH,
  height: NODE_HEIGHT,
  ports,
})
register({
  shape: 'custom-node-rect',
  component: NodeRect,
  zIndex: NODE_ZINDEX,
  width: NODE_WIDTH,
  height: NODE_HEIGHT,
  ports,
})

export class Tree extends EventEmitter {
  config = {
    grid: {
      size: 10,
      visible: true,
    },
    panning: {
      enabled: true,
      modifiers: [],
      eventTypes: 'leftMouseDown',
    },
    mousewheel: {
      enabled: true,
      factor: 1.2,
      zoomAtMousePosition: true,
      global: false,
      modifiers: ['ctrl', 'meta'],
    },
    scaling: {},
    preventDefaultContextMenu: false,
    connecting: {
      validateConnection({ targetCell, sourceCell }) {
        const inComeCount = this.getIncomingEdges(targetCell)
        const { nodeInfo } = sourceCell.data
        const isSelf = sourceCell.id === targetCell.id
        let isLoop = false
        const neighborNodes = this.getNeighbors(sourceCell)
        neighborNodes?.forEach((cell) => {
          if (cell.id === targetCell.id) {
            isLoop = true
          }
        })

        // 节点回环
        if (isLoop) {
          return false
        }

        // 内连接
        if (isSelf) {
          return false
        }

        // 是否允许连接
        if (nodeInfo.disabledAddSub) {
          return false
        }

        // 入口数量检测
        if (inComeCount) {
          return false
        }

        return true
      },
    },
  }
  edges = []
  nodes = []
  graph = null
  opt = null
  treeIds = {}
  self = null
  selectCell = null

  constructor({ graphConfig = {}, nodes = [], flows = [], nodeInfos = {}, flowInfos = {}, opt, treeIds }) {
    super()
    merge(this.config, graphConfig)

    this.self = this
    this.opt = opt
    this.treeIds = treeIds

    this.nodeInfos = nodeInfos
    this.flowInfos = flowInfos

    this.createGraph()
    this.adapter(nodes, flows, nodeInfos, flowInfos)
    this.render()
  }

  createGraph() {
    const { config } = this
    const graph = new Graph(config)
    const self = this
    const dnd = new Dnd({
      target: graph,
      scaled: false,
      dndContainer: config.dndBox,
      validateNode(node) {
        self.graph.emit('node:add', { node })
        return true
      },
    })

    this.bindEvents(graph)
    graph.use(
      new Snapline({
        enabled: false,
        sharp: true,
      }),
    )

    this.graph = graph
    this.dnd = dnd
  }

  render() {
    const { nodes, edges } = this
    this.graph.fromJSON({ nodes, edges })
    this.graph.centerContent()
    this.resize()
  }

  resize() {
    if (typeof window !== 'undefined') {
      const { container = {} } = this.config
      window.onresize = () => {
        Object.assign(container.style, {
          width: '100%',
          height: '100%',
        })
      }
    }
  }

  destroyed() {
    if (typeof window !== 'undefined') {
      window.onresize = () => {}
      this.unBindEvents()
      this.clearCells()
    }
  }

  adapter(nodes, flows, nodeInfos, flowInfos) {
    const nodeInfoMap = arrayToObject(nodeInfos, 'nodeCode')
    const flowInfoMap = arrayToObject(flowInfos, 'flowCode')

    this.edges = flows.map((edge) => {
      const { flowCode, fromNodeCode, toNodeCode } = edge
      const label = flowInfoMap[flowCode]?.viewContent || ''
      return {
        id: flowCode,
        shape: 'edge',
        source: {
          cell: fromNodeCode,
          port: `${fromNodeCode}-port`,
        },
        target: toNodeCode,
        attrs: EDGE_ATTRS,
        labels: [
          {
            attrs: {
              label: {
                text: label,
              },
            },
          },
        ],
        data: {
          opt: this.opt,
          treeIds: this.treeIds,
          edge,
          edgeInfo: flowInfoMap[flowCode],
        },
      }
    })
    this.nodes = nodes.map((node) => {
      const { location, nodeCode } = node
      const nodeInfo = nodeInfoMap[nodeCode]
      const shape = GRAPH_NODE_COMPONENTS[nodeInfo?.nodeType || 'PROCESS']
      const ports =
        this.opt === 'edit' && nodeInfo.nodeType !== 'RESULT'
          ? [
              {
                id: `${nodeCode}-port`,
                group: 'bottom',
              },
            ]
          : []
      return {
        id: nodeCode,
        shape,
        ...location,
        ports,
        data: {
          // self: this,
          graph: this.graph,
          func: {
            nodeEdit: this.nodeEdit,
            nodeDelete: this.nodeDelete,
          },
          opt: this.opt,
          treeIds: this.treeIds,
          node,
          nodeInfo,
        },
      }
    })
  }

  startDrag(e) {
    if (this.opt === 'show') return
    const target = e.target
    const type = target.getAttribute('data-type')
    const node = this.createNode({ type })
    this.dnd.start(node, e)
  }

  getNodeInfo(id) {
    const target = this.nodeInfos.find(({ nodeCode }) => nodeCode === id) || { nodeType: 'PROCESS' }
    const lines = this.edges?.filter(({ original }) => original?.fromNodeCode === id)?.map(({ original }) => original)
    target['lines'] = lines.map((line) => {
      const lineInfo = this.getEdgeInfo(line.flowCode)
      return {
        ...line,
        ...lineInfo,
      }
    })
    return target
  }

  getEdgeInfo(id) {
    return this.flowInfos.find(({ flowCode }) => flowCode === id) || {}
  }

  bindEvents(graph) {
    graph.on('edge:dblclick', ({ edge }) => {
      this.emit('edge:dblclick', { edge })
    })
    graph.on('node:dblclick', ({ node }) => {
      this.emit('node:dblclick', { node })
    })
    graph.on('node:mousemove', (args) => {
      if (this.opt === 'edit') {
        this.emit('node:move', args)
      }
    })
    graph.on('edge:contextmenu', (args) => {
      const { e, x, y, view, edge } = args
      e.preventDefault()
      const point = this.graph.localToClient({ x, y })
      this.emit('edge:contextmenu', { point, view, edge })
    })
    // 暂时使用节点内部菜单
    // graph.on('node:contextmenu', (args) => {
    //   const { e, x, y, view, node } = args
    //   e.preventDefault()
    //   const point = this.graph.localToClient({ x, y })
    //   this.emit('node:contextmenu', { point, view, node })
    // })
    graph.on('node:click', ({ node }) => {
      if (this.selectCell?.isEdge()) {
        this.selectCell.attr('line/stroke', '#C2C8D5')
      }
      this.selectCell = node
      graph.emit('node:select', { id: node.id })
    })
    graph.on('edge:click', ({ edge }) => {
      if (this.selectCell?.isEdge()) {
        this.selectCell.attr('line/stroke', '#C2C8D5')
      }
      edge.attr('line/stroke', '#409eff')
      this.selectCell = edge
      graph.emit('node:select', { id: '' })
    })
    graph.on('blank:click', () => {
      if (this.selectCell?.isEdge()) {
        this.selectCell.attr('line/stroke', '#C2C8D5')
      }
      this.selectCell = null
      graph.emit('node:select', { id: '' })
    })
  }

  unBindEvents() {
    const events = ['edge:dblclick', 'node:dblclick', 'node:mousemove', 'node:mousemove', 'edge:contextmenu']
    events.forEach((event) => this.graph.off(event))
  }

  nodeEdit(node, graph) {
    if (graph) {
      graph.emit('node:edit', { node })
      return
    }
    this.graph.emit('node:edit', { node })
  }

  nodeDelete(node, graph) {
    if (graph) {
      graph.emit('node:delete', { node })
      return
    }
    this.graph.emit('node:delete', { node })
  }

  zoom(level) {
    this.graph.zoomTo(level)
  }

  createNode({ type, x, y }) {
    return this.graph.createNode({
      shape: `custom-node-${type}`,
      x,
      y,
      data: {
        graph: this.graph,
        func: {
          nodeEdit: this.nodeEdit,
          nodeDelete: this.nodeDelete,
        },
        opt: this.opt,
        treeIds: this.treeIds,
        node: {},
        nodeInfo: {},
      },
    })
  }

  removeNode(node) {
    return this.graph.removeNode(node)
  }

  addEdges() {
    return this.group.addEdges({})
  }

  removeEdge(edge) {
    return this.graph.removeEdge(edge)
  }

  clearCells(options = {}) {
    return this.graph.clearCells(options)
  }

  getCells() {
    return this.graph.getCells()
  }

  getNodes() {
    return this.graph.getNodes()
  }

  getEdges() {
    return this.graph.getEdges()
  }

  getOutgoingEdges(cell) {
    return this.graph.getOutgoingEdges(cell)
  }

  getIncomingEdges(cell) {
    return this.graph.getIncomingEdges(cell)
  }

  getConnectedEdges(cell) {
    return this.graph.getConnectedEdges(cell)
  }

  getNeighbors(cell) {
    return this.graph.getNeighbors(cell)
  }

  toJSON(options = {}) {
    return this.graph.toJSON(options)
  }

  getCellById(id) {
    return this.graph.getCellById(id)
  }
}
