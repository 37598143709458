import request from "@/utils/request"

// 添加分组
export function savePresetFieldGroup(data) {
  return request({
    url: '/dlyk-decision/config/presetField/group/save',
    method: 'post',
    data,
  })
}

// 修改分组
export function updatePresetFieldGroup(data) {
  return request({
    url: '/dlyk-decision/config/presetField/group/modify',
    method: 'post',
    data,
  })
}

// 删除分组
export function deletePresetFieldGroup(data) {
  return request({
    url: '/dlyk-decision/config/presetField/group/remove',
    method: 'post',
    data,
  })
}

// 获取分组列表
export function getPresetFieldGroupList(data) {
  return request({
    url: '/dlyk-decision/config/presetField/group/list',
    method: 'post',
    data,
  })
}

// 【select】获取分组下拉列表
export function getPresetFieldGroupOptionsList() {
  return request({
    url: '/dlyk-decision/config/presetField/group/options',
    method: 'post',
  })
}

// 添加分组字段
export function savePresetField(data) {
  return request({
    url: '/dlyk-decision/config/presetField/field/save',
    method: 'post',
    data,
  })
}

// 删除预设字段
export function deletePresetField(data) {
  return request({
    url: '/dlyk-decision/config/presetField/field/remove',
    method: 'post',
    data,
  })
}

// 获取字段列表
export function getPresetFieldList(data) {
  return request({
    url: '/dlyk-decision/config/presetField/field/list',
    method: 'post',
    data,
  })
}

/**
 * =============================
 *     四轮定位 - 预设字段            
 * =============================
 */
// 分组 - 获取分组列表
export function getWheelPresetGroupList() {
  return request({
    url: `/wheelalignment/web/presetField/group/list`,
    method: 'get',
  })
}

// 分组 - 新增分组
export function saveWheelPresetGroup(data) {
  return request({
    url: '/wheelalignment/web/presetField/group/save',
    method: 'post',
    data,
  })
}

// 分组 - 修改分组名称
export function updateWheelPresetGroup(data) {
  return request({
    url: '/wheelalignment/web/presetField/group/updateName',
    method: 'post',
    data,
  })
}

// 分组 - 删除分组
export function deleteWheelPresetGroup(data) {
  return request({
    url: '/wheelalignment/web/presetField/group/remove',
    method: 'post',
    data,
  })
}

// 字段 - 获取字段列表
export function getWheelPresetFieldList(params) {
  return request({
    url: `/wheelalignment/web/presetField/field/list`,
    method: 'get',
    params
  })
}

// 字段 - 添加字段
export function saveWheelPresetField(data) {
  return request({
    url: '/wheelalignment/web/presetField/field/save',
    method: 'post',
    data,
  })
}

// 字段 - 删除字段
export function deleteWheelPresetField(data) {
  return request({
    url: '/wheelalignment/web/presetField/field/remove',
    method: 'post',
    data,
  })
}

/**
 * =============================
 *     四轮定位 - 索引枚举配置            
 * =============================
 */
// 获取索引字典列表
export function getWheelIndexItemList(params) {
  return request({
    url: `/wheelalignment/web/indexItem/list`,
    method: 'get',
    params
  })
}

// // 添加索引字典
export function saveWheelIndexItem(data) {
  return request({
    url: '/wheelalignment/web/indexItem/save',
    method: 'post',
    data,
  })
}

// // 更新索引字典启用、禁用状态
export function updateStateWheelIndexItem(data) {
  return request({
    url: '/wheelalignment/web/indexItem/updateState',
    method: 'post',
    data,
  })
}

/**
 * =============================
 *     四轮定位 - 车型悬挂管理            
 * =============================
 */
// 批量保存车型-悬挂形式关联
export function vehicleSaveBatch(data) {
  return request({
    url: '/wheelalignment/web/vehicle/saveBatch',
    method: 'post',
    data,
  })
}

// 获取已有关联 - 用于添加页排重
export function webVehicleList(data) {
  return request({
    url: '/wheelalignment/web/vehicle/list',
    method: 'post',
    data,
  })
}

// // 分页查询
// export function updateWheelPresetGroup(data) {
//   return request({
//     url: '/wheelalignment/web/vehicle/page',
//     method: 'post',
//     data,
//   })
// }

// 修改关联信息
export function vehicleReset(data) {
  return request({
    url: '/wheelalignment/web/vehicle/reset',
    method: 'post',
    data,
  })
}

// 删除关联
export function vehicleRemove(data) {
  return request({
    url: '/wheelalignment/web/vehicle/remove',
    method: 'post',
    data,
  })
}