<template>
  <div>
    <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false" class="drawer">
      <el-form ref="formRef" :model="form" :rules="rules">
        <div class="drawer-label">转交决策:</div>
        <div class="drawer-txt">请确认，是否将决策 {{ decisionId }}</div>
        <div class="drawer-txt">
          编写人由 {{ editorName }} 更改为：
          <el-form-item prop="newEditor" style="display: inline-block;">
            <el-select v-model="form.newEditor" clearable filterable placeholder="请选择编写人">
              <el-option
                v-for="item in userAllListEditor"
                :key="item.value"
                :label="item.label"
                :value="item.value"
                :disabled="item.value == editorId"
              />
            </el-select>
          </el-form-item>
        </div>
      </el-form>

      <div class="drawer-footer-btn">
        <el-button type="primary" @click="handleSubmit">确定</el-button>
        <el-button @click="handleClose">取消</el-button>
      </div>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, reactive } from 'vue'
import { useUserStore } from '@/store/user'
import { useWheelStore } from '@/store/wheelAlignment'
import { ElMessage } from 'element-plus'
import { userCommon } from '@/views/wheelAlignment/wheelCommon'

const emits = defineEmits(['update:modelValue', 'getWheelDecisionListGet'])
const props = defineProps({
  modelValue: Boolean,
  decisionCode: String,
  decisionId: Number,
  editorName: String,
  editorId: Number
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const wheelStore = useWheelStore()
const userStore = useUserStore()
const { userAllListEditor } = userCommon(userStore)

const formRef = ref(null)
const form = reactive({ newEditor: null })

const operator = computed(() => {
  return userStore?.userInfo?.id || ''
})

const handleClose = () => {
  emits('update:modelValue', false)
}
const handleSubmit = async () => {
  const validate = await formRef.value.validate(() => {})
  if (!validate) {
    return
  }

  try {
    await wheelStore.forwardWheelDecision({
      decisionId: props.decisionId,
      newEditor: form.newEditor,
      operator: operator.value
    })
    ElMessage.success('转交成功')
    emits('getWheelDecisionListGet')
    handleClose()
  } catch (error) {
    ElMessage.error(error)
  }
}
</script>

<style lang="less" scoped>
.drawer {
  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0;
  }

  &-record {
    max-height: 190px;
    overflow-y: auto;
  }

  &-txt {
    font-size: 14px;
    margin: 10px 0;
  }

  &-footer-btn {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>