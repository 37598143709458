<template>
  <!-- 待审核决策信息 -->
  <div class="audit">
    <div class="audit-form">
      <el-form :inline="true" :model="form" label-width="80px" label-position="left">
        <el-row>
          <el-col :span="6">
            <el-form-item label="决策编码:">
              <el-input
                v-model="form.decisionCode"
                placeholder="请输入决策编码"
                maxlength="50"
                style="width: 214px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="决策层数:">
              <el-select v-model="form.layers" clearable filterable placeholder="请选择决策层数">
                <el-option
                  v-for="item in DECISION_LAYERS"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="编写人:">
              <el-select v-model="form.editor" clearable filterable placeholder="请选择编写人">
                <el-option
                  v-for="item in userAllListEditor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核人:">
              <el-select v-model="form.reviewer" clearable filterable placeholder="请选择审核人">
                <el-option
                  v-for="item in userAllListAuditor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="操作时间:">
              <el-date-picker
                v-model="operatingTime"
                type="daterange"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                class="audit-date-picker"
                style="width: 214px"
                value-format="YYYY-MM-DDTHH:mm:ss"
                @change="operatingTimeChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="24" class="audit-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="audit-table">
      <el-table show-header stripe border :data="tableData">
        <!-- <el-table-column align="center" label prop type="selection" :selectable="selectableFn" /> -->
        <el-table-column align="center" label="序号" prop type="index" width="60px" />
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="决策编码"
          prop="decisionCode"
          width="200px"
        />
        <el-table-column align="center" label="决策层数" prop="layers" width="100px" />
        <el-table-column align="left" label="更换方案" prop="name">
          <template #default="scope">
            <div v-for="(item, index) in getChangePlanTxt(scope.row)" :key="index">
              第{{index + 1}}层：
              <span v-for="(element, i) in item" :key="i">{{ element }}</span>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="编写人"
          prop="editorName"
          width="120px"
        />
        <el-table-column
          align="center"
          show-overflow-tooltip
          label="审核人"
          prop="reviewerName"
          width="120px"
        />
        <el-table-column align="center" show-overflow-tooltip label="最后操作时间" width="165px">
          <template #default="scope">{{ GMTToLocal(scope.row.lastModifyTime) }}</template>
        </el-table-column>
        <el-table-column align="center" class="icon" width="100px">
          <template #header>
            操作
            <!-- <Stamp class="icon-common icon-stamp" @click="examine('batch')" /> -->
          </template>
          <template #default="scope">
            <Tickets class="icon-common icon-tickets" @click="toDecision(scope.row)" />
            <Stamp class="icon-common icon-stamp" @click="examine(scope.row)" />
          </template>
        </el-table-column>
      </el-table>
      <PaginationCmpt :current-page="page" :total="total" @current-change="currentChange" />
    </div>

    <el-dialog
      v-model="dialogAuditVisible"
      title="批量审核:"
      :show-close="false"
      :close-on-click-modal="false"
      class="audit-dialog"
    >
      <div class="audit-dialog-title">审核意见:</div>
      <el-form ref="auditFormRef" :model="auditForm" :rules="rules" class="audit-dialog-form">
        <el-form-item label prop="content">
          <el-input type="textarea" :rows="4" v-model="auditForm.content" maxlength="200" />
        </el-form-item>
      </el-form>
      <template #footer>
        <el-row>
          <el-col :span="2" style="text-align: left">
            <el-button @click="handleCancel">关闭</el-button>
          </el-col>
          <el-col :span="22">
            <el-button type="primary" @click="handleConfirm">通过</el-button>
            <el-button @click="handleReject">退回</el-button>
          </el-col>
        </el-row>
        <span class="dialog-footer"></span>
      </template>
    </el-dialog>
  </div>
</template>
  
  <script setup>
import { ref, unref, reactive, computed, onMounted } from 'vue'
import { useUserStore } from '@/store/user'
import PaginationCmpt from '@/components/PaginationCmpt'
import { DECISION_LAYERS } from '@/utils/constants'
import { useWheelStore } from '@/store/wheelAlignment'
import { GMTToLocal } from '@/utils'
import { getChangePlanTxt } from '@/views/wheelAlignment/wheelAlignment'
import { ElMessage } from 'element-plus'
import { userCommon } from '@/views/wheelAlignment/wheelCommon'

const wheelStore = useWheelStore()
const userStore = useUserStore()
const { userAllListEditor, userAllListAuditor, getUserInfo } = userCommon(
  userStore
)

const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)
const dialogAuditVisible = ref(false)

const operator = computed(() => {
  return userStore?.userInfo?.id || ''
})

const operatingTimeChange = value => {
  if (value) {
    form.beginTime = value[0]
    const endTime = value[1].slice(0, value[1].indexOf('T') + 1)
    form.endTime = endTime + '23:59:59'
  }
}
const operatingTime = ref(null)
const auditForm = reactive({ content: '' })
const form = reactive({
  decisionCode: null,
  decisionState: 'TO_BE_REVIEWED', // 待审核
  layers: null,
  editor: null,
  reviewer: null,
  beginTime: null,
  endTime: null
})
const auditFormRef = ref(null)
const rules = reactive({
  content: [{ required: true, message: '请填写提交说明', trigger: 'change' }]
})

onMounted(async () => {
  getWheelDecisionList()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  getWheelDecisionList()
}

const reset = () => {
  form.decisionCode = null
  form.layers = null
  form.editor = null
  form.reviewer = null
  form.beginTime = null
  form.endTime = null
  operatingTime.value = null
}

const currentChange = value => {
  page.value = value
  getWheelDecisionList()
}
async function getWheelDecisionList() {
  try {
    const result = await wheelStore.getWheelDecisionList({
      pageIndex: unref(page),
      pageSize: unref(size),
      ...form
      // authorId: userStore.userInfo.id
    })

    tableData.value = result.records.map(item => {
      return {
        editorName: getUserInfo(item.editor),
        reviewerName: getUserInfo(item.reviewer),
        ...item
      }
    })
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}

const toDecision = row => {
  window.open(`/wheelAlignment/decision/info?decisionId=${row.id}`, '_blank')
}

const examineData = ref(null)
const examine = row => {
  examineData.value = row
  dialogAuditVisible.value = true
}
const handleCancel = () => {
  dialogAuditVisible.value = false
  auditForm.opinion = ''
}
const handleConfirm = () => {
  handleSubmit('APPROVED')
}
const handleReject = () => {
  handleSubmit('REJECTED')
}

const handleSubmit = async reviewState => {
  const validate = await auditFormRef.value.validate(() => {})
  if (!validate) {
    return
  }

  try {
    await wheelStore.reviewWheelDecision({
      decisionId: examineData.value?.id,
      reviewState,
      content: auditForm.content,
      operator: operator.value
    })
    ElMessage.success('审核成功')
    getWheelDecisionList()
    handleCancel()
  } catch (error) {
    ElMessage.error(error)
  }
}
</script>
  
  <style lang="less" scoped>
.audit {
  padding: 10px 30px;

  &-form {
    &-field {
      // display: flex;
      // justify-content: space-between;
      // padding: 10px 0;
    }

    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }

    .icon {
      &-common {
        width: 1.5em;
        height: 1.5em;
        margin: 0 8px;
        cursor: pointer;
        vertical-align: middle;
      }
      &-tickets {
        color: #409eff;
      }
      &-stamp {
        color: #f56c6c;
      }
      &-disabled {
        color: #aaa;
        cursor: not-allowed;
      }
    }
  }

  &-dialog {
    &-title {
      margin: 0 30px;
      margin-bottom: 20px;
      font-size: 18px;
    }
    &-form {
      margin: 0 30px;
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>