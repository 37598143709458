import { defineStore } from "pinia"
import {
  savePresetFieldGroup,
  updatePresetFieldGroup,
  deletePresetFieldGroup,
  getPresetFieldGroupList,
  getPresetFieldGroupOptionsList,
  savePresetField,
  deletePresetField,
  getPresetFieldList,
  getWheelPresetGroupList,
  saveWheelPresetGroup,
  updateWheelPresetGroup,
  deleteWheelPresetGroup,
  getWheelPresetFieldList,
  saveWheelPresetField,
  deleteWheelPresetField,
  getWheelIndexItemList,
  saveWheelIndexItem,
  updateStateWheelIndexItem,
} from '@/api/presetField'

export const useFieldsStore = defineStore('fields', {
  persist: true,
  state: () => {
    return {}
  },
  actions: {
    async savePresetFieldGroup(data) {
      try {
        const result = await savePresetFieldGroup(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updatePresetFieldGroup(data) {
      try {
        const result = await updatePresetFieldGroup(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async deletePresetFieldGroup(data) {
      try {
        const result = await deletePresetFieldGroup(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getPresetFieldGroupList() {
      try {
        const result = await getPresetFieldGroupList()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getPresetFieldGroupOptionsList() {
      try {
        const result = await getPresetFieldGroupOptionsList()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async savePresetField(data) {
      try {
        const result = await savePresetField(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async deletePresetField(data) {
      try {
        const result = await deletePresetField(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getPresetFieldList(data) {
      try {
        const result = await getPresetFieldList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

/**
 * =============================
 *     四轮定位 - 预设字段            
 * =============================
 */
    async getWheelPresetGroupList() {
      try {
        const result = await getWheelPresetGroupList()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async saveWheelPresetGroup(data) {
      try {
        const result = await saveWheelPresetGroup(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updateWheelPresetGroup(data) {
      try {
        const result = await updateWheelPresetGroup(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async deleteWheelPresetGroup(data) {
      try {
        const result = await deleteWheelPresetGroup(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getWheelPresetFieldList(data) {
      try {
        const result = await getWheelPresetFieldList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async saveWheelPresetField(data) {
      try {
        const result = await saveWheelPresetField(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async deleteWheelPresetField(data) {
      try {
        const result = await deleteWheelPresetField(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    /**
 * =============================
 *     四轮定位 - 索引枚举配置            
 * =============================
 */
    async getWheelIndexItemList(data) {
      try {
        const result = await getWheelIndexItemList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async saveWheelIndexItem(data) {
      try {
        const result = await saveWheelIndexItem(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async updateStateWheelIndexItem(data) {
      try {
        const result = await updateStateWheelIndexItem(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },
  }
})
