<template>
  <el-menu-item-group v-if="item.children && item.group">
    <template #title>
      <span>{{ item.name }}</span>
    </template>
    <MenuItemCmp
      v-for="(child, index) in item.children"
      :key="index"
      :item="child"
    />
  </el-menu-item-group>
  <el-sub-menu v-else-if="item.children" :index="item.path">
    <template #title>
      <el-icon>
        <component :is="iconsMap[item.icon]"></component>
      </el-icon>
      <span>{{ item.name }}</span>
    </template>
    <MenuItemCmp
      v-for="(child, index) in item.children"
      :key="index"
      :item="child"
    />
  </el-sub-menu>
  <el-menu-item v-else :index="item.path" @click="linkTo(item)">
    <span>{{ item.name }}</span>
  </el-menu-item>
</template>

<script setup>
import { defineProps } from 'vue'
import { useRouter } from 'vue-router'
import IconSetting from './icons/IconSetting'
import IconCar from './icons/IconCar'
import IconIndex from './icons/IconIndex'
import IconRemark1 from './icons/IconRemark1'
defineProps({
  item: Object
})

const router = useRouter()
const iconsMap = {
  'IconSetting': IconSetting,
  'IconCar': IconCar,
  'IconIndex': IconIndex,
  'IconRemark1': IconRemark1,
}

function linkTo(item) {
  router.push({
    path: item.path,
  });
}
</script>