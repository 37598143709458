import { createRouter, createWebHistory } from 'vue-router'
import { useUserStore } from '@/store/user'
import { ElMessage } from 'element-plus'

import HomeView from '@/views/HomeView'
import LoginView from '@/views/LoginView'
import UserInfo from '@/views/user/UserInfo'
import SystemLogs from '@/views/logs/SystemLogs'
import CommonConfigCarCode from '@/views/commonConfig/CommonConfigCarCode'
import CommonConfigPresetField from '@/views/commonConfig/CommonConfigPresetField'
import CommonConfigOperationView from '@/views/commonConfig/OperationView'
import CarFaultCheckMyTree from '@/views/faultDiagnosis/CarFaultCheckMyTree'
import CarFaultCheckAllCode from '@/views/faultDiagnosis/CarFaultCheckAllCode'
import CarFaultCheckAllTree from '@/views/faultDiagnosis/CarFaultCheckAllTree'
import CarFaultCheckToAuditTree from '@/views/faultDiagnosis/CarFaultCheckToAuditTree'
import CarFaultCheckReportExtract from '@/views/faultDiagnosis/CarFaultCheckReportExtract'
import CreateTree from '@/views/tree/CreateTree'
import ShowTree from '@/views/tree/ShowTree'
import EditTree from '@/views/tree/EditTree'
import AuditTree from '@/views/tree/AuditTree'
import FaultCodeInfo from '@/views/faultCode/FaultCodeInfo'
import CommonConfigIndex from '@/views/commonConfig/CommonConfigIndex'
import CommonConfigWAPreset from '@/views/commonConfig/CommonConfigWAPreset'
import CommonConfigSuspension from '@/views/commonConfig/CommonConfigSuspension'
import CommonConfigSuspensionAdd from '@/views/commonConfig/CommonConfigSuspensionAdd'
import CommonConfigPartsHours from '@/views/commonConfig/CommonConfigPartsHours'
import CommonConfigPartsHoursAdd from '@/views/commonConfig/CommonConfigPartsHoursAdd'
import WheelAlignmentIndexAll from '@/views/wheelAlignment/IndexAll'
import WheelAlignmentIndexInvalid from '@/views/wheelAlignment/IndexInvalid'
import IndexAndDecision from '@/views/wheelAlignment/IndexAndDecision'
import IndexAndRecords from '@/views/wheelAlignment/IndexAndRecords'
import WheelAlignmentDecisionAll from '@/views/wheelAlignment/DecisionAll'
import WheelAlignmentDecisionAudit from '@/views/wheelAlignment/DecisionAudit'
import WheelAlignmentDecisionMy from '@/views/wheelAlignment/DecisionMy'
import WheelAlignmentDecisionAdd from '@/views/wheelAlignment/DecisionAdd'
import WheelAlignmentDecisionInfo from '@/views/wheelAlignment/DecisionInfo'
import WheelAlignmentDecisionSameIndex from '@/views/wheelAlignment/DecisionSameIndex'
import WheelAlignmentDecisionEditChangePlan from '@/views/wheelAlignment/DecisionEditChangePlan'
import WheelAlignmentRemarkInfo from '@/views/wheelAlignment/RemarkInfo';
import WheelAlignmentRemarkIndexInfo from '@/views/wheelAlignment/RemarkIndexInfo';
import WheelAlignmentRemarkIndexInfoEdit from '@/views/wheelAlignment/RemarkIndexInfoEdit';

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeView,
    // redirect: '/config/carCode/brand',
    children: [
      {
        path: 'config/carCode/brand',
        name: 'brand',
        component: CommonConfigCarCode,
        meta: {
          title: '通用配置',
          roles: [32, 33],
        }
      },
      {
        path: 'config/carCode/preset',
        name: 'preset',
        component: CommonConfigPresetField,
        meta: {
          title: '通用配置',
          roles: [32, 33],
        }
      },
      {
        path: 'config/carCode/operate',
        name: 'operation',
        component: CommonConfigOperationView,
        meta: {
          title: '通用配置',
          roles: [32, 33],
        }
      },
      {
        path: '/config/wheelAlignment/index',
        name: 'wheelAlignmentIndex',
        component: CommonConfigIndex,
        meta: {
          title: '四轮定位',
          roles: [39, 40, 41, 42],
        }
      },
      {
        path: '/config/wheelAlignment/Preset',
        name: 'wheelAlignmentPreset',
        component: CommonConfigWAPreset,
        meta: {
          title: '四轮定位',
          roles: [40, 41],
        }
      },
      {
        path: '/config/wheelAlignment/suspension',
        name: 'suspension',
        component: CommonConfigSuspension,
        meta: {
          title: '四轮定位',
          roles: [39, 40, 41, 42],
        }
      },
      {
        path: '/config/wheelAlignment/suspensionAdd',
        name: 'suspensionAdd',
        component: CommonConfigSuspensionAdd,
        meta: {
          title: '四轮定位',
          roles: [39, 40, 41, 42],
        }
      },
      {
        path: '/config/wheelAlignment/partsHours',
        name: 'partsHours',
        component: CommonConfigPartsHours,
        meta: {
          title: '四轮定位',
          roles: [39, 40, 41, 42],
        }
      },
      {
        path: '/config/wheelAlignment/partsHoursAdd',
        name: 'partsHoursAdd',
        component: CommonConfigPartsHoursAdd,
        meta: {
          title: '四轮定位',
          roles: [39, 40, 41, 42],
        }
      },

      {
        path: 'audit/carCodeLib/all',
        name: 'carcodeliball',
        component: CarFaultCheckAllCode,
        meta: {
          title: '汽车故障诊断',
          roles: [34, 35, 36],
        }
      },
      {
        path: 'audit/treeCodeLib/all',
        name: 'treecodeliball',
        component: CarFaultCheckAllTree,
        meta: {
          title: '汽车故障诊断',
          roles: [34, 35, 36],
        }
      },
      {
        path: 'audit/treeCodeLib/audit',
        name: 'treecodelibaudit',
        component: CarFaultCheckToAuditTree,
        meta: {
          title: '汽车故障诊断',
          roles: [34],
        }
      },
      {
        path: 'audit/myTree/info',
        name: 'mytreeinfo',
        component: CarFaultCheckMyTree,
        meta: {
          title: '汽车故障诊断',
          roles: [35],
        }
      },
      {
        path: 'audit/statisticsCode/get',
        name: 'statisticscodeget',
        component: CarFaultCheckReportExtract,
        meta: {
          title: '汽车故障诊断',
          roles: [35],
        }
      },

      {
        path: '/wheelAlignment/index/all',
        name: 'wheelAlignmentIndexAll',
        component: WheelAlignmentIndexAll,
        meta: {
          title: '四轮定位',
          roles: [40, 41, 42],
        }
      },
      {
        path: '/wheelAlignment/index/invalid',
        name: 'wheelAlignmentIndexInvalid',
        component: WheelAlignmentIndexInvalid,
        meta: {
          title: '四轮定位',
          roles: [40],
        }
      },

      {
        path: '/wheelAlignment/decision/all',
        name: 'WheelAlignmentDecisionAll',
        component: WheelAlignmentDecisionAll,
        meta: {
          title: '四轮定位',
          roles: [40, 41, 42],
        }
      },
      {
        path: '/wheelAlignment/decision/audit',
        name: 'wheelAlignmentDecisionAudit',
        component: WheelAlignmentDecisionAudit,
        meta: {
          title: '四轮定位',
          roles: [40],
        }
      },
      {
        path: '/wheelAlignment/decision/my',
        name: 'WheelAlignmentDecisionMy',
        component: WheelAlignmentDecisionMy,
        meta: {
          title: '四轮定位',
          roles: [41],
        }
      },
      {
        path: '/wheelAlignment/remark/info',
        name: 'WheelAlignmentRemarkInfo',
        component: WheelAlignmentRemarkInfo,
        meta: {
          title: '四轮定位',
          roles: [39, 40, 41, 42],
        }
      },

      {
        path: 'logs',
        name: 'logs',
        component: SystemLogs,
        meta: {
          title: '操作日志',
          roles: [],
        }
      },
      {
        path: 'userinfo',
        name: 'userinfo',
        component: UserInfo,
        meta: {
          title: '个人中心',
          roles: [],
        }
      },
    ]
  },
  {
    path: '/tree/create',
    name: 'createTree',
    component: CreateTree,
    meta: {
      title: '创建树',
      roles: [35],
    }
  },
  {
    path: '/tree/show',
    name: 'showTree',
    component: ShowTree,
    meta: {
      title: '查看树',
      roles: [33, 34, 35, 36],
    }
  },
  {
    path: '/tree/edit',
    name: 'editTree',
    component: EditTree,
    meta: {
      title: '编辑树',
      roles: [34, 35],
    }
  },
  {
    path: '/tree/audit',
    name: 'auditTree',
    component: AuditTree,
    meta: {
      title: '审核树',
      roles: [34],
    }
  },
  {
    path: '/faultCode/info',
    name: 'faultCodeInfo',
    component: FaultCodeInfo,
    meta: {
      title: '故障码详情',
      roles: [33, 34, 35, 36],
    }
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      title: '登陆',
    }
  },

  {
    path: '/wheelAlignment/index/decision',
    name: 'IndexAndDecision',
    component: IndexAndDecision,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },
  {
    path: '/wheelAlignment/index/records',
    name: 'IndexAndRecords',
    component: IndexAndRecords,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },

  {
    path: '/wheelAlignment/decision/add',
    name: 'WheelAlignmentDecisionAdd',
    component: WheelAlignmentDecisionAdd,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },
  {
    path: '/wheelAlignment/decision/info',
    name: 'WheelAlignmentDecisionInfo',
    component: WheelAlignmentDecisionInfo,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },
  {
    path: '/wheelAlignment/decision/editChangePlan',
    name: 'WheelAlignmentDecisionEditChangePlan',
    component: WheelAlignmentDecisionEditChangePlan,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },
  {
    path: '/wheelAlignment/decision/sameIndex',
    name: 'WheelAlignmentDecisionSameIndex',
    component: WheelAlignmentDecisionSameIndex,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },
  {
    path: '/wheelAlignment/remark/indexInfo',
    name: 'WheelAlignmentRemarkIndexInfo',
    component: WheelAlignmentRemarkIndexInfo,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },
  {
    path: '/wheelAlignment/remark/indexInfoEdit',
    name: 'WheelAlignmentRemarkIndexInfoEdit',
    component: WheelAlignmentRemarkIndexInfoEdit,
    meta: {
      title: '四轮定位',
      roles: [39, 40, 41, 42],
    }
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const { token, userInfo } = useUserStore()

  if (to.path !== '/login' && !token) {
    next('/login')
  }

  if (to.path !== '/login' && token) {
    const { roles } = to.meta
    if (
      Array.isArray(roles) &&
      !userInfo.roleList.some(item => roles.includes(item.id))
    ) {
      ElMessage.error('没有访问权限')
      return
    }
  }

  if (to.path === '/login' && token) {
    next(from.path)
  }

  next()
})

export default router
