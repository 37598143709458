<template>
  <div class="suspension">
    <div class="suspension-form">
      <el-form :inline="true" :model="form" label-width="86px" label-position="right">
        <LinkageSelectCmpt ref="linkageSelectRef" :span="6" :isExtra="true" />

        <el-row>
          <el-col :span="12">
            <el-button type="success" @click="create">新增</el-button>
          </el-col>
          <el-col :span="12" class="suspension-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="suspension-table">
      <el-table show-header stripe border :data="tableData">
        <!-- <el-table-column align="center" label prop type="selection" /> -->
        <el-table-column align="center" label="序号" prop type="index" width="60px" />
        <el-table-column align="center" show-overflow-tooltip label="品牌" prop="brandName" />
        <el-table-column align="center" show-overflow-tooltip label="厂商" prop="manufacturerName" />
        <el-table-column align="center" show-overflow-tooltip label="车系" prop="seriesName" />
        <el-table-column align="center" show-overflow-tooltip label="车组" prop="groupName" />
        <el-table-column align="center" show-overflow-tooltip label="车型" prop="modelName" />
        <el-table-column align="center" show-overflow-tooltip label="悬挂形式" prop="suspensionTypeName" />
        <el-table-column align="center" show-overflow-tooltip label="前外倾角是否可调整" prop="frontCamberAdjustableLabel" />
        <el-table-column align="center" show-overflow-tooltip label="后外倾角是否可调整" prop="nearCamberAdjustableLabel" />
        <el-table-column align="center" show-overflow-tooltip label="前束值是否可调整" prop="toeAdjustableLabel" />
        <el-table-column align="center" show-overflow-tooltip label="前上摆臂球头是否单独更换" prop="frontUpperArmBallHeadSRLabel"  />
        <el-table-column align="center" show-overflow-tooltip label="前下摆臂球头是否单独更换" prop="frontLowerArmBallHeadSRLabel"  />
        <el-table-column align="center" show-overflow-tooltip label="前下控制臂位置位于半轴前" prop="frontLowerControlArmOTFALabel" />
        <el-table-column align="center" show-overflow-tooltip label="前转向横拉杆拉杆球头形状" prop="frontSteeringCrossTieRodBallHeadShapeLabel" />
        <el-table-column align="center" show-overflow-tooltip label="前拉杆球头位于半轴前" prop="frontSteeringCrossTieRodBallHeadOTFALabel" />
        <el-table-column align="center" show-overflow-tooltip label="前转向节连接上摆臂部位形状" prop="frontSteeringKnuckleConnectUpperSwingArmShapeLabel" />
        <el-table-column align="center" class="icon" width="110px">
          <template #header>
            操作
            <!-- <Edit class="icon-common icon-edit" />
            <Delete class="icon-common icon-delete" /> -->
          </template>
          <template #default="scope">
            <Edit @click="editFn(scope.row)" class="icon-common icon-edit" />
            <ElPopconfirm title="您确定要删除吗?" @confirm="removeFn(scope.row)">
              <template #reference>
                <Delete class="icon-common icon-delete" />
              </template>
            </ElPopconfirm>
          </template>
        </el-table-column>
      </el-table>
      <PaginationCmpt
        :current-page="page"
        :total="total"
        @current-change="currentChange"
      />
    </div>

    <SuspensionEditCmpt :info="editInfo" v-model="dialogAddGroupVisible" @refreshList="preSearch" />
  </div>
</template>

<script setup>
import { ref, unref, reactive, onMounted } from 'vue'
import { useWheelStore } from '@/store/wheelAlignment'
import { useUserStore } from '@/store/user'
import PaginationCmpt from '@/components/PaginationCmpt'
import { useRouter } from 'vue-router'
import { vehicleRemove } from '@/api/presetField'
import { ElMessage, ElPopconfirm } from 'element-plus'
import LinkageSelectCmpt from '@/views/commonConfig/components/LinkageSelectCmpt'
import SuspensionEditCmpt from '@/views/commonConfig/components/SuspensionEditCmpt'

const router = useRouter()
const wheelStore = useWheelStore()
const userStore = useUserStore()
const linkageSelectRef = ref(null)
const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)
const dialogAddGroupVisible = ref(false)
let editInfo = reactive({})
const form = reactive({})

onMounted(async () => {
  await userStore.getUserAllInfo()
  getWheelVehicleList()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  search()
}
const search = () => {
  const linkageForm =  linkageSelectRef.value.getForm()
  getWheelVehicleList(linkageForm)
}
const reset = () => {
  linkageSelectRef.value.reset()
}
const create = () => {
  router.push(`/config/wheelAlignment/suspensionAdd`)
}
const editFn = (row) => {
  Object.assign(editInfo, {
    ...row
  })

  dialogAddGroupVisible.value = true
}

const removeFn = async (row) => {
  await vehicleRemove({ id: row.id})
  ElMessage({
    message: '删除成功',
    type: 'success'
  })
  preSearch()
}

const currentChange = value => {
  page.value = value
  search()
}
async function getWheelVehicleList(data = {}) {
  try {
    const result = await wheelStore.getWheelVehicleList({
      pageIndex: unref(page),
      pageSize: unref(size),
      ...data,
      // authorId: userStore.userInfo.id
    })

    const labeledProps = [
      'adjustable', 'frontCamberAdjustable', 'nearCamberAdjustable',
      'toeAdjustable', 'frontUpperArmBallHeadSR', 'frontLowerArmBallHeadSR', 'frontLowerControlArmOTFA',
      'frontSteeringCrossTieRodBallHeadShape', 'frontSteeringCrossTieRodBallHeadOTFA',
      'frontSteeringKnuckleConnectUpperSwingArmShape'
    ]

    tableData.value = result.records.map(res => {
      const labeledRes = {}
      labeledProps.forEach(prop => {
        if (res[prop] === null) {
          labeledRes[`${prop}Label`] = ''
        } else if (prop.includes('Shape')) {
          labeledRes[`${prop}Label`] = res[prop] == 1 ? '直' : '弯'
        } else {
          labeledRes[`${prop}Label`] = res[prop] == 1 ? '是' : '否'
        }
      })

      return { ...res, ...labeledRes }
    })

    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}

</script>

<style lang="less" scoped>
.suspension {
  padding: 10px 30px;

  &-form {
    &-field {
      // display: flex;
      // justify-content: space-between;
      // padding: 10px 0;
    }

    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }
    .icon {
      &-common {
        width: 1.5em;
        height: 1.5em;
        margin: 0 10px;
        cursor: pointer;
        vertical-align: middle;
      }
      &-edit {
        color: #409eff;
      }
      &-delete {
        color: #f56c6c;
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>