<template>
  <div :style="style" class="node-contextmenu">
    <div
      class="node-contextmenu-item"
      v-for="item in menus"
      :key="item.value"
      @mousedown="emits('select', { item, position })"
    >{{ item.label }}</div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'

const emits = defineEmits(['select'])
const props = defineProps({
  menus: [],
  position: Object,
})

const style = computed(() => {
  const { x, y } = props.position
  return `top:${y}px;left:${x}px;`
})
</script>

<style lang="less" scoped>
.node {
  &-contextmenu {
    position:fixed;
    box-shadow:0px 0px 12px rgba(0, 0, 0, 0.12);
    padding: 5px 0;
    background-color:white;
    border-radius: 4px;
    font-size: 13px;

    &-item {
      padding: 8px 20px;
      cursor: pointer;

      &:hover {
        background-color: #dedfe0;
      }
    }
  }
}
</style>