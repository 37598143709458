<template>
  <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false">
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane name="code" label="关联故障码">
        <div class="tree-edit-label">逻辑树关联关系:</div>
        <el-form :model="formCode" label-width="80px" status-icon>
          <el-form-item label="品牌:">
            <el-select
              v-model="formCode.brand"
              clearable
              @change="changeBrand"
              placeholder="请选择"
            >
              <el-option
                v-for="item in brandList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="车型:">
            <el-select
              v-model="formCode.model"
              clearable
              @change="changeModel"
              placeholder="请选择"
            >
              <el-option
                v-for="item in modelList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="故障码:">
            <el-select v-model="formCode.code" clearable placeholder="请选择">
              <el-option
                v-for="item in faultCodeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </el-form-item>
          <el-form-item label="优先级:">
            <el-select v-model="formCode.grade" clearable placeholder="请选择">
              <el-option
                v-for="item in 9"
                :key="item"
                :label="item"
                :value="item"
              />
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="addCode">新增</el-button>
          </el-form-item>
        </el-form>
        <TableCmpt :case-list="codes" :header-list="headerList" />
      </el-tab-pane>
      <el-tab-pane name="remark" label="整树备注">
        <div class="tree-edit-remark-header">
          <div>整树备注</div>
          <el-button type="success" @click="addRemark">新增备注</el-button>
        </div>
        <TreeRemark
          v-for="(item, index) in remarks"
          :key="index"
          :remark="item"
          :title="`备注${index + 1}`"
        >
          <el-icon class="remark-icon" @click="editRemark(item)"><Edit /></el-icon>
          <el-icon class="remark-icon" @click="deleteRemark(item)"><Delete /></el-icon>
        </TreeRemark>
      </el-tab-pane>
    </el-tabs>
    <div class="tree-edit-btns">
      <template v-if="activeTab === 'code'">
        <el-button type="primary" @click="confirm">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </template>
      <el-button v-else @click="cancel">关闭</el-button>
    </div>
    <DialogRemark
      v-model="isShow"
      :remark="targetRemark"
      @confirm="remarkConfirm"
    />
  </el-drawer>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  ref,
  unref,
  reactive,
  onMounted,
  watch
} from 'vue'
import { useCarStore } from '@/store/car'
import { useTreeStore } from '@/store/tree'
import TreeRemark from './TreeRemark'
import TableCmpt from '@/components/TableCmpt'
import { ElMessage, ElMessageBox } from 'element-plus'
import DialogRemark from './DialogRemark'
import { merge } from 'lodash'

const emits = defineEmits(['update:modelValue', 'code'])
const props = defineProps({
  modelValue: Boolean,
  id: Number,
  codes: {
    type: Array,
    default: () => ([])
  },
  status: String,
})
const carStore = useCarStore()
const treeStore = useTreeStore()
const formCode = reactive({
  brand: null,
  model: null,
  code: null,
  grade: null,
})
const brandList = ref([])
const modelList = ref([])
const faultCodeList = ref([])
const activeTab = ref('code')
const remarks = ref([])
const isShow = ref(false)
const targetRemark = reactive({
  opt: 'add',
  remark: {},
  treeId: null,
})
const headerList = computed(() => {
  const list = [
    {
      type: 'index',
      label: '序号',
      width: 60,
    },
    {
      prop: 'faultCode',
      label: '所关联故障码',
      formatter(row) {
        const code = row.faultCode
        return (
          <router-link target="_blank" to={`/faultCode/info?code=${code}&id=${row.id}`}>
            <div>{code}</div>  
          </router-link>
        )
      }
    },
  ]

  if (props.status === 'show') {
    list.push({
      prop: 'enabled',
      label: '本树状态',
      formatter(row) {
        const treeStatusChange = value => row.enabled = value
        return (<el-switch value={!!row.enabled} onChange={treeStatusChange} />)
      }
    })
  }

  return [
    ...list,
    {
      prop: 'priority',
      label: '本树优先级',
      formatter(row) {
        return (
          <el-select clearable v-model={row.priority} placeholder="请选择">
            {[1,2,3,4,5,6,7,8,9].map(item => {
              return (<el-option key={item} label={item} value={item} />)
            })}
          </el-select>
        )
      }
    },
    {
      prop: 'id',
      label: '操作',
      formatter(row) {
        const del = () => {
          emits('code', {
            ...row,
            type: 'delete',
          })
        }
        return (<el-button type="danger" size="small" onClick={del}>删除</el-button>)
      }
    }
  ]
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

watch(value, (status) => {
  if (status) {
    activeTab.value = 'code'
  }
})

onMounted(() => {
  getCarBrandList()
  getRelationTreeRemarks()
})

const cancel = () => {
  emits('update:modelValue', false)
}
const confirm = () => {
  if (unref(activeTab) === 'code') {
    updateRelationBindTreeFaultCode()
  }

  if (unref(activeTab) === 'remark') {
    updateRelationBindTreeFaultCode()
  }
  cancel()
}

const addRemark = () => {
  Object.assign(targetRemark, {
    opt: 'add',
    remark: {},
    treeId: props.id
  })
  isShow.value = true
}
const editRemark = (item) => {
  merge(targetRemark, {
    opt: 'edit',
    remark: item,
    treeId: props.id,
  })
  isShow.value = true
}
const deleteRemark = (item) => {
  ElMessageBox.confirm(
    '是否删除备注?',
    '警告',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    relationRemoveTreeRemark(item.id)
  }).catch(() => {})
}
const remarkConfirm = (data) => {
  const { content, faultCode, fileList } = data
  const { remark, opt } = targetRemark
  if (opt === 'add') {
    updateRelationBindTreeRemark({
      treeId: props.id,
      remark: content,
      faultCodes: faultCode.map(
        code => ({ id: code.key, faultCode: code.faultCode })
      ),
      files: fileList,
    })
  }

  if(opt === 'edit') {
    relationModifyTreeRemark({
      id: remark.id,
      remark: content,
      faultCodes: faultCode.map(
        code => ({ id: code.key, faultCode: code.faultCode })
      ),
      files: fileList,
    })
  }
}

async function getCarBrandList() {
  try {
    const list = await carStore.getCarBrandOptions()
    const brands = list.map(item => ({
      value: item.key,
      label: item.value,
      original: item,
    }))
    brandList.value = brands
  } catch (error) {
    console.error(error)
  }
}
async function getCarModelList(carBrandId) {
  try {
    const list = await carStore.getCarModelOptions({ carBrandId })
    const models = list.map(item => ({
      value: item.key,
      label: item.value,
      original: item,
    }))
    modelList.value = models
  } catch (error) {
    console.error(error)
  }
}
async function getCarFaultCodeList(data) {
  try {
    const list = await carStore.getCarFaultCodeOptions(data)
    const faultCodes = list.map(item => ({
      value: item.key,
      label: item.value,
      original: item,
    }))
    faultCodeList.value = faultCodes
  } catch (error) {
    console.error(error)
  }
}
async function updateRelationBindTreeFaultCode() {
  try {
    const faultCodes = unref(props.codes).map(item => {
      let obj = {
        faultCodeId: item.id,
        priority: item.priority,
      }

      if (props.status === 'show') {
        obj = {
          ...obj,
          enabled: item.enabled ? 1 : 0,
        }
      }

      return obj
    })
    const result = await treeStore.updateRelationBindTreeFaultCode({
      treeId: props.id,
      faultCodes,
    })
    console.log('关联结束:', { result })
  } catch (error) {
    console.error(error)
  }
}
async function getRelationTreeRemarks() {
  try {
    const result = await treeStore.getRelationTreeRemarks({ treeId: props.id })
    remarks.value = result
  } catch (error) {
    console.error(error)
  }
}
async function updateRelationBindTreeRemark(data) {
  try {
    const result = await treeStore.updateRelationBindTreeRemark(data)
    remarks.value.push({
      ...data,
      id: result,
    })
    ElMessage.success('添加成功')
  } catch (error) {
    console.error(error)
    ElMessage.error('添加失败')
  }
}
async function relationModifyTreeRemark(data) {
  try {
    await treeStore.relationModifyTreeRemark(data)
    remarks.value.forEach(remark => {
      if (remark.id === data.id) {
        Object.assign(remark, data)
      }
    })
    ElMessage.success('修改成功')
  } catch (error) {
    console.error(error)
    ElMessage.error('修改失败')
  }
}
async function relationRemoveTreeRemark(id) {
  try {
    await treeStore.relationRemoveTreeRemark({ id })
    remarks.value = unref(remarks).filter(item => item.id !== id)
    ElMessage.success('删除成功')
  } catch (error) {
    console.error(error)
    ElMessage.success('删除失败')
  }
}

const addCode = () => {
  const targetCode = (unref(faultCodeList).find(
    item => item.value === formCode.code
  ) || {}).original
  const carBrand = (unref(brandList).find(
    item => item.value === formCode.brand
  ))?.label
  const carModel = (unref(modelList).find(
    item => item.value === formCode.model
  ))?.label

  if (!targetCode?.value) {
    ElMessage.warning('请选择添加的故障码')
    return
  }

  const has = props.codes.some(
    code => code.id === targetCode.key
  )
  if (has) {
    ElMessage.warning('不能重复关联相同故障码')
    return
  }
  emits('code', {
    type: 'add',
    id: targetCode.key,
    carBrand,
    carModel,
    enabledTreeCode: null,
    faultCode: targetCode.value,
    priority: formCode.grade,
    relationStatus: 10
  })
}
const changeBrand = id => {
  getCarModelList(id)
  formCode.model = null
  formCode.code = null
}
const changeModel = id => {
  getCarFaultCodeList({
    carBrandId: formCode.brand,
    carModelId: id,
  })
  formCode.code = null
}
</script>

<style lang="less" scoped>
.tree-edit {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-btns {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &-remark-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.remark-icon {
  margin-left: 5px;
}
</style>