<template>
  <el-drawer
    v-model="value"
    :with-header="false"
    :close-on-click-modal="false"
    class="node-edit"
  >
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane name="content" label="节点逻辑:">
        <div class="node-edit-item">
          <div class="node-edit-label">
            <span class="required-icon">*</span>
            节点类型:
          </div>
          <el-radio-group :disabled="auth" v-model="form.type">
            <el-radio v-if="form.type === 'ROOT'" label="ROOT">开始节点</el-radio>
            <template v-else>
              <el-radio label="PROCESS">过程节点</el-radio>
              <el-radio label="OUTPUT_OR_PROCESS">输出+过程节点</el-radio>
              <el-radio label="RESULT">结果节点</el-radio>
            </template>
          </el-radio-group>
        </div>
        <template v-if="form.type !== 'RESULT'">
          <div class="node-edit-item">
            <div style="display:flex;align-items:center;justify-content:space-between;">
              <div class="node-edit-label">
                <span class="required-icon">*</span>
                展示内容:
              </div>
              <div class="node-edit-link" @click="presetField('show')">预设字段</div>
            </div>
            <div>
              <el-input
                v-model="form.viewContent"
                :disabled="auth"
                :rows="2"
                maxlength="200"
                show-word-limit
                type="textarea"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="node-edit-item">
            <div style="display:flex;align-items:center;justify-content:space-between;">
              <div class="node-edit-label">增加故障码集合:</div>
              <div class="node-edit-link" @click="addFaultCodeSet">增加新故障码集合</div>
            </div>
            <div>
              <div v-for="(item, index) in form.faultCodeSets" :key="index" style="margin: 5px 0 10px;">
                <div style="display:flex;align-items:center;justify-content:space-between;">
                  <div>故障码{{ item.type === 'WITHIN' ? '包含' : '跳转'}}集合{{ index + 1}}</div>
                  <div>
                    <el-icon @click="editFaultCodeSet(item, index)"><Edit /></el-icon>
                    <el-icon @click="deleteFaultCodeSet(item, index)"><Delete /></el-icon>
                  </div>
                </div>
                <div class="node-edit-code-list" style="border-bottom:1px solid #dcdfe6;">
                  <router-link
                    target="_blank"
                    v-for="code in item.faultCodes"
                    :key="code.id"
                    :to="`/faultCode/info?code=${code.faultCode}&id=${code.id}`"
                  >
                    {{ code.faultCode }}
                  </router-link>
                </div>
              </div>
            </div>
          </div>
        </template>
        <template v-if="form.type === 'OUTPUT_OR_PROCESS' || form.type === 'RESULT'">
          <div class="node-edit-item">
            <div style="display:flex;align-items:center;justify-content:space-between;">
              <div class="node-edit-label">
                <span class="required-icon">*</span>
                输出内容:
              </div>
              <div class="node-edit-link" @click="presetField('output')">预设字段</div>
            </div>
            <div>
              <el-input
                v-model="form.outputContent"
                :disabled="auth"
                :rows="2"
                maxlength="200"
                show-word-limit
                type="textarea"
                placeholder="请输入"
              />
            </div>
          </div>
          <div class="node-edit-item">
            <div style="display:flex;align-items:center;justify-content:space-between;">
              <div style="display:flex;align-items:center;">
                <div class="node-edit-label">
                  <span class="required-icon">*</span>
                  输出类型:
                </div>
                <el-select
                  style="width:100px;"
                  :disabled="auth"
                  v-model="form.outputType"
                  clearable
                  placeholder="请选择"
                >
                  <el-option label="结论" value="CONCLUSION" />
                  <el-option label="放行" value="RELEASE" />
                </el-select>
              </div>
            </div>
            <template v-if="form.outputType === 'CONCLUSION'">
              <div>
                <el-select
                  style="width:100%;"
                  :disabled="auth"
                  v-model="form.outputTypeTpl"
                  clearable
                  filterable
                  @change="getTpl"
                  placeholder="请选择"
                >
                  <el-option
                    v-for="tpl in outputTemplateList"
                    :key="tpl.index"
                    :label="tpl.name"
                    :value="tpl.index"
                  />
                </el-select>
              </div>
              <SolutionTemplate
                ref="templateSolution"
                :tpl="form.tpl"
                :conclusionPadValues="form.conclusionPadValues"
                @add="presetField('tpl', $event)"
              />
            </template>
            <template v-if="form.outputType === 'RELEASE'">
              <div class="release-template" style="display: flex;align-items:center;">
                <span style="width: 95px;">放行配件:</span>
                <el-input
                  v-model="form.releaseParts"
                  :disabled="true"
                >
                  <template #append>
                    <span @click="presetField('release')">字段</span>
                  </template>
                </el-input>
              </div>
            </template>
          </div>
        </template>
      </el-tab-pane>
      <el-tab-pane name="remark" label="节点备注">
        <NodeInfoRemarkEdit
          v-if="activeTab === 'remark'"
          status="edit"
          :node="node"
        />
      </el-tab-pane>
    </el-tabs>
    <div class="node-edit-btns">
      <template v-if="activeTab === 'content'">
        <el-button :disabled="auth" type="primary" @click="confirm">确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </template>
      <template v-else>
        <el-button @click="cancel">关闭</el-button>
      </template>
    </div>
    <DialogAddFaultCodeSet
      v-model="showCodeSet"
      :faultCodeSet="faultCodeSet"
      :list="form.faultCodeSets"
      @confirm="faultCodeSetsConfirm"
    />
    <DialogPresetFields
      v-model="showPresetField"
      @select="presetFieldSelect"
      :position="presetFieldPosition"
      :outputType="form.outputType"
    />
  </el-drawer>
</template>

<script setup>
import { 
  defineProps,
  defineEmits,
  computed,
  reactive,
  ref,
  unref,
  watch,
  onBeforeMount,
} from 'vue'
import { useTreeStore } from '@/store/tree'
import DialogAddFaultCodeSet from './DialogAddFaultCodeSet'
import DialogPresetFields from './DialogPresetFields'
import SolutionTemplate from './SolutionTemplate'
import { ElMessage } from 'element-plus'
import NodeInfoRemarkEdit from './NodeInfoRemarkEdit'

const emits = defineEmits([
  'update:modelValue',
  'reset',
  'remark',
])
const props = defineProps({
  modelValue: Boolean,
  node: {
    type: Object,
    default: () => ({})
  },
  auth: Boolean
})

let presetFieldTargetType = null
let presetFieldTargetPos = null
const treeStore = useTreeStore()
const templateSolution = ref()
const faultCodeSet = reactive({})
const activeTab = ref('content')
const showCodeSet = ref(false)
const showPresetField = ref(false)
const outputTemplateList = ref([])
const presetFieldPosition = ref('')
const form = reactive({
  type: '',
  viewContent: '',
  outputContent: '',
  outputType: '',
  outputTypeTpl: null,
  faultCodeSets: [],
  tpl: '',
  releaseParts: '',
  releasePartsList: [],
  conclusionPadValues: [],
})

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

onBeforeMount(async () => {
  await getAllRuleConClusion()
})

watch(value, (val) => {
  const { node } = props
  const { nodeInfo } = node.data
  if (val) {
    activeTab.value = 'content'

    const tplObject = unref(outputTemplateList).find(
      item => item.index === nodeInfo.conclusionId
    )

    Object.assign(form, {
      type: nodeInfo.nodeType,
      viewContent: nodeInfo.viewContent,
      outputContent: nodeInfo.outputContent,
      outputType: nodeInfo.outputType,
      outputTypeTpl: nodeInfo.conclusionId,
      faultCodeSets: nodeInfo.faultCodeSets || [],
      releasePartsList: (nodeInfo.parts || []),
      releaseParts: nodeInfo?.parts?.map(item => item.name)?.join(' '),
      conclusionPadValues: nodeInfo.conclusionPadValues,
      tpl: tplObject?.name,
    })
  }
})

const cancel = () => {
  emits('update:modelValue', false)
}
const confirm = () => {
  updateTreeModifyNode(
    templateSolution.value?.tplValue.filter(
      item => item.type === 'el-input'
    )
  )
}
const presetField = (type, data) => {
  presetFieldTargetType = type
  presetFieldPosition.value = type
  if(type === 'tpl') {
    presetFieldTargetPos = data
  }
  if (type === 'release') {
    presetFieldTargetPos = {
      list: []
    }
  }
  showPresetField.value = true
}
const addFaultCodeSet = () => {
  showCodeSet.value = true
  Object.assign(faultCodeSet, {
    faultCodes: [],
    flowCode: null,
    type: 'FORWARD',
    opt: 'add',
  })
}
const editFaultCodeSet = (item, index) => {
  addFaultCodeSet()
  Object.assign(faultCodeSet, {
    ...item,
    opt: 'edit',
    index,
  })
}
const deleteFaultCodeSet = (item, pos) => {
  form.faultCodeSets = form.faultCodeSets.filter(
    (code, index) => index !== pos
  )
}
const getTpl = value => {
  form.tpl = unref(outputTemplateList)?.find(
    item => item.index == value
  )?.name || ''
  form.conclusionPadValues = []
}
const faultCodeSetsConfirm = data => {
  if (data.opt === 'edit') {
    Object.assign(form.faultCodeSets[data.index], {
      faultCodes: data.fieldList,
      type: data.type,
    })
  }
  if (data.opt === 'add') {
    form.faultCodeSets.push({
      faultCodes: data.fieldList,
      type: data.type,
    })
  }
}
const presetFieldSelect = ({ type, item }) => {
  if (presetFieldTargetType === 'show') {
    form.viewContent = form.viewContent 
                        ? form.viewContent + item.name
                        : item.name
  }
  if (presetFieldTargetType === 'output') {
    form.outputContent = form.outputContent
                        ? form.outputContent + item.name 
                        : item.name
  }
  if (presetFieldTargetType === 'release') {
    let has = false
    if(type === 'field') {
      has = presetFieldTargetPos.list.some(
        field => (field.id === item.id)
      )
    } else {
      has = presetFieldTargetPos.list.some(
        field => (field.code === item.code)
      )
    }
    if(!has) {
      // if (form.releaseParts) {
      //   form.releaseParts += ` ${item.name}`
      // } else {
      //   form.releaseParts = item.name
      // }
      form.releasePartsList.push(item)
      presetFieldTargetPos.list.push(item)
    } else {
      if (type === 'field') {
        form.releasePartsList = form.releasePartsList.filter(
          field => field.id !== item.id
        )
        presetFieldTargetPos.list = presetFieldTargetPos.list.filter(
          field => field.id !== item.id
        )
      } else {
        form.releasePartsList = form.releasePartsList.filter(
          field => field.code !== item.code
        )
        presetFieldTargetPos.list = presetFieldTargetPos.list.filter(
          field => field.code !== item.code
        )
      }
    }
    form.releaseParts = form.releasePartsList.map(part => part.name).join(' ')
  }
  if (presetFieldTargetType === 'tpl') {
    presetFieldTargetPos.item.value = presetFieldTargetPos.item.value + item.name
  }
}
async function updateTreeModifyNode(conclusionList) {
  try {
    const { treeIds, nodeInfo } = props.node.data
    const result = await treeStore.updateTreeModifyNode({
      treeId: treeIds.treeId,
      snapshotId: treeIds.snapshotId,
      authorId: treeIds.authorId,
      nodeCode: nodeInfo.nodeCode,
      nodeType: form.type,
      viewContent: form.viewContent,
      faultCodeSets: form.faultCodeSets?.map(item => {
        return {
          type: item.type,
          flowCode: item.flowCode,
          faultCodeIds: item.faultCodes?.map(({ id }) => id)
        }
      }),
      outputContent: form.outputContent,
      outputType: form.outputType,
      conclusionId: form.outputTypeTpl,
      conclusionPadValues: conclusionList?.map(item => item.value),
      parts: form.releasePartsList.map(
        item => ({ code: item.code, name: item.name })
      ),
    })
    emits('reset', result)
    cancel()
  } catch (error) {
    ElMessage.error(error)
    console.error(error)
  }
}
async function getAllRuleConClusion() {
  try {
    const result = await treeStore.getAllRuleConClusion()
    outputTemplateList.value = result
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.node-edit {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-item {
    margin: 10px 0;
  }

  &-label {
    font-size: 15px;
    font-weight: bold;
    margin: 10px 10px 10px 0;
  }

  &-link {
    font-size: 13px;
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-btns {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }

  &-code-list {
    & > a {
      margin: 0 5px;
    }
  }
}

.remark-icon {
  margin-left: 5px;
}

.release-template {
  :deep(.el-input-group__append) {
    cursor: pointer;
  }
}

.required-icon {
  color: #f56c6c;
}
</style>