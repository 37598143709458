<template>
  <el-drawer
    v-model="value"
    :with-header="false"
    :close-on-click-modal="false"
    class="edge-edit"
  >
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane name="content" label="线逻辑:">
        <div style="display:flex;align-items:center;justify-content:space-between;">
          <div class="edge-edit-label">
            <span class="required-icon">*</span>
            线内容:
          </div>
          <div class="edge-edit-link" @click="presetField">预设字段</div>
        </div>
        <div>
          <el-input
            v-model="showContent"
            :disabled="auth"
            :rows="2"
            type="textarea"
            placeholder="请输入"
          />
        </div>
      </el-tab-pane>
      <el-tab-pane name="remark" label="线备注:">
        <EdgeInfoRemarkEdit
          v-if="activeTab === 'remark'"
          status="edit"
          :edge="edge"
        />
      </el-tab-pane>
    </el-tabs>
    <div class="edge-edit-btns">
      <template v-if="activeTab === 'content'">
        <el-button
          :disabled="auth"
          type="primary"
          @click="confirm"
        >确定</el-button>
        <el-button @click="cancel">取消</el-button>
      </template>
      <el-button v-else @click="cancel">关闭</el-button>
    </div>
    <DialogPresetFields
      v-model="showPresetField"
      @select="presetFieldSelect"
    />
  </el-drawer>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  ref,
  unref,
  watch
} from 'vue'
import { useTreeStore } from '@/store/tree'
import DialogPresetFields from './DialogPresetFields'
import EdgeInfoRemarkEdit from './EdgeInfoRemarkEdit'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  edge: {
    type: Object,
    default: () => ({})
  },
  auth: Boolean
})

const treeStore = useTreeStore()
const showContent = ref('')
const showPresetField = ref(false)
const activeTab = ref('content')
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

watch(value, () => {
  if (value) {
    activeTab.value = 'content'
    showContent.value = props.edge?.getLabelAt(0)?.attrs?.label?.text || ''
  }
})

const cancel = () => {
  emits('update:modelValue', false)
}
const confirm = () => {
  if (unref(activeTab) === 'content') {
    updateTreeModifyFlow()
  }
  cancel()
}
const presetField = () => {
  showPresetField.value = true
}
const presetFieldSelect = ({ type, item }) => {
  console.log('边-预设字段', { type, item })
  showContent.value += item.name
}
async function updateTreeModifyFlow() {
  try {
    const { edge } = props
    const { treeIds } = edge.data
    const viewContent = unref(showContent)
    await treeStore.updateTreeModifyFlow({
      treeId: treeIds.treeId,
      snapshotId: treeIds.snapshotId,
      authorId: treeIds.authorId,
      flowCode: edge.id,
      viewContent,
    })
    edge.setLabelAt(0, {
      attrs: {
        label: {
          text: viewContent
        }
      }
    })
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.edge-edit {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-link {
    font-size: 13px;
    color: #409eff;
    text-decoration: underline;
    cursor: pointer;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-btns {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}

.remark-icon {
  margin-left: 5px;
}

.required-icon {
  color: #f56c6c;
}
</style>