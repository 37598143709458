<template>
  <div class="brand-add">
    <div class="brand-add-form">
      <div class="brand-add-label">新增品牌:</div>
      <el-form :model="form">
        <el-form-item label="品牌名称:" :rules="[{ required: true }]">
          <el-input
            style="width:200px;"
            maxlength="50"
            v-model="form.brandName"
            placeholder="请输入"
          />
        </el-form-item>
      </el-form>
      <div class="brand-add-mark">*品牌名称一经增加后不可更改</div>
      <div class="brand-add-btns">
        <el-button type="primary" @click="add">新增</el-button>
      </div>
    </div>
    <div class="brand-add-list">
      <div class="brand-add-label">已有品牌:</div>
      <ListCmpt :list="brands" @select="disabledHandle" />
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted } from 'vue'
import { useCarStore } from '@/store/car'
import ListCmpt from './ListCmpt'
import { ElMessage } from 'element-plus'

const carStore = useCarStore()
const form = reactive({ brandName: '' })
const brands = ref([])

onMounted(() => {
  getCarBrandList()
})

const add = () => {
  saveCarBrand()
}
const disabledHandle = item => {
  updateCarBrandVisible({
    id: item.value,
    status: item.disabled == 1 ? 0 : 1, // 0: 显示 1: 隐藏
  })
}
async function getCarBrandList() {
  try {
    const list = await carStore.getCarBrandList()
    brands.value = list.map(item => ({
      value: item.id,
      label: item.name,
      disabled: item.invisible,
      original: item,
    }))
  } catch (error) {
    console.error(error)
  }
}
async function saveCarBrand() {
  try {
    const { brandName } = form
    const result = await carStore.saveCarBrand({
      name: brandName
    })
    brands.value.push({
      id: result,
      value: result,
      label: brandName,
    })
    ElMessage.success('添加成功')
  } catch (error) {
    ElMessage.error(error)
  }
}
async function updateCarBrandVisible(data) {
  try {
    const result = await carStore.updateCarBrandVisible(data)
    brands.value.forEach(item => {
      if (item.value === result.id) {
        item.disabled = result.status
      }
    })
    ElMessage.success('操作成功')
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.brand-add {
  display: flex;

  & > div {
    flex: 1;
    padding: 10px 20px;
  }

  &-form {
    border-right: 1px solid #dcdfe6;
  }
  
  &-list {
    border-left: 1px solid #dcdfe6;
  }

  &-label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-mark {
    font-size: 12px;
    color: red;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>