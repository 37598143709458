import { defineStore } from "pinia"
import {
  getTreePageList,
  getRelationListForCreateTree,
  saveTree,
  getPhysicalTreeCodes,
  getTreeStructure,
  getRelationTreeRemarks,
  getRelationNodeRemarks,
  getRelationEdgeRemarks,
  getRelationTreeFaultCodes,
  treeReview,
  treeVerify,
  treeForward,
  treeRepeatVerify,
  treeSnapshot,
  updateRelationBindTreeFaultCode,
  updateTreeModifyFlow,
  getRelationTreeFaultCodeOptions,
  treeSubmit,
  treeRemove,
  updateRelationBindTreeRemark,
  relationRemoveTreeRemark,
  updateRelationBindNodeRemark,
  relationModifyTreeRemark,
  relationModifyNodeRemark,
  relationRemoveNodeRemark,
  updateRelationBindFlowRemark,
  relationModifyFlowRemark,
  relationRemoveFlowRemark,
  treeSaveNode,
  treeSaveFlow,
  treeRemoveNode,
  treeRemoveFlow,
  treeMoveNode,
  treeApplyCode,
  treeUndo,
  treeRedo,
  treeCommit,
  updateTreeModifyNode,
  getAllRuleConClusion,
  getTreeOperateRecords,
  saveTreeOperateRecord,
  getTreeOperateTypes,
} from '@/api/tree'
import {
  getRuleSystemSparePartGroupList,
  getRuleSystemSparePartList,
} from '@/api/rules'

export const useTreeStore = defineStore('tree', {
  state: () => {
    return {
      treeList: null,
      reviewResultList: [],
      treeRecordTypeList: [],
    }
  },
  actions: {
    async getTreePageList(data) {
      try {
        const result = await getTreePageList(data)
        this.treeList = result.records
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getRelationListForCreateTree(data) {
      try {
        const result = await getRelationListForCreateTree(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async saveTree(data) {
      try {
        const nodeCode = await this.treeApplyCode()
        const result = await saveTree({
          ...data,
          rootNode: {
            nodeCode,
            attributes: {
              ports: [
                {
                  id: `${nodeCode}-port`,
                  group: "bottom"
                }
              ]
            }
          }
        })
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getPhysicalTreeCodes() {
      try {
        const result = await getPhysicalTreeCodes()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getTreeStructure(data) {
      try {
        const result = await getTreeStructure(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async treeSnapshot(data) {
      try {
        const result = await treeSnapshot(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getRelationTreeRemarks(data) {
      try {
        const result = await getRelationTreeRemarks(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getRelationNodeRemarks(data) {
      try {
        const result = await getRelationNodeRemarks(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getRelationEdgeRemarks(data) {
      try {
        const result = await getRelationEdgeRemarks(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getRelationTreeFaultCodes(data) {
      try {
        const result = await getRelationTreeFaultCodes(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树审查
    async treeReview(data) {
      try {
        const result = await treeReview(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树验证
    async treeVerify(data) {
      try {
        const result = await treeVerify(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树转交
    async treeForward(data) {
      try {
        const result = await treeForward(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树分段检验
    async treeRepeatVerify(data) {
      try {
        const result = await treeRepeatVerify(data)
        const len = this.reviewResultList.length
        // 处理结果
        this.reviewResultList.push({
          title: `第${len + 1}次重复校验:`,
          treeList: result,
        })
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 编辑-树
    async updateRelationBindTreeFaultCode(data) {
      try {
        const result = await updateRelationBindTreeFaultCode(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 编辑 - 线
    async updateTreeModifyFlow(data) {
      try {
        const result = await updateTreeModifyFlow(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 【select】获取树对应的故障码
    async getRelationTreeFaultCodeOptions(data) {
      try {
        const result = await getRelationTreeFaultCodeOptions(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 提交
    async treeSubmit(data) {
      try {
        const result = await treeSubmit(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 删除
    async treeRemove(data) {
      try {
        const result = await treeRemove(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },
    
    // 树 - 备注 - 添加,
    async updateRelationBindTreeRemark(data) {
      try {
        const result = await updateRelationBindTreeRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树 - 备注 - 删除
    async relationRemoveTreeRemark(data) {
      try {
        const result = await relationRemoveTreeRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树 - 备注 - 修改
    async relationModifyTreeRemark(data) {
      try {
        const result = await relationModifyTreeRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 节点 - 备注 - 添加
    async updateRelationBindNodeRemark(data) {
      try {
        const result = await updateRelationBindNodeRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 节点 - 备注 - 修改
    async relationModifyNodeRemark(data) {
      try {
        const result = await relationModifyNodeRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 节点 - 备注 - 删除
    async relationRemoveNodeRemark(data) {
      try {
        const result = await relationRemoveNodeRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 线 - 备注 - 添加
    async updateRelationBindFlowRemark(data) {
      try {
        const result = await updateRelationBindFlowRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 线 - 备注 - 修改
    async relationModifyFlowRemark(data) {
      try {
        const result = await relationModifyFlowRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 线 - 备注 - 删除
    async relationRemoveFlowRemark(data) {
      try {
        const result = await relationRemoveFlowRemark(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 重置重复校验
    async resetReviewResultList() {
      this.reviewResultList = []
    },

    // 添加节点
    async treeSaveNode(data) {
      try {
        const result = await treeSaveNode(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 添加边
    async treeSaveFlow(data) {
      try {
        const result = await treeSaveFlow(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 移除节点
    async treeRemoveNode(data) {
      try {
        const result = await treeRemoveNode(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 移除边
    async treeRemoveFlow(data) {
      try {
        const result = await treeRemoveFlow(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 移动节点
    async treeMoveNode(data) {
      try {
        const result = await treeMoveNode(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树 - 创建 - 申请节点 CODE
    async treeApplyCode() {
      try {
        const result = await treeApplyCode()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 回退
    async treeUndo(data) {
      try {
        const result = await treeUndo(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 撤销回退
    async treeRedo(data) {
      try {
        const result = await treeRedo(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 保存快照
    async treeCommit(data) {
      try {
        const result = await treeCommit(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 节点编辑
    async updateTreeModifyNode(data) {
      try {
        // 扫描flowCode
        const { faultCodeSets } = data
        const len = faultCodeSets.length
        for(let i = 0; i < len; i++) {
          if (!faultCodeSets[i].flowCode) {
            const flowCode = await this.treeApplyCode()
            faultCodeSets[i].flowCode = flowCode
          }
        }
        const result = await updateTreeModifyNode(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 规则系统/配件分组查询
    async getRuleSystemSparePartGroupList(data) {
      try {
        const result = await getRuleSystemSparePartGroupList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    async getRuleSystemSparePartList(data) {
      try {
        const result = await getRuleSystemSparePartList(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取所有的规则结论
    async getAllRuleConClusion() {
      try {
        const result = await getAllRuleConClusion()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树 - 获取操作记录
    async getTreeOperateRecords(data) {
      try {
        const result = await getTreeOperateRecords(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树 - 保存操作记录
    async saveTreeOperateRecord(data) {
      try {
        const result = await saveTreeOperateRecord(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 树 - 操作类型列表
    async getTreeOperateTypes() {
      try {
        const result = await getTreeOperateTypes()
        this.treeRecordTypeList = result
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

  }
})