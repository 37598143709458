<template>
  <!-- 我的决策信息 -->
  <div class="my-tree">
    <div class="my-tree-form">
      <el-form :inline="true" :model="form" label-width="80px" label-position="left">
        <el-row>
          <el-col :span="6">
            <el-form-item label="决策编码:">
              <el-input
                v-model="form.decisionCode"
                placeholder="请输入决策编码"
                maxlength="50"
                style="width: 214px"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="决策状态:">
              <el-select v-model="form.decisionState" clearable filterable placeholder="请选择决策状态">
                <el-option
                  v-for="item in DECISION_STATE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="决策层数:">
              <el-select v-model="form.layers" clearable filterable placeholder="请选择决策层数">
                <el-option
                  v-for="item in DECISION_LAYERS"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="审核人:">
              <el-select v-model="form.reviewer" clearable filterable placeholder="请选择审核人">
                <el-option
                  v-for="item in userAllListAuditor"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>

          <el-col :span="6">
            <el-form-item label="操作时间:">
              <el-date-picker
                v-model="operatingTime"
                type="daterange"
                range-separator="-"
                start-placeholder="开始时间"
                end-placeholder="结束时间"
                class="my-tree-date-picker"
                style="width: 214px"
                value-format="YYYY-MM-DDTHH:mm:ss"
                @change="operatingTimeChange"
              />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-button v-if="isEditor" type="success" @click="create">新增决策</el-button>
          </el-col>
          <el-col :span="12" class="my-tree-form-btns">
            <el-button type="primary" @click="preSearch">查询</el-button>
            <el-button type="warning" @click="reset">重置</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="my-tree-table">
      <TableCmpt :case-list="tableData" :header-list="headerList" />
      <PaginationCmpt :current-page="page" :total="total" @current-change="currentChange" />
    </div>
  </div>
</template>
  
<script setup>
import { ref, unref, reactive, computed, onMounted } from 'vue'
import { useUserStore } from '@/store/user'
import PaginationCmpt from '@/components/PaginationCmpt'
import TableCmpt from '@/components/TableCmpt'
import { DECISION_LAYERS, DECISION_STATE } from '@/utils/constants'
import { useWheelStore } from '@/store/wheelAlignment'
import { useEnumSelectStore } from '@/store/enumSelect'
import { GMTToLocal } from '@/utils'
import { getChangePlanTxt } from '@/views/wheelAlignment/wheelAlignment'
import { userCommon } from '@/views/wheelAlignment/wheelCommon'

const wheelStore = useWheelStore()
const userStore = useUserStore()
const enumStore = useEnumSelectStore()
const { getUserInfo, userAllListAuditor } = userCommon(userStore)

const tableData = ref([])
const page = ref(1)
const size = ref(10)
const total = ref(0)
const userId = computed(() => {
  return userStore?.userInfo?.id || ''
})

const isEditor = computed(() => {
  // 【41编写员】
  return userStore.userInfo?.roleList.some(({ id }) => id === 41)
})

const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'decisionCode',
    label: '决策编码',
    width: '200px'
  },
  {
    prop: 'decisionState',
    label: '决策状态',
    width: '150px',
    formatter(row) {
      const stateName = enumStore.getEnumName2(
        'DECISION_STATE',
        row.decisionState
      )
      return <div>{stateName || '-'}</div>
    }
  },
  {
    prop: 'layers',
    label: '决策层数',
    width: '100px'
  },
  {
    prop: '',
    label: '更换方案',
    align: 'left',
    formatter(row) {
      const changePlanTxt = getChangePlanTxt(row) || []
      console.log('####', changePlanTxt)
      return (
        <div>
          {changePlanTxt.map((item, index) => {
            return (
              <div key={index}>
                第{index + 1}层：{item}
              </div>
            )
          })}
        </div>
      )
    }
  },
  {
    prop: 'reviewerName',
    label: '审核人',
    width: '120px',
    formatter(row) {
      return <div>{getUserInfo(row.reviewer) || '-'}</div>
    }
  },
  {
    prop: 'lastModifyTime',
    label: '最后操作时间',
    width: '165px',
    formatter(row) {
      const datetime = GMTToLocal(row.lastModifyTime)
      return <div>{datetime}</div>
    }
  },
  {
    prop: 'label',
    label: '操作',
    width: '80px',
    formatter(row) {
      return (
        <router-link
          target='_blank'
          to={`/wheelAlignment/decision/info?decisionId=${row.id}`}
        >
          <el-icon style='font-size: 20px; color: #409eff;'>
            <Tickets />
          </el-icon>
        </router-link>
      )
    }
  }
]

const operatingTimeChange = value => {
  if (value) {
    form.beginTime = value[0]
    const endTime = value[1].slice(0, value[1].indexOf('T') + 1)
    form.endTime = endTime + '23:59:59'
  }
}
const operatingTime = ref(null)
const form = reactive({
  decisionCode: null,
  decisionState: null,
  layers: null,
  editor: userId.value, // 编写人，我自己
  reviewer: null,
  beginTime: null,
  endTime: null
})

onMounted(async () => {
  getWheelDecisionList()
})

const preSearch = () => {
  page.value = 1
  total.value = 0
  search()
}
const search = () => {
  getWheelDecisionList()
}
const reset = () => {
  form.decisionCode = null
  form.decisionState = null
  form.layers = null
  form.reviewer = null
  form.beginTime = null
  form.endTime = null
  operatingTime.value = null
}

const currentChange = value => {
  page.value = value
  search()
}
async function getWheelDecisionList() {
  try {
    const result = await wheelStore.getWheelDecisionList({
      pageIndex: unref(page),
      pageSize: unref(size),
      ...form
      // authorId: userStore.userInfo.id
    })

    tableData.value = result.records
    total.value = result.total
  } catch (error) {
    console.error(error)
  }
}

const create = () => {
  window.open(`/wheelAlignment/decision/add`, '_blank')
}
</script>
  
  <style lang="less" scoped>
.my-tree {
  padding: 10px 30px;

  &-form {
    & :deep(.el-input__wrapper) {
      border-radius: 0;
    }

    &-btns {
      text-align: right;
    }
  }

  &-table {
    padding-top: 40px;

    &-pagination {
      margin-top: 30px;
    }

    .icon {
      &-common {
        width: 1.5em;
        height: 1.5em;
        margin: 0 8px;
        cursor: pointer;
        vertical-align: middle;
      }
      &-warning,
      &-connection {
        color: #409eff;
      }
      &-remove {
        color: #f56c6c;
      }
      &-disabled {
        color: #aaa;
        cursor: not-allowed;
      }
    }
  }

  .text-center {
    text-align: center;
  }
}
</style>