<template>
  <div class="home">
    <div class="home-menu">
      <MenuCmpt :menuType="menuType" @toggle="toggle" />
    </div>
    <div :class="['home-content', isCollapse ? 'fold' : 'expand']">
      <StatusBar :title="title" v-model="menuType" />
      <router-view></router-view>
    </div>
  </div>
</template>

<script setup>
import { unref, computed, ref } from 'vue'
import { useRouter } from 'vue-router';
import MenuCmpt from '@/components/MenuCmpt'
import StatusBar from '@/components/StatusBar'
import { useEnumSelectStore } from '@/store/enumSelect';

const router = useRouter()
const enumStore = useEnumSelectStore()
const isCollapse = ref(false)

const title = computed(() => {
  return unref(router.currentRoute).meta?.title
})

const menuType = computed({
  get() {
    return enumStore.menuType
  },
  set(value) {
    enumStore.setMenuType(value)
  }
})

const toggle = val => {
  isCollapse.value = val
}
</script>

<style lang="less" scoped>
.home {
  width: 100vw;
  height: 100vh;
  position: relative;

  &-menu {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 99;
  }

  &-content {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;

    &.fold {
      transition: padding-left .6s;
      padding-left: 65px;
    }

    &.expand {
      transition: padding-left .6s;
      padding-left: 200px;
    }
  }
}
</style>