<template>
  <el-drawer
    v-model="value"
    :with-header="false"
    :close-on-click-modal="false"
    class="tree-info"
  >
    <el-tabs type="border-card" v-model="activeTab">
      <el-tab-pane name="content" label="节点逻辑">
        <div class="tree-info-label">节点类型:</div>
        <div class="tree-info-txt">{{
          GRAPH_NODES_TYPE_NAME[info.nodeType] || '-'
        }}</div>
        <template v-if="info.nodeType !== 'RESULT'">
          <div class="tree-info-label">展示内容:</div>
          <div class="tree-info-txt">{{ info.viewContent || '-' }}</div>
        </template>
        <div class="tree-info-label">故障码集合:</div>
        <div class="tree-info-txt">
          <template v-if="info.faultCodeSets">
            <div v-for="(codes, index) in info.faultCodeSets" :key="index">
              <div>故障码{{ codes.type === 'WITHIN' ? '包含' : '跳转'}}集合:</div>
              <div>
                <router-link
                  target="_blank"
                  v-for="code in codes.faultCodes"
                  :key="code.id"
                  :to="`/faultCode/info?code=${code.faultCode}&id=${code.id}`"
                >{{ code.faultCode }}、</router-link>
              </div>
            </div>
          </template>
          <div v-else>-</div>
        </div>
        <template
          v-if="info.nodeType === 'OUTPUT_OR_PROCESS' || info.nodeType === 'RESULT'"
        >
          <div class="tree-info-label">输出内容:</div>
          <div class="tree-info-txt">{{ info.outputContent || '-' }}</div>
          <template v-if="['CONCLUSION', 'RELEASE'].includes(info.outputType)">
            <div class="tree-info-label">{{
              (info.outputType === 'CONCLUSION') ? '输出结论' : '放行'
            }}:</div>
            <div class="tree-info-txt">{{ outputContent(info) }}</div>
          </template>
        </template>
      </el-tab-pane>
      <el-tab-pane name="remark" label="节点备注">
        <NodeInfoRemarkEdit
          v-if="activeTab === 'remark'"
          :status="remarkStatus"
          :node="node"
        />
      </el-tab-pane>
    </el-tabs>
    <el-button class="tree-info-close" @click="close">关闭</el-button>
  </el-drawer>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  onMounted,
  ref,
  watch
} from 'vue'
import { GRAPH_NODES_TYPE_NAME } from '@/utils/constants'
import { useTreeStore } from '@/store/tree';
import NodeInfoRemarkEdit from './NodeInfoRemarkEdit'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  node: {
    type: Object,
    default: () => ({})
  },
  info: {
    type: Object,
    default: () => ({})
  }
})

const treeStore = useTreeStore()
const outputTemplateList = ref([])
const activeTab = ref('content')
const remarkStatus = ref('show')

const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

watch(value, () => {
  if (value) {
    const {
      treeStatus,
      userInfo,
      reviewerId
    } = props?.node?.data?.treeIds
    activeTab.value = 'content'
    if (
      treeStatus === 30 &&
      reviewerId === userInfo.id
    ) {
      remarkStatus.value = 'edit'
    }
  }
})
const close = () => {
  emits('update:modelValue', false)
}
const outputContent = (info) => {
  const { outputType, parts } = info
  let content = '-'
  if (outputType === 'CONCLUSION') {
    content = compilerTpl(info)
  }
  if (outputType === 'RELEASE') {
    content = parts?.map(
      item => item.name
    ).join(' ')
  }
  if (outputType === 'FORWARD') {
    content = ''
  }
  return content
}

onMounted(() => {
  getAllRuleConClusion()
})

async function getAllRuleConClusion() {
  try {
    const result = await treeStore.getAllRuleConClusion()
    outputTemplateList.value = result
  } catch (error) {
    console.error(error)
  }
}

const compilerTpl = (info) => {
  const tplObject = (outputTemplateList.value).find(
    item => item.index === info.conclusionId
  )
  let index = 0
  return tplObject?.name?.replace(/（）/g, () => {
    const txt = info.conclusionPadValues[index]
    index++
    return txt
  })
}
</script>

<style lang="less" scoped>
.tree-info {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>