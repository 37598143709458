<template>
  <div>
    <el-dialog v-model="value" title="新增备注" :show-close="false" :close-on-click-modal="false">
      <el-form :model="form" :rules="rules" label-width="130px">
        <el-row>
          <el-col :span="12">
            <el-form-item label="悬挂形式:" prop="suspensionType">
              <el-select v-model="form.suspensionType" clearable filterable placeholder="请选择悬挂形式">
                <el-option
                  v-for="item in SUSPENSION_TYPE"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="外倾角是否可调整:" prop="adjustable">
              <el-select v-model="form.adjustable" clearable placeholder="请选择">
                <el-option
                  v-for="item in ADJUSTABLE_MENU"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="受损车轮位置:">
              <el-select
                v-model="form.damagedWheelPosition"
                clearable
                filterable
                placeholder="请选择受损车轮位置"
              >
                <el-option
                  v-for="item in DAMAGED_WHEEL_POSITION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="车轮受力方向:">
              <el-select
                v-model="form.wheelForceDirection"
                clearable
                filterable
                placeholder="请选择车轮受力方向"
              >
                <el-option
                  v-for="item in WHEEL_FORCE_DIRECTION"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item
              v-for="(item, index) in DAMAGE_CATEGORY"
              :key="index"
              :label="item.label + ':'"
            >
              <el-radio-group v-model="form.radio1" class="decision-radio-group">
                <el-radio
                  v-for="(element, i) in ABNORMAL_TYPE"
                  :key="i"
                  :label="element.value"
                  size="large"
                >{{element.label}}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button type="primary" @click="handleSubmit">确定</el-button>
          <el-button @click="handleClose">取消</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, reactive } from 'vue'
// import { useWheelStore } from '@/store/wheelAlignment'
import { ADJUSTABLE_MENU } from '@/utils/constants'
import { useEnumSelectStore } from '@/store/enumSelect'
// import { ElMessage } from 'element-plus'

const enumStore = useEnumSelectStore()
// const wheelStore = useWheelStore()
const SUSPENSION_TYPE = computed(() => {
  return enumStore.SUSPENSION_TYPE || enumStore.getWheelIndexItemList()
})
const DAMAGED_WHEEL_POSITION = computed(() => {
  return (
    enumStore.DAMAGED_WHEEL_POSITION ||
    enumStore.getWheelEnumDamageWheelPosition()
  )
})
const WHEEL_FORCE_DIRECTION = computed(() => {
  return (
    enumStore.WHEEL_FORCE_DIRECTION || enumStore.getWheelEnumForceDirection()
  )
})
const DAMAGE_CATEGORY = computed(() => {
  return enumStore.DAMAGE_CATEGORY || enumStore.getWheelEnumDamagedCategory()
})
const ABNORMAL_TYPE = computed(() => {
  return enumStore.ABNORMAL_TYPE || enumStore.getWheelEnumAbnormalType()
})
const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:w', value)
  }
})

const form = reactive({})

const handleClose = () => {
  emits('update:modelValue', false)
}
const handleSubmit = () => {
  handleClose()
}
</script>

<style lang="less" scoped>
</style>