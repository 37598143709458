<template>
  <div class="operate">
    <div class="operate-tabs">
      <el-button :type="tabIndex === 1 ? 'primary' : ''" round @click="switchTab(1)">品牌</el-button>
      <el-button :type="tabIndex === 2 ? 'primary' : ''" round @click="switchTab(2)">车型</el-button>
      <el-button :type="tabIndex === 3 ? 'primary' : ''" round @click="switchTab(3)">故障码</el-button>
    </div>
    <div class="operate-content">
      <AddBrandCmpt v-if="tabIndex === 1" />
      <AddCarModelCmpt v-if="tabIndex === 2" />
      <AddFaultCodeCmpt v-if="tabIndex === 3" />
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useCarStore } from '@/store/car'
import AddBrandCmpt from './components/AddBrandCmpt'
import AddCarModelCmpt from './components/AddCarModelCmpt'
import AddFaultCodeCmpt from './components/AddFaultCodeCmpt'

const carStore = useCarStore()
const tabIndex = ref(1)

onMounted(() => {
  carStore.getCheckEnumElectronicControlSystem()
})

const switchTab = index => {
  tabIndex.value = index
}
</script>

<style lang="less" scoped>
.operate {
  height: 100%;
  display: flex;
  flex-direction: column;

  &-tabs {
    margin: 10px 20px;
  
    & > :deep(.el-button ) {
      font-size: 12px;
      padding: 0 30px;
    }
  }

  &-content {
    flex: 1;
    display: flex;

    & > div {
      flex: 1;
    }
  }
}
</style>