<template>
  <!-- 需要修改接口数据 -->
  <div>
    <StatusBar :title="brandModelCode" />

    <div class="decision">
      <div class="decision-title">决策索引：</div>
      <div class="decision-content">后倾角：异常数值＜最小值 ； 外倾角：异常数值＞最大值</div>

      <div class="decision-title">更换方案：</div>
      <div class="decision-content">
        <div>第一层</div>
        <div>第一层</div>
        <div>第一层</div>
      </div>

      <div class="decision-title">相同索引查询：</div>
      <div class="decision-content">
        <el-form
          :inline="true"
          :model="form"
          label-width="100px"
          label-position="left"
          class="decision-form"
        >
          <el-row>
            <el-col :span="6">
              <el-form-item label="车轮受力方向:">
                <el-select v-model="form.checker" clearable filterable placeholder="请选择车轮受力方向">
                  <el-option
                    v-for="item in userAllListReviewerOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="损伤类别:">
                <el-select v-model="form.checker" clearable filterable placeholder="请选择损伤类别">
                  <el-option
                    v-for="item in userAllListReviewerOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6">
              <el-form-item label="异常数值状态:">
                <el-select v-model="form.checker" clearable filterable placeholder="请选择异常数值状态">
                  <el-option
                    v-for="item in userAllListReviewerOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="6" class="decision-form-btns">
              <el-button type="primary" @click="search">查询</el-button>
              <el-button type="warning" @click="reset">重置</el-button>
            </el-col>
          </el-row>
        </el-form>
      </div>

      <TableCmpt :case-list="tableData" :header-list="headerList" />
    </div>
  </div>
</template>

<script setup>
import StatusBar from '@/components/StatusBar'
import TableCmpt from '@/components/TableCmpt'
import { computed, ref, reactive } from 'vue'

const brandModelCode = computed(() => {
  return '麦弗逊（单下臂）+是+左前轮+前方位'
})

const form = reactive({
  checked1: true,
  checked2: false,
  checked3: false,
  radio1: '1',
  radio2: '',
  radio3: ''
})
const userAllListReviewerOptions = [{ label: '选项1', value: 1 }]

const tableData = ref([])
const headerList = [
  {
    type: 'index',
    label: '序号',
    width: '60px'
  },
  {
    prop: 'lastModifiedTime',
    label: '车轮受力方向',
    width: '150px',
    formatter(row) {
      const datetime = row.lastModifiedTime
      return <div>{datetime}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '异常状态',
    width: '500px',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '已启用决策编码',
    width: '200px',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'reviewerId',
    label: '更换方案',
    formatter(...args) {
      const checker = args[2]
      console.log(checker)
      return <div>{'-'}</div>
    }
  },
  {
    prop: 'label',
    label: '操作',
    width: '130px',
    formatter(...args) {
      console.log(args)
      return (
        <div class='decision-icon' style='font-size: 20px;'>
          <router-link
            target='_blank'
            to={`/wheelAlignment/index/decision?completeIndexId=${args[0].id}`}
          >
            <Connection class='decision-icon-common decision-icon-connection' />
          </router-link>
          <router-link target='_blank' to={`/wheelAlignment/index/records?id=`}>
            <Warning class='decision-icon-common decision-icon-warning' />
          </router-link>
        </div>
      )
    }
  }
]

const search = () => {
  // const {
  //   systemCode,
  //   physicalTreeCode,
  //   serialNumber,
  //   status,
  //   checker,
  //   date1,
  //   date2
  // } = form
  // getTreePageList({
  //   treeCode: {
  //     systemCode: systemCode,
  //     physicalTreeCode: physicalTreeCode,
  //     serialNumber: serialNumber
  //   },
  //   treeStatus: status,
  //   reviewerId: checker,
  //   modifiedBeginTime: LocalToGMT(date1),
  //   modifiedEndTime: LocalToGMT(date2)
  // })
}
const reset = () => {
  form.systemCode = null
  form.physicalTreeCode = null
  form.serialNumber = null
  form.status = null
  form.checker = null
  form.date1 = null
  form.date2 = null
}

</script>

<style lang="less" scoped>
.decision {
  padding: 20px 40px;

  &-title {
    padding: 20px 0;
    font-weight: bold;
  }
  &-content {
    margin-left: 30px;
  }
  &-form {
    margin-bottom: 30px;
    &-btns{
      text-align: right;
    }
  }
}
/deep/ .decision-icon {
  &-common {
    width: 1em;
    height: 1em;
    margin: 0 7px;
    cursor: pointer;
    vertical-align: -2px;
  }
  &-warning,
  &-connection {
    color: #409eff;
  }
  &-remove {
    color: #f56c6c;
  }
  &-disabled {
    color: #aaa;
    cursor: not-allowed;
  }
}
</style>