<template>
  <div class="node-paralle" @dblclick="select" @contextmenu.prevent="showContextMenu">
    <div :class="['node-paralle-bg', isSelect ? 'select' : '']"></div>
    <div class="node-remark">
      <IconFaultCode v-if="hasFaultCode" />
      <IconRemark v-if="hasRemark" />
    </div>
    <div class="node-paralle-content">
      <div v-if="content">展示内容: {{ content }}</div>
      <div v-if="outputContent">输出内容: {{ outputContent }}</div>
    </div>
    <div v-if="show" class="node-contextmenu">
      <div
        class="node-contextmenu-item"
        v-for="item in menus"
        :key="item.value"
        @mousedown="selectContextMenu(item, node)"
      >
        {{ item.label }}
      </div>
    </div>
  </div>
</template>

<script setup>
import { inject, ref, computed, onMounted, onUnmounted } from 'vue'
import { ElMessage } from 'element-plus'
import IconFaultCode from '@/components/icons/IconFaultCode'
import IconRemark from '@/components/icons/IconRemark'

const getNode = inject('getNode')
const node = getNode()
const show = ref(false)
const isSelect = ref(false)
const menus = [
  {
    value: 'edit',
    label: '编辑',
  },
  {
    value: 'delete',
    label: '删除',
  },
]
node.on('node:select', ({ id }) => {
  if (node.id === id) {
    isSelect.value = true
  } else {
    isSelect.value = false
  }
})
node.on('node:remark', ({ id }) => {
  if (node.id === id) {
    hasRemark.value = node?.data?.nodeInfo?.hasRemark
  }
})
const content = computed(() => {
  const { viewContent } = node?.data?.nodeInfo || {}
  const txt = viewContent
  return txt || ''
})
const outputContent = computed(() => {
  const { outputContent } = node?.data?.nodeInfo || {}
  const txt = outputContent
  return txt || ''
})

const hasFaultCode = ref(node?.data?.nodeInfo?.faultCodeSets?.length > 0)
const hasRemark = ref(node?.data?.nodeInfo?.hassRemark)

const select = () => {
  console.log('查看--self', node.data)
}
const showContextMenu = () => {
  if (node.data.opt === 'edit') {
    show.value = true
  }
}
const hideContextMenu = () => {
  show.value = false
}
const selectContextMenu = (menu, node) => {
  const { func, graph } = node.data
  if (menu.value === 'edit') {
    const { disabledEdit } = node.data.nodeInfo
    if (disabledEdit) {
      ElMessage.warning('该节点禁止编辑')
      return
    }
    func.nodeEdit(node, graph)
  }
  if (menu.value === 'delete') {
    const { disabledDelete } = node.data.nodeInfo
    if (disabledDelete) {
      ElMessage.warning('该节点禁止删除')
      return
    }
    func.nodeDelete(node, graph)
  }
}

onMounted(() => {
  window.addEventListener('mousedown', hideContextMenu)
  window.addEventListener('mousewheel', hideContextMenu)
})
onUnmounted(() => {
  window.removeEventListener('mousedown', hideContextMenu)
  window.removeEventListener('mousewheel', hideContextMenu)
})
</script>

<style lang="less" scoped>
.node-paralle {
  width: 100%;
  height: 100%;
  text-align: center;
  position: relative;

  &-bg {
    width: calc(100% - 70px);
    height: 100%;
    margin: 0 auto;
    transform: skew(-30deg);
    border: 1px solid #a9abaf;

    &.select {
      border: 1px solid #0193df;
    }
  }

  &-content {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 10px 60px;
    word-break: break-all;
  }
}

.node-remark {
  position: absolute;
  top: 5px;
  right: 20px;
  cursor: pointer;
  z-index: 9;

  & > .icon {
    width: 20px;
    height: 20px;
  }
}

.node {
  &-contextmenu {
    position: absolute;
    top: 0;
    right: -70px;
    box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.12);
    padding: 5px 0;
    background-color: white;
    border-radius: 4px;
    font-size: 13px;

    &-item {
      padding: 8px 20px;
      cursor: pointer;

      &:hover {
        background-color: #dedfe0;
      }
    }
  }
}
</style>
