import request from "@/utils/request"

/**
 * =============================
 *           树 - 查询          
 * =============================
 */
// 查看树信息
export function getTreeStructure(data) {
  return request({
    url: '/dlyk-decision/tree/view',
    method: 'post',
    data,
  })
}

// 获取树绑定的故障码
export function getRelationTreeFaultCodes(data) {
  return request({
    url: '/dlyk-decision/relation/treeFaultCodes',
    method: 'post',
    data,
  })
}

// 备注 - 获取树关联备注
export function  getRelationTreeRemarks(data) {
  return request({
    url: '/dlyk-decision/relation/treeRemarks',
    method: 'post',
    data,
  })
}

// 备注 - 获取节点查询备注
export function getRelationNodeRemarks(data) {
  return request({
    url: '/dlyk-decision/relation/nodeRemarks',
    method: 'post',
    data,
  })
}

// 备注 - 获取线查询备注
export function getRelationEdgeRemarks(data) {
  return request({
    url: '/dlyk-decision/relation/flowRemarks',
    method: 'post',
    data,
  })
}


/**
 * =============================
 *           树 - 编辑          
 * =============================
 */
// 查看树结构 - 点击编辑时
export function treeSnapshot(data) {
  return request({
    url: '/dlyk-decision/tree/snapshot',
    method: 'post',
    data,
  })
}

// 树 - 绑定故障码
export function updateRelationBindTreeFaultCode(data) {
  return request({
    url: '/dlyk-decision/relation/bindTreeFaultCode',
    method: 'post',
    data,
  })
}

// 树 - 备注 - 添加
export function updateRelationBindTreeRemark(data) {
  return request({
    url: '/dlyk-decision/relation/bindTreeRemark',
    method: 'post',
    data,
  })
}

// 树 - 备注 - 删除
export function relationRemoveTreeRemark(data) {
  return request({
    url: '/dlyk-decision/relation/removeTreeRemark',
    method: 'post',
    data,
  })
}

// 树 - 备注 - 修改
export function relationModifyTreeRemark(data) {
  return request({
    url: '/dlyk-decision/relation/modifyTreeRemark',
    method: 'post',
    data,
  })
}

// 节点 - 备注 - 添加
export function updateRelationBindNodeRemark(data) {
  return request({
    url: '/dlyk-decision/relation/bindNodeRemark',
    method: 'post',
    data,
  })
}

// 节点 - 备注 - 修改
export function relationModifyNodeRemark(data) {
  return request({
    url: '/dlyk-decision/relation/modifyNodeRemark',
    method: 'post',
    data,
  })
}

// 节点 - 备注 - 删除
export function relationRemoveNodeRemark(data) {
  return request({
    url: '/dlyk-decision/relation/removeNodeRemark',
    method: 'post',
    data,
  })
}

// 线 - 备注 - 添加
export function updateRelationBindFlowRemark(data) {
  return request({
    url: '/dlyk-decision/relation/bindFlowRemark',
    method: 'post',
    data,
  })
}

// 线 - 备注 - 修改
export function relationModifyFlowRemark(data) {
  return request({
    url: '/dlyk-decision/relation/modifyFlowRemark',
    method: 'post',
    data,
  })
}

// 线 - 备注 - 删除
export function relationRemoveFlowRemark(data) {
  return request({
    url: '/dlyk-decision/relation/removeFlowRemark',
    method: 'post',
    data,
  })
}

// 线 - 内容
export function updateTreeModifyFlow(data) {
  return request({
    url: '/dlyk-decision/tree/modifyFlow',
    method: 'post',
    data,
  })
}

// 节点 - 编辑
export function updateTreeModifyNode(data) {
  return request({
    url: '/dlyk-decision/tree/modifyNode',
    method: 'post',
    data,
  })
}


/**
 * =============================
 *           树 - 添加          
 * =============================
 */
// 节点 - 添加
export function treeSaveNode(data) {
  return request({
    url: '/dlyk-decision/tree/saveNode',
    method: 'post',
    data,
  })
}

// 边 - 添加
export function treeSaveFlow(data) {
  return request({
    url: '/dlyk-decision/tree/saveFlow',
    method: 'post',
    data,
  })
}


/**
 * =============================
 *           树 - 删除          
 * =============================
 */
// 节点 - 删除
export function treeRemoveNode(data) {
  return request({
    url: '/dlyk-decision/tree/removeNode',
    method: 'post',
    data,
  })
}

// 边 - 删除
export function treeRemoveFlow(data) {
  return request({
    url: '/dlyk-decision/tree/removeFlow',
    method: 'post',
    data,
  })
}


/**
 * =============================
 *           树 - 移动          
 * =============================
 */
// 节点 - 移动
export function treeMoveNode(data) {
  return request({
    url: '/dlyk-decision/tree/moveNode',
    method: 'post',
    data,
  })
}


/**
 * =============================
 *           树 - 操作          
 * =============================
 */
// 树 - 创建/拷贝
export function saveTree(data) {
  return request({
    url: '/dlyk-decision/tree/save',
    method: 'post',
    data,
  })
}

// 树 - 删除
export function treeRemove(data) {
  return request({
    url: '/dlyk-decision/tree/remove',
    method: 'post',
    data,
  })
}

// 树 - 回退
export function treeUndo(data) {
  return request({
    url: '/dlyk-decision/tree/undo',
    method: 'post',
    data,
  })
}

// 树 - 撤回回退
export function treeRedo(data) {
  return request({
    url: '/dlyk-decision/tree/redo',
    method: 'post',
    data,
  })
}

// 树 - 保存快照
export function treeCommit(data) {
  return request({
    url: '/dlyk-decision/tree/commit',
    method: 'post',
    data,
  })
}

// 树 - 审核
export function treeReview(data) {
  return request({
    url: '/dlyk-decision/tree/review',
    method: 'post',
    data,
  })
}

// 树 - 验证
export function treeVerify(data) {
  return request({
    url: '/dlyk-decision/tree/verify',
    method: 'post',
    data,
  })
}

// 树 - 转交
export function treeForward(data) {
  return request({
    url: '/dlyk-decision/tree/forward',
    method: 'post',
    data,
  })
}

// 树 - 分段校验
export function treeRepeatVerify(data) {
  return request({
    url: '/dlyk-decision/tree/repeatVerify',
    method: 'post',
    data,
  })
}

// 树 - 提交
export function treeSubmit(data) {
  return request({
    url: '/dlyk-decision/tree/submit',
    method: 'post',
    data,
  })
}


/**
 * =============================
 *           树 - 列表          
 * =============================
 */
// 获取逻辑树列表【分页】
export function getTreePageList(data) {
  return request({
    url: '/dlyk-decision/tree/page',
    method: 'post',
    data,
  })
}

// 获取所有物理编码
export function getPhysicalTreeCodes() {
  return request({
    url: '/dlyk-decision/tree/physicalCodes',
    method: 'post',
  })
}


/**
 * =============================
 *           树 - 其他          
 * =============================
 */
// 获取故障码信息 - 创建树时使用
export function getRelationListForCreateTree(data) {
  return request({
    url: '/dlyk-decision/relation/listForCreateTree',
    method: 'post',
    data,
  })
}

// 获取树对应的故障码列表
export function getRelationTreeFaultCodeOptions(data) {
  return request({
    url: '/dlyk-decision/relation/treeFaultCodeOptions',
    method: 'post',
    data,
  })
}

// 树 - 创建 - 申请节点Node
export function treeApplyCode() {
  return request({
    url: '/dlyk-decision/tree/applyCode',
    method: 'get',
  })
}

// 获取所有的规则结论
export function getAllRuleConClusion() {
  return request({
    url: '/check/enum/rule-conclusion',
    method: 'get',
    params: {
      keyword: 'GZM'
    }
  })
}

// 树 - 获取操作记录
export function getTreeOperateRecords(data) {
  return request({
    url: '/dlyk-decision/tree/operateRecords',
    method: 'post',
    data,
  })
}

// 树 - 保存操作记录
export function saveTreeOperateRecord(data) {
  return request({
    url: '/dlyk-decision/tree/saveOperateRecord',
    method: 'post',
    data,
  })
}

// 树 - 操作类型列表
export function getTreeOperateTypes() {
  return request({
    url: '/dlyk-decision/tree/operateTypes',
    method: 'get',
  })
}
