<template>
  <svg
    t="1677835064221"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="3787"
    width="200"
    height="200"
  >
    <path
      d="M874.666667 853.333333v63.957334A64.064 64.064 0 0 1 810.666667 981.333333c-35.349333 0-64-28.565333-64-64.042666V853.333333H277.333333v63.957334A64.064 64.064 0 0 1 213.333333 981.333333c-35.349333 0-64-28.565333-64-64.042666V853.333333c-58.816-0.085333-106.666667-47.893333-106.666666-106.517333V490.517333c0-37.013333 18.88-69.568 47.573333-88.661333l66.133333-264.661333C169.728 83.904 222.506667 42.666667 277.269333 42.666667h469.504c54.869333 0 107.541333 41.258667 120.853334 94.528l66.197333 264.789333A106.453333 106.453333 0 0 1 981.333333 490.517333v256.298667A106.602667 106.602667 0 0 1 874.666667 853.333333zM784.832 157.888C780.992 142.549333 762.432 128 746.752 128H277.248c-15.573333 0-34.24 14.592-38.08 29.888L182.634667 384h658.709333l-56.533333-226.112zM128 490.517333v256.298667c0 11.52 9.685333 21.184 21.482667 21.184h725.034666A21.269333 21.269333 0 0 0 896 746.816V490.517333c0-11.52-9.685333-21.184-21.482667-21.184H149.482667A21.269333 21.269333 0 0 0 128 490.517333zM277.333333 682.666667a64 64 0 1 1 0-128 64 64 0 0 1 0 128z m469.333334 0a64 64 0 1 1 0-128 64 64 0 0 1 0 128z"
      p-id="3788"
    ></path>
  </svg>
</template>
