import { reactive, ref } from "vue"
import { EDGE_DEFAULT_DATA } from '@/utils/constants'
import { merge } from "lodash"

export function useEdgeEdit({ treeStore, treeIds }) {
  const edgeInfo = reactive({})
  const edgeDrawer = ref(false)

  async function getRelationEdgeRemarks(flowCode) {
    try {
      const result = await treeStore.getRelationEdgeRemarks({ flowCode })
      Object.assign(edgeInfo, {
        remarks: result,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async function treeRemoveFlow(edge) {
    try {
      const { treeId, snapshotId, userInfo } = treeIds
      await treeStore.treeRemoveFlow({
        treeId,
        authorId: userInfo.id,
        snapshotId,
        flowCode: edge.id,
      })
      edge.remove()
    } catch (error) {
      console.error(error)
    }
  }

  async function treeSaveFlow(edge, graph) {
    try {
      const { treeId, snapshotId, userInfo } = treeIds
      const { source, target } = edge
      const result = await treeStore.treeSaveFlow({
        treeId,
        authorId: userInfo.id,
        snapshotId,
        fromNodeCode: source.cell,
        toNodeCode: target.cell,
      })
      edge.attr('line/stroke', '#C2C8D5')
      edge.setData({
        self: graph,
        opt: graph.opt,
        treeIds: graph.treeIds,
        edge: merge({}, EDGE_DEFAULT_DATA.edge, {
          flowCode: result,
          fromNodeCode: source.cell,
          toNodeCode: target.cell,
        }),
        edgeInfo: merge({}, EDGE_DEFAULT_DATA.edgeInfo, {
          flowCode: result,
        })
      })
      graph.graph.updateCellId(edge, result)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    edgeInfo,
    edgeDrawer,
    getRelationEdgeRemarks,
    treeRemoveFlow,
    treeSaveFlow,
  }
}