import dayjs from 'dayjs'
var utc = require('dayjs/plugin/utc')
dayjs.extend(utc)

export function treeCodeToString(treeCode) {
  if (!treeCode) return '-'
  const { physicalTreeCode, serialNumber, systemCode } = treeCode
  return `${systemCode}${physicalTreeCode}${serialNumber}`
}

export function GMTToLocal(datetime) {
  if (!datetime) return null
  return dayjs(new Date(datetime)).format('YYYY-MM-DD HH:mm:ss')
}

export function LocalToGMT(datetime) {
  if (!datetime) return null
  return dayjs(datetime).utc().format().replace('+00:00', 'Z')
}