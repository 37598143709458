<template>
  <div class="car-model">
    <div class="car-model-head">
      <div class="car-model-head-label">
        <span>*</span>所属品牌:
      </div>
      <el-select
        v-model="brandValue"
        clearable
        @change="selectBrand"
        placeholder="请选择"
      >
        <el-option
          v-for="item in brands"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="car-model-content">
      <div class="car-model-form">
        <div class="car-model-label">新增车型:</div>
        <el-form :model="form">
          <el-form-item label="车型名称:" :rules="[{ required: true }]">
            <el-input
              style="width:200px;"
              maxlength="50"
              v-model="form.carModelName"
              placeholder="请输入"
            />
          </el-form-item>
        </el-form>
        <div class="car-model-mark">*车型名称一经增加后不可更改</div>
        <div class="car-model-btns">
          <el-button type="primary" @click="add">新增</el-button>
        </div>
      </div>
      <div class="car-model-list">
        <div class="car-model-label">已有车型:</div>
        <ListCmpt :list="carModels" @select="disabledHandle" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, reactive, onMounted, unref } from 'vue'
import { useCarStore } from '@/store/car'
import ListCmpt from './ListCmpt'
import { ElMessage } from 'element-plus'

const carStore = useCarStore()
const form = reactive({ carModelName: '' })
const brandValue = ref('')
const carModels = ref([])
const brands = ref([])

onMounted(() => {
  getCarBrandList()
})

const selectBrand = value => {
  getCarModelList(value)
}
const add = () => {
  saveCarModel()
}
const disabledHandle = item => {
  updateCarModelVisible({
    id: item.value,
    status: item.disabled == 1 ? 0 : 1, // 0: 显示 1: 隐藏
  })
}
async function getCarBrandList() {
  try {
    const list = await carStore.getCarBrandOptions()
    brands.value = list.map(item => ({
      value: item.key,
      label: item.value,
      original: item,
    }))
  } catch (error) {
    console.error(error)
  }
}
async function saveCarModel() {
  try {
    const { carModelName } = form
    const result = await carStore.saveCarModel({
      carBrandId: unref(brandValue),
      name: carModelName
    })
    carModels.value.push({
      id: result,
      value: result,
      label: carModelName
    })
    ElMessage.success('添加成功')
  } catch (error) {
    ElMessage.error(error)
  }
}
async function getCarModelList(carBrandId) {
  try {
    const list = await carStore.getCarModelList({ carBrandId })
    carModels.value = list.map(item => ({
      value: item.id,
      label: item.name,
      disabled: item.invisible,
      original: item,
    }))
  } catch (error) {
    console.error(error)
  }
}
async function updateCarModelVisible(data) {
  try {
    const result = await carStore.updateCarModelVisible(data)
    carModels.value.forEach(item => {
      if (item.value === result.id) {
        item.disabled = result.status
      }
    })
    ElMessage.success('操作成功')
  } catch (error) {
    console.error(error)
  }
}
</script>

<style lang="less" scoped>
.car-model {
  display: flex;
  flex-direction: column;

  &-head {
    display: flex;
    align-items: center;
    padding: 10px 20px;

    &-label {
      font-size: 14px;
      color: #606266;
      margin-right: 10px;

      & > span {
        color: red;
      }
    }
  }

  &-content {
    flex: 1;
    display: flex;

    & > div {
      flex: 1;
      padding: 10px 20px;
    }
  }

  &-form {
    border-right: 1px solid #dcdfe6;
  }
  
  &-list {
    border-left: 1px solid #dcdfe6;
  }

  &-label {
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 20px;
  }

  &-mark {
    font-size: 12px;
    color: red;
  }

  &-btns {
    display: flex;
    justify-content: flex-end;
    margin-top: 20px;
  }
}
</style>