<template>
  <div>
    <!-- 编辑 证据/备注 -->
    <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false" class="evidence">
      <div class="evidence-title">配件名称:</div>
      <div class="evidence-label">备注内容:</div>
      <div class="evidence-content">
        <el-input v-model="remark" type="textarea" rows="4" placeholder></el-input>
      </div>

      <el-form-item label="上传附件:">
        <el-upload
          :file-list="form.fileList"
          multiple
          :on-change="handleChange"
          :limit="9"
          :auto-upload="false"
          accept=".jpg, .png, .xls, .xlsx, .doc, .docx, .pdf, .mp4, .mov, .avi, .rmvb"
        >
          <el-button type="primary">点击上传</el-button>
          <template #tip>
            <div class="evidence-tip">
              <p>最多只能上传9个文件,且文件最大不能超过50MB</p>
              <p>(上传文件格式能且只能为: .jpg/.png/.xls/.xlsx/.doc/.docx/.pdf/.mp4/.mov/.avi/.rmvb)</p>
            </div>
          </template>
        </el-upload>
      </el-form-item>

      <el-button class="evidence-close" @click="close">关闭</el-button>
    </el-drawer>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, reactive } from 'vue'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  isEdit: Boolean,
  info: {
    type: Object,
    default: () => ({})
  }
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const form = reactive({
  fileList: [
    {
      name: 'favicon.ico',
      percentage: 0,
      status: 'ready',
      size: 9662,
      raw: {
        uid: 1678082927750
      },
      uid: 1678082927750
    },
    {
      name: 'favicon.ico',
      percentage: 0,
      status: 'ready',
      size: 9662,
      raw: {
        uid: 1678082927750
      },
      uid: 1678082927750
    }
  ]
})
const handleChange = (file, uploadFiles) => {
  form.fileList = uploadFiles
}

const close = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="less" scoped>
.evidence {
  &-title {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  &-label {
    font-size: 14px;
    margin: 20px 0 10px;
    color: #606266;
  }
  &-content {
    margin: 10px 20px 20px;
  }
  &-tip {
    margin-top: 20px;
    font-size: 12px;
  }

  &-txt {
    font-size: 14px;
    margin: 5px 0;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
/deep/ .el-form-item__label{
  font-size: 14px;
}
</style>