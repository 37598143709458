<template>
  <el-drawer
    v-model="value"
    :with-header="false"
    :close-on-click-modal="false"
  >
    <div class="tree-info-label">操作记录:</div>
    <div
      class="tree-info-txt"
      v-for="item in treeRecordList"
      :key="item.id"
    >
      {{ `${item.operatorName}于${item.operateTime}进行了${item.operateTypeName} ${item.content}` }}
    </div>

    <div class="tree-info-close">
      <el-button @click="close">关闭</el-button>
    </div>
  </el-drawer>
</template>

<script setup>
import { defineProps, defineEmits, computed } from 'vue'
import { useTreeStore } from '@/store/tree'
import { useUserStore } from '@/store/user'
import { GMTToLocal } from '@/utils'

const emits = defineEmits(['update:modelValue'])
const props = defineProps({
  modelValue: Boolean,
  records: Array,
})
const userStore = useUserStore()
const treeStore = useTreeStore()
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})
const treeRecordList = computed(() => {
  const { userAllList } = userStore
  const { treeRecordTypeList } = treeStore
  return props.records?.map(item => {
    const user = userAllList.find(
      userInfo => userInfo.id ===item.operatorId
    )
    const type = treeRecordTypeList.find(
      typeInfo => typeInfo.key === item.operateType
    )

    return {
      ...item,
      operatorName: user.userName,
      operateTypeName: type.value,
      content: item.content,
      operateTime: GMTToLocal(item.operateTime),
    }
  })
})

const close = () => {
  emits('update:modelValue', false)
}
</script>

<style lang="less" scoped>
.tree-info {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 12px;
    margin: 5px 0;
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>