import { ElMessage } from 'element-plus'
import { useRouter } from 'vue-router'

export function useToolBar({ treeStore, treeIds, resetGraph }) {
  const router = useRouter()
  const toolBarAction = (data) => {
    switch(data.type) {
      case 'save':
        treeCommit(data.duration)
        break
      case 'redo':
        treeRedo()
        break
      case 'undo':
        treeUndo()
        break
    }
  }

  const graphZoom = (level, graph) => {
    graph.zoom(level/100)
  }

  async function treeCommit(duration) {
    try {
      const { treeId, userInfo, snapshotId } = treeIds
      await treeStore.treeCommit({
        treeId,
        authorId: userInfo.id,
        snapshotId,
        duration, // 编辑持续时间  秒
      })
      router.push({
        path: '/tree/show',
        query: {
          id: treeId
        }
      })
    } catch (error) {
      ElMessage.error(error)
      console.error(error)
    }
  }
  async function treeRedo() {
    try {
      const { treeId, userInfo, snapshotId } = treeIds
      const result = await treeStore.treeRedo({
        treeId,
        authorId: userInfo.id,
        snapshotId,
      })
      resetGraph(result)
    } catch (error) {
      console.error(error)
    }
  }
  async function treeUndo() {
    try {
      const { treeId, userInfo, snapshotId } = treeIds
      const result = await treeStore.treeUndo({
        treeId,
        authorId: userInfo.id,
        snapshotId,
      })
      resetGraph(result)
    } catch (error) {
      console.error(error)
    }
  }

  return {
    toolBarAction,
    graphZoom,
  }
}