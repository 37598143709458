<template>
  <svg
    t="1684288363020"
    class="icon"
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    p-id="1767"
    width="200"
    height="200"
  >
    <path
      d="M864 640h-277.333333c-17.066667 0-32 14.933333-32 32V725.333333H298.666667c-10.666667 0-21.333333-2.133333-32-6.4s-19.2-10.666667-27.733334-19.2-14.933333-17.066667-19.2-27.733333-6.4-21.333333-6.4-32 2.133333-21.333333 6.4-32 10.666667-19.2 19.2-27.733333 17.066667-14.933333 27.733334-19.2 21.333333-6.4 32-6.4h170.666666v10.666666c0 17.066667 14.933333 32 32 32h21.333334c17.066667 0 32-14.933333 32-32V554.666667h170.666666c44.8 0 89.6-17.066667 121.6-49.066667 32-32 49.066667-74.666667 49.066667-121.6s-17.066667-89.6-49.066667-121.6C814.933333 230.4 770.133333 213.333333 725.333333 213.333333H469.333333V160c0-17.066667-14.933333-32-32-32h-277.333333C142.933333 128 128 142.933333 128 160v192c0 17.066667 14.933333 32 32 32h277.333333c17.066667 0 32-14.933333 32-32V298.666667h256c23.466667 0 44.8 8.533333 59.733334 25.6 17.066667 17.066667 25.6 38.4 25.6 59.733333s-8.533333 44.8-25.6 59.733333c-14.933333 17.066667-36.266667 25.6-59.733334 25.6h-170.666666v-10.666666c0-17.066667-14.933333-32-32-32h-21.333334c-17.066667 0-32 14.933333-32 32v10.666666h-170.666666c-23.466667 0-44.8 4.266667-66.133334 12.8-21.333333 8.533333-40.533333 21.333333-55.466666 36.266667-14.933333 17.066667-27.733333 36.266667-36.266667 55.466667-8.533333 21.333333-12.8 42.666667-12.8 66.133333s4.266667 44.8 12.8 66.133333c8.533333 21.333333 21.333333 40.533333 36.266667 55.466667s34.133333 27.733333 55.466666 36.266667c21.333333 8.533333 42.666667 12.8 66.133334 12.8h256v53.333333c0 17.066667 14.933333 32 32 32h277.333333c17.066667 0 32-14.933333 32-32v-192c0-17.066667-14.933333-32-32-32zM384 298.666667h-170.666667v-85.333334h170.666667v85.333334z m426.666667 512h-170.666667v-85.333334h170.666667v85.333334z"
      fill="#ffffff"
      p-id="1768"
    />
  </svg>
</template>
