import axios from 'axios'
import { ElMessage } from 'element-plus'
import { useUserStore } from '@/store/user'

const request = axios.create({
  // baseURL: 'https://mock.apifox.cn/m1/1852701-0-default/',
  timeout: 5000
})
let userStore = null
let timer = null
function updateToken() {
  const diffTime = new Date() - new Date(userStore.tokenDateTime)
  if (diffTime >= 1000 * 60 * 17) {
    timer && clearTimeout(timer)
    timer = setTimeout(() => {
      userStore.resetTokenDateTime()
    }, 1000)
  }
}

request.interceptors.request.use(
  async(config) => {
    if (!userStore) {
      userStore = useUserStore()
    }
    config.headers['Authorization'] = 'Bearer ' + userStore.token;
    config.headers['userId'] = userStore?.userInfo?.id;

    // 校验token时效
    if (userStore.token && userStore.tokenDateTime) {
      updateToken()
    }

    return config
  },
  error => Promise.reject(error)
)

request.interceptors.response.use(
  async(response) => {
    const { data } = response

    if (data.code === 0) {
      return data.data
    }

    ElMessage.error(data.data.message)

    return Promise.reject(data.message)
  },
  error => {
    const { response } = error

    ElMessage.error(error.message)

    // 401
    if (response.status === 401) {
      userStore.reset()
      location.href = '/'
    }

    return Promise.reject(error)
  }
)

export default request
