import { ref, unref, onBeforeMount } from 'vue'

export function useFaultCode({ treeStore, treeIds }) {
  const treeRelationCodes = ref([])

  const addFaultCode = (data) => {
    treeRelationCodes.value.push(data)
  }
  const deleteFaultCode = (data) => {
    treeRelationCodes.value = unref(treeRelationCodes).filter(
      item => item.id !== data.id
    )
  }
  const faultCodeHandler = (data) => {
    const { type } = data
    if (type === 'add') {
      addFaultCode(data)
    }
    if (type === 'delete') {
      deleteFaultCode(data)
    }
  }

  async function getRelationTreeFaultCodes() {
    try {
      const result = await treeStore.getRelationTreeFaultCodes({
        treeId: treeIds.treeId
      })
      treeRelationCodes.value = result
    } catch (error) {
      console.error(error)
    }
  }

  onBeforeMount(() => {
    getRelationTreeFaultCodes()
  })

  return {
    treeRelationCodes,
    faultCodeHandler,
  }
}