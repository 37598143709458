import { computed, unref } from 'vue'
import { useUserStore } from '@/store/user'

export function useUserInfo() {
  const userStore = useUserStore()
  const userAllList = computed(() => {
    return (userStore.userAllList || []).filter(({ inside }) => inside)
  })
  const userAllListOptions = computed(() => {
    return (userStore.userAllList || []).filter(
      ({ inside }) => inside).map(
        item => ({
          label: item.userName,
          value: item.id,
          original: item
        })
      )
  })
  const getTargetUserInfo = id => (unref(userAllList).find(item => item.id ===  id) || {})
  return {
    getTargetUserInfo,
    userAllListOptions,
  }
}