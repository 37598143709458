<template>
  <div>
    <div class="edge-edit-remark-header">
      <div>线备注</div>
      <el-button
        v-if="status === 'edit'"
        type="success"
        @click="addRemark"
      >新增备注</el-button>
    </div>
    <TreeRemark
      v-for="(item, index) in remarks"
      :key="index"
      :remark="item"
      :title="`备注${index + 1}`"
    >
      <template v-if="status === 'edit'">
        <el-icon class="remark-icon" @click="editRemark(item)"><Edit /></el-icon>
        <el-icon class="remark-icon" @click="deleteRemark(item)"><Delete /></el-icon>
      </template>
    </TreeRemark>
    <DialogRemark
      v-model="isShow"
      :remark="targetRemark"
      @confirm="remarkConfirm"
    />
  </div>
</template>

<script setup>
import {
  defineProps,
  ref,
  unref,
  reactive,
  onMounted,
  defineExpose
} from 'vue'
import TreeRemark from './TreeRemark'
import DialogRemark from './DialogRemark'
import { ElMessage, ElMessageBox } from 'element-plus'
import { merge } from 'lodash';
import { useTreeStore } from '@/store/tree'

const props = defineProps({
  status: String,
  edge: {
    type: Object,
    default: () => ({})
  }
})

const treeStore = useTreeStore()
const isShow = ref(false)
const remarks = ref([])
const targetRemark = reactive({
  opt: 'add',
  remark: {},
  treeId: null,
})

onMounted(() => {
  getRelationEdgeRemarks()
})

const addRemark = () => {
  const { treeIds } = props.edge.data
  Object.assign(targetRemark, {
    opt: 'add',
    remark: {},
    treeId: treeIds.treeId
  })
  isShow.value = true
}
const editRemark = (item) => {
  const { treeIds } = props.edge.data
  merge(targetRemark, {
    opt: 'edit',
    remark: item,
    treeId: treeIds.treeId,
  })
  isShow.value = true
}
const deleteRemark = (item) => {
  ElMessageBox.confirm(
    '是否删除备注?',
    '警告',
    {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning',
    }
  ).then(() => {
    relationRemoveFlowRemark(item.id)
  }).catch(() => {})
}
const remarkConfirm = (data) => {
  const { content, faultCode, fileList } = data
  const { edge } = props
  const { treeIds } = edge.data
  const { remark, opt } = targetRemark
  if (opt === 'add') {
    updateRelationBindFlowRemark({
      treeId: treeIds.treeId,
      flowCode: edge.id,
      remark: content,
      faultCodes: faultCode.map(
        code => ({ id: code.key, faultCode: code.faultCode })
      ),
      files: fileList,
    })
  }
  if(opt === 'edit') {
    relationModifyFlowRemark({
      id: remark.id,
      remark: content,
      faultCodes: faultCode.map(
        code => ({ id: code.key, faultCode: code.faultCode })
      ),
      files: fileList,
    })
  }
}
async function getRelationEdgeRemarks() {
  try {
    const { edge }= props
    const result = await treeStore.getRelationEdgeRemarks({
      flowCode: edge.id
    })
    remarks.value = result
  } catch (error) {
    console.error(error)
  }
}
async function updateRelationBindFlowRemark(data) {
  try {
    const result = await treeStore.updateRelationBindFlowRemark(data)
    remarks.value.push({
      ...data,
      id: result,
    })
    ElMessage.success('添加成功')
  } catch (error) {
    console.error(error)
    ElMessage.error('添加失败')
  }
}
async function relationModifyFlowRemark(data) {
  try {
    await treeStore.relationModifyFlowRemark(data)
    remarks.value.forEach(remark => {
      if (remark.id === data.id) {
        Object.assign(remark, data)
      }
    })
    ElMessage.success('修改成功')
  } catch (error) {
    console.error(error)
    ElMessage.error('修改失败')
  }
}
async function relationRemoveFlowRemark(id) {
  try {
    await treeStore.relationRemoveFlowRemark({ id })
    remarks.value = unref(remarks).filter(item => item.id !== id)
    ElMessage.success('删除成功')
  } catch (error) {
    console.error(error)
    ElMessage.success('删除失败')
  }
}

defineExpose({
  getRelationEdgeRemarks
})
</script>

<style lang="less" scoped>
.edge-edit {
  &-remark-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
  }
}

.remark-icon {
  margin-left: 5px;
}
</style>