import { defineStore } from "pinia"
import {
  getAuthCode,
  login,
  logout,
  updateAuth,
  updatePassword,
  updateUserInfo,
  getUserAllInfo,
  getOSSSTSAuth,
  getEnumVehicleType,
} from '@/api/user'
import md5 from 'js-md5';
let bcrypt = require('bcryptjs');

export const useUserStore = defineStore('user', {
  persist: true,
  state: () => {
    return {
      token: null,
      tokenDateTime: null,
      userInfo: null,
      userAllList: null,
    }
  },
  actions: {
    async reset() {
      this.token = null
      this.tokenDateTime = null
      this.userInfo = null
      this.userAllList = null
    },

    async resetTokenDateTime() {
      this.tokenDateTime = null
      this.updateAuth()
    },

    // 获取验证码
    async getAuthCode(username) {
      try {
        const code = await getAuthCode(username)
        return code
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 登陆
    async login(data) {
      try {
        const md5Pass = md5(data.password).toUpperCase();
        const password = bcrypt.hashSync(md5Pass, bcrypt.genSaltSync(10));
        const result = await login({
          ...data,
          password,
        })
        this.token = result.jwtToken
        this.tokenDateTime = new Date()
        this.userInfo = result.user
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 退出登陆
    async logout(flag = 12) {
      try {
        const { loginName, userName } = this.userInfo
        const result = await logout(flag, {
          user: {
            loginName,
            userName,
          }
        })
        this.reset()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 更新Token
    async updateAuth() {
      try {
        const result = await updateAuth({
          Authorization: this.token
        })
        this.token = result.jwtToken
        this.tokenDateTime = new Date()
        this.userInfo = result.user
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 修改密码
    async updatePassword(data) {
      try {
        const result = await updatePassword(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 修改用户信息
    async updateUserInfo(data) {
      try {
        const result = await updateUserInfo(data)
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取所有用户的信息
    async getUserAllInfo() {
      try {
        const result = await getUserAllInfo()
        this.userAllList = result
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取OSS上传STS授权
    async getOSSSTSAuth() {
      try {
        const result = await getOSSSTSAuth()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取字典
    async getEnumVehicleType() {
      try {
        const result = await getEnumVehicleType()
        return result
      } catch (error) {
        return Promise.reject(error)
      }
    },

    // 获取ID对应用户信息
    async getTargetUserInfo(id) {
      return this.userAllList?.find(user => user.id === id)
    },
  }
})