<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<style lang="less">
html,
body {
  padding: 0;
  margin: 0;
}
</style>
