import { reactive, ref } from 'vue'
import { NODE_DEFAULT_DATA } from '@/utils/constants'
import { merge } from 'lodash'

export function useNodeEdit({ treeStore, treeIds }) {
  const nodeInfo = reactive({})
  const nodeDrawer = ref(false)

  const deleteCells = (graph, list) => {
    for (let i = 0; i < list.length; i++) {
      const cell = list[i]
      if (cell.type === 'node') {
        graph.removeNode(cell.id)
      } else {
        graph.removeEdge(cell.id)
      }
    }
  }

  async function getRelationNodeRemarks(nodeCode) {
    try {
      const result = await treeStore.getRelationNodeRemarks({
        nodeCode,
      })
      Object.assign(nodeInfo, {
        remarks: result,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async function treeRemoveNode(node) {
    try {
      const { treeId, snapshotId, userInfo } = treeIds
      const result = await treeStore.treeRemoveNode({
        treeId,
        snapshotId,
        authorId: userInfo.id,
        nodeCode: node.id,
      })
      const graph = node.data.graph
      node.remove()

      const { removeFlows = [], removeNodes = [] } = result
      const cells = [
        ...removeFlows.map((id) => ({ id, type: 'edge' })),
        ...removeNodes.map((id) => ({ id, type: 'node' })),
      ]
      deleteCells(graph, cells)
    } catch (error) {
      console.error(error)
    }
  }

  async function treeMoveNode({ location, nodeCode }) {
    try {
      const { treeId, snapshotId, userInfo } = treeIds
      await treeStore.treeMoveNode({
        treeId,
        snapshotId,
        authorId: userInfo.id,
        nodeCode,
        location,
      })
    } catch (error) {
      console.error(error)
    }
  }

  async function treeSaveNode({ location, nodeType, node, graph }) {
    try {
      const { treeId, snapshotId, userInfo } = treeIds
      const result = await treeStore.treeSaveNode({
        treeId,
        snapshotId,
        authorId: userInfo.id,
        location,
        nodeType,
      })
      node.setData({
        self: graph,
        opt: graph.opt,
        treeIds: graph.treeIds,
        node: merge({}, NODE_DEFAULT_DATA.node, {
          nodeCode: result,
          location,
        }),
        nodeInfo: merge({}, NODE_DEFAULT_DATA.nodeInfo, {
          nodeCode: result,
          nodeType,
        }),
      })
      if (nodeType !== 'RESULT') {
        node.addPort({
          id: `${result}-port`,
          group: 'bottom',
        })
      }
      graph.graph.updateCellId(node, result)
      return result
    } catch (error) {
      console.error(error)
    }
  }

  return {
    nodeInfo,
    nodeDrawer,
    getRelationNodeRemarks,
    treeRemoveNode,
    treeMoveNode,
    treeSaveNode,
  }
}
