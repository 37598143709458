<template>
  <el-drawer v-model="value" :with-header="false" :close-on-click-modal="false">
    <div class="tree-info-label">转交逻辑树:</div>
    <div class="tree-info-txt">
      <div>请确认，是否将逻辑树 {{ treeCodeToString(tree) }}</div>
      <div class="tree-info-editors">
        <span>编写人由{{ editors.userName || '-' }}更改为:</span>
        <el-select v-model="user" clearable filterable placeholder="请选择">
          <el-option
            v-for="item in userAllListOptionsFilter"
            :key="item.value"
            :label="item.label"
            :value="item.value"
          />
        </el-select>
      </div>
    </div>
    <div class="tree-info-close">
      <el-button type="primary" @click="confirm">确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </el-drawer>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, unref } from 'vue'
import { useUserInfo } from '../hooks/userInfo'
import { treeCodeToString } from '@/utils'

const emits = defineEmits(['update:modelValue', 'confirm'])
const props = defineProps(['modelValue', 'tree', 'authorId'])
const user = ref('')
const { getTargetUserInfo, userAllListOptions } = useUserInfo()
const userAllListOptionsFilter = computed(() => {
  return unref(userAllListOptions).filter(item => {
    const { original } = item || {}
    return original?.roleList?.some(
      user => [35].includes(user.id)
    ) && original.id != unref(editors).id
  })
})
const editors = computed(() => {
  const { authorId } = props
  return getTargetUserInfo(authorId) || {}
})
const value = computed({
  get() {
    return props.modelValue
  },
  set(value) {
    emits('update:modelValue', value)
  }
})

const cancel = () => {
  emits('update:modelValue', false)
}
const confirm = () => {
  emits('confirm', {
    id: unref(user),
    oldEditor: unref(editors).userName
  })
  cancel()
}
</script>

<style lang="less" scoped>
.tree-info {
  &-encode {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &-relation {
    & :deep(.cell) {
      font-size: 12px;
    }
  }

  &-label {
    font-size: 14px;
    font-weight: bold;
    margin: 20px 0 10px;
  }

  &-txt {
    font-size: 12px;
    margin-top: 20px;
    margin-left: 20px;

    & > div {
      margin: 10px 0;
    }
  }

  &-editors {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;

    & > span {
      margin-right: 8px;
    }
  }

  &-close {
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
</style>